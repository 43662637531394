import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import DataTable from 'react-data-table-component';
import {useLocation} from "react-router-dom";
import {get_budget_reappropriate} from "../../helper/Api";
import {AiOutlineEye} from 'react-icons/ai';
import {Link} from 'react-router-dom';

export default function BudgetReappropriateList(filterData) {
    const {authData} = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let {state} = useLocation();
    const [data, setData] = useState(state && state.data ? state.data : []);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'View Order',
            selector: (row) => <Link to={`/add-budget-reappropriate/${row.id}`}><AiOutlineEye/></Link>,
            wrap: true,
            width: "100px",
            center: true
        },
        {
            name: 'Order No',
            selector: (row) => row.order_no,
            wrap: true,
            width: "100px"
        },
        {
            name: 'Order Date',
            selector: (row) => row.order_date,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Type of scheme',
            selector: (row) => row.type,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Financial Year',
            selector: (row) => row.financial_year,
            wrap: true,
            width: "150px"
        },
        {
            name: 'From Scheme Code',
            selector: (row) => row.code,
            wrap: true,
            width: "100px"
        },
        {
            name: 'From Scheme Name',
            selector: (row) => row.name,
            wrap: true,
            width: "300px"
        },
        {
            name: 'Order Amount',
            selector: (row) => row.order_value,
            wrap: true,
            width: "100px"
        },
        {
            name: 'Financial year',
            selector: (row) => {
                let year = '';
                row.toSubheads.map(e => {
                    year += e.financial_year;
                })
                return <>{year}</>
            },
            wrap: true,
            width: "150px"
        },
        {
            name: 'To Scheme Code',
            selector: (row) => {
                let year = '';
                row.toSubheads.map(e => {
                    year += e.code;
                })
                return <>{year}</>
            },
            wrap: true,
            width: "150px"
        },
        {
            name: 'To Scheme Name',
            selector: (row) => {
                let year = '';
                row.toSubheads.map(e => {
                    year += e.name;
                })
                return <>{year}</>
            },
            wrap: true,
            width: "300px"
        },
        {
            name: 'Reappropriate Amount',
            selector: (row) => {
                let year = '';
                row.toSubheads.map(e => {
                    year += e.reappropriation_amount;
                })
                return <>{year}</>
            },
            wrap: true,
            width: "100px"
        },
        {
            name: 'Status L2',
            selector: (row) => row.l2_approval == null ? "Pending" : row.l2_approval == 1 ? "Approved" : "Rejected",
            wrap: true,
            width: "100px"
        },
        {
            name: 'Status L3',
            selector: (row) => row.l3_approval == null ? "Pending" : row.l3_approval == 1 ? "Approved" : "Rejected",
            wrap: true,
            width: "100px"
        },
    ];

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    const handleFilterClick = async () => {
        // await fetchBeneficiary(schemeId)
        setData([]);
        await fetchChallans()
    };
    const clearFilter = async () => {
        setFromDate(''); // Clear fromDate
        setToDate('');
        await fetchChallans()
        // await fetchBeneficiary(schemeId)
    };

    const fetchChallans = async () => {
        try {
            const resp = await get_budget_reappropriate({
                from_date: filterData?.fromDate || '', 
                to_date: filterData?.toDate || '',
                search_str: filterData?.searchStr || '',
                dateColumn: filterData?.dateColumn || ''
            });
            if (resp.data.status) {
                setData(resp.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }
    // console.log('fdate', filterData.fromDate);
    useEffect(() => {
        fetchChallans();
    }, [filterData.fromDate, filterData.toDate, filterData.dateColumn, filterData.searchStr])



    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )

}