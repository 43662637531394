import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Grid, Box } from '@mui/material';
import Sidebar from './Sidebar/Sidebar';
import '../App.css';
import Tools from '../components/Tools/Tools';

const Layout = ({ children }) => {
  const [collapse, setCollapse] = useState(true);

  const toggleButton = (
    <button
    className={`${collapse? null: 'rotate'} transition`}
    onClick={() => setCollapse((prev) => !prev)}
    style={{
      cursor: 'pointer',
      zIndex: 5,
      backgroundColor: "transparent",
      border: "none",
    }}
  >
  <i className={`icon-first_page fs-4 cursor-pointer text-white `} ></i>
  </button>
  )

  return (
    <div className="main_page overflow-x-hidden">
      <Box display="flex" width="100%">
        {/* Sidebar */}
        <Box
          sx={{
            width: collapse ? '16.66%' : '8.33%', 
            transition: 'width 0.3s ease-in-out',
            overflow: 'hidden', 
            position: 'sticky',
            top: 0,
            left: 0,
          }}
        >
          <Sidebar collapse={collapse} />
        </Box>

        {/* Main Content */}
        <Box
          sx={{
            width: collapse ? '83.33%' : '91.67%', 
            transition: 'width 0.3s ease-in-out',
          }}
          className="overflow-x-hidden"
        >
          <Tools toggleButton={toggleButton}></Tools>

          {children}
          <Outlet />
        </Box>
      </Box>
    </div>
  );
};

export default Layout;
