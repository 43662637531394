import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import './sanctionOrderModal.css';

const AddChallanPreview = ({showPreview, setShowPreview, formData, snaName, headAccount, handleSubmit}) => {
    // console.log(formData)

    const handleClose = () => setShowPreview(false);

    return (
        <div>
            <Modal
                size="lg"
                show={showPreview} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Challan Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className='previewModalBody'>
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <th>Account Name</th>
                            <th>Account No</th>
                            <th>From Date</th>
                            <th>To Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>{formData.account_name}</td>
                            <td>{formData.account_no}</td>
                            <td>{formData.from_date}</td>
                            <td>{formData.to_date}</td>
                        </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Change
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        SUBMIT
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddChallanPreview
