import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import DataTable from 'react-data-table-component';
import {useLocation} from "react-router-dom";
import {getGSTReports} from "../../helper/Api";
import {AddCommasToAmount} from "../../helper/Utils";
import {AiOutlineEye} from 'react-icons/ai';

const GSTReportList = (filterDate) => {
    const {authData} = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let {state} = useLocation();
    const [data, setData] = useState(state && state.data ? state.data : []);
    const [vendor, setVendorData] = useState('');

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Beneficiary\n' +
                'Name',
            selector: (row) => <b>{row.company_name || ''}</b>,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Voucher No',
            selector: (row) => <b>{row.voucher_no}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Voucher\n' +
                'Date',
            selector: (row) => <b>{row.voucher_date}</b>,
            sortable: true,
            width: "150px",
            right: false
        },

        {
            name: 'INVOICE\n' +
                'AMOUNT',
            selector: (row) => <b>{row.payable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Taxable\n' +
                'Amount',
            selector: (row) => <b>{row.taxable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'IGST Amount',
            selector: (row) => <b>{row?.i_gst_amount}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'SGST Amount',
            selector: (row) => <b>{row?.s_gst_amount}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'CGST Amount',
            selector: (row) => <b>{row?.c_gst_amount}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        // {
        //     name: 'Code',
        //     selector: (row) => <b>{row?.code}</b>,
        //     sortable: true,
        //     width: "150px",
        //     right: false
        // },{
        //     name: 'BSR Code',
        //     selector: (row) => <b>{row?.bsr_code}</b>,
        //     sortable: true,
        //     width: "150px",
        //     right: false
        // },
        {
            name: 'Challan Date',
            selector: (row) => <b>{row?.challan_date}</b>,
            sortable: true,
            width: "150px",
            right: false
        },{
            name: 'Challan No',
            selector: (row) => <b>{row?.challan_no}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Beneficiary GST',
            selector: (row) => <b>{row.gst_no}</b>,
            sortable: true,
            width: "200px",
            wrap: true

        },
        {
            name: 'View Challan',
            width: "200px",
            selector: (row) => row?.challan_url ?
                <a href={row?.challan_url} target="_blank" rel="noreferrer"><AiOutlineEye/></a> : '',
            sortable: false,
        },
    ];

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };


    const calculateTotalAmount = (propertyName) => {
        let total = 0;
        data.forEach((row) => {
            const depositAmount = Number(parseFloat(row[propertyName]));
            if (!isNaN(depositAmount)) {
                total += depositAmount;
            }
        });
        return total.toFixed(2);
    };

    const totalInvoiceAmount = calculateTotalAmount('i_gst_amount');
    const totalCInvoiceAmount = calculateTotalAmount('c_gst_amount');
    const totalSInvoiceAmount = calculateTotalAmount('s_gst_amount');

    const footerRow = {
        isFooter: true,
        selectableRowsSingle: false,
        voucher_no: <b>Total</b>,
        i_gst_amount: <b>{AddCommasToAmount(totalInvoiceAmount)}</b>,
        c_gst_amount: <b>{AddCommasToAmount(totalCInvoiceAmount)}</b>,
        s_gst_amount: <b>{AddCommasToAmount(totalSInvoiceAmount)}</b>,
    };

    const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const fetchChallans = async () => {
        try {

            const resp = await getGSTReports({
                from_date: filterDate?.fromDate || '', 
                to_date: filterDate?.toDate || '',
                searchStr: filterDate?.searchStr,
                dateColumn: filterDate?.dateColumn
            });
            if (resp.data.status) {
                setData(resp.data.list);
            }
        } catch (error) {
            setData([]);
        }
    }

    // useEffect(() => {
    //     fetchChallans();

    // }, [])

    useEffect(() => {
        fetchChallans();
    }, [filterDate.fromDate, filterDate.toDate, filterDate.searchStr, filterDate.dateColumn]);



    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}

export default GSTReportList
