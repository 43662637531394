import React from 'react'
import Tools from "../../components/Tools/Tools"
import EditUserForm from '../../components/Users/EditUserForm'

const EditUser = () => {
  return (
    <div>
      {/* <Tools></Tools> */}
      <EditUserForm></EditUserForm>
    </div>
  )
}

export default EditUser