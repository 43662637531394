import React, { useEffect, useState } from "react";
import { get_financial_year, getSchemeAccountBalances } from "../../helper/Api";
import moment from "moment";

const BankBalanceOverlay = ({openOverlay, bankBalanceOverlays, fetchBankBalance}) => {
    const [bankBalanceOverlay, setBankBalanceOverlay] = useState(false);
    const [fy_list, set_fy_list] = useState([]);
    const storedGlobalDateId = localStorage.getItem("globalDateId");
    const [accountBalances, setAccountBalances] = useState([]);
    const [bankOverlayDropDown, setBankOverlayDropDown] = useState(false);
    const [listview, setListview] = useState(true);
    
  const formatNumber = (number, style = "international") => {
    number = parseFloat(number).toFixed(2); // Ensure input is a float with two decimal places

    if (style === "indian") {
      return number.replace(/\B(?=(\d{3})(\d{2})*(?!\d))/g, ",");
    } else {
      return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  const openBankBalanceOverlay = (value) => {
    setBankBalanceOverlay(value);
    bankBalanceOverlays(value)
  };
  useEffect(()=>{
    setBankBalanceOverlay(openOverlay)
  },[openOverlay])

    const get_balance_interest = async () => {
      const accountData = await getSchemeAccountBalances();
      if (accountData?.data?.status) {
        setAccountBalances(accountData?.data?.result);
        fetchBankBalance(accountData?.data?.result)
        // console.log(accountData?.data?.result)
      }
    };
      useEffect(() => {
        get_balance_interest();
      }, [storedGlobalDateId]);

  const handleYearDropdown = (e) => {
    console.log(e.target.value);
  };
  const get_balance_interest_date = async () => {
    try {
      const fy = await get_financial_year();
      if (fy.data.status) {
        set_fy_list(fy.data.list);
      }
    } catch (error) {
      console.log(error.name);
    }
  };
    useEffect(() => {
      get_balance_interest_date();
    }, [storedGlobalDateId]);

  return (
    <div>
      {/* =====================bank balance overlay========================== */}

      <div
        className={`bank-balance-overlay ${
          bankBalanceOverlay ? "d-none" : null
        }`}
        // onClick={() => openBankBalanceOverlay(true)}
      ></div>

      <div
        className={`bank-balance-overlay-flow overflow-x-hidden  ${
          bankBalanceOverlay ? "left-100" : null
        } `}
      >
        <p className="bg-secondary-100 position-relative text-white p-3 fw-600 fs-18px d-flex justify-content-between align-items-center">
          <div className="d-flex gap-1 align-items-center ">
            <span>
              <i
                className="icon-close_circle_fill cursor-pointer text-white"
                onClick={() => openBankBalanceOverlay(true)}
              ></i>
            </span>
            <span className=" fw-500">Bank Balance FY </span>
            <select
              name="financial_year_id"
              onChange={handleYearDropdown}
              className="bg-transparent border-0 text-white fw-500 fs-16px select"
              disabled
            >
              <option value="" className="text-black">
                select year
              </option>
              {fy_list?.map((fy) => {
                return (
                  <option
                    value={storedGlobalDateId}
                    key={fy.id}
                    className="text-black"
                    selected={
                      String(fy.id) === storedGlobalDateId ||
                      (!storedGlobalDateId &&
                        fy.start_year === moment().format("YYYY"))
                    }
                  >
                    {fy.year}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="d-flex gap-2 align-items-center">
            <span className="fw-500">
              As on date{" "}
              {
                <select
                  name="financial_year_id"
                  className="bg-transparent border-0 text-white fw-500 fs-16px select"
                  disabled
                >
                  <option value="" className="text-black">
                    select year
                  </option>
                  {fy_list?.map((fy) => {
                    return (
                      <option
                        value={storedGlobalDateId}
                        key={fy.id}
                        className="text-black"
                        selected={
                          String(fy.id) === storedGlobalDateId ||
                          (!storedGlobalDateId &&
                            fy.start_year === moment().format("YYYY"))
                        }
                      >
                        {fy.end_year === moment().format("YYYY")
                          ? moment().format("Do MMM YYYY")
                          : "31 Mar " + fy.end_year}
                      </option>
                    );
                  })}
                </select>
              }
            </span>
            <i
              className="icon-arrow_drop_down_circle fs-18px cursor-pointer"
              onClick={() => setBankOverlayDropDown((prev) => !prev)}
            ></i>
          </div>
          <div
            className={`position-absolute bg-prime-100 w-100 p-3 ${
              !bankOverlayDropDown ? "d-none" : "d-inline-block"
            }`}
            style={{ top: "100%", left: "70%", zIndex: "10" }}
          >
            <div className="d-flex flex-column gap-4">
              <span className="d-flex align-items-center gap-2">
                <i className="icon-featured_play_list"></i> Export Excel
              </span>
              <span className="d-flex align-items-center gap-2">
                <i className="icon-picture_as_pdf"></i> Export PDF
              </span>
              <span
                className="d-flex align-items-center gap-2 cursor-pointer"
                onClick={() => setListview(false)}
              >
                <i className="icon-view_list"></i> List View
              </span>
              <span
                className="d-flex align-items-center gap-2 cursor-pointer"
                onClick={() => setListview(true)}
              >
                <i className="icon-grid_on"></i> Grid View
              </span>
            </div>
          </div>
        </p>

        <div className="overflow-auto px-3 fs-14px m-0 h-100">
          <div className="row bg-prime-100 p-0 m-0 py-2 px-2">
            <div className="col-3 p-0 m-0 text-white fs-14px">SNA Account</div>
            <div className="col-3 p-0 m-0 text-white fs-14px">Account No.</div>
            <div className="col-3 p-0 m-0 text-white fs-14px">Balance</div>
            <div className="col-3 p-0 m-0 text-white fs-14px">Interest</div>
          </div>

          {accountBalances?.map((val, ind) => (
            <div className="row p-0 m-0 py-2 px-2 border-bottom">
              <div className="col-3 p-0 m-0 fs-14px">
                <div>{val?.account_name}</div>
                <div className="d-flex gap-1">
                  {val?.l2_approval == 0 ? (
                    <button className="fs-12px border-0 rounded-1 px-2 py-1 d-flex gap-1 align-items-center bg-danger-20">
                      <span className="d-flex align-items-center gap-1">
                        l2
                        <i className="icon-close_circle_stroke fs-14px m-0 p-0 text-danger-100"></i>
                      </span>
                    </button>
                  ) : val?.l2_approval == 1 ? (
                    <button className="fs-12px border-0 rounded-1 px-2 py-1 d-flex gap-1 align-items-center bg-success-20">
                      <span className="d-flex align-items-center gap-1">
                        l2
                        <i className="icon-verified fs-14px m-0 p-0 text-success-100"></i>
                      </span>
                    </button>
                  ) : (
                    <button className="fs-12px border-0 rounded-1 px-2 py-1 d-flex gap-1 align-items-center bg-warning-20">
                      <span className="d-flex align-items-center gap-1">
                        l2
                        <i className="icon-warning_amber text-warning-100 fs-14px m-0 p-0"></i>
                      </span>
                    </button>
                  )}
                  {val?.l3_approval == 0 ? (
                    <button className="fs-12px border-0 rounded-1 px-2 py-1 d-flex gap-1 align-items-center bg-danger-20">
                      <span className="d-flex align-items-center gap-1">
                        l3
                        <i className="icon-close_circle_stroke fs-14px m-0 p-0 text-danger-100"></i>
                      </span>
                    </button>
                  ) : val?.l3_approval == 1 ? (
                    <button className="fs-12px border-0 rounded-1 px-2 py-1 d-flex gap-1 align-items-center bg-success-20">
                      <span className="d-flex align-items-center gap-1">
                        l3
                        <i className="icon-verified fs-14px m-0 p-0 text-success-100"></i>
                      </span>
                    </button>
                  ) : (
                    <button className="fs-12px border-0 rounded-1 px-2 py-1 d-flex gap-1 align-items-center bg-warning-20">
                      <span className="d-flex align-items-center gap-1">
                        l3
                        <i className="icon-warning_amber text-warning-100 fs-14px m-0 p-0"></i>
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <div className="col-3 p-0 m-0 fs-14px">{val?.account_no}</div>
              <div className="col-3 p-0 m-0 fs-14px">
                <div className="fw-600 ">
                  {formatNumber(val?.account_balance, "indian")}
                </div>
                <div className="fs-12px fst-italic">
                  as on {val?.balance_date}
                </div>
              </div>
              <div className="col-3 p-0 m-0 fs-14px">
                <div className="row">
                  <div className="col-6 m-0 p-0 border-end">
                    Q1 :{" "}
                    {val?.quarterlyInterest?.map((val, ind) =>
                      val?.quarter == "1" ? val?.amount : "0"
                    )}
                  </div>
                  <div className="col-6 m-0 p-0 ps-2">
                    Q2 :{" "}
                    {val?.quarterlyInterest?.map((val, ind) =>
                      val?.quarter == "2" ? val?.amount : "0"
                    )}
                  </div>
                  <div className="col-6 m-0 p-0 border-end">
                    Q3 :{" "}
                    {val?.quarterlyInterest?.map((val, ind) =>
                      val?.quarter == "3" ? val?.amount : "0"
                    )}
                  </div>
                  <div className="col-6 m-0 p-0 ps-2">
                    Q4 :{" "}
                    {val?.quarterlyInterest?.map((val, ind) =>
                      val?.quarter == "4" ? val?.amount : "0"
                    )}
                  </div>
                </div>
                <div className="text-end text-success-100 fs-14px fw-500">
                  Total :{" "}
                  {val?.quarterlyInterest?.reduce(
                    (sum, item) =>
                      item?.quarter ? sum + (item?.amount || 0) : sum,
                    0
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BankBalanceOverlay;
