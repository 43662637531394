import React, {useEffect, useState} from 'react';
import {Row} from 'react-bootstrap';
import {Col} from 'react-bootstrap';
import {Form} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import {ADD_CHALLAN_FAIL, ADD_CHALLAN_START} from "../../RTK/Slices/ChallanSlice";
import Button from 'react-bootstrap/Button';
import {
    get_beneficiary,
    addBeneficiaryAccount
} from '../../helper/Api';
import {toast} from 'react-toastify';

export default function AddBeneficiaryAccount() {
    const {authData} = useSelector((state) => state.authData);
    const {id} = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        id: '',
        beneficiary_id: id,
        beneficiary_name: '',
        project_name: '',
        bank_name: '',
        branch_name: '',
        account_no: '',
        ifsc_code: '',
        created_by: authData && authData.status ? authData.user.id : null
    });

    const handleFormDataChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value);
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const handleSubmit = async (e) => {
        if (!id) {
            if (e && typeof e.preventDefault === 'function') {
                e.preventDefault();
            }
        }
        try {
            console.log(formData);
            if(formData.beneficiary_name.trim() !== '' && formData.project_name.trim() !== '' && formData.bank_name.trim() !== '' && formData.branch_name.trim() !== '' && formData.account_no.trim() !== '' && formData.ifsc_code.trim() !== ''){
            const post_data = new FormData();
            post_data.append('beneficiary_id', formData.beneficiary_id);
            post_data.append('beneficiary_name', formData.beneficiary_name);
            post_data.append('project_name', formData.project_name);
            post_data.append('bank_name', formData.bank_name);
            post_data.append('branch_name', formData.branch_name);
            post_data.append('account_no', formData.account_no);
            post_data.append('ifsc_code', formData.ifsc_code);
            dispatch(ADD_CHALLAN_START());
            const response = await addBeneficiaryAccount(post_data);
            let addAccountData = response.data;
            if (addAccountData.status) {
                // setShowPreview(false);
                // dispatch(ADD_CHALLAN_SUCCESS(addDepositoryResponseData.list[0]));
                navigate(`/beneficiary/${id}`);
            } else {
                toast.error(addAccountData.message, {
                    position: toast.POSITION.TOP_LEFT
                });
                dispatch(ADD_CHALLAN_FAIL(addAccountData.message));
            }
        }else{
            toast.error('Please fill all required Fields', {
                position: toast.POSITION.TOP_CENTER
            });
            // dispatch(ADD_CHALLAN_FAIL(addAccountData.message));
        }
        }catch(e) {
            dispatch(ADD_CHALLAN_FAIL('error getting while adding beneficary account'));
        }
    }

    const previewHandler = (e) => {
        e.preventDefault();
      };

return (
    <>
    <Form onSubmit={previewHandler}>
        <div className="add_new_user mt-2">
            <h4> Add Beneficiary</h4>
            <div>
                <Button variant="primary" onClick={handleSubmit}> Submit </Button>
            </div>
        </div>
        <div className="add_new_user mt-2">
            <Row>
            <Col md="6" className="mb-4">
                    <Row>
                        <Col sm="4">
                            <Form.Label htmlFor="project_name">Project Name </Form.Label>
                            <span className="text-danger"> *</span> :
                        </Col>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                name='project_name'
                                id="project_name"
                                maxLength={50}
                                onChange={(e) => handleFormDataChange(e)}
                                placeholder='Enter Project Name'
                                required
                                aria-describedby=""/>
                        </Col>
                    </Row>
                </Col>
                <Col md="6" className="mb-4">
                    <Row>
                        <Col sm="4">
                            <Form.Label htmlFor="beneficiary_name">Beneficiary Name </Form.Label>
                            <span className="text-danger"> *</span> :
                        </Col>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                name='beneficiary_name'
                                id="beneficiary_name"
                                maxLength={50}
                                onChange={(e) => handleFormDataChange(e)}
                                placeholder='Enter Beneficiary Name'
                                aria-describedby=""/>
                        </Col>
                    </Row>
                </Col>

                <Col md="6" className="mb-4">
                    <Row>
                        <Col sm="4">
                            <Form.Label htmlFor="bank_name">Bank Name </Form.Label>
                            <span className="text-danger"> *</span> :
                        </Col>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                id="bank_name"
                                maxLength={50}
                                onChange={(e) => handleFormDataChange(e)}
                                name='bank_name'
                                placeholder='Enter Bank Name'
                                aria-describedby=""/>
                        </Col>
                    </Row>
                </Col>

                <Col md="6" className="mb-4">
                    <Row>
                        <Col sm="4">
                            <Form.Label htmlFor="branch_name">Branch Name </Form.Label>
                            <span className="text-danger"> *</span> :
                        </Col>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                name='branch_name'
                                maxLength={50}
                                onChange={(e) => handleFormDataChange(e)}
                                id="branch_name"
                                placeholder='Enter Branch Name'
                                aria-describedby=""/>
                        </Col>
                    </Row>
                </Col>


                <Col md="6" className="mb-4">
                    <Row>
                        <Col sm="4">
                            <Form.Label htmlFor="account_no">Account No </Form.Label>
                            <span className="text-danger"> *</span> :
                        </Col>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                maxLength={20}
                                onChange={(e) => handleFormDataChange(e)}
                                id="account_no"
                                name='account_no'
                                placeholder='Enter Account No'
                                aria-describedby=""/>
                        </Col>
                    </Row>
                </Col>

                <Col md="6" className="mb-4">
                    <Row>
                        <Col sm="4">
                            <Form.Label htmlFor="ifsc_code">IFSC Code </Form.Label>
                            <span className="text-danger"> *</span> :
                        </Col>
                        <Col sm="8">
                            <Form.Control
                                type="text"
                                id="ifsc_code"
                                name='ifsc_code'
                                maxLength={11}
                                onChange={(e) => handleFormDataChange(e)}
                                placeholder='Enter IFSC Code'
                                aria-describedby=""/>
                        </Col>
                    </Row>
                </Col>

                <Col md="6" className="mb-4"></Col>
            </Row>
        </div>
    </Form>
    </>
)
}