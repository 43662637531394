import React, { useEffect, useState, useRef } from 'react'
import './AddBudgetForm.css'
import { Alert, Form, InputGroup } from 'react-bootstrap';
import {
    get_financial_year,
    get_scheme_type,
    get_schemes,
    add_budget,
    get_budget_list,
    update_budget_by_id,
    getActivity, getSubActivity
} from '../../../helper/Api';
import { useSelector } from 'react-redux';
import { FaUpload } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import { DeleteForever } from '@mui/icons-material';
import { AddCommasToAmount } from '../../../helper/Utils';
import { toast } from 'react-toastify';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
const EditBudgetForm = () => {
    const { id } = useParams();
    const { authData } = useSelector((state) => state.authData);
    const [fy_list, set_fy_list] = useState([]);
    const [st_list, set_st_list] = useState([]);
    const [schemes, set_schemes] = useState([]);
    const [activity, setActivity] = useState([]);
    const [subActivity, setSubActivity] = useState([]);
    const [min_max_date, setMinMaxDate] = useState({
        min: null,
        max: null,
    });
    const [subheadsList, set_subheadsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const sid = useRef('');
    const [formData, setFormData] = useState({
        "id": "",
        "subhead_id": "",
        "l2remarks": "",
        "l3remarks": "",
        "scheme_id": "",
        "financial_year": "",
        "budget_order_no": "",
        "budget_order_date": "",
        "budget": "",
        "provisional_budget": "",
        "budget_date": "",
        "subhead_code": "",
        "subhead_name": "",
        "scheme_code": "",
        "scheme_name": "",
        "attachments": [],
        "activity": [],
    });

    const navigate = useNavigate();

    const fetch_list = async () => {
        const fy = await get_financial_year();
        const st = await get_scheme_type();
        const schemes_list = await get_schemes();
        if (schemes_list.data.status) {
            set_schemes(schemes_list.data.schemes);
            setTimeout(() => {
                if (sid.current) {
                    const filter = schemes_list.data.schemes.filter((scheme) => scheme.id == sid.current);
                    if (filter.length > 0) {
                        set_subheadsList(filter[0].sub_head_list);
                    } else {
                        set_subheadsList([]);
                    }
                }
            }, 0)
        } else {
            set_schemes([]);
        }
        if (fy.data.status) {
            set_fy_list(fy.data.list)
        }
        if (st.data.status) {
            set_st_list(st.data.list)
        }
    }

    useEffect(() => {
        fetchActivity();
        fetchSubActivity();
        get_budget_details();
        fetch_list();
    }, [])


    const get_budget_details = async () => {
        const { data } = await get_budget_list(null, id);
        if (data.status) {
            let activities = [];
            if (data.budgets[0].activities.length != 0) {
                activities = data.budgets[0].activities.map(activity => {
                    return {
                        id: activity.id,
                        subhead_activity_id: activity.subhead_activity_id,
                        subhead_subactivity_id: activity.subhead_subactivity_id,
                        provisional_budget: activity.provisional_budget,
                        budget: activity.budget,
                    };
                });
            }
            setFormData({ ...data.budgets[0], provisional_budget: data.budgets[0].subhead_provisional_budget, activity: activities });
            sid.current = data.budgets[0].scheme_id;

        } else {
            setFormData([]);
        }
    }

    const get_schemeName = (id) => {
        if (id) {
            const filter = schemes.filter((scheme) => scheme.id == id);
            if (filter.length > 0) {
                return filter[0].name;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    const get_subheadName = (id) => {
        if (id) {
            const filter = subheadsList.filter((subhead) => subhead.id == id);
            if (filter.length > 0) {
                return filter[0].name;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }


    const handleSchemFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
        if (name == 'scheme_id') {
            if (value) {
                const filter = schemes.filter((scheme) => scheme.id == value);
                if (filter.length > 0) {
                    set_subheadsList(filter[0].sub_head_list);
                } else {
                    set_subheadsList([]);
                }
            } else {
                set_subheadsList([]);
            }
        } else if (name == 'financial_year') {
            const filter = fy_list.filter((fy) => fy.id == value);
            if (filter.length > 0) {
                setMinMaxDate({
                    min: `${filter[0].start_year}-04-01`,
                    max: `${filter[0].end_year}-03-31`
                })
            } else {
                setMinMaxDate({
                    min: null,
                    max: null
                })
            }
        }
    }

    const handleAttachmentChange = (event) => {
        // const files = event.target.files;
        const files = Array.from(event.target.files);
        setFormData({
            ...formData,
            attachments: files
        });
            };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const post_data = new FormData();
        for (let i = 0; i < formData.attachments.length; i++) {
            if (formData.attachments[i]['name']) {
                post_data.append('attachment[]', formData.attachments[i]);
            }
        }
        post_data.append('id', formData.id);
        post_data.append('subhead_id', formData.subhead_id);
        post_data.append('scheme_id', formData.scheme_id);
        post_data.append('financial_year', formData.financial_year);
        post_data.append('budget', formData.budget);
        post_data.append('budget_order_no', formData.budget_order_no);
        post_data.append('budget_order_date', formData.budget_order_date);
        post_data.append('provisional_budget', formData.provisional_budget);
        post_data.append('budget_date', formData.budget_date);
        post_data.append('added_by', authData.user.id);
        post_data.append('activities', JSON.stringify(formData.activity));
        try {
            setLoading(true);
            const budgetResponse = await update_budget_by_id(post_data);
            const budgetResponseData = budgetResponse.data;
            if (budgetResponseData.status) {
                setLoading(false);
                toast.success(budgetResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                navigate('/budgets');
            } else {
                setLoading(false);
                toast.error(budgetResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        } catch (error) {
            setLoading(false);
            toast.error("Something went wrong.Code Error.", {
                position: toast.POSITION.TOP_CENTER
            });
            console.log(error)
        }
    }

    const total_scheme_budget = () => {
        const subheads = formData.sub_heads;
        let total_budget = 0;
        subheads.map((sub) => {

            if (sub.budget) {
                total_budget = total_budget + parseFloat(sub.budget)
            }
            return {}
        })
        return AddCommasToAmount(total_budget.toFixed(2));
    }
    const fetchActivity = async () => {
        try {
            const resp = await getActivity();
            if (resp.data.status) {
                setActivity(resp.data.result);
            }
        } catch (error) {
            setActivity([]);
        }
    }
    const fetchSubActivity = async () => {
        try {
            const resp = await getSubActivity();
            if (resp.data.status) {
                setSubActivity(resp.data.result);
            }
        } catch (error) {
            setSubActivity([]);
        }
    }
    const findSubActivitiesById = (id) => {
        // console.log('sctivityid', id);
        let listData = subActivity;
        return listData.filter(subActivity => subActivity.activity_id === id);
    };
    const handleActivityChange = (activityIndex, e, activityId) => {
        const { name, value } = e.target;
        if (activityId) {
            const activityValid = formData.activity.find(item => item.subactivity_id === value && item.activity_id === activityId);
            if (activityValid) {
                return alert('Sub-activity already selected');
            }
        }
        setFormData(prevFormData => {
            const updatedActivities = prevFormData.activity.map((activity, aIdx) => {
                if (aIdx === activityIndex) {
                    const updatedActivity = {
                        ...activity,
                        [name]: value
                    };
                    if (name === 'activity_id') {
                        updatedActivity.subactivity_id = '';
                    }
                    return updatedActivity;
                }
                return activity;
            });
            return {
                ...prevFormData,
                activity: updatedActivities
            };
        });
    };
    const handleSubheadBudget = (activityIndex, e) => {
        const { name, value } = e.target;
        setFormData(prevFormData => {
            const updatedActivities = prevFormData.activity.map((activity, aIdx) => {
                if (aIdx === activityIndex) {
                    const updatedActivity = {
                        ...activity,
                        [name]: value
                    };
                    return updatedActivity;
                }
                return activity;
            });
            return {
                ...prevFormData,
                activity: updatedActivities
            };
        });
        // console.log(formData.sub_heads);
    };
    const addNewActivity = () => {
        const newActivity = { subhead_activity_id: '', subhead_subactivity_id: '', provisional_budget: 0, budget: 0 };
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                activity: [...prevFormData.activity, newActivity] // Push new activity to the end
            };
        });
    };
    const deleteNewActivity = (activityIndex) => {
        setFormData(prevFormData => {
            const updatedActivities = [...prevFormData.activity];
            updatedActivities.splice(activityIndex, 1);
            return {
                ...prevFormData,
                activity: updatedActivities
            };
        });
    };
    return (
        <div>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={handleSubmit}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4> Update Budget</h4>
                                <div>
                                    <Link to="/budgets">
                                        <button type="button" className="btn btn-light">CANCEL</button>
                                    </Link>
                                    <button type="submit" disabled={loading}
                                        className="btn btn-primary"> {loading ? "LOADING" : "UPDATE"}</button>
                                </div>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputDepartment"
                                                        className="col-sm-4 col-form-label">Scheme Code
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control "
                                                            id="scheme_id" name='scheme_id'
                                                            required
                                                            defaultValue={formData.scheme_id}
                                                            onChange={handleSchemFormData}>
                                                            <option value="">Select Scheme Code</option>
                                                            {
                                                                schemes.map((schemes) => {
                                                                    return (
                                                                        schemes.l2_status == 1 && schemes.l3_status == 1 &&
                                                                        <option value={schemes.id}
                                                                            key={schemes.id}
                                                                            selected={formData.scheme_id == schemes.id}>{schemes.code}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">
                                                        Scheme Name
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <label htmlFor="">{get_schemeName(formData.scheme_id)}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-6 p-2'>
                                                <div className="form-group row">
                                                    <label for="inputFinancial"
                                                        className="col-sm-4 col-form-label">
                                                        Financial Year
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control " id="financial_year"
                                                            name='financial_year'
                                                            onChange={handleSchemFormData}
                                                            required
                                                        >
                                                            <option value="">---select year---</option>
                                                            {
                                                                fy_list.map((fy) => {
                                                                    return (
                                                                        <option value={fy.id} key={fy.id}
                                                                            selected={formData.financial_year == fy.id}>{fy.year}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-sm-6 p-2'></div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="inputBudget"
                                                        className="col-sm-4 col-form-label">Budget Order Date
                                                        :</label>
                                                    <div className="col-sm-8 ">
                                                        <Form.Control
                                                            type="date"
                                                            name="budget_order_date"
                                                            onChange={handleSchemFormData}
                                                            value={formData.budget_order_date}
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="inputBudget"
                                                        className="col-sm-4 col-form-label">Budget Order No.
                                                        :</label>
                                                    <div className="col-sm-8">
                                                        <input type="text"
                                                            className="form-control"
                                                            id="budget_order_no"
                                                            name="budget_order_no"
                                                            placeholder="Enter Budget Order No."
                                                            onChange={handleSchemFormData}
                                                            value={formData.budget_order_no}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='container-fluid'>
                                                <div className='row eachsubheadBlock'>
                                                    <div className="col-sm-6 p-2">
                                                        <div className="form-group row">
                                                            <label htmlFor="subhead_id"
                                                                className="col-sm-4 col-form-label">Sub Head Code
                                                                <span className="text-danger">*</span> :
                                                            </label>
                                                            <div className="col-sm-8">
                                                                <select className="form-control "
                                                                    id="subhead_id" name='subhead_id'
                                                                    required
                                                                    onChange={handleSchemFormData}
                                                                >
                                                                    <option value="">---Select Sub Head Code---</option>
                                                                    {
                                                                        subheadsList.map((subhead) => {
                                                                            return (
                                                                                <option value={subhead.id}
                                                                                    key={subhead.id}
                                                                                    selected={formData.subhead_id == subhead.id}>{subhead.code}</option>
                                                                            );
                                                                        })
                                                                    }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-2">
                                                        <div className="form-group row">
                                                            <label for="inputHead" className="col-sm-4 col-form-label">Sub
                                                                Head Name
                                                                <span className="text-danger">*</span> :</label>
                                                            <div className="col-sm-8 col-form-label">
                                                                <label>{get_subheadName(formData.subhead_id)}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-2">
                                                        <div className="form-group row">
                                                            <label htmlFor="budget_date"
                                                                className="col-sm-4 col-form-label">
                                                                Budget Date
                                                                <span className="text-danger">*</span> :</label>
                                                            <div className="col-sm-8 ">
                                                                <Form.Control
                                                                    type="date"
                                                                    onChange={handleSchemFormData}
                                                                    max={min_max_date.max}
                                                                    min={min_max_date.min}
                                                                    name="budget_date"
                                                                    id='budget_date'
                                                                    value={formData.budget_date}

                                                                />

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-2"></div>
                                                    <div className="col-sm-6 p-2">
                                                        <div className="form-group row">
                                                            <label htmlFor="provisional_budget"
                                                                className="col-sm-4 col-form-label">Provisional
                                                                Budget
                                                                :</label>
                                                            <div className="col-sm-8">
                                                                <input type="number"
                                                                    value={formData.provisional_budget}
                                                                    className="form-control" id="provisional_budget"
                                                                    name='provisional_budget'
                                                                    onChange={handleSchemFormData}
                                                                    placeholder="₹ Enter Provisional Budget Amount" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 p-2">
                                                        <div className="form-group row">
                                                            <label htmlFor="budget" className="col-sm-4 col-form-label">Release Budget
                                                                :</label>
                                                            <div className="col-sm-8">
                                                                <input type="text"
                                                                    value={formData.budget}
                                                                    name='budget'
                                                                    id="budget"
                                                                    onChange={handleSchemFormData}
                                                                    className="form-control"
                                                                    placeholder="₹ Enter Budget Amount" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {formData.is_activity_active=="1" && formData.monitoring_level == '1' && <div className="row">
                                                        <table className="table table-bordered">
                                                            <thead className="text-center-custom">
                                                                <tr>
                                                                    <th scope="col">Activity</th>
                                                                    <th scope="col">Subactivity</th>
                                                                    <th scope="col">Provision budget</th>
                                                                    <th scope="col">Release budget</th>
                                                                    <th scope="col">Action</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody className="text-center-custom">
                                                                {formData.activity.map((activityData, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {/* {activityData.activity_id} */}
                                                                            <select
                                                                                className="form-control"
                                                                                name='subhead_activity_id'
                                                                                value={activityData.subhead_activity_id ? activityData.subhead_activity_id : ""}
                                                                                onChange={(e) => handleActivityChange(index, e)}>
                                                                                <option value="">---Select---</option>
                                                                                {activity.map(e => (
                                                                                    <option key={e.id} value={e.id}>{e.activity}</option>
                                                                                ))}
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            {/* {activityData.subactivity_id} */}
                                                                            <select
                                                                                className="form-control"
                                                                                name='subhead_subactivity_id'
                                                                                value={activityData.subhead_subactivity_id}
                                                                                onChange={(e) => handleActivityChange(index, e, activityData.subhead_activity_id)}>
                                                                                <option value="">---Select---</option>
                                                                                {subActivity.map(e => {
                                                                                    if (e.activity_id) {
                                                                                        if (e.activity_id == activityData.subhead_activity_id) {
                                                                                            return <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                                        }
                                                                                    } else {
                                                                                        return <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                                    }

                                                                                })}
                                                                            </select>
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                name='provisional_budget'
                                                                                placeholder="Enter Provision Budget"
                                                                                value={activityData.provisional_budget}
                                                                                onChange={(e) => handleSubheadBudget(index, e)}
                                                                            ></input>
                                                                        </td>
                                                                        <td>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                name='budget'
                                                                                placeholder="Enter Release Budget"
                                                                                value={activityData.budget}
                                                                                onChange={(e) => handleSubheadBudget(index, e)}
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            <button type="button" className="btn btn-primary mx-2" onClick={(e) => addNewActivity()}>
                                                                                <AddIcon />
                                                                            </button>
                                                                            <button type="button"
                                                                                className="btn btn-danger mx-2"
                                                                                onClick={(e) => deleteNewActivity(index)}>
                                                                                <DeleteForever /></button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="inputBranch" className="col-sm-4 col-form-label">L2
                                                        Remark
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <InputGroup>
                                                            <Form.Control as="textarea" aria-label="With textarea"
                                                                name='l2remarks'
                                                                id="l2remarks"
                                                                disabled={authData.user.user_type !== 'l2'}
                                                                value={formData.l2remarks}>
                                                            </Form.Control>
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="inputBranch" className="col-sm-4 col-form-label">L3
                                                        Remark
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <InputGroup>
                                                            <Form.Control as="textarea" aria-label="With textarea"
                                                                name='l3remarks'
                                                                id="l3remarks"
                                                                disabled={authData.user.user_type !== 'l3'}
                                                                value={formData.l3remarks}>
                                                            </Form.Control>
                                                        </InputGroup>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-sm-12 p-2">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="input-group">
                                                            <label className="input-group-btn my-0">
                                                                <input type="file"
                                                                    className='form-control'
                                                                    onChange={handleAttachmentChange}
                                                                    name='attachments'
                                                                    accept=".pdf,.jpeg,.jpg,.png" multiple />
                                                            </label>
                                                        </div>
                                                        <div className='mt-2'>
                                                            {
                                                                formData?.attachments.length!=0 && formData?.attachments.map((att, i) => {
                                                                    return (<a href={att.file_url}
                                                                        target='_blank'>Attachment {i + 1}</a>);
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="col-sm-12 p-2">
                                            <div className="border mt-3">
                                                <div className="p-1 pt-2 pb-2">
                                                    <p><b>Total Scheme Budget </b>: 
                                                    {total_scheme_budget()}
                                                    </p>
                                                </div>
                                            </div>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default EditBudgetForm
