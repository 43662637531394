import React from 'react';
import Tools from "../../components/Tools/Tools"
import AddAuthority from '../../components/Authority/AddAuthority';

const AddAuthorityPage = () => {
  return (
    <div>
      <Tools/>
      <AddAuthority />
    </div>
  )
}

export default AddAuthorityPage
