import React from 'react';
import Tools from "../../../components/Tools/Tools"
import AddSubactivity from '../../../components/Multi/Department-Subcategory/AddSubactivity';

const AddSubactivityPage = () => {
  return (
    <div>
      <AddSubactivity />
    </div>
  )
}

export default AddSubactivityPage
