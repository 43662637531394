import React from 'react';
import Tools from "../../../components/Tools/Tools"
import AllocateBudget from '../../../components/Multi/BudgetAllocation/AllocateBudget';

const AllocateBudgetPage = () => {
  return (
    <div>
      <AllocateBudget />
    </div>
  )
}

export default AllocateBudgetPage
