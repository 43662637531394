import React, { useState, useRef, useEffect } from 'react'
import './AddUser.css';
import finger_print_img from '../../assets/images/finger_print.png';
import { Alert, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ADD_USER_START, ADD_USER_SUCCESS, ADD_USER_FAIL } from '../../RTK/Slices/UserSlice';
import {add_department_user} from '../../helper/Api';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const  DepartmentUserForm = () => {
    const { authData } = useSelector((state) => state.authData);
    const { loading } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        code: null,
        user_name: null,
        designation: null,
        mobile: null,
        email: null,
        added_by: authData.user.id,
        department_level: authData.user.department_details.department_level,
        department_id: authData.user.department_id,
    });
    useEffect(() => {
    
    }, [])
    const handleFormDataChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'department_level') {
            setFormData({ ...formData, [name]: value, t2_department_id: null, t3_department_id: null, t4_department_id: null })
        } else {
            setFormData({ ...formData, [name]: value })
        }
        if (name === 'department_level') {
            // setDepartment([])
        }
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(formData);
        if (!validateForm()) {
            alert('Please fill in all fields correctly.');
            return;
        }
        try {
            dispatch(ADD_USER_START());
            let payload = { ...formData };
            const add_user_response = await add_department_user(payload);
            let addUserResponseData = add_user_response.data;
            if (addUserResponseData.status) {
                toast.success(addUserResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                navigate('/users');
            } else {
                toast.error(addUserResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                dispatch(ADD_USER_FAIL(addUserResponseData.message));
            }
        } catch (error) {
            toast.error("error getting while add user", {
                position: toast.POSITION.TOP_CENTER
            });
            dispatch(ADD_USER_FAIL('error getting while add user'));
        }
    }
    const validateForm = () => {
        // Validate each field individually
        console.log('formData', formData);
        if (!formData.code.trim() ||
            !formData.user_name.trim() ||
            !formData.designation.trim() ||
            !formData.mobile.trim() ||
            !formData.email.trim()) {
            return false; // If any field is empty, return false
        }
        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            return false; // If email format is invalid, return false
        }
        // Add more validation logic as needed
        return true; // All fields are valid
    };
    return (
        <div>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={handleSubmit}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h3>Register User</h3>
                                <div>
                                    <button type="button" className="btn btn-light">Cancel</button>
                                    <button type="submit" disabled={loading} className="btn btn-primary">
                                        {loading ? 'loading' : "Register User"}</button>
                                </div>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="form-group row p-2">
                                            <label for="inputEmployee" className="col-sm-4 col-form-label">Employee
                                                Code<span className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" id="inputEmployee"
                                                    name="code"
                                                    value={formData.code}
                                                    onChange={handleFormDataChange}
                                                    required
                                                    maxLength={10}
                                                    placeholder="Enter Employee Code" />
                                            </div>
                                        </div>

                                        <div className="form-group row p-2">
                                            <label for="inputUserid" className="col-sm-4 col-form-label">User Name<span
                                                className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputUserid"
                                                    name="user_name"
                                                    required
                                                    maxLength={30}
                                                    value={formData.user_name}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter User Name" />
                                            </div>
                                        </div>

                                        <div className="form-group row p-2">
                                            <label for="inputDesignation"
                                                className="col-sm-4 col-form-label">Designation<span
                                                    className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputDesignation"
                                                    name="designation"
                                                    maxLength={30}
                                                    value={formData.designation}
                                                    onChange={handleFormDataChange}
                                                    required
                                                    placeholder="Enter Designation" />
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputMobile" className="col-sm-4 col-form-label">Mobile
                                                Number<span
                                                    className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputMobile"
                                                    inputMode="numeric"
                                                    pattern="[6789][0-9]{9}"
                                                    name="mobile"
                                                    required
                                                    value={formData.mobile}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter Mobile Number" />
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputEmail" className="col-sm-4 col-form-label">Email ID<span
                                                className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="email" className="form-control"
                                                    id="inputEmail"
                                                    name="email"
                                                    required
                                                    value={formData.email}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter Email ID" />
                                            </div>
                                        </div>
                                        {/* <div className="form-group row p-2">
                                            <label for="inputPassword"
                                                className="col-sm-4 col-form-label">Password<span
                                                    className="text-danger">*</span>:</label>
                                            <div className="col-sm-8">
                                                <div className="input-group" id="show_hide_password">
                                                    <input className="form-control input_width"
                                                        type="password"
                                                        name="password"
                                                        required
                                                        pattern="[/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/]"
                                                        value={formData.password}
                                                        onChange={handleFormDataChange}
                                                        placeholder="Password" />
                                                    <span className="input-group-addon">
                                                        <a href=""><i className="fa fa-eye-slash"
                                                            aria-hidden="true" /></a>
                                                    </span>
                                                </div>
                                                {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                                                <small className="form-text text-muted">
                                                    Password must be at least 8 characters long and contain at least one
                                                    uppercase letter, one lowercase letter, one digit, and one special
                                                    character.
                                                </small>

                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}
export default DepartmentUserForm
