import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux';
import DataTable from 'react-data-table-component';
import {useLocation} from "react-router-dom";
import {getNPSReports} from "../../helper/Api";
import {AddCommasToAmount} from "../../helper/Utils";
import {AiOutlineEye} from 'react-icons/ai';

const NPSReportList = (filterDate) => {
    const {authData} = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let {state} = useLocation();
    const [data, setData] = useState(state && state.data ? state.data : []);


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Beneficiary\n' +
                'Name',
            selector: (row) => <b>{row.company_name || ''}</b>,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Invoice No',
            selector: (row) => <b>{row.invoice_no}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Date',
            selector: (row) => <b>{row.invoice_date}</b>,
            sortable: true,
            width: "150px",
            right: false
        },

        {
            name: 'INVOICE\n' +
                'AMOUNT',
            selector: (row) => <b>{row.payable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Taxable\n' +
                'Amount',
            selector: (row) => <b>{row.taxable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Invoice Sanctioned Amount',
            selector: (row) => <b>{row.sanction_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'NPS Amount',
            selector: (row) => <b>{row?.nps}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Challan No',
            selector: (row) => <b>{row?.challan_no}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Challan Date',
            selector: (row) => <b>{row?.challan_date}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'View Challan',
            width: "200px",
            selector: (row) => row?.challan_url ?
                <a href={row?.challan_url} target="_blank" rel="noreferrer"><AiOutlineEye/></a> : '',
            sortable: false,
        },
        {
            name: 'View Invoice',
            width: "200px",
            selector: (row) => row?.invoice ?
                <a href={row?.invoice} target="_blank" rel="noreferrer"><AiOutlineEye/></a> : '',
            sortable: false,
        },
    ];

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };


    const calculateTotalAmount = (propertyName) => {
        let total = 0;
        data.forEach((row) => {
            const depositAmount = Number(parseFloat(row[propertyName]));
            if (!isNaN(depositAmount)) {
                total += depositAmount;
            }
        });
        return total.toFixed(2);
    };

    const totalInvoiceAmount = calculateTotalAmount('nps');

    const footerRow = {
        isFooter: true,
        selectableRowsSingle: false,
        voucher_no: <b>Total</b>,
        nps: <b>{AddCommasToAmount(totalInvoiceAmount)}</b>,
    };

    // const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
    // // console.log('data', data, paginatedData);

    // let updatedData = [];

    // if(paginatedData.length) {
    //     updatedData = [...paginatedData, footerRow];
    // } else {
    //     updatedData = [];
    // }
    const fetchChallans = async () => {
        // setData([]);
        try {

            const resp = await getNPSReports({
                from_date: filterDate?.fromDate || '', 
                to_date: filterDate?.toDate || '',
                searchStr: filterDate.searchStr,
                dateColumn: filterDate.dateColumn
            });
            if (resp.data.status) {
                setData(resp.data.list);
            }
        } catch (error) {
            setData([]);
        }
    }

    useEffect(() => {
        fetchChallans();
    }, [filterDate.fromDate, filterDate.toDate, filterDate.searchStr, filterDate.dateColumn])



    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}

export default NPSReportList
