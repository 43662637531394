import React from 'react';
import Tools from "../../components/Tools/Tools";
import ViewBeneficiaryReimbursement from '../../components/BeneficiaryReimbursement/ViewBeneficiaryReimbursement';

export default function ViewBeneficiaryReimbursementPage() {
    return (
        <div>
            {/* <Tools /> */}
            <ViewBeneficiaryReimbursement />
        </div>
    )
}