import React, { useEffect, useMemo, useState } from 'react'
import { delete_user_by_id, get_users, reset_user_by_id} from '../../helper/Api';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
import { GET_USER_FAIL,GET_USER_START,GET_USER_SUCCESS,DELETE_USER_START,DELETE_USER_SUCCESS } from '../../RTK/Slices/UserSlice';
import {FaRegEdit,FaEye} from "react-icons/fa"
import {MdDelete} from "react-icons/md"
import {Link} from 'react-router-dom';
import './ManageUser.css';
import { toast } from 'react-toastify';
import ResponsiveDataTable from '../ResponsiveDataTable/ResponsiveDataTable';

const ManageUser = (filterData) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const {authData}=useSelector((state)=>state.authData);
    const dispatch=useDispatch();
    const user_type=authData.user.user_type;
    const [users,setUsers]=useState([]);

    const fetchUsers=async()=>{
      dispatch(GET_USER_START());
      try {
        const users=await get_users({
          searchStr: filterData.searchStr,
          department_level: filterData.department_level,
          department_id: filterData.departmentId ? filterData.departmentId : authData.user.department_id
        });
        if(users.data.status){
          // console.log('users', users);
          dispatch(GET_USER_SUCCESS(users.data.users))
          setUsers(users.data.users)
        }else{
          dispatch(GET_USER_FAIL(users.data.message))
        }
      } catch (error) {
        dispatch(GET_USER_FAIL('something went wrong'))
      }
    }

    useEffect(() => {
        fetchUsers();
    }, [filterData.searchStr, filterData.department_level])

    // const customStyles = {
     
    //   headCells: {
    //       style: {
    //           fontWeight:"bold",
    //           fontSize:"14px"
    //       },
    //   },
    // };

  
    // const columnss = [
    //   {
    //     name: 'Empl. Code',
    //     selector: (row) => row.code,
    //     sortable: true,
    //     width: '150px'
    //   },
    //   // {
    //   //   name: 'Level',
    //   //   selector: (row) => row.user_type,
    //   //   sortable: true,
    //   //   width:"100px"
    //   // },
    //   {
    //     name: 'Username',
    //     selector: (row) => row.user_name,
    //     sortable: true,
    //     wrap:true,
    //     width: '200px'
    //   },
    //   {
    //     name: 'Designation',
    //     selector: (row) => row.designation,
    //     sortable: true,
    //     wrap:true,
    //     width: '200px'
    //   },
    //   {
    //     name: 'Mobile No',
    //     selector: (row) => row.mobile,
    //     sortable: true,
    //     width: '200px'
    //   },
    //   {
    //     name: 'Email',
    //     selector: (row) => row.email,
    //     sortable: true,
    //     wrap:true,
    //     width: '200px'
    //   },
     
    //   {
    //     name: 'Action',
    //     width:"200px",
    //     omit:user_type==='l3'?false:true,
    //     selector: (row) => {
    //       return (
    //         <div className='user_action'>
    //           {/* <span className='text-primary' onClick={()=>reset_user(row.id)}>Reset</span> */}
    //           <span className='pr-4'><Link to={`/edit-user/${row.id}`}><FaRegEdit/></Link></span>
    //           <span className='pr-4'><Link to={`/view-user/${row.id}`}><FaEye /></Link></span>
    //           {/* <span className='pr-4 text-danger' onClick={()=>delete_user(row.id)}> <MdDelete></MdDelete></span> */}
    //         </div>
    //       );        
    //     },
    //     sortable: true,
    //   }
    // ];

    const columns = useMemo( ()=> [
      {
        title: 'Empl. Code',
       
      },
      {
        title: 'Username',
      },
      {
        title: 'Designation',
      },
      {
        title: 'Mobile No',
      },
      {
        title: 'Email',
      },
      // {
      //   name: 'Action',
      //   width:"200px",
      //   omit:user_type==='l3'?false:true,
      //   selector: (row) => {
      //     return (
      //       <div className='user_action'>
      //         {/* <span className='text-primary' onClick={()=>reset_user(row.id)}>Reset</span> */}
      //         <span className='pr-4'><Link to={`/edit-user/${row.id}`}><FaRegEdit/></Link></span>
      //         <span className='pr-4'><Link to={`/view-user/${row.id}`}><FaEye /></Link></span>
      //         {/* <span className='pr-4 text-danger' onClick={()=>delete_user(row.id)}> <MdDelete></MdDelete></span> */}
      //       </div>
      //     );        
      //   },
      //   sortable: true,
      // }
    ],[]);

    
      const datas = useMemo(
        () =>
          users.map((item) => [
            item?.code,
            item?.user_name,
            item?.designation,
            item?.mobile,
            item?.email,
          ]),
        [users]
      );
    
  
    const reset_user=async(user_id)=>{
      if(user_id){
        const result = window.confirm("Are you sure you want to reset the user");
        if(result){
          try {
            const {data}=await reset_user_by_id(user_id);
            toast.success(data.message,{
              position: toast.POSITION.TOP_CENTER
            });
          } catch (error) {
            toast.error("getting error while reset user.",{
              position: toast.POSITION.TOP_CENTER
            });
          }
        }
      }else{
        toast.error("user id required.",{
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
  
    const delete_user=async(user_id)=>{
      if(user_id){
        const result = window.confirm("Are you sure you want to delete the user");
        if(result){
          try {
            dispatch(DELETE_USER_START());
            let formData={
              "added_by":authData.user.id
            }
            const {data}=await delete_user_by_id(formData,user_id);
            if(data.status){
              dispatch(DELETE_USER_SUCCESS({"id":user_id}));
              toast.success(data.message,{
                position: toast.POSITION.TOP_CENTER
              });
            }else{
              toast.error(data.message,{
                position: toast.POSITION.TOP_CENTER
              });
            }
            
          } catch (error) {
            toast.error("getting error while delete user.",{
              position: toast.POSITION.TOP_CENTER
            });
          }
        }
      }else{
        toast.error("user id required.",{
          position: toast.POSITION.TOP_CENTER
        });
      }
    }
    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    return (
    <div>
        {/* <DataTable
            customStyles={customStyles}
            className="dataTables_wrapper"
            columns={columnss}
            data={users}
            fixedHeader
            fixedHeaderScrollHeight="600px"
            pagination
            paginationPerPage={rowsPerPage}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            onChangeRowsPerPage={handleChangeRowsPerPage}
        /> */}

        <ResponsiveDataTable data={datas} columns={columns} />
    </div>
  )
}

export default ManageUser
