import React from 'react';
import Tools from "../../../components/Tools/Tools"
import EditAllocateBudget from '../../../components/Multi/BudgetAllocation/EditAllocateBudget';
const BudgetPaymentPage = () => {
    return (
        <div>
            <EditAllocateBudget />
        </div>
    )
}
export default BudgetPaymentPage