import React from 'react';
import Tools from "../../../components/Tools/Tools"
import MprReport from '../../../components/Multi/MRP/MprReport';

const MprReportPage = () => {
  return (
    <div>
      <MprReport />
    </div>
  )
}

export default MprReportPage
