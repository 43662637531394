import React from 'react'
import Tools from '../../components/Tools/Tools';
import AddBeneficiaryAccount from '../../components/Beneficiary/AddBeneficiaryAccount';

export default function AddBeneficiaryAccountPage() {
    return (
        <div className='addNewScheme'>
          {/* <Tools/> */}
          { <AddBeneficiaryAccount/> }
        </div>
      )
}