import React, {useEffect, useState} from 'react'
import {delete_beneficiary_by_id, get_beneficiary} from '../../helper/Api';
import DataTable from 'react-data-table-component';
import {useDispatch, useSelector} from 'react-redux';
import {BiSolidEdit} from 'react-icons/bi'
import {AiOutlineEye} from 'react-icons/ai'
import {
    DELETE_BENEFICIARY_START,
    DELETE_BENEFICIARY_SUCCESS,
    GET_BENEFICIARY_FAIL,
    GET_BENEFICIARY_START,
    GET_BENEFICIARY_SUCCESS
} from '../../RTK/Slices/BeneficiarySlice';
import {Link} from 'react-router-dom';
import {MdDelete} from 'react-icons/md';
import {toast} from 'react-toastify';

const ManageBeneficiary = (listType) => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const {authData} = useSelector((state) => state.authData);
    const dispatch = useDispatch();

    const [beneficiariesList, setBeneficiaries] = useState([]);
    const columns = [
        {
            name: 'Action',
            selector: (row) => {
                return (
                    <span className='user_action'>
                        <Link to={`/beneficiary/${row.id}`}>
                            <span><AiOutlineEye/></span>
                        </Link>

                        {authData.user.user_type === 'l1' && (row.l2_status === "2" || row.l3_status === "2") ?
                            <Link to={`/edit-beneficiary/${row.id}`}>
                                <span><BiSolidEdit/></span>
                            </Link> : ""}

                        { authData.user.user_type === 'l3' && row.l2_status === "1" && row.l3_status === "1" ?
                            <span className='pr-4 text-danger'
                                  onClick={() => delete_beneficiary(row.id)}><MdDelete></MdDelete></span> : ""}
              </span>
                );
            },
            sortable: true,
        },
        {
            name: 'Status (L2)',
            selector: (row) => row.l2_status === "0" ? "Pending" : row.l2_status === "1" ? "Approved" : "Rejected",
            sortable: true,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Status (L3)',
            selector: (row) => row.l3_status === "0" ? "Pending" : row.l3_status === "1" ? "Approved" : "Rejected",
            sortable: true,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Beneficiary Name',
            selector: (row) => row.company_name || '-',
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'GST No',
            selector: (row) => row.gst_no || '-',
            sortable: true,
            wrap: true,
            width: "150px"
        },
        {
            name: 'PAN',
            selector: (row) => row.pan_no || '-',
            sortable: true,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Contact Person',
            selector: (row) => row.contact_person || '-',
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Mobile',
            selector: (row) => row.mobile || '-',
            sortable: true,
            wrap: true,
            width: "150px"
        },

    ];
    const fetchBeneficiaries = async () => {
        dispatch(GET_BENEFICIARY_START());
        try {
            const beneficiaries = await get_beneficiary({
                from_date: listType?.fromDate || '', 
                to_date: listType?.toDate || '', 
                search_str: listType?.searchStr || '', 
                department_id: authData.user.department_id || ''
            });
            if (beneficiaries.data.status && beneficiaries.data.list) {
                if (listType.listType === 'list') {
                    dispatch(GET_BENEFICIARY_SUCCESS(beneficiaries.data.list))
                    setBeneficiaries(beneficiaries.data.list)
                } else if (listType.listType === 'actionList' && authData.user.user_type === 'l2') {
                    const list = beneficiaries.data.list.filter(el => el.l2_status === "0");
                    dispatch(GET_BENEFICIARY_SUCCESS(list))
                    setBeneficiaries(list)
                } else if (listType.listType === 'actionList' && authData.user.user_type === 'l3') {
                    const list = beneficiaries.data.list.filter(el => el.l3_status === "0");
                    dispatch(GET_BENEFICIARY_SUCCESS(list))
                    setBeneficiaries(list)
                }
            } else {
                dispatch(GET_BENEFICIARY_FAIL(beneficiaries.data.message))
            }
        } catch (error) {
            dispatch(GET_BENEFICIARY_FAIL('something went wrong'))
        }
    }

    useEffect(() => {
        fetchBeneficiaries();
    }, [listType?.fromDate, listType?.toDate, listType?.searchStr, listType.listType, dispatch])

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const delete_beneficiary = async (beneficiary_id) => {
        if (beneficiary_id) {
            const result = window.confirm("Are you sure you want to delete the beneficiary?");
            if (result) {
                try {
                    dispatch(DELETE_BENEFICIARY_START());
                    let formData = {
                        "beneficiary_id": beneficiary_id
                    }
                    const {data} = await delete_beneficiary_by_id(formData);
                    if (data.status) {
                        dispatch(DELETE_BENEFICIARY_SUCCESS({"id": beneficiary_id}));
                        fetchBeneficiaries();
                        toast.success(data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else {
                        toast.error(data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                } catch (error) {
                    toast.error("getting error while delete user.", {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        } else {
            toast.error("beneficiary id required.", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
    };
    return (
        <div>
            <DataTable
                className="dataTables_wrapper"
                customStyles={customStyles}
                columns={columns}
                data={beneficiariesList}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}

export default ManageBeneficiary
