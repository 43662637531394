import React from 'react';
import Tools from "../../../components/Tools/Tools"
import AddSchemeAccount from '../../../components/Multi/ChildSchemeAccount/AddSchemeAccount';

const AddScheneAccountPage = () => {
  return (
    <div>
      <AddSchemeAccount />
    </div>
  )
}

export default AddScheneAccountPage
