import React, { useState } from 'react'
import Tools from '../../../components/Tools/Tools'
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import ManageDisbursmentHeader from "../../../components/Disbursment/ManageDisbursmentHeader";
import BudgetAllocationList from '../../../components/Multi/BudgetAllocation/BudgetAllocationList';
import { useNavigate, useParams } from 'react-router-dom';
const AllocateListPage = () => {
    const navigate = useNavigate();
    // const { authData } = useSelector((state) => state.authData);
    const [key, setKey] = useState('list');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');
    const {listType} = useParams();
    const [departmentId, setDepartmentId] = useState('');
    const [tier, setTier] = useState('');

    const handleDateChange = (start, end, dateColumn, searchStr, fyId, tier, departmentId) => {
        setFromDate(start);
        setToDate(end);
        setToSearchStr(searchStr);
        setTier(tier);
        setDepartmentId(departmentId);
    };

    const handleTabSelect = (key) => {
        if (key == 'approvalList') {
            navigate('/allocate-budget-approval');
        }
    };

    return (
        <div>
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} type="Allocate Budgets" />
            <div className='scheme p-3'>
                {
                    (listType && listType !== '') ? <div className='all_tabs'>
                                <BudgetAllocationList listType={key} fromDate={fromDate} toDate={toDate} searchStr={searchStr} approval={true} tier={tier} departmentId={departmentId} />

                    </div> : 
                    <><BudgetAllocationList listType={key} fromDate={fromDate} toDate={toDate} searchStr={searchStr} approval={true} tier={tier} departmentId={departmentId} /></>
                }

            </div>
        </div>
    )
}
export default AllocateListPage
