import React, { useState } from "react";

const TableWithPagination = ({data}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // Sort data based on column and direction
  const sortedData = [...data].sort((a, b) => {
    if (!sortConfig.key) return 0; // No sorting by default
    const { key, direction } = sortConfig;
    if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
    if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
    return 0;
  });

    // Filter data based on search query
    const filteredData = sortedData.filter((item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase())
      );

  // Calculate total pages
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  // Get data for the current page
  const startIndex = (currentPage - 1) * rowsPerPage;
  const currentData = filteredData.slice(startIndex, startIndex + rowsPerPage);

  // Handle pagination
  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

   // Handle change in rows per page
   const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page
  };

    // Handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to the first page when searching
      };

       // Handle sorting
  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  return (
    <div>

     {/* Search Bar */}
     <div>
        <label htmlFor="search">Search: </label>
        <input
          id="search"
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search by name..."
        />
      </div>

         {/* Rows per page selector */}
      <div>
        <label htmlFor="rowsPerPage">Rows per page: </label>
        <select
          id="rowsPerPage"
          value={rowsPerPage}
          onChange={handleRowsPerPageChange}
        >
          <option value="3">3</option>
          <option value="5">5</option>
          <option value="7">7</option>
          <option value="10">10</option>
        </select>
      </div>
        <div className="overflow-x-auto">

      <table className="w-100 ">
        <thead className="bg-primary">
          <tr>

            <th onClick={() => handleSort("code")}>
            <span className="d-flex justify-content-center align-items-center">
            <span>

            code 
            </span>
            {sortConfig.key === "code" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </span>
            </th>

            <th onClick={() => handleSort("name")}>
            <span className="d-flex justify-content-center align-items-center">
                <span>

            Name 
                </span>
            {sortConfig.key === "name" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </span>
            </th>

            <th onClick={() => handleSort("nomiclature")}>
            <span className="d-flex justify-content-center align-items-center">
                <span>

            Department Level 
                </span>
            {sortConfig.key === "nomiclature" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </span>
            </th>

            <th onClick={() => handleSort("department_level")}>
            <span className="d-flex justify-content-center align-items-center">
                <span>

            Tier 
                </span>
            {sortConfig.key === "department_level" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </span>
            </th>

            <th onClick={() => handleSort("parent_department_name")}>
            <span className="d-flex justify-content-center align-items-center">
                <span>

            Parent Dept. Name
                </span>
            {sortConfig.key === "parent_department_name" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </span>
            </th>

            <th onClick={() => handleSort("parent_department_level")}>
            <span className="d-flex justify-content-center align-items-center">
                <span>
            Parent Dept. Tier 
                </span>
            {sortConfig.key === "parent_department_level" &&
                (sortConfig.direction === "ascending" ? " ▲" : " ▼")}
            </span>
            </th>

          </tr>
        </thead>
        <tbody>
          {currentData.map((item, index) => (
            <tr key={item.id}>
              <td>{item?.code}</td>
              <td>{item?.name}</td>
              <td>{item?.nomiclature}</td>
              <td>{item?.department_level}</td>
              <td>{item?.parent_department_name}</td>
              <td>{item?.parent_department_level}</td>
            </tr>
          ))}
        </tbody>
      </table>

        </div>

      <div className="pagination">
        <button onClick={handlePrevPage} disabled={currentPage === 1}>
          Prev
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNextPage} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default TableWithPagination;
