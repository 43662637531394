import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net';
import 'datatables.net-dt/css/dataTables.dataTables.css';
import 'datatables.net-responsive';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';

const ResponsiveDataTable = ({ data, columns }) => {
  const tableRef = useRef(null);

  useEffect(() => {
    // Initialize DataTable
    const table = $(tableRef.current).DataTable({
      data: data,
      columns: columns,
      responsive: true,
      paging: true,  // Enable pagination
  searching: true, // Enable search
  ordering: true,  // Enable column sorting
    });

    // Clean up to avoid reinitialization
    return () => {
      table.destroy();
    };
  }, [data, columns]);

  return (
    <div>
      <table ref={tableRef} className="display-one" style={{ width: '100%' }}></table>
    </div>
  );
};

export default ResponsiveDataTable;
