import React, {useState} from 'react'
import Tools from '../../components/Tools/Tools'
import './Schemes.css';
import BudgetList from '../../components/BudgetAllocation/Schemes/BudgetList';
import {Tab, Tabs} from "react-bootstrap";
import {useSelector} from "react-redux";
import ManageDisbursmentHeader from "../../components/Disbursment/ManageDisbursmentHeader";


const Budgets = () => {
    const {authData} = useSelector((state) => state.authData);
    const [key, setKey] = useState('list');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');
    const [fyId, setFyId] = useState(2);
    const [departmentId, setDepartmentId] = useState('');
    const [tier, setTier] = useState('');



    const handleDateChange = (start, end, dateColumn, searchStr, fyId, tier, departmentId) => {
        setFromDate(start);
        setToDate(end);
        setToSearchStr(searchStr);
        setFyId(fyId);
        setDepartmentId(departmentId);
        setTier(tier);
    };

  return (
    <div>
        {/* <Tools/> */}
        <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} fyId={fyId} type="Budgets" />
        <div className='scheme p-2'>
             <BudgetList listType={key} fromDate={fromDate} toDate={toDate} fyId={fyId} searchStr={searchStr} tier={tier} departmentId={departmentId}/>
        </div>
    </div>
  )
}

export default Budgets
