import react , { useEffect, useState, useRef, useMemo } from "react";
import DataTable from "react-data-table-component";
import { delete_scheme_by_id, get_schemes } from "../../../helper/Api";
import {
  CustomPagination,
  AddCommasToAmount,
  show_l1_action_btn,
} from "../../../helper/Utils";
import {
  DELETE_SCHEME_START,
  DELETE_SCHEME_SUCCESS,
  GET_SCHEME_FAIL,
  GET_SCHEME_START,
  GET_SCHEME_SUCCESS,
} from "../../../RTK/Slices/SchemeSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { FaEdit, FaInfoCircle } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { AiOutlineEye } from "react-icons/ai";
import { Col, Container, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


const subcolumns = [
  {
    name: "id",
    selector: (row) => "",
    width: "50px",
  },
  {
    name: "Code",
    selector: (row) => row.code,
    width: "150px",
  },
  {
    name: "Department Name",
    selector: (row) => "",
    width: "260px",
    wrap: true,
  },
  {
    name: "Scheme Name",
    selector: (row) => row.name,
    // sortable: true,
    wrap: true,
    width: "350px",
  },
  {
    name: "Scheme Type",
    selector: (row) => row.type,
    // sortable: true,
    width: "240px",
  },
  {
    name: "Financial Year",
    selector: (row) => row.financial_year,
    // sortable: true,
    width: "200px",
  },
  {
    name: "Provisioned Budget",
    selector: (row) => <b>{AddCommasToAmount(row.provisional_budget)}</b>,
    // sortable: true,
    width: "200px",
    wrap: true,
    // right: true
  },
  {
    name: "Released Budget",
    selector: (row) => AddCommasToAmount(row.budget),
    // sortable: true,
    width: "200px",
    wrap: true,
    // right: true
  },
  {
    name: "Under Approval",
    selector: (row) => AddCommasToAmount(row.pending_budget),
    // sortable: true,
    width: "200px",
    wrap: true,
    // right: true
  },
  {
    name: "Sanction Amount",
    selector: (row) =>
      row.utilised_budget ? AddCommasToAmount(row.utilised_budget) : 0,
    // sortable: true,
    // right: true
    width: "200px",
  },
  {
    name: "Paid Payment",
    selector: (row) =>
      row.total_payment ? AddCommasToAmount(row.total_payment) : 0,
    // sortable: true,
    width: "200px",
  },
  {
    name: "Pending Payment",
    selector: (row) =>
      row.total_payment
        ? AddCommasToAmount(row.utilised_budget - row.total_payment)
        : 0,
    // sortable: true,
    // right: true
    width: "200px",
  },
  {
    name: "Prev Balance",
    selector: (row) => (
      <b>
        {row.pervious_year_budget
          ? AddCommasToAmount(row.pervious_year_budget)
          : 0}
      </b>
    ),
    // sortable: true,
    width: "200px",
    // right: true
  },
  {
    name: "Budget Balance",
    selector: (row) => (row.balance ? AddCommasToAmount(row.balance) : 0),
    // sortable: true,
    // right: true
    width: "200px",
  },
  {
    name: "Status (l2)",
    selector: (row) => "",
    // sortable: true,
    width: "150px",
    right: false,
  },
  {
    name: "Status (l3)",
    selector: (row) => "",
    // sortable: true,
    width: "150px",
    right: false,
  },
  {
    name: "attachment",
    selector: (row) => "",
    width: "200px",
  },
  {
    name: "Action",
    selector: (row) => "",
    width: "200px",
  },
];

const ExpandedComponent = ({ data }) => {
  let sub_heads = data.sub_head_list;

  // console.log(sub_heads);
  return (
    <>
      <DataTable data={sub_heads} columns={subcolumns} noTableHead={true} />
    </>
  );
};
export function SchemesList(listType) {
  const currentFY = useRef({});
  const { authData } = useSelector((state) => state.authData);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { schemes, loading } = useSelector((state) => state.schemeData);
  const [schemesList, setSchemesList] = useState(schemes);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [scroll, setScroll] = useState(false);
  const [activeCardChild, setActiveCardChild] = useState("1");
  const [overlay, setOverlay] = useState(true);
  const [overlayDropDown, setOverlayDropDown] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width:"50%"
  };


  const fetchSchemes = async () => {
    dispatch(GET_SCHEME_START());
    try {
      const schemes = await get_schemes({
        from_date: listType?.fromDate || "",
        to_date: listType?.toDate || "",
        search_str: listType?.searchStr || "",
        fy_id: listType?.fyId || "",
        department_id: listType?.departmentId || "",
      });
      if (schemes.data.status) {
        if (schemes.data.schemes) {
          if (listType.listType === "list") {
            let balance = 0;
            currentFY.current = {
              fy: schemes.data.current_fy,
              total_provisional_budget: 0,
              totalSanctionAmount: 0,
              totalReleasedAmount: 0,
              totalUnderApproval: 0,
              totalPaidAmount: 0,
              totalPendingAmount: 0,
              totalBalanceAmount: 0,
            };
            schemes.data.schemes.forEach((e) => {
              balance += e.balance;
              currentFY.current.total_provisional_budget +=
                e.total_provisional_budget;
              currentFY.current.totalSanctionAmount += e.utilised_budget;
              currentFY.current.totalReleasedAmount += e.total_budget;
              currentFY.current.totalUnderApproval += e.pending_budget;
              currentFY.current.totalPaidAmount += e.total_payment;
              currentFY.current.totalPendingAmount +=
                e.utilised_budget - e.total_payment;
              currentFY.current.totalBalanceAmount += e.balance;
            });
            dispatch(GET_SCHEME_SUCCESS(schemes.data.schemes));
          } else if (
            listType.listType === "actionList" &&
            authData.user.user_type == "l2"
          ) {
            const list = schemes.data.schemes.filter((el) => el.l2_status == 0);
            dispatch(GET_SCHEME_SUCCESS(list));
          } else if (
            listType.listType === "actionList" &&
            authData.user.user_type == "l3"
          ) {
            const list = schemes.data.schemes.filter((el) => el.l3_status == 0);
            dispatch(GET_SCHEME_SUCCESS(list));
          }
        }
      } else {
        dispatch(GET_SCHEME_FAIL(schemes.data.message));
      }
    } catch (error) {
      dispatch(GET_SCHEME_FAIL("something went wrong"));
    }
  };

  useEffect(() => {
    fetchSchemes();
  }, [
    listType?.fromDate,
    listType?.toDate,
    listType?.searchStr,
    listType.listType,
    listType.fyId,
    listType.departmentId,
    dispatch,
  ]);

  useEffect(() => {
    setSchemesList(schemes);
  }, [schemes]);

  // const paginatedData = schemesList?.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        position: "relative",
        zIndex: "1 !important",
      },
    },
    rows: {
      style: {
        zIndex: "1 !important", // Adjust or remove this value
      },
    },
  };

  const columnss = [
    {
      name: "Scheme code",
      selector: (row) => row.code,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Department Name",
      selector: (row) => row.department,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Scheme Name",
      selector: (row) => row.name,
      sortable: true,
      wrap: true,
      width: "400px",
    },
    {
      name: "Scheme Type",
      selector: (row) => row.type,
      sortable: true,
      width: "200px",
      wrap: true,
    },
    {
      name: "Financial Year",
      selector: (row) => currentFY.current.fy,
      // sortable: true,
      width: "200px",
    },
    {
      name: "Provisioned Budget",
      selector: (row) => (
        <b>{AddCommasToAmount(row.total_provisional_budget)}</b>
      ),
      // sortable: true,
      width: "200px",
      wrap: true,
      // right: true
    },
    {
      name: "Released Budget",
      selector: (row) => <b>{AddCommasToAmount(row.total_budget)}</b>,
      // sortable: true,
      width: "200px",
      wrap: true,
      // right: true
    },
    {
      name: "Under Approval",
      selector: (row) => <b>{AddCommasToAmount(row.pending_budget)}</b>,
      // sortable: true,
      width: "200px",
      wrap: true,
      // right: true
    },
    {
      name: "Sanction Amount",
      selector: (row) => (
        <b>
          {row.utilised_budget ? AddCommasToAmount(row.utilised_budget) : 0}
        </b>
      ),
      // sortable: true,
      wrap: true,
      width: "200px",
      // right: true
    },
    {
      name: "Paid Payment",
      selector: (row) => (
        <b>{row.total_payment ? AddCommasToAmount(row.total_payment) : 0}</b>
      ),
      // sortable: true,
      width: "200px",
      // right: true
    },
    {
      name: "Pending Payment",
      selector: (row) => (
        <b>
          {row.total_payment
            ? AddCommasToAmount(row.utilised_budget - row.total_payment)
            : 0}
        </b>
      ),
      // sortable: true,
      width: "200px",
      // right: true
    },

    {
      name: "Prev Balance",
      selector: (row) => (
        <b>
          {row.prevYearBalance ? AddCommasToAmount(row.prevYearBalance) : 0}
        </b>
      ),
      // sortable: true,
      width: "200px",
      // right: true
    },

    {
      name: "Budget Balance",
      selector: (row) => (
        <b>{row.balance ? AddCommasToAmount(row.balance) : 0}</b>
      ),
      // sortable: true,
      width: "200px",
      // right: true
    },
    {
      name: "Status (L2)",
      selector: (row) =>
        row.l2_status == 0
          ? "Pending"
          : row.l2_status == 1
          ? "Approved"
          : "Rejected",
      // sortable: true,
      width: "150px",
      right: false,
    },
    {
      name: "Status (L3)",
      selector: (row) =>
        row.l3_status == 0
          ? "Pending"
          : row.l3_status == 1
          ? "Approved"
          : "Rejected",
      // sortable: true,
      width: "150px",
      right: false,
    },
    {
      name: "Attachment",
      width: "200px",
      selector: (row) => {
        const files = row.attachments;
        return files.map((file, i) => {
          return (
            <a href={file.file_url} target="_blank">
              attachment {i + 1}
            </a>
          );
        });
      },
      // sortable: true,
    },
    {
      name: "Action",
      width: "200px",
      selector: (row) => {
        return (
          <>
            <div className="user_action">
              {/* <span className='pr-4'><Link to={`/schemes/${row.id}`}><FaInfoCircle/></Link></span> */}
              <span className="pr-4">
                <Link to={`/view-scheme/${row.id}`}>
                  <AiOutlineEye />
                </Link>
              </span>
              {show_l1_action_btn(
                authData.user.user_type,
                row.l2_status,
                row.l3_status
              ) ? (
                <span className="pr-4">
                  <Link to={`/edit-scheme/${row.id}`}>
                    <FaEdit />
                  </Link>
                </span>
              ) : (
                ""
              )}

              {show_l1_action_btn(
                authData.user.user_type,
                row.l2_status,
                row.l3_status
              ) ? (
                <span
                  className="pr-4 text-danger"
                  onClick={() => delete_scheme(row.id)}
                >
                  <MdDelete></MdDelete>
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        );
      },
    },
  ];

  const delete_scheme = async (scheme_id) => {
    if (scheme_id) {
      try {
        dispatch(DELETE_SCHEME_START());
        let formData = {
          scheme_id: scheme_id,
        };
        const { data } = await delete_scheme_by_id(formData);
        if (data.status) {
          dispatch(DELETE_SCHEME_SUCCESS({ id: scheme_id }));
          Swal.fire({
            title: "Deleted!",
            text: "Your data has been deleted.",
            icon: "success",
          });
        } else {
          toast.error(data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        toast.error("getting error while delete user.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      toast.error("scheme id required.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const alertFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await delete_scheme(id);
      }
    });
  };

  const [hoveredId, setHoveredId] = useState(null); // Track the hovered ID
  const [schemeId, setSchemeId] = useState(null); // Track the hovered ID

  const handleMouseEnter = (id) => {
    setHoveredId(id); // Set the ID of the hovered div
  };

  const handleMouseLeave = () => {
    setHoveredId(null); // Reset when hover ends
  };
  const [selectedValue, setSelectedValue] = useState("");
  const [controlValue, setControlValue] = useState("");
  const [dbtRadio, setDbtRadioValue] = useState("");

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleControlRadio = (e)=>{
    setControlValue(e.target.value)
  }

  const handleDbtradio = (e)=>{
    setDbtRadioValue(e.target.value)
  }

  return (
    <>
      {/* <DataTable
            customStyles={customStyles }
            className="table-wrapper"
            columns={columnss}
            progressPending={loading}
            data={schemesList}
            fixedHeaderScrollHeight="600px"
            expandableRowsComponent={ExpandedComponent}
            paginationPerPage={rowsPerPage}
            paginationTotalRows={schemesList?.length}
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            fixedHeader
            expandableRows
            pagination
            responsive
        /> */}

      <div className="row">
        <div
          className="px-1 col-9 d-flex flex-column gap-3 overflow-y-auto overflow-x-hidden"
          style={{ height: "100vh" }}
        >
          {schemes.map((val, ind) => (
            <div
              className="bg-white hover-card "
              style={{ borderRadius: "8px" }}
              key={val?.id}
              onMouseEnter={() => handleMouseEnter(val?.id)}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className="bg-prime-20 d-flex justify-content-between align-items-center p-2 hover-card-color transition overflow-hidden"
                style={{ borderRadius: "8px 8px 0 0" }}
              >
                {/* ======================card header================== */}
                <span
                  className={`fs-14px white-space-nowrap overflow-x-hidden ${
                    hoveredId === val.id && val?.department?.length >= 70
                      ? null
                      : "text-overflow-ellipsis"
                  }`}
                  style={{ width: "95%", fontStyle: "italic" }}
                >
                  {/* =======================card header====================== */}
                  <span
                    className={`fs-14px white-space-nowrap overflow-x-hidden ${
                      hoveredId === val.id && val?.department?.length >= 70
                        ? null
                        : "text-overflow-ellipsis"
                    }`}
                    style={{ width: "95%", fontStyle: "italic" }}
                  >
                    Scheme code-
                    {val?.code} {val?.department}
                  </span>
                </span>

                <span className="d-flex gap-3">
                  <i
                    className="icon-remove_red_eye cursor-pointer"
                    onClick={() => setOverlay(false)}
                  ></i>
                  <i className="icon-account_balance_wallet cursor-pointer"></i>
                </span>
              </div>

              {/* ================== card body ========================= */}

              <div className="row p-2">
                <div className="col d-flex align-items-center border py-1 rounded-2 ms-3">
                  <span className="fs-12px text-black-60 w-50">
                    Provisional Budget (Var1)
                  </span>
                  <span className="fs-14px fw-600 w-50">
                    {val?.total_provisional_budget
                      ? val.total_provisional_budget
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.total_provisional_budget}
                  </span>
                </div>
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60">
                    Release FY (var1)
                  </span>
                  <span className="fs-14px fw-600">
                    {val?.total_budget
                      ? val?.total_budget
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.total_budget}
                  </span>
                </div>
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60 d-flex justify-content-between align-items-center">
                    Previous FY Balance
                    <span>
                      <i className="icon-info"></i>
                    </span>
                  </span>
                  <span className="fs-14px fw-600">
                    {val?.prevYearBalance
                      ? val?.prevYearBalance
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.prevYearBalance}
                  </span>
                </div>
                {/* <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60">
                    Utilized Balance
                  </span>
                  <span className="fs-14px fw-600">
                    {val?.utilised_budget
                      ? val?.utilised_budget 
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.utilised_budget}
                  </span>
                </div> */}
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60">Total Opening</span>
                  <span className="fs-14px fw-600">
                    {val?.total_budget && val?.prevYearBalance
                      ? (val?.total_budget + val?.prevYearBalance)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.total_budget + val?.prevYearBalance}
                  </span>
                </div>
              </div>

              <div className="row p-2 ms-2">
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60 d-flex justify-content-between align-items-center">
                    <span>Under approval</span>
                    <span>
                      <i className="icon-open_in_new cursor-pointer fs-12px"></i>
                    </span>
                  </span>
                  <span className="fs-14px fw-600 ">
                    {val?.total_provisional_budget
                      ? val.total_provisional_budget
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.total_provisional_budget}
                  </span>
                </div>
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60 d-flex justify-content-between align-items-center">
                    Sanction
                    <span>
                      <i className="icon-open_in_new cursor-pointer"></i>
                    </span>
                  </span>
                  <span className="fs-14px fw-600">
                    {val?.utilised_budget
                      ? val?.utilised_budget
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.utilised_budget}
                  </span>
                </div>
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60 d-flex justify-content-between align-items-center">
                    Remaining previous FY
                    <span>
                      <i className="icon-info cursor-pointer"></i>
                    </span>
                  </span>
                  <span className="fs-14px fw-600">
                    {val?.prevYearBalance
                      ? val?.prevYearBalance
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.prevYearBalance}
                  </span>
                </div>
                <div className="col d-flex flex-column bg-success-20 py-1 me-3">
                  <span className="fs-12px text-black-60 d-flex justify-content-between align-items-center">
                    Total Balance
                    <span>
                      <i className="icon-info cursor-pointer"></i>
                    </span>
                  </span>
                  <span className="fs-14px fw-600 text-success-100">
                    {val?.utilised_budget
                      ? val?.utilised_budget
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.utilised_budget}
                  </span>
                </div>
              </div>

              {/* ===================card footer ==================== */}
              <div className="p-2 d-flex justify-content-between align-items-center ">
                <div className="d-flex align-items-center gap-3 ">
                  <span
                    className={` ${
                      val?.l2_status == 0
                        ? "bg-warning-20"
                        : val?.l2_status == 1
                        ? "bg-success-20"
                        : "bg-danger-20"
                    } rounded-2   d-flex align-items-center px-2   gap-1`}
                  >
                    <span className="fs-12px">L2</span>
                    <span>
                      {val?.l2_status == 0 ? (
                        <i
                          className="icon-warning text-warning-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : val?.l2_status == 1 ? (
                        <i
                          className="icon-verified text-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : (
                        <i
                          className="icon-close_circle_stroke text-danger-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      )}
                    </span>
                  </span>

                  <span
                    className={` ${
                      val?.l3_status == 0
                        ? "bg-warning-20"
                        : val?.l3_status == 1
                        ? "bg-success-20"
                        : "bg-danger-20"
                    } rounded-2   d-flex align-items-center px-2   gap-1`}
                  >
                    <span className="fs-12px">L3</span>
                    <span>
                      {val?.l3_status == 0 ? (
                        <i
                          className="icon-warning text-warning-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : val?.l3_status == 1 ? (
                        <i
                          className="icon-verified text-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : (
                        <i
                          className="icon-close_circle_stroke text-danger-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      )}
                    </span>
                  </span>
                </div>

                <div className="d-flex align-items-center gap-3">
                  <i
                    className="icon-remove_red_eye text-info-100 cursor-pointer"
                    onClick={() => navigate(`/view-scheme/${val?.id}`)}
                  ></i>

                  {show_l1_action_btn(
                    authData.user.user_type,
                    val?.l2_status,
                    val?.l3_status
                  ) ? (
                    <span className="">
                      <Link
                        to={`/edit-scheme/${val?.id}`}
                        className="text-decoration-none"
                      >
                        <i className="icon-edit text-success-100 cursor-pointer"></i>
                      </Link>
                    </span>
                  ) : (
                    ""
                  )}

                  {show_l1_action_btn(
                    authData.user.user_type,
                    val?.l2_status,
                    val?.l3_status
                  ) ? (
                    <span
                      className="pr-4 text-danger"
                      onClick={() => alertFunction(val?.id)}
                    >
                      <i className="icon-delete_outline text-danger-100 cursor-pointer"></i>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* =============================last footer ================================ */}

              <div className="border mx-2"></div>
              <div className="p-2">
                <div className="p-2 d-flex justify-content-between text-secondary-50 px-4">
                  {/* Under Approval Button */}
                  <button
                    onClick={() => {
                      if (schemeId === val?.code) {
                        setSchemeId(null); // Deselect if already selected
                      } else {
                        setSchemeId(val?.code); // Select new one
                      }
                    }}
                    className={`${
                      schemeId === val?.code ? "bg-secondary-20" : "bg-white"
                    } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px px-2 py-1 rounded-4`}
                  >
                    {schemeId === val?.code ? (
                      <i className="icon-remove_circle_outline"></i>
                    ) : (
                      <i className="icon-add_circle_outline"></i>
                    )}
                    Under approval
                  </button>

                  {/* Sanction Button */}
                  <button
                    onClick={() => {
                      if (schemeId === val?.id) {
                        setSchemeId(null); // Deselect if already selected
                      } else {
                        setSchemeId(val?.id); // Select new one
                      }
                    }}
                    className={`${
                      schemeId === val?.id ? "bg-secondary-20" : "bg-white"
                    } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px px-2 py-1 rounded-4`}
                  >
                    {schemeId === val?.id ? (
                      <i className="icon-remove_circle_outline"></i>
                    ) : (
                      <i className="icon-add_circle_outline"></i>
                    )}
                    Sanction
                  </button>
                </div>
                {schemeId === val?.code && (
                  <div>
                    <div className="d-flex px-2 py-1">
                      <div className="w-25 text-black-60 fs-12px">Sub Head</div>
                      <div className="w-25 text-black-60 fs-12px">Gross</div>
                      <div className="w-25 text-black-60 fs-12px">
                        Total Deducation
                      </div>
                      <div className="w-25 text-black-60 fs-12px">
                        Net Payment
                      </div>
                    </div>
                    <div className="d-flex px-2 py-1">
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        Vendor
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500 text-success-100">
                        2,000,000,000.00
                      </div>
                    </div>
                    <div className="d-flex px-2 py-1">
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        DBT/Nacho
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500 text-success-100">
                        2,000,000,000.00
                      </div>
                    </div>
                    <div className="d-flex px-2 py-1">
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        Salary
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500 text-success-100">
                        2,000,000,000.00
                      </div>
                    </div>
                    <div className="d-flex px-2 py-1">
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        Child Allocatoin
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500 text-success-100">
                        2,000,000,000.00
                      </div>
                    </div>
                    <div className="d-flex px-2 py-1 bg-info-20">
                      <div className="w-25 fs-14px fw-500 text-prime-100">
                        Total
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500 text-success-100">
                        2,000,000,000.00
                      </div>
                    </div>
                  </div>
                )}
                {schemeId === val?.id && (
                  <div>
                    <div className="d-flex px-2 py-1">
                      <div className="w-25 text-black-60 fs-12px">
                        Sub Head san
                      </div>
                      <div className="w-25 text-black-60 fs-12px">Gross</div>
                      <div className="w-25 text-black-60 fs-12px">
                        Total Deducation
                      </div>
                      <div className="w-25 text-black-60 fs-12px">
                        Net Payment
                      </div>
                    </div>
                    <div className="d-flex px-2 py-1">
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        Vendor
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500 text-success-100">
                        2,000,000,000.00
                      </div>
                    </div>
                    <div className="d-flex px-2 py-1">
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        DBT/Nacho
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500 text-success-100">
                        2,000,000,000.00
                      </div>
                    </div>
                    <div className="d-flex px-2 py-1">
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        Salary
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500 text-success-100">
                        2,000,000,000.00
                      </div>
                    </div>
                    <div className="d-flex px-2 py-1">
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        Child Allocatoin
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500 text-success-100">
                        2,000,000,000.00
                      </div>
                    </div>
                    <div className="d-flex px-2 py-1 bg-info-20">
                      <div className="w-25 fs-14px fw-500 text-prime-100">
                        Total
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500">
                        2,000,000,000.00
                      </div>
                      <div className="w-25 text-black-100 fs-14px fw-500 text-success-100">
                        2,000,000,000.00
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        {/* ================== right side bar ================== */}

        {listType.listType === "list" ? (
          <div className="px-3 col-3">
            <div className="bg-white p-2 d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-1">
                <span className="fs-12px text-black-60">
                  Total Provisioned Budget
                </span>
                <span className="fs-16px fw-600">
                  {" "}
                  {currentFY?.current?.total_provisional_budget}
                </span>
              </div>

              <div className="d-flex flex-column gap-1">
                <span className="fs-12px text-black-60">
                  Total Released Budget
                </span>
                <span className="fs-16px fw-600">
                  {currentFY?.current?.totalReleasedAmount}
                </span>
              </div>

              <div className="d-flex flex-column gap-1">
                <span className="fs-12px text-black-60">
                  Total Under Approval
                </span>
                <span className="fs-16px fw-600">
                  {currentFY?.current?.totalUnderApproval}
                </span>
              </div>

              <div className="d-flex flex-column gap-1">
                <span className="fs-12px text-black-60">
                  Total Sanction Amount
                </span>
                <span className="fs-16px fw-600">
                  {currentFY?.current?.totalSanctionAmount}
                </span>
              </div>

              <div className="d-flex flex-column gap-1">
                <span className="fs-12px text-black-60">
                  Total Paid Payment
                </span>
                <span className="fs-16px fw-600">
                  {currentFY?.current?.totalPaidAmount}
                </span>
              </div>

              <div className="d-flex flex-column gap-1">
                <span className="fs-12px text-black-60">
                  Total Pending Payment
                </span>
                <span className="fs-16px fw-600">
                  {currentFY?.current?.totalPendingAmount}
                </span>
              </div>

              <div className="d-flex flex-column gap-1">
                <span className="fs-12px text-black-60">
                  Total Budget Balance
                </span>
                <span className="fs-16px fw-600">
                  {currentFY?.current?.totalBalanceAmount}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* ==============================overlay====================== */}

      <div className={`overlay ${overlay ? "d-none" : null}  `}></div>

      <div
        className={`overlayContent overflow-auto ${
          overlay ? "left-100" : null
        } `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-secondary-100 position-relative text-white d-flex justify-content-between align-items-center ps-4">
          <div className="fw-600 fs-16px d-flex align-items-center gap-2 ">
            <i
              className="icon-close_circle_fill fs-5  cursor-pointer"
              onClick={() => setOverlay(true)}
            ></i>
            Scheme
          </div>

          <div className="d-flex gap-2 align-items-center h-100 py-3 position-relative pe-4">
            <span>As on date (date)</span>
            <i
              className="icon-arrow_drop_down_circle fs-18px cursor-pointer"
              onClick={() => setOverlayDropDown((prev) => !prev)}
            ></i>

            <div
              className={`position-absolute bg-prime-100 w-100 p-3 ${
                !overlayDropDown ? "d-none" : "d-inline-block"
              }`}
              style={{ top: "100%", zIndex: "10" }}
            >
              <div className="d-flex flex-column gap-4">
                <span className="d-flex align-items-center gap-2 cursor-pointer">
                  <i className="icon-picture_as_pdf"></i> Export PDF
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 d-flex flex-column gap-2">

          {/* ======================card view=========================== */}

          <div
            className="bg-white border-prime-20"
          >
            <div
              className="bg-prime-20 d-flex justify-content-between align-items-center p-2 transition overflow-hidden"
            >
              {/* ======================card header================== */}
              <span
                className="fs-14px white-space-nowrap overflow-x-hidden"
                style={{ width: "95%", fontStyle: "italic" }}
              >
                {/* =======================card header====================== */}
                <span
                  className="fs-14px white-space-nowrap overflow-x-hidden "
                  style={{ width: "95%",}}
                >
                  <span className="fw-600 fs-16px" style={{ fontStyle:"normal" }}>
                    General
                  </span>
                </span>
              </span>
            </div>

            {/* ================================card body========================= */}

            <div className="row p-2 m-0">
              <div className="px-1 col-4">
                <span
                  className="d-flex flex-column p-0 px-1"
                  style={{ background: "#F3F3F3" }}
                >
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Financial Year* :
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    placeholder="Data here"
                    style={{ background: "#F3F3F3" }}
                    disabled
                  />
                </span>
              </div>

              <div className="col-4 px-1">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Department
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>

              <div className="col-4 px-1">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Scheme Type
                  </label>
                  <select
                    name="type"
                    id="type"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  >
                    <option value="type1" selected>
                      {" "}
                      Type 1
                    </option>
                    <option value="type2">type2</option>
                  </select>
                </span>
              </div>
            </div>

              <div className="row m-0 p-2">
                <div className="col-2 px-1 ">
                  <span className="d-flex flex-column p-0 px-1">
                    <label htmlFor="text" className="fs-12px text-prime-100">
                      Grant Code
                    </label>
                    <input
                      type="text"
                      className="border-bottom-only unactive-border fs-14px"
                      placeholder="Data here"
                      disabled
                    />
                  </span>
                </div>
                <div className="col-2 px-1">
                  <span className="d-flex flex-column p-0 px-1">
                    <label htmlFor="text" className="fs-12px text-prime-100">
                      Scheme Code
                    </label>
                    <input
                      type="text"
                      className="border-bottom-only unactive-border fs-14px"
                      placeholder="Data here"
                      disabled
                    />
                  </span>
                </div>
                <div className="col-8 px-1">
                  <span className="d-flex flex-column p-0 px-1">
                    <label htmlFor="text" className="fs-12px text-prime-100">
                      Scheme Name
                    </label>
                    <input
                      type="text"
                      className="border-bottom-only unactive-border fs-14px"
                      placeholder="Data here"
                      disabled
                    />
                  </span>
                </div>
              </div>

                <div className="row m-0 p-2">
                <div className="px-1 col-10">
                <span className="d-flex flex-column p-0 px-1 ">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  Account No.
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
                </div>

                  <div className="col-2 p-0 m-0 px-1 d-flex align-items-center gap-2">
                    <button onClick={handleOpen} className="m-0 py-1 px-1 text-prime-100 fs-16px bg-white rounded-2 d-flex align-items-center w-100 gap-1" style={{border:"1px solid #2490EA"}}> <i className="icon-account_balance"></i> Know more</button>
                    <i className="icon-picture_as_pdf fs-4 p-0 m-0"></i>
                  </div>

                </div>

            <div className="row m-0 p-2">
                <div className="px-1 col-2">
                    <span
                      className="d-flex flex-column p-0 px-1"
                      style={{ background: "#F3F3F3" }}
                    >
                      <label htmlFor="text" className="fs-12px text-prime-100">
                      Sub Head Code* 
                      </label>
                      <input
                        type="text"
                        className="border-bottom-only unactive-border fs-14px"
                        placeholder="Data here"
                        style={{ background: "#F3F3F3" }}
                        disabled
                      />
                    </span>
                  </div>
                <div className="px-1 col-8">
                    <span
                      className="d-flex flex-column p-0 px-1"
                      style={{ background: "#F3F3F3" }}
                    >
                      <label htmlFor="text" className="fs-12px text-prime-100">
                      Sub Head Name* 
                      </label>
                      <input
                        type="text"
                        className="border-bottom-only unactive-border fs-14px"
                        placeholder="Data here"
                        style={{ background: "#F3F3F3" }}
                        disabled
                      />
                    </span>
                  </div>
                <div className="px-1 col-2">
                    <span
                      className="d-flex flex-column p-0 px-1"
                      style={{ background: "#F3F3F3" }}
                    >
                      <label htmlFor="text" className="fs-12px text-prime-100">
                      creation Date 
                      </label>
                      <input
                        type="text"
                        className="border-bottom-only unactive-border fs-14px"
                        placeholder="Data here"
                        style={{ background: "#F3F3F3" }}
                        disabled
                      />
                    </span>
                  </div>
            </div>

            {/* ========================================budgeting================================== */}

            <div className="bg-prime-20 px-2 fs-16px fw-600 py-1">
              Budgeting
            </div>
            <div className="px-2 d-flex gap-1 py-2">
                <span className="pe-3 fs-16px">Activity and Sub-activity</span>

                <div className="d-flex align-items-center gap-1 me-2">
                <input type="radio" name="en" id="ds" value="disable" onChange={handleChange} />
                <label htmlFor="ds" className="fs-14px">Disable</label>
                </div>

                <div className="d-flex align-items-center gap-1">
                <input type="radio" name="en" id="en" value="enable" onChange={handleChange} />
                <label htmlFor="en" className="fs-14px">Enable</label>
                </div>
            </div>
                {
                  selectedValue === "enable" ? <div className="row m-0 px-2">
              <div className="col-2 m-0 p-0">
                <span className="pe-3 fs-16px">Control level</span>
              </div>
              <div className="col-10">
                <div className="d-flex align-items-center gap-1 me-2">  
                  <input type="radio" name="report" id="report" value="report" onChange={handleControlRadio} />
                  <label htmlFor="report" className="fs-14px">Report Expenses Solely by Activity and Sub-activity (Monitor only )</label>
                </div>
                <div className="d-flex align-items-center gap-1 me-2">  
                  <input type="radio" name="report" id="allocation" value="allocation" onChange={handleControlRadio} />
                  <label htmlFor="allocation" className="fs-14px">Allocate Budget to T2/T3/T4 by Activity and Sub-activity (Control Child Budget)</label>
                </div>
                <div className="d-flex align-items-center gap-1 me-2">  
                  <input type="radio" name="report" id="budget" value="budget" onChange={handleControlRadio} />
                  <label htmlFor="budget" className="fs-14px">Add Budget at T1 and Allocate to T2/T3/T4 by Activity and Sub-activity (Control Budgeting at All Levels</label>
                </div>
              </div>
                    {
                      controlValue === "budget" ?  <div className="row m-0 p-0 my-2">
                <div className="col-2 p-0">
                    <span
                      className="d-flex flex-column p-0 "
                    >
                      <label htmlFor="text" className="fs-12px text-prime-100">
                      Activity
                      </label>
                      <input
                        type="text"
                        className="border-bottom-only unactive-border fs-14px"
                        placeholder="Data here"
                        disabled
                      />
                    </span>
                  </div>
                <div className="px-1 col-8">
                    <span
                      className="d-flex flex-column p-0"
                    >
                      <label htmlFor="text" className="fs-12px text-prime-100">
                      Subactivity
                      </label>
                      <input
                        type="text"
                        className="border-bottom-only unactive-border fs-14px"
                        placeholder="Data here"
                        disabled
                      />
                    </span>
                  </div>
                <div className="px-1 col-2">
                    <span
                      className="d-flex flex-column p-0 "
                    >
                      <label htmlFor="text" className="fs-12px text-prime-100">
                      creation Date 
                      </label>
                      <input
                        type="text"
                        className="border-bottom-only unactive-border fs-14px"
                        placeholder="Data here"
                        disabled
                      />
                    </span>
                  </div>
            </div> : null
                    }
             
            </div> : null
                }
            
            {/* ========================================DBT I NACH================================== */}

            <div className="bg-prime-20 px-2 fs-16px fw-600 py-1">
            DBT I NACH
            </div>
            <div className="px-2 d-flex gap-1 py-2">
                <span className="pe-3 fs-16px">Payment Mandate</span>

                  <div className="d-flex align-items-center gap-1 me-2">
                <input type="radio" name="en" id="ena" value="department" onChange={handleDbtradio}  />
                <label htmlFor="ena" className="fs-14px">Enable for All Department</label>
                  </div>  

                  <div className="d-flex align-items-center gap-1">
                <input type="radio" name="en" id="pay" value="mandate" onChange={handleDbtradio}  />
                <label htmlFor="pay" className="fs-14px">Custom Payment Mandate</label>
                  </div>
            </div>
                {
                  dbtRadio === "mandate" ? 
                  <div className="px-2 d-flex gap-1 py-2 rpw">
                     <span className="pe-3 fs-16px col-2">Custom Payment Authorization</span>

                     <span className="d-flex align-items-start gap-1 me-2">
                      <input type="checkbox" name="t1" id="t1" style={{marginTop:"0.3rem"}} />
                      <label htmlFor="tl">T1</label>
                     </span>
                     <span className="d-flex align-items-start gap-1 me-2">
                      <input type="checkbox" name="t2" id="t2" style={{marginTop:"0.3rem"}} />
                      <label htmlFor="t2">T2</label>
                     </span>
                     <span className="d-flex align-items-start gap-1 me-2">
                      <input type="checkbox" name="t3" id="t3" style={{marginTop:"0.3rem"}} />
                      <label htmlFor="t3">T3</label>
                     </span>
                     <span className="d-flex align-items-start gap-1 me-2">
                      <input type="checkbox" name="t4" id="t4" style={{marginTop:"0.3rem"}} />
                      <label htmlFor="t4">T4</label>
                     </span>
                  </div> 
            
            : null
                }
            

          </div>
        </div>
      </div>

      {/* ==========================================modal================================= */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" className="bg-prime-20 px-2">
                <div className="d-flex justify-content-between align-items-center py-2">
                <span className="fw-600 fs-16px ff-poppins">Bank Account Details</span>
                <i className="icon-close_circle_fill cursor-pointer" onClick={handleClose}></i>
                </div>
          </Typography>
          <Typography>
                  <div className="ff-poppins px-1 pb-4 m-0">
                  <div className="row m-0">
              <div className="px-1 col-4">
                <span
                  className="d-flex flex-column p-0 mt-3"
                >
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  Bank
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    placeholder="Data here"
                    disabled
                  />
                </span>
              </div>

              <div className="col-4 px-1">
                <span className="d-flex flex-column p-0 mt-3">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  Branch
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>

              <div className="col-4 px-1">
                <span className="d-flex flex-column p-0 mt-3">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  Account Type
                  </label>
                  <select
                    name="type"
                    id="type"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  >
                    <option value="type1" selected>
                      {" "}
                      Type 1
                    </option>
                    <option value="type2">type2</option>
                  </select>
                </span>
              </div>

              <div className="col-4 px-1">
                <span className="d-flex flex-column p-0 mt-3">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  Account No.
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>

              <div className="col-4 px-1">
                <span className="d-flex flex-column p-0 mt-3">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  Account Name
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>

              <div className="col-4 px-1">
                <span className="d-flex flex-column p-0 mt-3">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  Bank email
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>

              <div className="col-4 px-1">
                <span className="d-flex flex-column p-0 mt-3">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  IFSC Code (Bank)
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>

              <div className="col-4 px-1">
                <span className="d-flex flex-column p-0 mt-3">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  IFSC Code (IFMS)
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>

              <div className="col-4 px-1">
                <span className="d-flex flex-column p-0 mt-3">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  Bank Contact
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>

            </div>
                  </div>
          </Typography>
        </Box>
      </Modal>

    </>
  );
}
