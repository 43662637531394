import React, { useEffect, useState } from 'react'
import './AddSchemeForm.css';
import { Button, Form } from 'react-bootstrap';
import { get_financial_year, get_scheme_type, add_schemes, getSchemeAccountBalances, getActivity, getSubActivity, get_schemes_flags } from '../../../helper/Api';
import { ADD_SCHEME_START, ADD_SCHEME_FAIL, ADD_SCHEME_SUCCESS } from '../../../RTK/Slices/SchemeSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaUpload } from 'react-icons/fa'
import { AddCommasToAmount } from '../../../helper/Utils';
import { DeleteForever } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { toast } from 'react-toastify';
import AddSchemePreview from '../../Modal/AddSchemePreview';
import { Hidden } from '@mui/material';

const AddSchemeForm = () => {

    const [fy_list, set_fy_list] = useState([]);
    const [st_list, set_st_list] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [activity, setActivity] = useState([]);
    const [subActivity, setSubActivity] = useState([]);
    const dispatch = useDispatch();
    const { authData } = useSelector((state) => state.authData);
    const navigate = useNavigate();
    const [subheadBudget, setSubheadBudget] = useState([]);
    const [schemeFlag, setSchemeFlag] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        code: '',
        type: '',
        grant_code: '',
        department: '',
        financial_year: '',
        carry_forwarded: '',
        is_activity_active: '',
        monitoring_level: '',
        attachment: [],
        sub_heads: [
            {
                name: '',
                code: '',
                financial_year: '',
                budget: '',
                provisional_budget: '',
                activity: [{
                    activity_id: '',
                    subactivity_id: '',
                    provisional_budget: 0,
                    budget: 0,
                }]
            },
        ],
        account_id: '',
        change_reimbursement_payment_flow: "",
        reimbursement_payment_levels: [],
        added_by: authData && authData.status ? authData.user.id : null
    });
    const fetch_list = async () => {
        const fy = await get_financial_year();
        const st = await get_scheme_type();
        const accounts = await getSchemeAccountBalances();
        if (fy.data.status) {
            set_fy_list(fy.data.list)
        }
        if (st.data.status) {
            set_st_list(st.data.list)
        }
        if (accounts.data.status) {
            setAccountList(accounts.data.result);
        }
    }
    const fetchActivity = async () => {
        try {
            const resp = await getActivity();
            if (resp.data.status) {
                setActivity(resp.data.result);
            }
        } catch (error) {
            setActivity([]);
        }
    }
    const fetchSchemeFlag = async () => {
        try {
            const resp = await get_schemes_flags();
            if (resp.data.status) {
                setSchemeFlag(resp.data.result);
            }
        } catch (error) {
            setSchemeFlag([]);
        }
    }
    const fetchSubActivity = async () => {
        try {
            const resp = await getSubActivity();
            if (resp.data.status) {
                setSubActivity(resp.data.result);
            }
        } catch (error) {
            setSubActivity([]);
        }
    }
    const findSubActivitiesById = (id) => {
        let listData = subActivity;
        return listData.filter(subActivity => subActivity.activity_id === id);
    };
    useEffect(() => {
        fetchSchemeFlag();
        fetch_list();
        fetchActivity();
        fetchSubActivity();
    }, [])

    const handleAttachmentChange = (event) => {
        const files = event.target.files;
        // console.log(files);
        setFormData({
            ...formData,
            attachment: files
        });
        // console.log(formData)
    };
    const handleSchemFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name == 'reimbursement_payment_levels') {
            let updatedLevels = [...formData.reimbursement_payment_levels];
            if (e.target.checked) {
                if (!updatedLevels.includes(value)) {
                    updatedLevels.push(value);
                }
            } else {
                updatedLevels = updatedLevels.filter(level => level !== value);
            }
            setFormData({
                ...formData,
                reimbursement_payment_levels: updatedLevels,
            });
        }
        else {
            setFormData({ ...formData, [name]: value })
        }
    }

    const handleSubHeadChange = (index, field, value) => {
        const newSubHeads = [...formData.sub_heads];
        newSubHeads[index][field] = value;
        setFormData({
            ...formData,
            sub_heads: newSubHeads
        });
    };
    const handleActivityChange = (subHeadIndex, activityIndex, e, activityId) => {
        const { name, value } = e.target;
        const SubHeads = [...formData.sub_heads];
        if (activityId) {
            const activityValid = SubHeads[subHeadIndex].activity.find(item => item.subactivity_id === value && item.activity_id === activityId);
            if (activityValid) {
                return alert('Sub-activity already selected');
            }
        }
        setFormData(prevFormData => {
            const updatedSubHeads = prevFormData.sub_heads.map((subHead, sIdx) => {
                if (sIdx === subHeadIndex) {
                    const updatedActivities = subHead.activity.map((activity, aIdx) => {
                        if (aIdx === activityIndex) {
                            const updatedActivity = {
                                ...activity,
                                [name]: value
                            };
                            if (name === 'activity_id') {
                                updatedActivity.subactivity_id = '';
                            }
                            return updatedActivity;
                        }
                        return activity;
                    });
                    return {
                        ...subHead,
                        activity: updatedActivities
                    };
                }
                return subHead;
            });
            return {
                ...prevFormData,
                sub_heads: updatedSubHeads
            };
        });
        console.log(formData.sub_heads);
    };

    const total_scheme_budget = () => {
        const subheads = formData.sub_heads;
        let total_budget = 0;
        subheads.map((sub) => {

            if (sub.budget) {
                total_budget = total_budget + parseFloat(sub.budget)
            }
            return {}
        })
        return AddCommasToAmount(total_budget.toFixed(2));
    }


    // const handleBankChange = (field, value) => {
    //     const newBankData = {
    //         ...formData.bank,
    //         [field]: value
    //     };
    //     setFormData({
    //         ...formData,
    //         bank: newBankData
    //     });
    // };

    const handleAddSubHead = () => {
        const newSubHeads = [...formData.sub_heads];
        if (formData.is_activity_active == '1' && formData.is_activity_active == '2') {
            newSubHeads.push({
                name: '', code: '', financial_year: '', budget: '',
                activity: [{
                    activity_id: '',
                    subactivity_id: '',
                    provisional_budget: 0,
                    budget: 0,
                }]
            });
        }
        else {
            newSubHeads.push({
                name: '', code: '', financial_year: '', budget: ''
            });
        }
        setFormData({
            ...formData,
            sub_heads: newSubHeads
        });
    };
    const currentDate = new Date().toISOString().split('T')[0];


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // console.log(formData);return false;
            const post_data = new FormData();
            for (let i = 0; i < formData.attachment.length; i++) {
                post_data.append('attachment[]', formData.attachment[i]);
            }
            post_data.append('name', formData.name);
            post_data.append('code', formData.code);
            post_data.append('type', formData.type);
            post_data.append('grant_code', formData.grant_code);
            post_data.append('department', formData.department);
            post_data.append('financial_year', formData.financial_year);
            post_data.append('is_activity_active', formData.is_activity_active);
            post_data.append('change_reimbursement_payment_flow', formData.change_reimbursement_payment_flow);
            post_data.append('reimbursement_payment_levels',JSON.stringify(formData.reimbursement_payment_levels));
            post_data.append('monitoring_level', formData.monitoring_level);
            post_data.append('account_id', formData.account_id);
            post_data.append('sub_heads', JSON.stringify(formData.sub_heads));
            post_data.append('bank', JSON.stringify(formData.bank));
            post_data.append('added_by', authData.user.id);


            dispatch(ADD_SCHEME_START());
            const add_scheme_response = await add_schemes(post_data);
            // console.log(add_scheme_response);
            // return false;
            let addSchemeResponseData = add_scheme_response.data;
            if (addSchemeResponseData.status) {
                setShowPreview(false);
                dispatch(ADD_SCHEME_SUCCESS(addSchemeResponseData.list[0]));
                navigate('/schemes');
            } else {
                toast.error(addSchemeResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                dispatch(ADD_SCHEME_FAIL(addSchemeResponseData.message));
            }
        } catch (error) {
            // console.log(error);
            dispatch(ADD_SCHEME_FAIL('error getting while add scheme'));

        }
    }

    const deleteSubhead = (index) => {
        const updatedSubHeads = [...formData.sub_heads];
        updatedSubHeads.splice(index, 1);
        setFormData({
            ...formData,
            sub_heads: updatedSubHeads,
        });
    };

    const previewhandler = (e) => {
        e.preventDefault();
        if (formData.is_activity_active == '1' && formData.monitoring_level == '1') {
            const isValid = handleAmountValidation();
            if (isValid) {
                setShowPreview(true);
            }
        }
        else {
            setShowPreview(true);
        }
    };
    const addNewActivity = (index) => {
        const newActivity = { activity_id: '', subactivity_id: '', provisional_budget: 0, budget: 0 };
        setFormData(prevFormData => {
            const updatedSubHeads = prevFormData.sub_heads.map((subHead, idx) => {
                if (idx === index && subHead.activity) {
                    return {
                        ...subHead,
                        activity: [...subHead.activity, newActivity]
                    };
                }
                return subHead;
            });
            return {
                ...prevFormData,
                sub_heads: updatedSubHeads
            };
        });
    };
    const deleteNewActivity = (index, activityIndex) => {
        if (formData.sub_heads[index].activity.length > 1) {
            setFormData(prevFormData => {
                const updatedSubHeads = prevFormData.sub_heads.map((subHead, idx) => {
                    if (idx === index && subHead.activity) {
                        const updatedActivities = [...subHead.activity];
                        updatedActivities.splice(activityIndex, 1);
                        return {
                            ...subHead,
                            activity: updatedActivities
                        };
                    }
                    return subHead;
                });
                return {
                    ...prevFormData,
                    sub_heads: updatedSubHeads
                };
            });
        }
    };
    const handleSubheadBudgetFormData = (index, activityIndex, e) => {
        const { name, value } = e.target;
        // console.log('value', value);
        let totalProvisionalBudget = 0;
        let totalBudget = 0;
        formData.sub_heads[index].activity.forEach((item, activityI) => {
            if (activityI !== activityIndex) {
                totalProvisionalBudget += parseFloat(item.provisional_budget || 0);
                totalBudget += parseFloat(item.budget || 0);
                // console.log(typeof totalProvisionalBudget, totalProvisionalBudget);
            }
        });
        if (name === 'provisional_budget') {
            // console.log(typeof totalProvisionalBudget, typeof Number(value))
            // console.log('tpd->before', totalProvisionalBudget);
            totalProvisionalBudget = Number(totalProvisionalBudget) + Number(value);
            // console.log('tpd', totalProvisionalBudget);
            if (totalProvisionalBudget > formData.sub_heads[index][name]) {
                return alert('Total provisional budget exceeds the allowed amount.');
            }
        }
        if (name === 'budget') {
            totalBudget = totalBudget + Number(value);
            if (totalBudget > formData.sub_heads[index][name]) {
                return alert('Total budget exceeds the allowed amount.');
            }
        }
        setFormData(prevFormData => {
            const updatedSubHeads = prevFormData.sub_heads.map((subHead, sIdx) => {
                if (sIdx === index) {
                    const updatedActivities = subHead.activity.map((activity, aIdx) => {
                        if (aIdx === activityIndex) {
                            const updatedActivity = {
                                ...activity,
                                [name]: value
                            };
                            return updatedActivity;
                        }
                        return activity;
                    });
                    return {
                        ...subHead,
                        activity: updatedActivities
                    };
                }
                return subHead;
            });
            return {
                ...prevFormData,
                sub_heads: updatedSubHeads
            };
        });
        console.log(formData.sub_heads);
    };
    const handleAmountValidation = () => {
        const errors = [];
        formData.sub_heads.forEach((subhead) => {
            const totalActivityProvisionalBudget = subhead.activity.reduce(
                (sum, activity) => sum + parseFloat(activity.provisional_budget || 0),
                0
            );
            const totalActivityBudget = subhead.activity.reduce(
                (sum, activity) => sum + parseFloat(activity.budget || 0),
                0
            );
            const subheadProvisionalBudget = parseFloat(subhead.provisional_budget || 0);
            const subheadBudget = parseFloat(subhead.budget || 0);
            if (subheadProvisionalBudget !== totalActivityProvisionalBudget) {
                errors.push({
                    id: subhead.id,
                    field: 'provisional_budget',
                    message: `Provisional budget does not match the total of its activities.`,
                });
            }
            if (subheadBudget !== totalActivityBudget) {
                errors.push({
                    id: subhead.id,
                    field: 'budget',
                    message: `Budget not match the total of its activities.`,
                });
            }
        });

        if (errors.length) {
            const errorMessages = errors
                .map((err) => `${err.message}`)
                .join('\n');

            toast.error(`${errorMessages}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 5000, // optional: closes after 5 seconds
            });
            return false;
        } else {
            return true;
        }
    };
    return (
        <div>
            <AddSchemePreview showPreview={showPreview} setShowPreview={setShowPreview} formData={formData}
                handleSubmit={handleSubmit} activity={activity} subActivity={subActivity} schemeTypes={st_list} financialYears={fy_list} schemeFlag={schemeFlag}></AddSchemePreview>
            <div className="p-3">
                <div className="row">
                    {/* <Form> */}
                    <div className="col-md-12">
                        <div className="add_new_user">
                            <h4> Add New Scheme</h4>
                            <div>
                                <a href="#" type="button" className="btn btn-light">Cancel</a>
                                <button type="submit" className="btn btn-primary" onClick={previewhandler}>Save</button>
                            </div>
                        </div>
                        <div className="card p-3 mt-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-sm-6 p-2">
                                            <div className="form-group row">
                                                <label for="inputDepartment"
                                                    className="col-sm-4 col-form-label">Department
                                                    Name
                                                    <span className="text-danger">*</span> :</label>
                                                <div className="col-sm-8">
                                                    <input type="text"
                                                        className="form-control" id="department"
                                                        name='department'
                                                        maxLength={50}
                                                        required
                                                        onChange={handleSchemFormData}
                                                        placeholder="Enter Department Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-2">
                                            <div className="form-group row">
                                                <label for="inputType" className="col-sm-4 col-form-label">Type of
                                                    Scheme
                                                    <span className="text-danger">*</span> :</label>
                                                <div className="col-sm-8">
                                                    <select className="form-control "
                                                        id="type" name='type'
                                                        required
                                                        onChange={handleSchemFormData}>
                                                        <option value="">Select Scheme Type</option>
                                                        {
                                                            st_list.map((st) => {
                                                                return (
                                                                    <option value={st.id}
                                                                        key={st.id}>{st.title}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-2">
                                            <div className="form-group row">
                                                <label for="inputScheme" className="col-sm-4 col-form-label">Scheme
                                                    Name
                                                    <span className="text-danger">*</span> :</label>
                                                <div className="col-sm-8">
                                                    <input type="text" className="form-control" id="name"
                                                        name='name'
                                                        required
                                                        maxLength={200}
                                                        onChange={handleSchemFormData}
                                                        placeholder="Enter Scheme Name" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-2">
                                            <div className="form-group row">
                                                <label for="inputGrant" className="col-sm-4 col-form-label">Grant
                                                    Code
                                                    <span className="text-danger">*</span> :
                                                </label>
                                                <div className="col-sm-8">
                                                    <input type="number" className="form-control" id="grant_code"
                                                        required
                                                        name='grant_code'
                                                        onChange={handleSchemFormData}
                                                        placeholder="Enter Grant Code" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-2">
                                            <div className="form-group row">
                                                <label for="inputFinancial"
                                                    className="col-sm-4 col-form-label">
                                                    Financial Year
                                                    <span className="text-danger">*</span> :</label>
                                                <div className="col-sm-8">
                                                    <select className="form-control " id="financial_year"
                                                        name='financial_year'
                                                        onChange={handleSchemFormData}
                                                        required
                                                    >
                                                        <option value="">---select year---</option>
                                                        {
                                                            fy_list.map((fy) => {
                                                                return (
                                                                    <option value={fy.id}
                                                                        key={fy.id}>{fy.year}</option>
                                                                );
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 p-2">
                                            <div className="form-group row">
                                                <label for="inputSchemee" className="col-sm-4 col-form-label">Scheme
                                                    Code <span className="text-danger">*</span> :</label>
                                                <div className="col-sm-8">
                                                    <input type="number" className="form-control" id="code"
                                                        name='code'
                                                        maxLength={13}
                                                        required
                                                        placeholder="Enter Scheme Code"
                                                        onChange={handleSchemFormData}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 p-2">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="input-group">
                                                        <label className="input-group-btn my-0">
                                                            Upload Scheme Document

                                                        </label>
                                                        <label className='p-1'><input id="" type="file"
                                                            className='form-control'
                                                            onChange={handleAttachmentChange}
                                                            name='attachment'
                                                            accept=".pdf,.jpeg,.jpg,.png"
                                                            multiple /></label>

                                                    </div>
                                                </div>

                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label for="inputType" className="col-sm-4 col-form-label">Activity wise Budgeting
                                                            <span className="text-danger">*</span> :</label>
                                                        <div className="col-sm-8">
                                                            <select className="form-control "
                                                                id="activity_wise" name='is_activity_active'
                                                                required
                                                                onChange={handleSchemFormData}>
                                                                <option value="">---Select---</option>
                                                                <option value="1">YES</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label for="inputType" className="col-sm-4 col-form-label">Bank Account
                                                            <span className="text-danger">*</span> :</label>
                                                        <div className="col-sm-8">
                                                            <select className="form-control " name='account_id'
                                                                required
                                                                onChange={handleSchemFormData}>
                                                                <option value="">---Select---</option>
                                                                {
                                                                    accountList.map(e => {
                                                                        return <option value={e.id}>{e.account_name} [A/c No: {e.account_no}]</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formData.is_activity_active == '1' && <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label for="inputType" className="col-sm-4 col-form-label">Monitor/Control Level
                                                            <span className="text-danger">*</span>:</label>
                                                        <div className="col-sm-8">
                                                            <select className="form-control"
                                                                id="activity_wise" name='monitoring_level'
                                                                required
                                                                onChange={handleSchemFormData}>
                                                                <option value="">---Select---</option>
                                                                {schemeFlag.map((option) => (
                                                                    <option key={option.id} value={option.id}>
                                                                        {option.name}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>}
                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label for="inputType" className="col-sm-4 col-form-label">Change Payment Flow
                                                            <span className="text-danger">*</span>:</label>
                                                        <div className="col-sm-8">
                                                            <select className="form-control"
                                                                id="activity_wise" name='change_reimbursement_payment_flow'
                                                                required
                                                                onChange={handleSchemFormData}>
                                                                <option value="">---Select---</option>
                                                                <option value="1">YES</option>
                                                                <option value="0">NO</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                {formData.change_reimbursement_payment_flow=="1" &&<div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputType" className="col-sm-4 col-form-label">
                                                            Payment Levels <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="checkbox"
                                                                    id="T1"
                                                                    name="reimbursement_payment_levels"
                                                                    value="T1"
                                                                    checked={formData.reimbursement_payment_levels.includes('T1')}
                                                                    onChange={handleSchemFormData}
                                                                    className="form-check-input"
                                                                />
                                                                <label className="form-check-label" htmlFor="T1">
                                                                    T1
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="checkbox"
                                                                    id="T2"
                                                                    name="reimbursement_payment_levels"
                                                                    value="T2"
                                                                    checked={formData.reimbursement_payment_levels.includes('T2')}
                                                                    onChange={handleSchemFormData}
                                                                    className="form-check-input"
                                                                />
                                                                <label className="form-check-label" htmlFor="T2">
                                                                    T2
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="checkbox"
                                                                    id="T3"
                                                                    name="reimbursement_payment_levels"
                                                                    value="T3"
                                                                    checked={formData.reimbursement_payment_levels.includes('T3')}
                                                                    onChange={handleSchemFormData}
                                                                    className="form-check-input"
                                                                />
                                                                <label className="form-check-label" htmlFor="T3">
                                                                    T3
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    type="checkbox"
                                                                    id="T4"
                                                                    name="reimbursement_payment_levels"
                                                                    value="T4"
                                                                    checked={formData.reimbursement_payment_levels.includes('T4')}
                                                                    onChange={handleSchemFormData}
                                                                    className="form-check-input"
                                                                />
                                                                <label className="form-check-label" htmlFor="T4">
                                                                    T4
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 p-2">
                                            <div className="border mt-3">
                                                <div className="p-1">
                                                    <p><span>Total Scheme Budget </span>: <span
                                                        className='scheme_budget'>{total_scheme_budget()}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            formData.sub_heads.map((subHead, i) => {
                                                return (
                                                    <>
                                                        <div className='container-fluid'>
                                                            <div className='row eachsubheadBlock'>
                                                                <div className="col-sm-6 p-2">
                                                                    <div className="form-group row">
                                                                        <label for="inputSub"
                                                                            className="col-sm-4 col-form-label">Sub
                                                                            Head Name {i + 1}
                                                                            <span className="text-danger">*</span> :
                                                                        </label>
                                                                        <div className="col-sm-8">
                                                                            <input type="text"
                                                                                className="form-control"
                                                                                id="name"
                                                                                maxLength={100}
                                                                                value={subHead.name}
                                                                                required
                                                                                onChange={(e) => handleSubHeadChange(i, 'name', e.target.value)}
                                                                                placeholder="Enter Sub Head Name" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 p-2">
                                                                    <div className="form-group row">
                                                                        <label for="inputFinancials"
                                                                            className="col-sm-4 col-form-label">
                                                                            Date
                                                                            <span className="text-danger">*</span> :</label>
                                                                        <div className="col-sm-8 ">
                                                                            <Form.Control
                                                                                type="date"
                                                                                max={currentDate}
                                                                                name="budget_date"
                                                                                value={subHead.budget_date}
                                                                                onChange={(e) => handleSubHeadChange(i, 'budget_date', e.target.value)}
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6 p-2">
                                                                    <div className="form-group row">
                                                                        <label for="inputHead"
                                                                            className="col-sm-4 col-form-label">Sub
                                                                            Head Code {i + 1}
                                                                            <span className="text-danger">*</span> :</label>
                                                                        <div className="col-sm-8">
                                                                            <input type="number"
                                                                                required
                                                                                className="form-control"
                                                                                id="code"
                                                                                value={subHead.code}
                                                                                onChange={(e) => handleSubHeadChange(i, 'code', e.target.value)}
                                                                                placeholder="Enter Sub Head Code" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="col-sm-6 p-2">
                                                                    <div className="form-group row">
                                                                        <label for="inputBudget"
                                                                            className="col-sm-4 col-form-label">Opening Balance Provisioned Budget
                                                                            :</label>
                                                                        <div className="col-sm-8">
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                {/* <div className="col-sm-6 p-2">
                                                                    <div className="form-group row">
                                                                        <label for="inputBudget"
                                                                            className="col-sm-4 col-form-label">Opening Balance Released Budget
                                                                            :</label>
                                                                        <div className="col-sm-8">
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className="row">
                                                                    <table className="table">
                                                                        <thead className="text-center-custom">
                                                                            <tr>
                                                                                <th scope="col" width="150px"><span className='not_visible'>Activity</span></th>
                                                                                <th scope="col" width="150px"><span className='not_visible'>Subactivity</span></th>
                                                                                <th scope="col" width="300px">Opening Provisioned Budget</th>
                                                                                <th scope="col" width="300px">Opening Released Budget</th>
                                                                                <th scope="col" width="150px"><span className='not_visible'>Action</span></th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td>
                                                                                    <input type="number"
                                                                                        className="form-control"
                                                                                        id="provisional_budget"
                                                                                        value={subHead.provisional_budget}
                                                                                        onChange={(e) => handleSubHeadChange(i, 'provisional_budget', e.target.value)}
                                                                                        placeholder="₹ Enter Provisional Budget Amount" />
                                                                                </td>
                                                                                <td>
                                                                                    <input type="number"
                                                                                        className="form-control"
                                                                                        id="budget"
                                                                                        value={subHead.budget}
                                                                                        onChange={(e) => handleSubHeadChange(i, 'budget', e.target.value)}
                                                                                        placeholder="₹ Enter Budget Amount" />
                                                                                </td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </thead>
                                                                    </table>
                                                                </div>
                                                                {formData.is_activity_active == '1' && formData.monitoring_level == '1' && <div className="row">
                                                                    <table className="table table-bordered">
                                                                        <thead className="text-center-custom">
                                                                            <tr>
                                                                                <th scope="col" width="150px">Activity</th>
                                                                                <th scope="col" width="150px">Subactivity</th>
                                                                                <th scope="col" width="300px">Opening Provision</th>
                                                                                <th scope="col" width="300px">Opening Release</th>
                                                                                <th scope="col" width="150px">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody className="text-center-custom">
                                                                            {subHead?.activity.map((activityData, index) => (
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        <select
                                                                                            className="form-control"
                                                                                            name='activity_id'
                                                                                            value={activityData.activity_id}
                                                                                            onChange={(e) => handleActivityChange(i, index, e)}>
                                                                                            <option value="">---Select---</option>
                                                                                            {activity.map(e => (
                                                                                                <option key={e.id} value={e.id}>{e.activity}</option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </td>
                                                                                    <td>
                                                                                        <select
                                                                                            className="form-control"
                                                                                            name='subactivity_id'
                                                                                            value={activityData.subactivity_id}
                                                                                            onChange={(e) => handleActivityChange(i, index, e, activityData.activity_id)}>
                                                                                            <option value="">---Select---</option>
                                                                                            {findSubActivitiesById(activityData.activity_id).map(e => (
                                                                                                <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            name='provisional_budget'
                                                                                            placeholder="Enter Provision Budget"
                                                                                            value={activityData?.provisional_budget}
                                                                                            onChange={(e) => handleSubheadBudgetFormData(i, index, e)}
                                                                                            disabled={subHead.provisional_budget <= 0}
                                                                                        ></input>
                                                                                    </td>
                                                                                    <td>
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control"
                                                                                            name='budget'
                                                                                            placeholder="Enter Release Budget"
                                                                                            value={activityData?.budget}
                                                                                            disabled={subHead.budget <= 0}
                                                                                            onChange={(e) => handleSubheadBudgetFormData(i, index, e)}
                                                                                        />
                                                                                    </td>
                                                                                    <td>
                                                                                        <button className="btn btn-primary mx-2" onClick={(e) => addNewActivity(i)}>
                                                                                            <AddIcon />
                                                                                        </button>
                                                                                        <button type="button"
                                                                                            className="btn btn-danger mx-2"
                                                                                            onClick={(e) => deleteNewActivity(i, index)}>
                                                                                            <DeleteForever /></button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>}
                                                            </div>
                                                            {
                                                                i > 0 && <button type="button"
                                                                    className="btn btn-outline-danger mb-2"
                                                                    onClick={() => deleteSubhead(i)}>Remove
                                                                    Sub Head {i + 1}<DeleteForever /></button>

                                                            }
                                                        </div>
                                                    </>
                                                );
                                            })
                                        }
                                        <div className="col-sm-12 mt-3 mb-3 ">
                                            <button type="button" className="btn btn-outline-primary"
                                                onClick={handleAddSubHead}><i className="fa fa-plus"></i>
                                                Add Sub Head
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </Form> */}
                </div>
            </div>
        </div>
    )
}

export default AddSchemeForm
