import React, {useState} from 'react'
// import { useSelector } from 'react-redux';
import Tools from "../../../components/Tools/Tools";
import ManageDisbursmentHeader from "../../../components/Disbursment/ManageDisbursmentHeader";
import RoyaltyReportList from '../../../components/Reconciliation/Royalty/RoyaltyReportList';


const RoyaltyReport = () => {
    // const {authData}=useSelector((state)=>state.authData);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');
    const [dateColumn, setDateColumn] = useState('voucher_date');


    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setToSearchStr(searchStr);
        setDateColumn(dateColumn);
    };

    return (
        <div>
            <Tools/>
            <div className='addNewScheme' >
                <div className="add_new_user">
                    <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} dateColumn={dateColumn} searchStr={searchStr} type={'Royalty'} />
                </div>
            </div>
            <div className='scheme p-3'>
                <RoyaltyReportList fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr}/>
            </div>
        </div>
    )
}

export default RoyaltyReport
