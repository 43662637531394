// import { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { updateSchemeBankAccountApproval } from '../../../helper/Api';
const SchemeAccountPreview = ({ showPreview, setShowPreview, formData, handleSubmit, department,scheme,accounts}) => {
    const { authData } = useSelector((state) => state.authData);
    const isReadOnly = showPreview;
    const handleClose = () => {
        setShowPreview(false);
    }
    const onApprove = async (status) => {
        formData.l2_approval = authData.user.user_type == 'l2' ? status : formData.l2_approval;
        formData.l3_approval = authData.user.user_type == 'l3' ? status : formData.l3_approval;
        let approvalData = {
            l2_approval: formData.l2_approval,
            l3_approval: formData.l3_approval,
            l2_remark: formData.l2_remark,
            l3_remark: formData.l3_remark
        }
        const { data } = await updateSchemeBankAccountApproval(approvalData, formData.id);
        if (data.status) {
            setShowPreview(false);
        }
    }
    return (
        <div>
            <Modal size="lg" show={showPreview} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{formData?.id ? 'Update Scheme Account Details' : 'Add Scheme Account Details'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="department_id">
                                        <Form.Label>Department<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            as="select"
                                            name='department_id'
                                            required
                                            value={formData.department_id}
                                            disabled={isReadOnly}
                                        >
                                            <option value="">Select Department Type</option>
                                            {
                                                department.map((dept) => {
                                                    return (
                                                        <option value={dept.id}>{dept.name}</option>
                                                    );
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="department_id">
                                        <Form.Label>Scheme<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            as="select"
                                            name='scheme_id'
                                            required
                                            value={formData.scheme_id}
                                            disabled={isReadOnly}
                                        >
                                            <option value="">Select Scheme Type</option>
                                            {
                                                scheme.map((dept) => {
                                                    return (
                                                        <option value={dept.id}>{dept.code}</option>
                                                    );
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="department_id">
                                        <Form.Label>Bank Account<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            as="select"
                                            name='account_id'
                                            required
                                            value={formData.account_id}
                                            disabled={isReadOnly}
                                        >
                                            <option value="">Select Bank Account</option>
                                            {
                                                accounts.map((dept) => {
                                                    return (
                                                        <option value={dept.id}>{dept.account_name}</option>
                                                    );
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                authData.user.user_type == 'l1' ?
                                    <>
                                        <label>L1 Remark</label>
                                        <textarea className='form-control' name="l1_remark" readOnly={isReadOnly} value={formData.l1_remark}></textarea>
                                    </>
                                    : ''
                            }
                            <br></br>
                            {
                                (formData.l2_approval !== 1 || formData.l3_approval !== 1) && authData.user.user_type === 'l3' ?
                                    <Button variant="primary" type="submit">
                                        {formData?.id ? 'Update Scheme Account' : 'Add Scheme Account'}
                                    </Button>
                                    : ''
                            }
                            {
                                authData.user.user_type === 'l2' && formData.l2_approval == null ?
                                    <>
                                        <label>L2 Remark</label>
                                        <textarea className='form-control' name="l2_remark" readOnly={isReadOnly} value={formData.l2_remark}></textarea>
                                        {!isReadOnly &&
                                            <>
                                                <button type="button" className="btn btn-danger w-30 mt-2" onClick={() => onApprove(0)}>Reject</button>
                                                <button type="button" className="btn btn-primary w-30 mt-2" style={{ marginLeft: '10px' }} onClick={() => onApprove(1)}>Approve</button>
                                            </>
                                        }
                                    </>
                                    : ''
                            }
                            {
                                authData.user.user_type === 'l3' && formData.l3_approval == null ?
                                    <>
                                        <label>L2 Remark</label>
                                        <textarea className='form-control' name="l3_remark" readOnly={isReadOnly} value={formData.l3_remark}></textarea>
                                        {!isReadOnly &&
                                            <>
                                                <button type="button" className="btn btn-danger w-30 mt-2" onClick={() => onApprove(0)}>Reject</button>
                                                <button type="button" className="btn btn-primary w-30 mt-2" style={{ marginLeft: '10px' }} onClick={() => onApprove(1)}>Approve</button>
                                            </>
                                        }
                                    </>
                                    : ''
                            }
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        CLOSE
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default SchemeAccountPreview
