import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  Box,
  TablePagination,
} from "@mui/material";
import "./Login.css";
import TextField from "@mui/material/TextField";
import {
  get_my_departments,
  login_user_via_verify_otpV2,
  setAuthToken,
  switch_department,
} from "../../../helper/Api";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
} from "../../../RTK/Slices/AuthSlice";
import { useDispatch } from "react-redux";

const DepartmentOtp = ({
  switchFormClose,
  colFull,
  departmentId,
  otp,
  userType,
}) => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [route, setRoute] = useState("/");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const contentRef = useRef(null);
  const [formData, setformData] = useState({
    email: null,
    otp: otp && otp ? Number(otp) : null,
    department_id: null,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setformData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  // console.log('auth', authData?.user);
  const validateForm = () => {
    if (!formData.department_id || !formData.email || !formData.otp) {
      return false;
    }
    return true;
  };

  // =============================form submit====================================

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      toast.error("All Fields Required", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }
    const loginResponse = await login_user_via_verify_otpV2(formData);
    if (loginResponse.data.status) {
      dispatch(LOGIN_SUCCESS(loginResponse.data));
      // toast.success(loginResponse.data.message, {
      //   position: toast.POSITION.TOP_CENTER,
      // });
      // sessionStorage.removeItem("departments");
      // sessionStorage.removeItem("email");
      sessionStorage.setItem("is_loggedIn", true);
      sessionStorage.setItem("userDetails", JSON.stringify(loginResponse.data));
      sessionStorage.setItem(
        "token",
        JSON.stringify(loginResponse?.data?.token)
      );
      const { token } = loginResponse?.data;
      setAuthToken(token);

      // Store the intended route in sessionStorage
      // sessionStorage.setItem("redirectAfterLogin", route);
      if(switchFormClose){
         switchFormClose();
         window.location.reload();
         if(route === '/'){
           sessionStorage.setItem("redirectAfterLogin", '/');
         }
         else if(route === '/beneficiary-reimbursement'){
           sessionStorage.setItem("redirectAfterLogin", '/beneficiary-reimbursement');
         }
         else if(route === '/payroll-salary'){
           sessionStorage.setItem("redirectAfterLogin", '/payroll-salary');
         }
         else if(route === '/approval'){
          sessionStorage.setItem("redirectAfterLogin", '/');
        }
        else if(route === '/ppas'){
          sessionStorage.setItem("redirectAfterLogin", '/');
        }
        else if(route === '/budget'){
          sessionStorage.setItem("redirectAfterLogin", '/');
        }
        else if(route === '/vendor'){
          sessionStorage.setItem("redirectAfterLogin", '/');
        }
        else if(route === '/report'){
          sessionStorage.setItem("redirectAfterLogin", '/');
        }
         
      }

      if(route === '/'){
        navigate('/');
      }
      else if(route === '/beneficiary-reimbursement'){
        navigate('/beneficiary-reimbursement');
      }
      else if(route === '/payroll-salary'){
        navigate('/payroll-salary');
      }
      else if(route === '/approval'){
        localStorage.setItem('routes', 'approval');
        navigate('/');
      }
      else if(route === '/ppas'){
        localStorage.setItem('routes', 'ppas');
        navigate('/');
      }
      else if(route === '/budget'){
        localStorage.setItem('routes', 'budget');
        navigate('/');
      }
      else if(route === '/vendor'){
        localStorage.setItem('routes', 'vendor');
        navigate('/');
      }
      else if(route === '/report'){
        localStorage.setItem('routes', 'report');
        navigate('/');
      }

    } else {
      toast.error(loginResponse.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  // In the component where `handleSubmit` is used
  useEffect(() => {
    // Check if there's a stored route in sessionStorage after page reload
    const redirectRoute = sessionStorage.getItem("redirectAfterLogin");
    if (redirectRoute) {
      navigate(redirectRoute);
      sessionStorage.removeItem("redirectAfterLogin"); // Clear the redirect route after navigating
    }
  }, []);

  // console.log(route)

  useEffect(() => {
    const departments = JSON.parse(sessionStorage.getItem("departments"));
    if (departments) {
      setData(departments);
    }
    const email = sessionStorage.getItem("email");
    if (email) {
      setformData((prevData) => ({
        ...prevData,
        email: email,
      }));
    }
  }, []);

  // ==================================select user role====================================

  const handleRadioChange = (id) => {
    const selectedId = id;
    setSelectedRow(selectedId);
    setformData((prevData) => ({
      ...prevData,
      department_id: selectedId,
    }));
  };

  // ============================sorting---------------------------======

  const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

  // Sort data based on column and direction
  const sortedData = [...data].sort((a, b) => {
    if (!sortConfig.key) return 0; // No sorting by default
    const { key, direction } = sortConfig;
    if (a[key] < b[key]) return direction === "ascending" ? -1 : 1;
    if (a[key] > b[key]) return direction === "ascending" ? 1 : -1;
    return 0;
  });

  // Handle sorting
  const handleSort = (key) => {
  // Dynamically toggle direction
  const direction = sortConfig.direction === "ascending" ? "descending" : "ascending";
  setSortConfig({ key, direction });
};

  return (
    <>
      <div
        className={`${
          colFull && colFull ? "col-12" : "col-12"
        }  m-0 p-0 d-flex justify-content-center align-items-center border-secondary-100-8px `}
        style={{ borderRadius: "25px" }}
      >
        <div>
          <div
            className="otp-box"
            style={{
              backgroundColor: "#f2f2f2",
              padding: "20px",
              borderRadius: "20px",
              boxShadow: "0px 4px 8px 0px rgba(0,0,0,0.2)",
            }}
          >
            {
              switchFormClose &&
            <div className="position-relative ">
            <span className="border rounded-circle d-block d-flex justify-content-center align-items-center cursor-pointer" style={{height:"20px", width:"20px" , position:"absolute", right:"0", }} onClick={()=>switchFormClose()}>x</span>
            </div>
            }

            <div>
              <h2 className="fs-18px fw-500 text-center mb-3">
                Choose Your Role
              </h2>
            </div>
            <TableContainer>
              <div style={{ height: "330px" }}>
                <Table
                  size="small"
                  className="department-table overflow-y-auto"
                  style={{ height: "100px" }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="p-3"
                        style={{
                          textAlign: "center",
                          color: "white",
                          padding: "8px",
                          background: "#28326F",
                        }}
                        onClick={() => handleSort("user_type")}
                      >
                        <div className="d-flex gap-1 align-items-center">
                          <span>User Role</span>
                          <span>
                            {sortConfig.direction === "ascending" ? " ▲" : " ▼"}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          color: "white",
                          padding: "8px",
                          background: "#28326F",
                        }}
                        onClick={() => handleSort("department_level")}
                      >
                        <div className="d-flex align-items-center">
                          <span>Department Level</span>
                          <span>
                            {sortConfig.direction === "ascending" ? " ▲" : " ▼"}
                          </span>
                        </div>
                      </TableCell>
                      <TableCell
                        onClick={() => handleSort("code")}
                        style={{
                          textAlign: "center",
                          color: "white",
                          padding: "8px",
                          background: "#28326F",
                        }}
                      >
                      <div className="d-flex gap-1 align-items-center">

                        <span>Department Code</span>

                        <span>
                          {sortConfig.direction === "ascending" ? " ▲" : " ▼"}
                        </span>
                      </div>
                      </TableCell>
                      <TableCell
                        style={{
                          textAlign: "center",
                          color: "white",
                          padding: "8px",
                          background: "#28326F",
                        }}
                        onClick={() => handleSort("name")}
                      >
                        <div className="d-flex gap-1 align-items-center">

                        <span>Department Name</span>

                        <span>
                          {sortConfig.direction === "ascending" ? " ▲" : " ▼"}
                        </span>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((row, index) => (
                      <TableRow
                        key={row?.id}
                        onClick={() =>
                          departmentId &&
                          departmentId == row?.department_id &&
                          row?.user_type == userType
                            ? null
                            : handleRadioChange(row?.id)
                        }
                        className={` cursor-pointer ${
                          departmentId &&
                          departmentId == row?.department_id &&
                          row?.user_type == userType
                            ? "tr-text-black-10 cursor-disable"
                            : null
                        }  ${
                          selectedRow == row?.id
                            ? "bg-active-prime-100 tr-text-white"
                            : "bg-hover-prime-20"
                        } `}
                      >
                        <TableCell align="center" className="p-3">
                          {row?.user_type}
                        </TableCell>
                        <TableCell align="center">
                          {row?.department_level}
                        </TableCell>
                        <TableCell align="center">{row?.code}</TableCell>
                        <TableCell align="center">{row?.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            </TableContainer>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: selectedRow
                  ? `${contentRef.current?.scrollHeight}px`
                  : "0px",
                transition: "height 0.3s ease-in-out",
                overflow: "hidden",
              }}
              ref={contentRef}
            >
              {/* OTP Input */}
              <div className="d-flex justify-content-between align-items-center gap-3 my-4 w-100">
                {otp && otp ? null : (
                  <TextField
                    margin="normal"
                    requi red
                    id="otp"
                    label="Enter OTP"
                    type="number"
                    name="otp"
                    onChange={handleChange}
                    autoComplete="otp"
                    autoFocus
                    value={formData?.otp}
                    sx={{ borderRadius: "8px" }}
                    className="text-secondary-100 w-100"
                  />
                )}
                <select
                  id="route"
                  name="route"
                  className="text-secondary-100 w-100 rounded-1 mt-2 w-50 bg-transparent px-3"
                  onChange={(e) => setRoute(e.target.value)}
                  style={{ height: "56px" }}
                >
                  <option value="/">Go To Dashboard</option>
                  <option value="/approval">Approval & Payment</option>
                  <option value="/ppas">PPA</option>
                  <option value="/budget">Budget</option>
                  <option value="/vendor">Vendor</option>
                  <option value="/beneficiary-reimbursement">DBT</option>
                  <option value="/payroll-salary">Salary</option>
                  <option value="/report">Reports</option>
                </select>
              </div>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{
                  display: "block",
                  margin: "auto",
                  padding: "10px 20px",
                  textAlign: "center",
                  background: "#2490EA",
                  width: "60%",
                }}
              >
                Sign in
              </Button>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
};
export default DepartmentOtp;
