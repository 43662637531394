import React from 'react'
import Tools from "../../components/Tools/Tools"
import DepartmentUserForm from '../../components/Users/DepartmentUser'

const AddDepartmentUser = () => {
  return (
    <div>
      {/* <Tools></Tools> */}
      <DepartmentUserForm></DepartmentUserForm>
    </div>
  )
}

export default AddDepartmentUser