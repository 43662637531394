import React, { useState, useEffect } from 'react'
import Tools from '../../components/Tools/Tools'
import { useSelector} from 'react-redux';
import ManageDisbursmentHeader from '../../components/Disbursment/ManageDisbursmentHeader'
import BeneficiaryReimbursementList from '../../components/BeneficiaryReimbursement/BeneficiaryReimbursementList';
import {Tab, Tabs} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {useParams} from 'react-router-dom';

export default function BeneficiaryReimbursementListPage() {
    const navigate = useNavigate();
    const { authData } = useSelector((state) => state.authData);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateColumn, setDateColumn] = useState('reimbursement_order_date');
    const [searchStr, setToSearchStr] = useState('');
    const [page, setPage] = useState('approve');
    const {listType} = useParams();
    const [heading, setHeading] = useState('');
    const [departmentId, setDepartmentId] = useState('');


    const defaultSelection = () => {
        if(listType === 'approval') {
            setHeading("Approval");
            setPage("approve");
        } else if(listType === 'payment') {
            setHeading("Payment");
            setPage("payment");
        } else if(listType === 'dbt_payment') {
            setHeading("DBT/Bulk/NACH Payments");
            setPage("dbt_payment");
        }
    }
    useEffect(() => {
        defaultSelection();
    }, [listType])

    const handleDateChange = (start, end, dateColumn, searchStr, fyId, tier, departmentId) => {
        setFromDate(start);
        setToDate(end);
        setDateColumn(dateColumn);
        setToSearchStr(searchStr);
        setDepartmentId(departmentId);

    };
    const handleTabSelect = (key) => {
        if (key == 'approve') {
            setHeading("Approval");
            setPage("approve");
            navigate('/beneficiary-reimbursement/approval');
        } else if(key == 'payment') {
            setHeading("Payment");
            setPage("payment");
            navigate('/beneficiary-reimbursement/payment');
        } else if(key == 'dbt_payment') {
            setHeading("DBT/Bulk/NACH Payments");
            setPage("dbt_payment");
            navigate('/beneficiary-reimbursement/dbt_payment');
        }
    };
    return (
        <div>
            {/* <Tools /> */}
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} dateColumnType={dateColumn} heading={heading} type="Beneficiary Reimbursement" />
            <div className='scheme p-2'>
                { (listType && listType !== '') ?  
                    <Tabs
                    id="uncontrolled-tab-example"
                    className="mb-3"
                    activeKey={page}
                    onSelect={handleTabSelect}
                >
                    {<Tab eventKey="approve" title="Approval" className='p-2'>
                        <BeneficiaryReimbursementList fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr} departmentId={departmentId} />
                    </Tab>}
                    {<Tab eventKey="payment" title="Payments" className='p-2'>
                        <BeneficiaryReimbursementList fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr} departmentId={departmentId} />
                    </Tab>}
                    {<Tab eventKey="dbt_payment" title="DBT/Bulk/NACH" className='p-2'>
                        <BeneficiaryReimbursementList fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr} departmentId={departmentId} />
                    </Tab>}
                </Tabs>
                : <BeneficiaryReimbursementList fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr} departmentId={departmentId} /> 
                }
                

                
            </div>
        </div>
    )
}