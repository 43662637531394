import React, { useState, useEffect, useRef } from 'react'
import './MprReport.css';
import { get_mpr, get_financial_year, get_departments } from '../../../helper/Api';
import { useNavigate } from 'react-router-dom';
import { useSelector} from 'react-redux';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { toast } from 'react-toastify';
import LootieLoaderDot from '../../LootieLoader/LootieLoaderDot';
const MprReport = () => {
    const { authData } = useSelector((state) => state.authData);
    const [loading, setLoader] = useState(false);
    const [expandedIndex, setExpandedIndex] = useState(null);
    const navigate = useNavigate();
    const [fy_list, set_fy_list] = useState([])
    const [tier, setTier] = useState('');
    const [department, setDepartment] = useState([]);
    const [departmentId, setDepartmentId] = useState('');
    const toggleCollapse = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };
    const [schemesList, setSchemesList] = useState([]);
    const fy_id = useRef('')
    const month = useRef('')

    const fetchDepartments = async (level) => {
            try {
                const departments = await get_departments({ department_level: level, parent_department_id:  authData.user.department_id});
                if (departments.data.status) {
                    setDepartment(departments.data.result);
                }
            } catch (error) {
                console.log("get_departments error");
            }
        }

    const handleInput = async (e) => {
        const name = e.target.name;
        let value = e.target.value;
        if (name === 'tier') {
            await fetchDepartments(value);
        }else if (name === 'department_id') {
            setDepartmentId(value);
        }
    }



    const fetchSchemes = async () => {
        setLoader(true);
        if (!fy_id.current || !month.current) {
            toast.error("Please select financial Year and month", {
                position: toast.POSITION.TOP_CENTER
            });
            setLoader(false);
            return;
        }
        try {
            const schemes = await get_mpr({
                fy_id: fy_id.current,
                month: month.current,
                department_id: departmentId || ''
            });
            if (schemes.data.status) {
                setLoader(false);
                if (schemes.data.schemes) {
                    setSchemesList(schemes.data.schemes);
                }
            } else {
                // dispatch(GET_SCHEME_FAIL(schemes.data.message));
            }
        } catch (error) {
            setLoader(false);
            // dispatch(GET_SCHEME_FAIL('something went wrong'));
        }
    }

    const fyList = async () => {
        try {
            const fy = await get_financial_year();
            if (fy.data.status) {
                set_fy_list(fy.data.list)
            }
        } catch (error) {
        }
    }
    const handleChange = (event) => {
        const selectedValue = event.target.value;
        fy_id.current = selectedValue;
    };
    const handleMonthChange = (event) => {
        const selectedMonth = event.target.value;
        month.current = selectedMonth; // Update the ref with the selected value.
    };
    useEffect(() => {
        fyList();
    }, [fy_id.current]);
    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const styles = {
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(255, 255, 255, 0.8)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999,
        },
        spinner: {
          color: '#3f51b5',
        },
    };
      
    return (
        <>
            {loading && <div >
            <LootieLoaderDot/>
            </div>}
            <div>
                <div className="p-3">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4>MPR : Monthly Progress Report</h4>
                                {/* <button className="btn btn-primary mr-2" style={{ marginLeft: "440px" }}>Download PDF</button>
                                <button className="btn btn-primary">Download Excel</button> */}
                            </div>
                            <div className="card p-3">
                                <div className="row">
                                    <div className="col-md-10 offset-md-1">
                                        <div className="row">
                                            <div className="col-sm-12 p-2">
                                                <div className="row ">
                                                    <div className="col-sm-2">
                                                        <label><b>For Financial Year</b></label>
                                                        <select className="form-control" name="financial_year_id" onChange={handleChange}>
                                                            <option value="">---select year---</option>
                                                            {
                                                                fy_list.map((fy) => {
                                                                    return (
                                                                        <option value={fy.id}
                                                                            key={fy.id}>{fy.year}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-sm-2">
                                                        <label><b>Month of</b></label>
                                                        <select className="form-control" name="month" onChange={handleMonthChange}>
                                                            <option value="">---select month---</option>
                                                            {months.map((month, index) => (
                                                                <option key={index} value={index + 1}>
                                                                    {month}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <label><b>Department Level</b></label>
                                                        <select
                                                            className="form-control"
                                                            name="tier"
                                                            onChange={handleInput}
                                                        >
                                                            <option value="">Select Department Tier</option>
                                                            {
                                                                authData.user.department_details.department_level === 'T1' && <option value="T1" selected={tier === 'T1' ? true : false}>T1</option>
                                                            }

                                                            {
                                                                (authData.user.department_details.department_level === 'T1' || authData.user.department_details.department_level === 'T2') && <option value="T2" selected={tier === 'T2' ? true : false}>T2</option>
                                                            }

                                                            {
                                                                (authData.user.department_details.department_level === 'T1' || authData.user.department_details.department_level === 'T2' || authData.user.department_details.department_level === 'T3') && <option value="T3" selected={tier === 'T3' ? true : false}>T3</option>
                                                            }

                                                            {
                                                                (authData.user.department_details.department_level === 'T1' || authData.user.department_details.department_level === 'T2' || authData.user.department_details.department_level === 'T3' || authData.user.department_details.department_level === 'T4') && <option value="T4" selected={tier === 'T4' ? true : false}>T4</option>
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className='col-md-3'>
                                                        <label><b>Department</b></label>
                                                        <select
                                                            className="form-control"
                                                            name="department_id"
                                                            onChange={handleInput}
                                                        >
                                                            <option value="">Select Department</option>
                                                            {department.map(option => (
                                                                                    <option 
                                                                                    key={option.id} 
                                                                                    value={option.id}
                                                                                    selected={departmentId === option.id ? true : false}
                                                                                    >
                                                                                        {option.name}
                                                                                    </option>
                                                                                ))}
                                                        </select>
                                                    </div>
                                                    {/* <div className="row mt-3"> */}
                                                    {/* style={{ marginLeft: '380px', marginTop: '-100px' }} */}
                                                        <div className="col-sm-3 ">
                                                            <button
                                                                className="btn btn-primary mt-3"  onClick={fetchSchemes}>
                                                                Search
                                                            </button>
                                                        </div>
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!loading && <div className="row">
                                    <div className="table-container">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th rowSpan="2" style={{ textAlign: 'center', background: '#5684e4', color: '#fff', width: '200px' }}>Scheme Code</th>
                                                    <th rowSpan="2" style={{ textAlign: 'center', background: '#5684e4', color: '#fff', width: "250px" }}>Scheme Name</th>
                                                    <th colSpan="3" style={{ textAlign: 'center', background: '#5684e4', color: '#fff', width: "600px" }}>Provisioned</th>
                                                    <th colSpan="3" style={{ textAlign: 'center', background: '#5684e4', color: '#fff', width: "600px" }}>Released</th>
                                                    <th colSpan="3" style={{ textAlign: 'center', background: '#5684e4', color: '#fff', width: "600px" }}>Expenses</th>
                                                    <th colSpan="3" style={{ textAlign: 'center', background: '#5684e4', color: '#fff', width: "600px" }}>Previous Year’s Balance</th>
                                                    <th colSpan="3" style={{ textAlign: 'center', background: '#5684e4', color: '#fff', width: "600px" }}>Expenses from Previous Year’s Balance</th>
                                                    <th></th>
                                                    <th></th>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Revenue</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Capital</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Total (3+4)</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Revenue</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Capital</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Total (3+4)</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Revenue</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Capital</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Total (3+4)</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Revenue</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Capital</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Total (3+4)</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Revenue</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Capital</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Total (3+4)</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Total Fund</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Total Expense</th>
                                                    <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>Selected Month Expense</th>
                                                </tr>
                                                <tr>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>1</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>2</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>3</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>4</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>5</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>6</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>7</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>8</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>9</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>10</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>11</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>12</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>13</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>14</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>15</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>16</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>17</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>18</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>19</th>
                                                    <th style={{ textAlign: 'center', background: '#e8eccd', color: '#000' }}>20</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    schemesList.map((e, index) => {
                                                        return (
                                                            <>
                                                                <tr key={index}>
                                                                    <td>{e.code}</td>
                                                                    <td>{e.name}</td>
                                                                    <td>{e.type.includes('Revenue') ? e.total_provisional_budget : 0}</td>
                                                                    <td>{e.type.includes('Capital') ? e.total_provisional_budget : 0}</td>
                                                                    <td>{e.total_provisional_budget}</td>
                                                                    <td>{e.type.includes('Revenue') ? e.total_budget : 0}</td>
                                                                    <td>{e.type.includes('Capital') ? e.total_budget : 0}</td>
                                                                    <td>{e.total_budget}</td>
                                                                    <td>{e.type.includes('Revenue') ? e.expenses : 0}</td>
                                                                    <td>{e.type.includes('Capital') ? e.expenses : 0}</td>
                                                                    <td>{e.expenses}</td>
                                                                    <td>{e.type.includes('Revenue') ? e.prevYearBalance : 0}</td>
                                                                    <td>{e.type.includes('Capital') ? e.prevYearBalance : 0}</td>
                                                                    <td>{e.prevYearBalance}</td>
                                                                    <td>{e.type.includes('Revenue') ? e.prevYearUtilization : 0}</td>
                                                                    <td>{e.type.includes('Capital') ? e.prevYearUtilization : 0}</td>
                                                                    <td>{e.prevYearUtilization}</td>
                                                                    <td>{e.total_budget + e.prevYearBalance}</td>
                                                                    <td>{e.expenses}</td>
                                                                    <td>{e.currentMonthUtilization}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={20} style={{ padding: '0px' }}>
                                                                        <div key={'scheme__' + index}>
                                                                            <table className="table table-bordered">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff', width: "200px" }} >
                                                                                            {expandedIndex === 'scheme__' + index ? (
                                                                                                <ExpandLessIcon
                                                                                                    style={{ position: 'absolute', left: '10px', cursor: 'pointer' }}
                                                                                                    onClick={() => toggleCollapse('scheme__' + index)}
                                                                                                />
                                                                                            ) : (
                                                                                                <ExpandMoreIcon
                                                                                                    style={{ position: 'absolute', left: '10px', cursor: 'pointer' }}
                                                                                                    onClick={() => toggleCollapse('scheme__' + index)}
                                                                                                />
                                                                                            )}
                                                                                            Subhead Code</th>
                                                                                        <th style={{ textAlign: 'center', background: '#5684e4', color: '#fff', width: "250px" }}>Subhead Name</th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                        <th></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {expandedIndex === 'scheme__' + index && e.sub_head_list.map((ele, subheadIndex) => (
                                                                                        <React.Fragment key={subheadIndex}>
                                                                                            <tr key={subheadIndex}>
                                                                                                <td>{ele.code}</td>
                                                                                                <td>{ele.name}</td>
                                                                                                <td>{e.type.includes('Revenue') ? ele.provisional_budget : 0}</td>
                                                                                                <td>{e.type.includes('Capital') ? ele.provisional_budget : 0}</td>
                                                                                                <td>{ele.provisional_budget}</td>
                                                                                                <td>{e.type.includes('Revenue') ? ele.budget : 0}</td>
                                                                                                <td>{e.type.includes('Capital') ? ele.budget : 0}</td>
                                                                                                <td>{ele.budget}</td>
                                                                                                <td>{e.type.includes('Revenue') ? ele.expense : 0}</td>
                                                                                                <td>{e.type.includes('Capital') ? ele.expense : 0}</td>
                                                                                                <td>{ele.expense}</td>
                                                                                                <td>{e.type.includes('Revenue') ? ele.pervious_year_budget : 0}</td>
                                                                                                <td>{e.type.includes('Capital') ? ele.pervious_year_budget : 0}</td>
                                                                                                <td>{ele.pervious_year_budget}</td>
                                                                                                <td>{e.type.includes('Revenue') ? ele.prevYearUtilization : 0}</td>
                                                                                                <td>{e.type.includes('Capital') ? ele.prevYearUtilization : 0}</td>
                                                                                                <td>{ele.prevYearUtilization}</td>
                                                                                                <td>{Number(ele.budget) + Number(ele.pervious_year_budget)}</td>
                                                                                                <td>{ele.expense}</td>
                                                                                                <td>{ele.currentMonthUtilization}</td>
                                                                                            </tr>
                                                                                        </React.Fragment>
                                                                                    ))}
                                                                                    {/* {expandedIndex === 'scheme__'+index && } */}
                                                                                </tbody>
                                                                                <tfoot>
                                                                                    <tr>
                                                                                        <th colSpan="2">total</th>
                                                                                        <th>{e.type.includes('Revenue') ? e.sub_head_list.reduce((sum, item) => sum + Number(item.provisional_budget), 0) : 0}</th>
                                                                                        <th>{e.type.includes('Capital') ? e.sub_head_list.reduce((sum, item) => sum + Number(item.provisional_budget), 0) : 0}</th>
                                                                                        <th>{e.sub_head_list.reduce((sum, item) => sum + Number(item.provisional_budget), 0)}</th>
                                                                                        <th>{e.type.includes('Revenue') ? e.sub_head_list.reduce((sum, item) => sum + Number(item.budget), 0) : 0}</th>
                                                                                        <th>{e.type.includes('Capital') ? e.sub_head_list.reduce((sum, item) => sum + Number(item.budget), 0) : 0}</th>
                                                                                        <th>{e.sub_head_list.reduce((sum, item) => sum + Number(item.budget), 0)}</th>
                                                                                        <th>{e.type.includes('Revenue') ? e.sub_head_list.reduce((sum, item) => sum + Number(item.expense), 0) : 0}</th>
                                                                                        <th>{e.type.includes('Capital') ? e.sub_head_list.reduce((sum, item) => sum + Number(item.expense), 0) : 0}</th>
                                                                                        <th>{e.sub_head_list.reduce((sum, item) => sum + Number(item.expense), 0)}</th>
                                                                                        <th>{e.type.includes('Revenue') ? e.sub_head_list.reduce((sum, item) => sum + Number(item.pervious_year_budget), 0) : 0}</th>
                                                                                        <th>{e.type.includes('Capital') ? e.sub_head_list.reduce((sum, item) => sum + Number(item.pervious_year_budget), 0) : 0}</th>
                                                                                        <th>{e.sub_head_list.reduce((sum, item) => sum + Number(item.pervious_year_budget), 0)}</th>
                                                                                        <th>{e.type.includes('Revenue') ? e.sub_head_list.reduce((sum, item) => sum + Number(item.prevYearUtilization), 0) : 0}</th>
                                                                                        <th>{e.type.includes('Capital') ? e.sub_head_list.reduce((sum, item) => sum + Number(item.prevYearUtilization), 0) : 0}</th>
                                                                                        <th>{e.sub_head_list.reduce((sum, item) => sum + Number(item.prevYearUtilization), 0)}</th>
                                                                                        <th>{e.sub_head_list.reduce((sum, item) => sum + Number(item.budget), 0) + e.sub_head_list.reduce((sum, item) => sum + Number(item.pervious_year_budget), 0)}</th>
                                                                                        <th>{e.sub_head_list.reduce((sum, item) => sum + Number(item.expense), 0)}</th>
                                                                                        <th>{e.sub_head_list.reduce((sum, item) => sum + Number(item.currentMonthUtilization), 0)}</th>
                                                                                    </tr>
                                                                                </tfoot>
                                                                            </table>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th colSpan="2" style={{ textAlign: 'center', background: '#5684e4', color: '#fff' }}>
                                                        Grand Total
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                        if(item.type.includes('Revenue')) {
                                                            return sum + Number(item.total_provisional_budget);
                                                        }
                                                            return sum;
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                        if(item.type.includes('Capital')) {
                                                            return sum + Number(item.total_provisional_budget);
                                                        }
                                                            return sum;
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                           return sum + Number(item.total_provisional_budget);
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                        if(item.type.includes('Revenue')) {
                                                            return sum + Number(item.total_budget);
                                                        }
                                                            return sum;
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                        if(item.type.includes('Capital')) {
                                                            return sum + Number(item.total_budget);
                                                        }
                                                            return sum;
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                           return sum + Number(item.total_budget);
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                        if(item.type.includes('Revenue')) {
                                                            return sum + Number(item.expenses);
                                                        }
                                                            return sum;
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                        if(item.type.includes('Capital')) {
                                                            return sum + Number(item.expenses);
                                                        }
                                                            return sum;
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                           return sum + Number(item.expenses);
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                        if(item.type.includes('Revenue')) {
                                                            return sum + Number(item.prevYearBalance);
                                                        }
                                                            return sum;
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                        if(item.type.includes('Capital')) {
                                                            return sum + Number(item.prevYearBalance);
                                                        }
                                                            return sum;
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                           return sum + Number(item.prevYearBalance);
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                        if(item.type.includes('Revenue')) {
                                                            return sum + Number(item.prevYearUtilization);
                                                        }
                                                            return sum;
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                        if(item.type.includes('Capital')) {
                                                            return sum + Number(item.prevYearUtilization);
                                                        }
                                                            return sum;
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                           return sum + Number(item.prevYearUtilization);
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                           return sum + Number(item.prevYearUtilization) + Number(item.total_budget);
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                           return sum + Number(item.expenses);
                                                        }, 0)}
                                                    </th>
                                                    <th>
                                                        {schemesList.reduce((sum, item) => {
                                                           return sum + Number(item.currentMonthUtilization);
                                                        }, 0)}
                                                    </th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default MprReport
