import React, { useState, useEffect, useRef } from 'react'
import {Form} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import {ADD_CHALLAN_FAIL, ADD_CHALLAN_START} from "../../RTK/Slices/ChallanSlice";
import {get_financial_year, 
    add_budget_reappropriate, 
    add_budget_reappropriate_subhead, 
    get_schemes, 
    get_single_budget_reappropriate} from '../../helper/Api';
import {toast} from 'react-toastify';
import { BiTrash } from 'react-icons/bi';

export default function AddBudgetReappropriate() {
    const dispatch = useDispatch();
    const {authData} = useSelector((state) => state.authData);
    const {id} = useParams();
    const [schemes, set_schemes] = useState([]);
    const [toSchemes, setToSchemes] = useState([]);
    const [fy_list, set_fy_list] = useState([]);
    const [subheadsList, set_subheadsList] = useState([]);
    const [toSubheadsList, setToSubheadsList] = useState([]);
    const totalAmount = useRef(0);
    const activeAddToSubhead = useRef(true);
    const navigate = useNavigate();

    const [fromSchemeDetails, setFromSchemeDetails] = useState({
        scheme_name: '',
        department_name: '',
        grant_code: '',
        scheme_type: '',
        subhead_name: '',
        subhead_balance: '',
        to_scheme_name: '',
        to_department_name: '',
        to_grant_code: '',
        to_scheme_type: '',
        to_subhead_name: '',
        to_subhead_balance: '',
    });

    const handleToSubheadSave = () => {
        if (!toSubheadData.to_scheme_id && !toSubheadData.to_subhead_id && !toSubheadData.to_financial_id && !toSubheadData.to_reappropriation_amount) {
            toast.error('Please Fill all required fields', {
                position: toast.POSITION.TOP_CENTER
            });
        } else if(totalAmount.current + Number(toSubheadData.to_reappropriation_amount) > Number(formData.reappropriation_amount)) {
            toast.error('Amount greater than reappropriation amount', {
                position: toast.POSITION.TOP_CENTER
            });
        } else {
            if(totalAmount.current + toSubheadData.to_reappropriation_amount > formData.reappropriation_amount) {
                activeAddToSubhead.current = true;
            }
            setFormData(prevState => {
                return {...prevState, to_subhead_list: [...formData.to_subhead_list, {...toSubheadData,  
                    scheme_name: fromSchemeDetails.to_scheme_name,
                    subhead_name: fromSchemeDetails.to_subhead_name,
                    subhead_balance: fromSchemeDetails.to_subhead_balance}]}
            });
            totalAmount.current += Number(toSubheadData.to_reappropriation_amount);

            setToSubheadData({
                to_scheme_id: '',
                to_subhead_id: '',
                to_financial_id: '',
                to_reappropriation_amount: '',
                scheme_name: '',
                financial_year: '',
                subhead_name: '',
                subhead_balance: '',
            });
            setToSubheadsList([]);
            setFromSchemeDetails(prevState => {
                return {...prevState, to_scheme_name: '',
                to_department_name: '',
                to_grant_code: '',
                to_scheme_type: '',
                to_subhead_name: '',
                to_subhead_balance: ''}
            })
        }
    }

    const [toSubheadData, setToSubheadData] = useState({
        to_scheme_id: '',
        to_subhead_id: '',
        to_financial_id: '',
        to_reappropriation_amount: '',
        scheme_name: '',
        financial_year: '',
        subhead_name: '',
        subhead_balance: '',
    })

    const [formData, setFormData] = useState({
        id: '',
        from_scheme_id: '',
        reappropriate_order_no: '',
        reappropriate_order_date: '',
        reappropriate_order_value: '',
        from_subhead_id: '',
        from_financial_year: '',
        reappropriation_amount: '',
        to_subhead_list: [],
        attachment: [],
        created_by: authData && authData.status ? authData.user.id : null
    });


    const fetch_list = async () => {
        const fy = await get_financial_year();
        // const st = await get_scheme_type();
        const schemes_list = await get_schemes();
        if (schemes_list.data.status) {
            set_schemes(schemes_list.data.schemes);
        } else {
            set_schemes([]);
        }
        if (fy.data.status) {
            set_fy_list(fy.data.list)
        }

        if(id) {
            const budgetData = await get_single_budget_reappropriate(id);
        }

    }

    const handleToSubheadData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setToSubheadData({
            ...toSubheadData,
            [name]: value
        });
        if (name == 'to_scheme_id') {
            if (value) {
                const filter = schemes.find((scheme) => scheme.id == value);
                if (filter) {
                    setFromSchemeDetails(prevState => {
                        return {
                            ...prevState, to_scheme_name: filter.name,
                            to_department_name: filter.department,
                            to_grant_code: filter.grant_code,
                            to_scheme_type: filter.type
                        }
                    })
                    setToSubheadsList(filter.sub_head_list);
                } else {
                    setToSubheadsList([]);
                }
            } else {
                setToSubheadsList([]);
            }
        }

        if(name == 'to_subhead_id') {
            if (value) {
                const subhead = toSubheadsList.find(e => e.id == value);
                if(subhead) {
                    setFromSchemeDetails(prevState => {
                        return {
                            ...prevState, 
                            to_subhead_name: subhead.name,
                            to_subhead_balance: subhead.balance
                        }
                    })
                }
            }
        }
    }

    const handleChallanFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({
            ...formData,
            [name]: value
        });
        if (name == 'reappropriation_amount') {
            if (totalAmount.current < value) {
                activeAddToSubhead.current = false;
            }
        }

        if (name == 'from_scheme_id') {
            if (value) {
                const filter = schemes.find((scheme) => scheme.id == value);
                if (filter) {
                    const schemeList = schemes.filter(f => f.type == filter.type);
                    setToSchemes(schemeList);
                    setFromSchemeDetails(prevState => {
                        return {
                            ...prevState, scheme_name: filter.name,
                            department_name: filter.department,
                            grant_code: filter.grant_code,
                            scheme_type: filter.type
                        }
                    })
                    set_subheadsList(filter.sub_head_list);
                    // clean to form data
                    setToSubheadData({
                        to_scheme_id: '',
                        to_subhead_id: '',
                        to_financial_id: '',
                        to_reappropriation_amount: '',
                        scheme_name: '',
                        financial_year: '',
                        subhead_name: '',
                        subhead_balance: '',
                    });
                    setToSubheadsList([]);
                    setFromSchemeDetails(prevState => {
                        return {...prevState, to_scheme_name: '',
                        to_department_name: '',
                        to_grant_code: '',
                        to_scheme_type: '',
                        to_subhead_name: '',
                        to_subhead_balance: ''}
                    })
                    // clean to form data ends
                } else {
                    set_subheadsList([]);
                }
            } else {
                set_subheadsList([]);
            }
        }

        if(name == 'from_subhead_id') {
            if (value) {
                const subhead = subheadsList.find(e => e.id == value);
                if(subhead) {
                    setFromSchemeDetails(prevState => {
                        return {
                            ...prevState, 
                            subhead_name: subhead.name,
                            subhead_balance: subhead.balance
                        }
                    })
                }
            }
        }
    }

    const handleAttachmentChange = (event) => {
        const files = event.target.files;
        setFormData({
            ...formData,
            attachment: files
        });
    };

    const previewHandler = (e) => {
        e.preventDefault();
      };

    const handleSubmit = async (e) => {
        if (!id) {
            if (e && typeof e.preventDefault === 'function') {
                e.preventDefault();
            }
        }
        try {
            if (formData.to_subhead_list.length && 
                formData.reappropriate_order_no && 
                formData.reappropriate_order_date && 
                formData.reappropriate_order_value && 
                formData.from_subhead_id && 
                formData.from_scheme_id && formData.reappropriation_amount) {
                const post_data = new FormData();
                for (let i = 0; i < formData.attachment.length; i++) {
                    post_data.append('attachment[]', formData.attachment[i]);
                }
                post_data.append('from_scheme_id', formData.from_scheme_id);
                post_data.append('reappropriate_order_no', formData.reappropriate_order_no);
                post_data.append('reappropriate_order_date', formData.reappropriate_order_date);
                post_data.append('reappropriate_order_value', formData.reappropriate_order_value);
                post_data.append('from_subhead_id', formData.from_subhead_id);
                post_data.append('from_financial_year', formData.from_financial_year);
                post_data.append('reappropriation_amount', formData.reappropriation_amount);
                post_data.append('to_subhead_list', formData.to_subhead_list);
                post_data.append('created_by', formData.created_by);
                dispatch(ADD_CHALLAN_START());
                
                const response = await add_budget_reappropriate(post_data);
                let addDepositoryResponseData = response.data;
                if (addDepositoryResponseData.status) {
                    formData.to_subhead_list.forEach(e => e.created_by = formData.created_by);
                    await add_budget_reappropriate_subhead({subhead: formData.to_subhead_list},addDepositoryResponseData.result);
                    navigate('/budget-reappropriate');
                } else {
                    toast.error(addDepositoryResponseData.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    dispatch(ADD_CHALLAN_FAIL(addDepositoryResponseData.message));
                }
            } else {
                toast.error('Please fill required fields.', {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        } catch (error) {
            dispatch(ADD_CHALLAN_FAIL('error getting while updating challan'));

        }
    }

    useEffect(() => {
        fetch_list();
    }, [])
    
    const removeSubhead = (scheme_id, subhead_id) => {
        const updatedSubHeads = formData.to_subhead_list.filter(e => e.to_scheme_id != scheme_id && e.to_subhead_id != subhead_id);

        totalAmount.current = 0;
        updatedSubHeads.forEach(e => {
            totalAmount.current += Number(e.to_reappropriation_amount);
        })
        if(totalAmount.current < Number(formData.reappropriation_amount)) {
            activeAddToSubhead.current = false;
        }
        setFormData(prevState => {
            return {
                ...prevState,
                to_subhead_list: updatedSubHeads
            }
        })
    }


    return (
        <div>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={previewHandler}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4> Add Budget Reappropriate</h4>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label for="" className="col-form-label">Reappropriation Order No. <span className="text-danger">*</span> :</label>
                                            <input type="text" 
                                            className="form-control"
                                            name='reappropriate_order_no'
                                            required
                                            value={formData.reappropriate_order_no}
                                            onChange={handleChallanFormData}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label for="" className="col-form-label">Reappropriation Order Date <span className="text-danger">*</span> :</label>
                                            <input type="date" 
                                            className="form-control"
                                            name='reappropriate_order_date'
                                            required
                                            value={formData.reappropriate_order_date}
                                            onChange={handleChallanFormData}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label for="" className="col-form-label">Budget Reappropriation Order Value <span className="text-danger">*</span> :</label>
                                            <input type="text" 
                                            className="form-control"
                                            name='reappropriate_order_value'
                                            required
                                            value={formData.reappropriate_order_value}
                                            onChange={handleChallanFormData}/>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="input-group">
                                            <label className="input-group-btn my-0 mt-2 mr-2">
                                                Upload Document  <span className="text-danger">*</span>
                                            </label>
                                            <label className='p-1'>
                                                <input id="" type="file"
                                                        className='form-control'
                                                        onChange={handleAttachmentChange}
                                                        name='attachment'
                                                        accept=".pdf,.jpeg,.jpg,.png"
                                                        required
                                                        multiple/>
                                            </label>
                                            {/* {
                                                !formData?.attachment?.length ? (
                                                    <label className='p-1'>
                                                        <input id="" type="file"
                                                                className='form-control'
                                                                onChange={handleAttachmentChange}
                                                                name='attachment'
                                                                accept=".pdf,.jpeg,.jpg,.png"
                                                                required
                                                                multiple/>
                                                    </label>
                                                ) : ''
                                                    // formData.attachment.map((att, i) => {
                                                    //     return (
                                                    //         <a className="mt-2 mx-2" href={att.file_url}
                                                    //             target='_blank'>Attachment {i + 1}</a>);
                                                    // })
                                                
                                            } */}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <hr />
                                    </div>
                                    <div className='col-md-12'>
                                    <h5>From Scheme</h5>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="from_scheme_id" className="col-form-label">Scheme <span className="text-danger">*</span></label>
                                            <select className="form-control "
                                                    id="from_scheme_id" name='from_scheme_id'
                                                    required
                                                    value={formData.from_scheme_id}
                                                    onChange={handleChallanFormData}
                                                    >
                                                <option>Select Scheme</option>
                                                {
                                                    schemes.map((schemes) => {
                                                        return (
                                                            schemes.l2_status == 1 && schemes.l3_status == 1 &&
                                                            <option value={schemes.id}
                                                                    key={schemes.id}>{schemes.code}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Scheme Name</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.scheme_name} readonly />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Department Name</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.department_name} readonly />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Grant Code</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.grant_code} readonly />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Type of Scheme</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.scheme_type} readonly />
                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-3'>
                                    <h5>From Subhead</h5>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <label for="inputFinancial" className="col-form-label"> Financial Year <span className="text-danger">*</span> :</label>
                                            <select className="form-control " id="financial_year"
                                                    name='from_financial_year'
                                                    onChange={handleChallanFormData}
                                                    required
                                            >
                                                <option value="">---select year---</option>
                                                {
                                                    fy_list.map((fy) => {
                                                        return (
                                                            <option value={fy.id}
                                                                    key={fy.id}>{fy.year}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <label for="inputFinancial" className="col-form-label"> Subhead <span className="text-danger">*</span> :</label>
                                            <select className="form-control " id="financial_year"
                                                    name='from_subhead_id'
                                                    onChange={handleChallanFormData}
                                                    required>
                                                <option value="">---Select Subhead---</option>
                                                {
                                                    subheadsList.map((subhead) => {
                                                        return (
                                                            <option
                                                                value={subhead.id}
                                                                key={subhead.id}>{subhead.code}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Subhead Name</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.subhead_name} readonly />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Subhead Balance</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.subhead_balance} readonly />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Reappropriation Amount</label>
                                            <input type="text" 
                                            className="form-control" 
                                            name="reappropriation_amount" 
                                            value={formData.reappropriation_amount} 
                                            onChange={handleChallanFormData}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <hr />
                                    </div>

                                    <div className="col-md-12">
                                        <table className="table table-responsive">
                                            <thead>
                                                <tr>
                                                    <th>Scheme Name</th>
                                                    <th>Financial Year</th>
                                                    <th>Subhead Name</th>
                                                    <th>Subhead Balance</th>
                                                    <th>Reappropriation Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                                
                                            <tbody>
                                                {
                                                    formData.to_subhead_list.length > 0 ?
                                                    formData.to_subhead_list.map(e => {
                                                        const financial_year = fy_list.find(y => y.id == e.to_financial_id);
                                                        return <tr>
                                                            <td>{e.scheme_name}</td>
                                                            <td>{financial_year?.year}</td>
                                                            <td>{e.subhead_name}</td>
                                                            <td>{e.subhead_balance}</td>
                                                            <td>{e.to_reappropriation_amount}</td>
                                                            <td><span onClick={() => removeSubhead(e.to_scheme_id, e.to_subhead_id)}><BiTrash /></span></td>
                                                        </tr>
                                                        })
                                                    : <tr><td colSpan={6} className='text-center'>No Data</td></tr>
                                                }
                                            </tbody>

                                            <tfoot>
                                                <tr>
                                                    <th colSpan={5}>Total Reappropriation Amount: </th>
                                                    <th >{totalAmount.current}</th>
                                                </tr>
                                            </tfoot>
                                            
                                        </table>
                                        <div className="mt-2 text-center">
                                            <Button type="submit" variant="primary" onClick={handleSubmit}> Save Budget Reappropriate </Button>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <hr />
                                    </div>
                                    <div className='col-md-12'>
                                    <h5>To Scheme</h5>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label for="to_scheme_id" className="col-form-label">Scheme <span className="text-danger">*</span></label>
                                            <select className="form-control "
                                                    id="to_scheme_id" name='to_scheme_id'
                                                    required
                                                    // disabled={type === 'view'}
                                                    value={toSubheadData.to_scheme_id}
                                                    onChange={handleToSubheadData}
                                                    >
                                                <option>Select Scheme</option>
                                                {
                                                    toSchemes.map((schemes) => {
                                                        return (
                                                            schemes.l2_status == 1 && schemes.l3_status == 1 &&
                                                            <option value={schemes.id}
                                                                    key={schemes.id}>{schemes.code}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="col-form-label">Scheme Name</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.to_scheme_name} readonly />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Department Name</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.to_department_name} readonly />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Grant Code</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.to_grant_code} readonly />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Type of Scheme</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.to_scheme_type} readonly />
                                        </div>
                                    </div>
                                    <div className='col-md-12 mt-3'>
                                    <h5>To Subhead</h5>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <label for="inputFinancial" className="col-form-label"> Financial Year <span className="text-danger">*</span> :</label>
                                            <select className="form-control " id="financial_year"
                                                    name='to_financial_id'
                                                    onChange={handleToSubheadData}
                                                    required
                                            >
                                                <option value="">---select year---</option>
                                                {
                                                    fy_list.map((fy) => {
                                                        return (
                                                            <option value={fy.id}
                                                                    key={fy.id}>{fy.year}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className='col-md-4'>
                                        <div className="form-group">
                                            <label for="inputFinancial" className="col-form-label"> Subhead <span className="text-danger">*</span> :</label>
                                            <select className="form-control " id="financial_year"
                                                    name='to_subhead_id'
                                                    onChange={handleToSubheadData}
                                                    required>
                                                <option value="">---Select Subhead---</option>
                                                {
                                                    toSubheadsList.map((subhead) => {
                                                        return (
                                                            <option
                                                                value={subhead.id}
                                                                key={subhead.id}>{subhead.code}</option>
                                                        );
                                                    })
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Subhead Name</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.to_subhead_name} readonly />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Subhead Balance</label>
                                            <input type="text" className="form-control" value={fromSchemeDetails.to_subhead_balance} readonly />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="col-form-label">Reappropriation Amount</label>
                                            <input type="text" 
                                            className="form-control" 
                                            name="to_reappropriation_amount" 
                                            value={toSubheadData.to_reappropriation_amount}
                                            onChange={handleToSubheadData}
                                            />
                                        </div>
                                    </div>
                                    <div className='col-md-12'><button type="button" onClick={handleToSubheadSave} disabled={activeAddToSubhead.current} className='btn btn-primary mt-2'>Add Subhead</button></div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}