import React, { useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { get_financial_year, get_departments } from "../../helper/Api";
import { useSelector } from 'react-redux';

const VenderContractServiceFilter = ({ filterType, onDateChange }) => {
    // Initialize refs to store form values instead of state
    const { authData } = useSelector((state) => state.authData);
    const searchStrRef = useRef('');
    const tierRef = useRef('');
    const departmentIdRef = useRef('');
    const voucherDateRef = useRef('voucher_date');
    const selectDateRef = useRef('');
    const departmentRef = useRef([]);

    // Fetch departments
    const fetchDepartments = async (level) => {
        try {
            const departments = await get_departments({ department_level: level, parent_department_id: authData.user.department_id });
            if (departments.data.status) {
                departmentRef.current = departments.data.result;
            }
        } catch (error) {
            console.log("get_departments error");
        }
    };

    const handleInput = async (e) => {
        const name = e.target.name;
        const value = e.target.value;

        // Update refs based on user input
        if (name === 'search_str') {
            searchStrRef.current = value;
        } else if (name === 'tier') {
            await fetchDepartments(value);
            tierRef.current = value;
        }
        else if (name === 'voucher_date') {
            voucherDateRef.current = value;
        }
        else if (name === 'department_id') {
            departmentIdRef.current = value;
        }
        else if(name === 'select_date')
        {
            selectDateRef.current = value;
        }
    };

    const handleFilterClick = () => {
        if (onDateChange) {
            onDateChange(
                searchStrRef.current,
                tierRef.current,
                departmentIdRef.current,
                voucherDateRef.current,
                selectDateRef.current
            );
        }
    };

    const clearFilter = () => {
        searchStrRef.current = '';
        tierRef.current = '';
        departmentIdRef.current = '';
        voucherDateRef.current = '';
        selectDateRef.current = '';
        if (onDateChange) {
            onDateChange('', '', '', '', '');
        }
    };

    return (
        <div className="add_new_user mt-2">
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <h5>Filters</h5>
                    </Col>
                    <Col sm={4}>
                        <Form.Group controlId="">
                            <select
                                name="voucher_date"
                                className="form-control mb-2"
                                onChange={handleInput}
                            >
                                <option value="voucher_date" selected={voucherDateRef.current === 'voucher_date'}>Voucher Date</option>
                                <option value="invoice_date" selected={voucherDateRef.current === 'invoice_date'}>Invoice Date</option>
                                <option value="sanction_order_date" selected={voucherDateRef.current === 'sanction_order_date'}>Payment Sanction Order Date</option>
                                <option value="invoice_payment.added_on" selected={voucherDateRef.current === 'invoice_payment.added_on'}>Transaction Success Date</option>
                            </select>
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Group className="" controlId="">
                            <Form.Control type="date" name="select_date"
                                value={selectDateRef.current}
                                onChange={handleInput} />
                        </Form.Group>
                    </Col>
                    <Col sm={4}>
                        <Form.Group controlId="">
                            <Form.Control
                                type="text"
                                name="search_str"
                                onChange={handleInput}
                                value={searchStrRef.current}
                                placeholder="Enter to search ..."
                            />
                        </Form.Group>
                    </Col>
                    {(filterType === 'child' || filterType === 'consolidate') && (
                        <>
                            <Col sm={4}>
                                <Form.Group controlId="">
                                    <select
                                        className="form-control"
                                        name="tier"
                                        onChange={handleInput}
                                    >
                                        <option value="">Select Department Tier</option>
                                        {authData.user.department_details.department_level === 'T1' && (
                                            <option value="T1" selected={tierRef.current === 'T1'}>
                                                T1
                                            </option>
                                        )}
                                        {(authData.user.department_details.department_level === 'T1' ||
                                            authData.user.department_details.department_level === 'T2') && (
                                                <option value="T2" selected={tierRef.current === 'T2'}>
                                                    T2
                                                </option>
                                            )}
                                        {(authData.user.department_details.department_level === 'T1' ||
                                            authData.user.department_details.department_level === 'T2' ||
                                            authData.user.department_details.department_level === 'T3') && (
                                                <option value="T3" selected={tierRef.current === 'T3'}>
                                                    T3
                                                </option>
                                            )}
                                        {(authData.user.department_details.department_level === 'T1' ||
                                            authData.user.department_details.department_level === 'T2' ||
                                            authData.user.department_details.department_level === 'T3' ||
                                            authData.user.department_details.department_level === 'T4') && (
                                                <option value="T4" selected={tierRef.current === 'T4'}>
                                                    T4
                                                </option>
                                            )}
                                    </select>
                                </Form.Group>
                            </Col>
                            <Col sm={4}>
                                <Form.Group controlId="">
                                    <select
                                        className="form-control"
                                        name="department_id"
                                        onChange={handleInput}
                                    >
                                        <option value="">Select Department</option>
                                        {departmentRef.current.map((option) => (
                                            <option
                                                key={option.id}
                                                value={option.id}
                                                selected={departmentIdRef.current === option.id}
                                            >
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </Form.Group>
                            </Col>
                        </>
                    )}
                    <Col sm={12}>
                        <div className="d-flex mt-3">
                            <div className="text-start mx-2">
                                <button
                                    type="button"
                                    className="btn btn-outline-primary"
                                    onClick={clearFilter}
                                >
                                    Clear
                                </button>
                            </div>
                            <div className="text-start">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={handleFilterClick}
                                >
                                    Filter
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
export default VenderContractServiceFilter;
