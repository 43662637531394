import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, InputGroup } from 'react-bootstrap';
import {getRoyaltyPaymentOtp,approveRoyaltyPayment} from "../../../helper/Api";
import { toast } from "react-toastify";
import {useNavigate} from "react-router-dom";
const RoyaltyPayment = ({ data }) => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState('');
    const [init_payment, setInitPayment] = useState(false);

    const initiate_payment = async (e) => {
        try {
            const otpDetail = await getRoyaltyPaymentOtp();
            if (otpDetail.data.status) {
                setInitPayment(true);
            }
        } catch (e) {
            toast.error("error getting while send otp", { position: toast.POSITION.TOP_CENTER });
        }
    }
    const handlePayNow = async() => {
        try {
            const otpDetail = await approveRoyaltyPayment({payment_order_no:data.payment_order_no,otp:otp});
            if (otpDetail.data.status) {
                navigate('/royalty_labour_approval');
                setOtp("");
                setInitPayment(false);
            }
            else{
                toast.error(otpDetail.data.error, { position: toast.POSITION.TOP_CENTER });
            }
        } catch (e) {
            toast.error("error getting while approve payment", { position: toast.POSITION.TOP_CENTER });
        }
    };

    return (
        <div className="col-md-12">
            <div className="card p-3 mt-3">
                <Row className="pt-4">
                    <Col md={12} className="p-3">
                        <h6 className="text-muted border-bottom" style={{ textAlign: 'center',fontWeight: 'bold' }}>BANK ACCOUNT DETAILS</h6>
                        <Row className="pt-2 justify-content-center">
                            <Col sm="2">
                            </Col>
                            <Col sm="4">
                                <p className="mb-0 text-muted">Account Name <span className="text-danger">*</span> :</p>
                            </Col>
                            <Col sm="4">
                                <InputGroup.Text className="h-100">{data?.bankDetails?.project_name}</InputGroup.Text>
                            </Col>
                        </Row>
                        <Row className="pt-2 justify-content-center">
                            <Col sm="2">
                            </Col>
                            <Col sm="4">
                                <p className="mb-0 text-muted">Bank <span className="text-danger">*</span> :</p>
                            </Col>
                            <Col sm="4">
                                <InputGroup.Text className="h-100">{data?.bankDetails?.bank_name}</InputGroup.Text>
                            </Col>
                        </Row>
                        <Row className="pt-2 justify-content-center">
                            <Col sm="2">
                            </Col>
                            <Col sm="4">
                                <p className="mb-0 text-muted">Branch <span className="text-danger">*</span> :</p>
                            </Col>
                            <Col sm="4">
                                <InputGroup.Text className="h-100">{data?.bankDetails?.branch}</InputGroup.Text>
                            </Col>
                        </Row>
                        <Row className="pt-2 justify-content-center">
                        <Col sm="2">
                        </Col>
                            <Col sm="4">
                                <p className="mb-0 text-muted">IFSC Code <span className="text-danger">*</span> :</p>
                            </Col>
                            <Col sm="4">
                                <InputGroup.Text className="h-100">{data?.bankDetails?.ifsc_code}</InputGroup.Text>
                            </Col>
                        </Row>
                        <Row className="pt-2 justify-content-center">
                            <Col sm="2">
                            </Col>
                            <Col sm="4">
                                <p className="mb-0 text-muted">Account No <span className="text-danger">*</span> :</p>
                            </Col>
                            <Col sm="4">
                                <InputGroup.Text className="h-100">{data?.bankDetails?.account_no}</InputGroup.Text>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <div className="border_dotted p-3" style={{ textAlign: 'left' }}>
                    <small style={{ fontWeight: 'bold' }}>Total Amount to Pay Now</small>
                    <p className="pt-2">₹ {data.amount}</p>
                </div>

                <div className="p-3 bg-light">
                    <Container>
                        <Row>
                            {/* <Col md={2}>
                                <Form.Label>Payment From : </Form.Label>
                            </Col> */}
                            <Col md={12}>
                                <Button
                                    variant="contained"
                                    style={{ margin: '10px 0px',
                                    backgroundColor: "#3498db",
                                    color: 'white'}}
                                    onClick={initiate_payment}
                                >
                                    Initiate Payment
                                </Button>
                            </Col>
                        </Row>

                        {/* Payment Mode */}
                        {/* <Row>
                            <Col md={2}>
                                <Form.Label>Payment Mode : </Form.Label>
                            </Col>
                            <Col md={10}>
                                <p>
                                    <input type="radio" value="offline" name="payment_mode" checked /> Offline
                                </p>
                                <p>
                                    <input type="radio" value="online" name="payment_mode" disabled /> Online
                                </p>
                            </Col>
                        </Row> */}

                        {/* OTP */}
                        <Row>
                            <Col md={2}>
                                <Form.Label>OTP : </Form.Label>
                            </Col>
                            <Col md={10}>
                                <div className="w-50">
                                    <Form.Control
                                        type="number"
                                        name="otp"
                                        onChange={(e) => setOtp(e.target.value)}
                                        value={otp}
                                        placeholder="Enter OTP"
                                        disabled={!init_payment}
                                    />
                                </div>
                                <Button
                                    variant="contained"
                                    style={{ margin: '10px 0px',
                                    backgroundColor: "#3498db",
                                    color: 'white'}}
                                    disabled={!otp}
                                    onClick={handlePayNow}
                                >
                                    PAY NOW
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    );
};
export default RoyaltyPayment;
