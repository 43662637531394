import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Radio } from "@mui/material";
// import login_logo from '../../../assets/images/wmdf_logo.jpeg';
import login_logo from "../../../assets/images/tangy_logo.jpeg";
import "./Login.css";
import { useState, useEffect } from "react";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
} from "../../../RTK/Slices/AuthSlice";
import {
  login_user,
  login_user_via_send_otp,
  login_user_via_verify_otp,
  setAuthToken,
  login_user_v2,
} from "../../../helper/Api";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import DepartmentOtp from "./DepartmentOtp";
import ForgetPassword from "./../ForgetPassword/Forget";
export default function Login() {
  const [error, setError] = React.useState(null);
  const [formData, setformData] = useState({
    email: null,
    password: null,
    user_type: null,
    mobile: null,
    otp: null,
  });
  const [show_password, setShow_password] = useState(false);
  const [isDisabled, setDisabled] = useState(false);
  const [send_otp, setSend_otp] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const recaptchaRef = React.createRef();
  const [passwordError, setPasswordError] = useState("");
  const [seconds, setSeconds] = useState(0);
  const [nextStep, setNextStop] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const startTimer = () => {
    setSeconds(60); // Reset timer to 60 seconds
  };
  useEffect(() => {
    if (seconds > 0) {
      // Timer logic
      const timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      // Cleanup function
      return () => clearInterval(timer);
    }
  }, [seconds]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setformData({ ...formData, [name]: value });

    /*if (name === 'password') {
            setPasswordError('');
        }*/
  };

  const validateForm = () => {
    // Validate password format
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      setPasswordError(
        "Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character."
      );
      return false;
    }
    // Add more validation logic as needed
    return true; // All fields are valid
  };

  const handleSubmit = async (e) => {
    setDisabled(true);
    e.preventDefault();
    if (send_otp) {
      await verify_otp_for_login();
    } else {
      if (!validateForm()) {
        toast.error("Invalid email or password", {
          position: toast.POSITION.TOP_CENTER,
        });
        setDisabled(false);
        return;
      }
      if (formData.email && formData.password) {
        dispatch(LOGIN_START);
        setDisabled(true);
        const loginResponse = await login_user_v2(formData);
        if (loginResponse.data.status) {
          console.log(loginResponse.data);
          // toast.success(loginResponse.data.message, {
          //   position: toast.POSITION.TOP_CENTER,
          // });
          //   navigate("/department-otp-verify");
          setNextStop(2);
          sessionStorage.setItem(
            "departments",
            JSON.stringify(loginResponse.data.departments)
          );
          sessionStorage.setItem("email", formData.email);
          // setDisabled(false)
          //await send_otp_for_login(loginResponse.data.user.mobile);
          // setDisabled(false)
          // dispatch(LOGIN_SUCCESS(loginResponse.data));
          // sessionStorage.setItem("is_loggedIn", true);
          // sessionStorage.setItem("userDetails", loginResponse.data);
          // window.location.href="/";
          setDisabled(false);
        } else {
          setDisabled(false);
          // console.log(loginResponse.data.message);
          dispatch(LOGIN_FAIL("Invalid username and password"));
          // setError('Invalid username and password')
          toast.error(loginResponse.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } else {
        toast.error("All fields Required.!", {
          position: toast.POSITION.TOP_CENTER,
        });
        setDisabled(false);
      }
    }
  };

  const verify_otp_for_login = async () => {
    if (formData.mobile && formData.otp) {
      if (!captchaValue) {
        alert("Please complete the CAPTCHA");
        setDisabled(false);
        return;
      }
      setDisabled(true);
      const loginResponse = await login_user_via_verify_otp(formData);
      // console.log(loginResponse);return false;
      if (loginResponse.data.status) {
        // toast.success(loginResponse.data.message, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
        dispatch(LOGIN_SUCCESS(loginResponse.data));
        sessionStorage.setItem("is_loggedIn", true);
        sessionStorage.setItem(
          "userDetails",
          JSON.stringify(loginResponse.data)
        );
        sessionStorage.setItem(
          "token",
          JSON.stringify(loginResponse?.data?.token)
        );
        const { token } = loginResponse?.data;
        setAuthToken(token);
        // window.location.href="/";
        navigate("/");
        setDisabled(false);
      } else {
        toast.error(loginResponse.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        setDisabled(false);
      }
    } else {
      toast.error("All the field required", {
        position: toast.POSITION.TOP_CENTER,
      });
      setDisabled(false);
    }
  };

  const send_otp_for_login = async (mobile) => {
    if (!mobile && formData?.mobile) {
      mobile = formData.mobile;
    }
    // console.log(formData);return false;
    if (mobile && formData.user_type) {
      let post_data = {
        mobile: mobile,
        user_type: formData.user_type,
      };
      setDisabled(true);
      const loginResponse = await login_user_via_send_otp(post_data);
      // console.log('loginResponse', loginResponse);
      if (loginResponse.data.status) {
        setSend_otp(true);
        setDisabled(false);
        setTimeout(startTimer, 500);
        // toast.success(loginResponse.data.message, {
        //   position: toast.POSITION.TOP_CENTER,
        // });
      } else {
        setDisabled(false);
        toast.error(loginResponse.data.message, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      toast.error("All the field required", {
        position: toast.POSITION.TOP_CENTER,
      });
      setDisabled(false);
    }
  };

  const handleSendOTP = async () => {
    // Call send_otp_for_login function with the mobile value
    const mobile = formData?.mobile; // Replace with your mobile value
    await send_otp_for_login(mobile);
  };

  return (
    <>
      <Grid
        container
        component="div"
        className="position-relative overflow-x-hidden"
        sx={{ height: "100vh" }}
      >
        <div
          className=" position-relative w-100 mobile-hide"
          style={{ height: "20%" }}
        >
          <div
            className="position-absolute d-flex justify-content-center align-items-center bg-white"
            style={{
              width: "160px",
              height: "160px",
              zIndex: "3",
              left: "6%",
              borderRadius: "0 0 80px 80px",
            }}
          >
            <img src="/logo.svg" alt="logo" className="cursor-pointer" style={{ scale: "1.2" }} onClick={()=>setNextStop(1)} />
          </div>

          <div
            className="position-absolute w-100"
            style={{ top: "-8%", zIndex: "2" }}
          >
            <div className="position-relative">
              <img
                src="/loginpage/darkBlueBg.svg"
                alt="svg"
                className="h-100 cursor-pointer"
              />

              <h2
                className="position-absolute text-white end-0 w-75 fw-600 text-end"
                style={{ top: "12%", fontSize: "34px", textWrap:"nowrap", paddingRight:"7.2rem" }}
              >
                SNA - Financial Management System
              </h2>
              <div className="bg-secondary-100 position-absolute end-0 w-100 top-0" style={{height:"40.7%",zIndex:"-1"}}></div>
            </div>
          </div>

          <div
            className="position-absolute w-100"
            style={{ top: "48%", zIndex: "1" }}
          >
            <div className="position-relative">
              <img src="/loginpage/skyBlueBg.svg" alt="svg" className="h-100" />
              <p
                className="position-absolute text-white end-0 text-end w-75"
                style={{ top: "6%", fontSize: "18px", paddingRight:"7.2rem" }}
              >
                Uttarakhand Rural Road Development Authority
              </p>
              <div className="bg-prime-100 position-absolute end-0 w-100" style={{height:"82%",zIndex:"-1", top:"-50%"}}></div>
            </div>
          </div>

        </div>

        <div className="d-lg-none w-100 mb-4 ">
          <div className="bg-secondary-100 text-white pb-3 d-flex align-items-center flex-column ">
          <div
            className="d-flex justify-content-center align-items-center bg-white "
            style={{
              width: "160px",
              height: "160px",
              zIndex: "3",
              left: "6%",
              borderRadius: "0 0 80px 80px",
            }}
          >
            <img src="/logo.svg" alt="logo" style={{ scale: "1.2" }} className="cursor-pointer" onClick={()=>setNextStop(1)} />
          </div>
            <span className="d-inline-block fs-24px text-center mt-1 w-100 px-3">SNA - Financial Management System</span>
          </div>
          <div className="bg-prime-100 py-3">
            <span className="d-inline-block fs-18px text-white text-center w-100 px-3">Uttarakhand Rural Road Development Authority</span>
          </div>
        </div>

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          component="div"
          className="d-flex"
        >
          {/* =====================images====================== */}
          <div
            component="main"
            maxWidth="xs"
            className="w-100 mt-lg-4"
            style={{ zIndex: "4" }}
          >
            {/* ============================form================================= */}
            <div className="container">
              <div className="row justify-content-between align-items-center" style={{scale:"0.9"}} >
                <div className="h-100 col-md-12 col-lg-6 d-flex justify-content-center justify-content-lg-end" >
                  {/* <img src={login_logo} alt="login_logo" className="login_logo" /> */}
                  {/* <h2>Directorate Women Welfare Uttarakhand</h2> */}

                  {/* ============================login form=================================== */}

                  {nextStep == 1 && (
                    <Box
                      component="form"
                      noValidate
                      sx={{ mt: 1, scale: "1" }}
                      onSubmit={handleSubmit}
                      className="form_box p-4"
                    >
                      {/* <Typography component="h1" variant="h5">
              Login
            </Typography>             */}
                      <Typography
                        component="p"
                        variant="p"
                        sx={{
                          color: "red",
                          fontWeight: "bold",
                          paddingLeft: "10px",
                          paddingTop: "10px",
                        }}
                      >
                        {error}
                      </Typography>
                      {send_otp ? (
                        <Typography
                          component="p"
                          variant="p"
                          sx={{
                            color: "blue",
                            fontWeight: "bold",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            cursor: "pointer",
                          }}
                          onClick={() => setSend_otp(false)}
                        >
                          Edit Email ?
                        </Typography>
                      ) : (
                        ""
                      )}
                      {send_otp ? (
                        <div>
                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="otp"
                            label="OTP"
                            type="number"
                            name="otp"
                            autoComplete="otp"
                            autoFocus
                            onChange={handleChange}
                            value={formData.otp}
                          />
                          <ReCAPTCHA
                            sitekey="6Lei810pAAAAAL-HaSHvdad5f5VBl-aiil_YTJUS"
                            ref={recaptchaRef}
                            onChange={(value) => setCaptchaValue(value)}
                          />

                          <Grid item xs>
                            {seconds > 0 ? (
                              <div>
                                <p>Resend OTP after {seconds}s</p>
                              </div>
                            ) : (
                              <Link variant="body2">
                                <div>
                                  <p onClick={handleSendOTP}>Resend OTP</p>
                                </div>
                              </Link>
                            )}
                          </Grid>
                        </div>
                      ) : (
                        <div>
                          <div>
                            <h2 className="fs-24px fw-500 text-center my-3">
                              Login Into Your Account
                            </h2>
                          </div>

                          <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChange}
                            value={formData.email}
                          />
                          <span className="password_wrapper">
                            <TextField
                              onChange={handleChange}
                              margin="normal"
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type={show_password ? "text" : "password"}
                              id="password"
                              autoComplete="current-password"
                              value={formData.password}
                            />

                            <span
                              className="view_password"
                              onClick={() => setShow_password(!show_password)}
                            >
                              {show_password ? (
                                <AiFillEye />
                              ) : (
                                <AiFillEyeInvisible />
                              )}
                            </span>
                          </span>

                          {/* <small className="form-text text-muted">
                                            Password must be at least 8 characters long and contain at least one
                                            uppercase letter, one lowercase letter, one digit, and one special
                                            character.
                                        </small>*/}
                          <ReCAPTCHA
                            sitekey="6Lei810pAAAAAL-HaSHvdad5f5VBl-aiil_YTJUS"
                            ref={recaptchaRef}
                            onChange={(value) => setCaptchaValue(value)}
                          />
                        </div>
                      )}

                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        disabled={isDisabled}
                        sx={{
                          mt: 3,
                          mb: 2,
                          background: "#2490EA",
                          padding: " 0.7rem 0.3rem",
                        }}
                      >
                        {send_otp ? "VERIFY OTP" : "Go Next"}
                      </Button>
                      {!send_otp ? (
                        <>
                          <Grid container>
                            <Grid item xs>
                              <Link
                                // to="/forgetPassword"
                                variant="body2"
                                className="fs-12px text-decoration-none text-black-50"
                              >
                                Forgot your password ? &nbsp;
                                <span
                                  className="text-link"
                                  onClick={() => setNextStop(3)}
                                >
                                  change now!
                                </span>
                              </Link>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                  )}

                  {nextStep == 2 && (
                    <div style={{scale:"0.9"}} className="mt-2">
                      {/* ======================================choose department ======================== */}

                      <DepartmentOtp />
                    </div>
                  )}

                  {nextStep == 3 && (
                    <div className="d-flex justify-content-center">
                      {/* ======================================choose department ======================== */}

                      <ForgetPassword />
                    </div>
                  )}
                </div>

                <div
                  className="col-md-12 col-lg-6 h-100 position-relative"
                  style={{ scale: "0.8" }}
                >
                  <img
                    src="/loginpage/mandir.svg"
                    alt=""
                    className="w-100"
                  />
                  <img
                    src="/loginpage/family.svg"
                    alt="vector"
                    className="position-absolute w-100"
                    style={{ top: "50%", left: "45%", translate: "-40% -45%", scale:"0.6" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <div
          className="w-100 d-flex flex-column justify-content-end"
          style={{ bottom: "0" }}
        >
          <p className="m-0 d-flex justify-content-end pe-5 pb-2">
            Powered by Tangy Media | India
          </p>
          <div className=" bg-prime-100 w-100" style={{ height: "5px" }}></div>
          <div
            className=" bg-secondary-100 w-100"
            style={{ height: "5px" }}
          ></div>
        </div>
      </Grid>
    </>
  );
}
