import React from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import "./Sidebar.css";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import Challan from "./../../pages/Reconciliation/challan/Challan";

const Sidebar = ({ collapse }) => {
  const [open, setOpen] = React.useState(false);
  const [FundReleaseOpen, SetFundReleaseOpen] = React.useState(false);
  const [reportsOpen, SetreportsOpen] = React.useState(false);
  const [reconciliatinOpen, SetReconciliatinOpen] = React.useState(false);
  const [depositoryOpen, setDepositoryOpen] = React.useState(false);
  const [budgetReapOpen, setBudgetReapOpen] = React.useState(false);
  const [beneficiaryOpen, setBeneficiaryOpen] = React.useState(false);
  const [bankSchemeOpen, setBankSchemeOpen] = React.useState(false);
  const [InvoiceOpen, setInvoiceOpen] = React.useState(false);
  const [ppaOpen, setppaOpen] = React.useState(false);
  const [user_type, set_User_type] = React.useState("");
  const { authData } = useSelector((state) => state.authData);
  const [isMulti, setIsMulti] = useState(
    authData.user.department_details.is_multi === 0 ? false : true
  );
  const [activeMenu, setActiveMenu] = useState("");
  const handleClick = () => {
    setOpen(!open);
  };
  
  const route = localStorage.getItem("routes");
  useEffect(()=>{
    if(route == 'approval'){
      SetFundReleaseOpen(true);
      setActiveMenu("approval");
    }
    else if(route == 'ppas'){
      setppaOpen(true)
      setActiveMenu("ppa");
    }
    else if(route == 'budget'){
      setOpen(true)
      setActiveMenu("budget");
    }
    else if(route == 'vendor'){
      setBeneficiaryOpen(true)
      setActiveMenu("beneficiary");
    }
    else if(route == 'report'){
      SetreportsOpen(true);
      setActiveMenu("reports");
    }
    else if(window.location.pathname == '/beneficiary-reimbursement'){
      setActiveMenu("/beneficiary-reimbursement")
    }
    else if(window.location.pathname == '/'){
      setActiveMenu("/")
    }
    else if(window.location.pathname == '/payroll-salary'){
      setActiveMenu("payroll-salary")
    }

  },[route])


  const handleClickFundRealse = () => {
    SetFundReleaseOpen(!FundReleaseOpen);
  };
  const handleClickReports = () => {
    SetreportsOpen(!reportsOpen);
  };

  const handleClickreconciliation = () => {
    SetReconciliatinOpen(!reconciliatinOpen);
  };
  const handleClickdepository = () => {
    setDepositoryOpen(!depositoryOpen);
  };

  const handleClickBudgetReappropriation = () => {
    setBudgetReapOpen(!budgetReapOpen);
  };
  const handleClickBankScheme = () => {
    setBankSchemeOpen(!bankSchemeOpen);
  };
  const handleClickInvoice = () => {
    setInvoiceOpen(!InvoiceOpen);
  };
  const handleClickBeneficiaryManagement = () => {
    setBeneficiaryOpen(!beneficiaryOpen);
  };
  const handleClickPPA = () => {
    setppaOpen(!ppaOpen);
  };
  useEffect(() => {
    set_User_type(authData.user.user_type);
  }, []);
  const location = useLocation();

  const isActive = (url) => {
    return location.pathname === url;
    setActiveMenu(url)
  };
  const isActiveStatus = (search) => {
    return location.search === search; // Check if the current search matches the provided search
  };
  return (
    <div
      className={`bg-white custom_scroll ${
        collapse && "overflow-y-auto"
      } z-3 position-static`}
      style={{ height: "100vh", zIndex: 50 }}
    >
      <List
        sx={{ width: "100%", maxWidth: 360 }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader
            component="div"
            id="nested-list-subheader"
            className="logoWrapper p-0 m-0 d-flex justify-content-center border-bottom"
          >
            <div
              className="d-flex justify-content-center  align-items-center px-2 gap-8px"
              style={{ height: "60px" }}
            >
              <img
                src="/logo.svg"
                alt="logo"
                className="p-0"
                height="36px"
                width="35px"
              />
              {collapse && (
                <span
                  className="fs-12px fw-600 text-black "
                  style={{ lineHeight: "15px" }}
                >
                  Uttarakhand Rural Road Development Authority
                </span>
              )}
            </div>
          </ListSubheader>
        }
      >
        {/* <ListSubheader component="div" className="department-header" style={{ marginLeft: 40, background: 'none' }}>
                {authData?.user?.department_details?.user_type || 'Department Code'}
                </ListSubheader>  */}
        {/*-------------------------------------Dashboard---------------------------------*/}
        <Link
          to="/"
          className={`${
            activeMenu === "/" ? "active text-white" : "submenu-hover"
          } px-3 d-flex align-items-center py-3  gap-8px text-black ${
            !collapse ? "justify-content-center" : "justify-content-start"
          } `}
          onClick={() => setActiveMenu("/")}
        >
          <i className="icon-dashboard fs-5"></i>
          {collapse && <span className="fs-14px">Dashboard</span>}
        </Link>
        {/*-------------------------------------Department Management---------------------------------*/}

        <div
          onClick={() => {
            handleClickBankScheme();
            setActiveMenu("department");
          }}
          className={`cursor-pointer collapse-submenu position-relative d-flex ${
            !collapse ? "justify-content-center" : "justify-content-between"
          }  align-items-center gap-8px p-3  ${
            activeMenu === "department" ? "active" : "submenu-hover"
          }`}
        >
          {!collapse && (
            <div className="position-absolute hover-menu d-none h-100 w-200-percent bg-white">
              <Link to="/departments">
                <div
                  className={`bg-white submenu-hover p-3 text-black fs-14px ${
                    isActive("/departments") ? "border-start-sub" : null
                  }`}
                >
                  Department
                </div>
              </Link>

              <Link to="/childAccountList">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/childAccountList") ? "border-start-sub" : null
                  }`}
                >
                  Child Bank Account
                </div>
              </Link>

              <Link to="/schemeAccountList">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/schemeAccountList") ? "border-start-sub" : null
                  }`}
                >
                  Child Scheme account
                </div>
              </Link>
            </div>
          )}

          <span className="gap-8px d-flex align-items-center">
            <i className="icon-category fs-5"></i>
            {collapse && <span className="fs-14px">Department</span>}
          </span>

          <div className="position-absolute end-0 pe-2">
          {bankSchemeOpen && collapse ? (
            <i className="icon-arrow_drop_down"></i>
          ) : (
            <i className="icon-arrow_right"></i>
          )}
          </div>

        </div>
        {collapse && (
          <Collapse
            in={bankSchemeOpen}
            timeout="auto"
            unmountOnExit
            className={
              bankSchemeOpen
                ? "subMenuWrapper subMenuWrapper_bottom_border"
                : ""
            }
          >
            <List component="div" disablePadding>
              {isMulti && (
                <Link to="/departments">
                  <div
                    className={`submenu-hover bg-light-blue p-3 text-black fs-14px  ${
                      isActive("/departments") ? "border-start-sub" : null
                    }`}
                  >
                    Department
                  </div>
                </Link>
              )}

              <Link to="/childAccountList">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/childAccountList") ? "border-start-sub" : null
                  }`}
                >
                  Child Bank Account
                </div>
              </Link>

              <Link to="/schemeAccountList">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/schemeAccountList") ? "border-start-sub" : null
                  }`}
                >
                  Child Scheme account
                </div>
              </Link>
            </List>
          </Collapse>
        )}

        {/*-------------------------------------User Management---------------------------------*/}
        <Link
          to="/users"
          className={`${
            activeMenu === "/users" ? "active text-white" : "submenu-hover"
          } px-3 d-flex align-items-center py-3 justify-content-start gap-8px text-black ${
            !collapse ? "justify-content-center" : "justify-content-start"
          } `}
          onClick={() => setActiveMenu("/users")}
        >
          <i className="icon-supervised_user_circle fs-5"></i>
          {collapse && <span className="fs-14px">User</span>}
        </Link>
        {/*-------------------------------------Budget Management---------------------------------*/}

        <div
          onClick={() => {
            handleClick();
            setActiveMenu("budget");
          }}
          className={`cursor-pointer collapse-submenu position-relative d-flex ${
            !collapse ? "justify-content-center" : "justify-content-between"
          } align-items-center gap-8px p-3  ${
            activeMenu === "budget" ? "active" : "submenu-hover"
          }`}
        >
          {!collapse && (
            <div className="position-absolute hover-menu d-none w-200-percent h-100 bg-white z-200">
              <Link to="/schemes">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/schemes") ? "border-start-sub" : null
                  }`}
                >
                  Scheme
                </div>
              </Link>

              <Link to="/budgets">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/budgets") ? "border-start-sub" : null
                  }`}
                >
                  Budget
                </div>
              </Link>

              <Link to="/budget-reappropriate">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/budget-reappropriate")
                      ? "border-start-sub"
                      : null
                  }`}
                >
                  Budget Revision
                </div>
              </Link>

              <Link
                to="/allocate-budgets"
                className={isActive("/allocate-budgets") ? "active" : ""}
              >
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/allocate-budgets") ? "border-start-sub" : null
                  }`}
                >
                  Child Budget Limit Allocation
                </div>
              </Link>

              <Link to="/department/activity">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/department/activity") ? "border-start-sub" : null
                  }`}
                >
                  Budget Activity
                </div>
              </Link>
              <Link to="/department/subactivity">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/department/subactivity")
                      ? "border-start-sub"
                      : null
                  }`}
                >
                  Budget Sub-Activity
                </div>
              </Link>
            </div>
          )}
          <span className="gap-8px d-flex align-items-center">
            <i className="icon-work_outline fs-5"></i>
            {collapse && <span className="fs-14px">Budget</span>}
          </span>

          <div className="position-absolute end-0 pe-2">

          {open && collapse ? (
            <i className="icon-arrow_drop_down"></i>
          ) : (
            <i className="icon-arrow_right"></i>
          )}
          </div>
        </div>
        {collapse && (
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            className={
              open ? "subMenuWrapper subMenuWrapper_bottom_border" : ""
            }
          >
            <List component="div" disablePadding>
              <Link to="/schemes">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/schemes") ? "border-start-sub" : null
                  }`}
                >
                  Scheme
                </div>
              </Link>

              <Link to="/budgets">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/budgets") ? "border-start-sub" : null
                  }`}
                >
                  Budget
                </div>
              </Link>

              <Link to="/budget-reappropriate">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/budget-reappropriate")
                      ? "border-start-sub"
                      : null
                  }`}
                >
                  Budget Revision
                </div>
              </Link>

              <Link
                to="/allocate-budgets"
                className={isActive("/allocate-budgets") ? "active" : ""}
              >
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/allocate-budgets") ? "border-start-sub" : null
                  }`}
                >
                  Budget Allocation
                </div>
              </Link>

              <Link to="/department/activity">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/department/activity") ? "border-start-sub" : null
                  }`}
                >
                  Budget Activity
                </div>
              </Link>
              <Link to="/department/subactivity">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/department/subactivity")
                      ? "border-start-sub"
                      : null
                  }`}
                >
                  Budget Sub-Activity
                </div>
              </Link>
            </List>
          </Collapse>
        )}

        {/*-------------------------------------Approval & Payments---------------------------------*/}
        <div
          onClick={() => {
            handleClickFundRealse();
            setActiveMenu("approval");
          }}
          className={`cursor-pointer d-flex collapse-submenu position-relative ${
            !collapse ? "justify-content-center" : "justify-content-between"
          } align-items-center gap-8px p-3  ${
            activeMenu === "approval" ? "active" : "submenu-hover"
          }`}
        >
        {!collapse && (
            <div className="position-absolute hover-menu d-none h-100 w-200-percent">
            <Link to="/fund-invoice-list">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/fund-invoice-list") ? "border-start-sub" : null
                  }`}
                >
                  Invoice
                </div>
              </Link>

              <Link to="/beneficiary-reimbursement/approval">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/beneficiary-reimbursement/approval")
                      ? "border-start-sub"
                      : null
                  }`}
                >
                  DBT/Nach
                </div>
              </Link>

              <Link to="/payroll-salary">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/payroll-salary") ? "border-start-sub" : null
                  }`}
                >
                  Salary
                </div>
              </Link>

              <Link to="/allocate-budget-approval">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/allocate-budget-approval")
                      ? "border-start-sub"
                      : null
                  }`}
                >
                  Child Budget Limit Allocation
                </div>
              </Link>
              <Link to="/royalty-labour-cess">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/royalty-labour-cess") ? "border-start-sub" : null
                  }`}
                >
                  Royalty & Labour Cess
                </div>
              </Link>
            </div>
          )}
          <span className="gap-8px d-flex align-items-center">
            <i className="icon-playlist_add_check fs-5"></i>
            {collapse && <span className="fs-14px">Approval & Payment</span>}
          </span>
          <div className="position-absolute end-0 pe-2">

          {FundReleaseOpen && collapse ? (
            <i className="icon-arrow_drop_down"></i>
          ) : (
            <i className="icon-arrow_right"></i>
          )}
          </div>
        </div>
        {collapse && (
          <Collapse
            in={FundReleaseOpen}
            timeout="auto"
            unmountOnExit
            className={
              FundReleaseOpen
                ? "subMenuWrapper subMenuWrapper_bottom_border"
                : ""
            }
          >
            {/*-------------------------------------Invoice---------------------------------*/}
            <List component="div" disablePadding>
              {/* <Link to="/department/subactivity">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/department/subactivity")
                    ? "border-start-sub"
                    : null
                }`}
              >
                Budget Sub-Activity
              </div>
            </Link> */}

              <Link to="/fund-invoice-list">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/fund-invoice-list") ? "border-start-sub" : null
                  }`}
                >
                  Invoice
                </div>
              </Link>

              <Link to="/beneficiary-reimbursement/approval">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/beneficiary-reimbursement/approval")
                      ? "border-start-sub"
                      : null
                  }`}
                >
                  DBT/NACH
                </div>
              </Link>

              <Link to="/payroll-salary">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/payroll-salary") ? "border-start-sub" : null
                  }`}
                >
                  Salary
                </div>
              </Link>

              <Link to="/allocate-budget-approval">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/allocate-budget-approval")
                      ? "border-start-sub"
                      : null
                  }`}
                >
                  Budget Allocation
                </div>
              </Link>
              <Link to="/royalty-labour-cess">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/royalty-labour-cess") ? "border-start-sub" : null
                  }`}
                >
                  Royalty & Labour Cess
                </div>
              </Link>
            </List>
          </Collapse>
        )}

        {/*-------------------------------------Beneficiary Reimbursements---------------------------------*/}
        {/* <List component="div" disablePadding>
                        <Link>
                            <ListItemButton sx={{ pl: 4 }} onClick={handleClickBudgetReappropriation}
                                className={budgetReapOpen ? 'subMenuWrapper subMenuWrapper_top_border' : ''}>
                                <ListItemText primary="DBT/Bulk/NACH" className='sidebarLinkText' />
                                {InvoiceOpen ? <ExpandLess /> : <ExpandMore />}
                            </ListItemButton>
                        </Link>
                        <Link style={{cursor: 'not-allowed', pointerEvents:'none'}}>
                            <ListItemButton sx={{ pl: 4 }} 
                                className={isActive('/beneficiary-reimbursement') ? 'active' : ""}>
                                <ListItemText primary="Child Budget Limit Allocation" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                        <Link style={{cursor: 'not-allowed', pointerEvents:'none'}}>
                            <ListItemButton sx={{ pl: 4 }} 
                                className={isActive('/beneficiary-reimbursement') ? 'active' : ""}>
                                <ListItemText primary="Royalty & Labour Cess" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                    </List>
                    
                </Collapse>
                {/*-------------------------------------Beneficiary Management---------------------------------*/}

        <div
          onClick={() => {
            handleClickBeneficiaryManagement();
            setActiveMenu("beneficiary");
          }}
          className={`cursor-pointer d-flex collapse-submenu position-relative ${
            !collapse ? "justify-content-center" : "justify-content-between"
          } align-items-center gap-8px p-3  ${
            activeMenu === "beneficiary" ? "active" : "submenu-hover"
          }`}
        >
          {!collapse && (
            <div className="position-absolute hover-menu d-none h-100 w-200-percent bg-white">
            <Link to="/beneficiary">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/beneficiary") ? "border-start-sub" : null
                  }`}
                >
                  Vendor Management
                </div>
              </Link>

              <Link to="/invoices">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/invoices") ? "border-start-sub" : null
                  }`}
                >
                  Vendor Payment
                </div>
              </Link>

              <Link to="/payment-deductions">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/payment-deductions") ? "border-start-sub" : null
                  }`}
                >
                  Deduction
                </div>
              </Link>
            </div>
          )}

          <span className="gap-8px d-flex align-items-center">
            <i className="icon-store fs-5"></i>
            {collapse && (
              <span className="fs-14px">Vendor</span>
            )}
          </span>

          <div className="position-absolute end-0 pe-2">
          {beneficiaryOpen && collapse ? (
            <i className="icon-arrow_drop_down"></i>
          ) : (
            <i className="icon-arrow_right"></i>
          )}
          </div>
        </div>
        {collapse && (
          <Collapse
            in={beneficiaryOpen}
            timeout="auto"
            unmountOnExit
            className={
              beneficiaryOpen
                ? "subMenuWrapper subMenuWrapper_bottom_border"
                : ""
            }
          >
            <List component="div" disablePadding>
              <Link to="/beneficiary">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/beneficiary") ? "border-start-sub" : null
                  }`}
                >
                  Vendor Management
                </div>
              </Link>

              <Link to="/invoices">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/invoices") ? "border-start-sub" : null
                  }`}
                >
                  Vendor Payment
                </div>
              </Link>

              <Link to="/payment-deductions">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/payment-deductions") ? "border-start-sub" : null
                  }`}
                >
                  Deduction
                </div>
              </Link>
            </List>
          </Collapse>
        )}

        {/* DBT/Bulk/Nach Management */}
        <Link
          to="/beneficiary-reimbursement"
          className={`${
            activeMenu === "/beneficiary-reimbursement"
              ? "active text-white"
              : "submenu-hover"
          } px-3 d-flex align-items-center py-3 ${
            !collapse ? "justify-content-center" : "justify-content-start"
          } gap-8px text-black `}
          onClick={() => setActiveMenu("/beneficiary-reimbursement")}
        >
          <i className="icon-nature_people fs-5"></i>
          {collapse && <span className="fs-14px">DBT/Nach</span>}
        </Link>

        <Link
          to="/payroll-salary"
          className={`${
            activeMenu === "payroll-salary"
              ? "active text-white"
              : "submenu-hover"
          } px-3 d-flex align-items-center py-3 ${
            !collapse ? "justify-content-center" : "justify-content-start"
          } gap-8px text-black `}
          onClick={() => setActiveMenu("payroll-salary")}
        >
          <i className="icon-assignment_turned_in fs-5"></i>
          {collapse && <span className="fs-14px">Salary</span>}
        </Link>

        {/* ==========================reconciliatin========================= */}

        <div
          onClick={() => {
            handleClickreconciliation();
            setActiveMenu("reconciliation");
          }}
          className={`cursor-pointer d-flex collapse-submenu position-relative ${
            !collapse ? "justify-content-center" : "justify-content-between"
          } align-items-center gap-8px p-3  ${
            activeMenu === "reconciliation" ? "active" : "submenu-hover"
          }`}
        >
        {!collapse && (
            <div className="position-absolute hover-menu d-none h-100 w-200-percent bg-white">
            <Link to="/tds-it">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/tds-it") ? "border-start-sub" : null
                  }`}
                >
                  TDS-IT
                </div>
              </Link>
              <Link to="/gst">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/gst") ? "border-start-sub" : null
                  }`}
                >
                  TDS-GST
                </div>
              </Link>
              <Link to="/gis">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/gis") ? "border-start-sub" : null
                  }`}
                >
                  GIS
                </div>
              </Link>
              <Link to="/nps">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/nps") ? "border-start-sub" : null
                  }`}
                >
                  NPS
                </div>
              </Link>

              <Link to="/other">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/other") ? "border-start-sub" : null
                  }`}
                >
                  Other Deduction
                </div>
              </Link>
              <Link to="/challan">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/challan") ? "border-start-sub" : null
                  }`}
                >
                  IFMS Interest Challan
                </div>
              </Link>
              <Link to="/royalty">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/royalty") ? "border-start-sub" : null
                  }`}
                >
                  Royalty
                </div>
              </Link>
            </div>
          )}
          <span className="gap-8px d-flex align-items-center">
            <i className="icon-emoji_flags fs-5"></i>
            {collapse && <span className="fs-14px">Reconciliation</span>}
          </span>
          <div className="position-absolute end-0 pe-3">

          {reconciliatinOpen && collapse ? (
            <i className="icon-arrow_drop_down"></i>
          ) : (
            <i className="icon-arrow_right"></i>
          )}
          </div>
        </div>
        {collapse && (
          <Collapse
            in={reconciliatinOpen}
            timeout="auto"
            unmountOnExit
            className={
              reconciliatinOpen
                ? "subMenuWrapper subMenuWrapper_bottom_border"
                : ""
            }
          >
            <List component="div" disablePadding>
              <Link to="/tds-it">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/tds-it") ? "border-start-sub" : null
                  }`}
                >
                  TDS-IT
                </div>
              </Link>
              <Link to="/gst">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/gst") ? "border-start-sub" : null
                  }`}
                >
                  TDS-GST
                </div>
              </Link>
              <Link to="/gis">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/gis") ? "border-start-sub" : null
                  }`}
                >
                  GIS
                </div>
              </Link>
              <Link to="/nps">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/nps") ? "border-start-sub" : null
                  }`}
                >
                  NPS
                </div>
              </Link>

              <Link to="/other">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/other") ? "border-start-sub" : null
                  }`}
                >
                  Other Deduction
                </div>
              </Link>
              <Link to="/challan">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/challan") ? "border-start-sub" : null
                  }`}
                >
                  IFMS Interest Challan
                </div>
              </Link>
              <Link to="/royalty">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/royalty") ? "border-start-sub" : null
                  }`}
                >
                  Royalty
                </div>
              </Link>
            </List>
          </Collapse>
        )}

        {/*-------------------------------------Depository---------------------------------*/}
        <div
          onClick={() => {
            handleClickdepository();
            setActiveMenu("depository");
          }}
          className={`cursor-pointer collapse-submenu position-relative d-flex ${
            !collapse ? "justify-content-center" : "justify-content-between"
          } align-items-center gap-8px p-3  ${
            activeMenu === "depository" ? "active" : "submenu-hover"
          }`}
        >
        {!collapse && (
            <div className="position-absolute hover-menu d-none h-100 w-200-percent bg-white">
            <Link to="/add-depository">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/add-depository") ? "border-start-sub" : null
                  }`}
                >
                  Add Depository
                </div>
              </Link>
              <Link to="/depository">
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/depository") ? "border-start-sub" : null
                  }`}
                >
                  Depositroy
                </div>
              </Link>

            </div>
          )}
          <span className="gap-8px d-flex align-items-center">
            <i className="icon-save_alt fs-5"></i>
            {collapse && <span className="fs-14px">Depository</span>}
          </span>
          <div className="position-absolute end-0 pe-3">

          {depositoryOpen && collapse ? (
            <i className="icon-arrow_drop_down"></i>
          ) : (
            <i className="icon-arrow_right"></i>
          )}
          </div>
        </div>
        {collapse && (
          <Collapse
            in={depositoryOpen}
            timeout="auto"
            unmountOnExit
            className={
              depositoryOpen
                ? "subMenuWrapper subMenuWrapper_bottom_border"
                : ""
            }
          >
            <List component="div" disablePadding>
              <Link to="/add-depository">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/add-depository") ? "border-start-sub" : null
                  }`}
                >
                  Add Depository
                </div>
              </Link>
              <Link to="/depository">
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/depository") ? "border-start-sub" : null
                  }`}
                >
                  Depositroy
                </div>
              </Link>
            </List>
          </Collapse>
        )}

        {/*-------------------------------------Print Payment Advice (PPA) ---------------------------------*/}
        <div
          onClick={() => {
            handleClickPPA();
            setActiveMenu("ppa");
          }}
          className={`cursor-pointer d-flex collapse-submenu position-relative ${
            !collapse ? "justify-content-center" : "justify-content-between"
          } align-items-center gap-8px p-3  ${
            activeMenu === "ppa" ? "active" : "submenu-hover"
          }`}
        >
        {!collapse && (
            <div className="position-absolute hover-menu d-none h-100 w-200-percent bg-white">
            <Link to="/ppa" className={isActive("/ppa") ? "active" : ""}>
                <div
                  className={`submenu-hover bg-white p-3 text-black fs-14px ${
                    isActive("/ppa") ? "border-start-sub" : null
                  }`}
                >
                  PPA - Print Payment Advice
                </div>
              </Link>
            </div>
          )}
          <span className="gap-8px d-flex align-items-center">
            <i className="icon-local_printshop fs-5"></i>
            {collapse && (
              <span className="fs-14px"> PPA - Print Payment Advice</span>
            )}
          </span>
          <div className="position-absolute end-0 pe-3">
          {ppaOpen && collapse ? (
            <i className="icon-arrow_drop_down"></i>
          ) : (
            <i className="icon-arrow_right"></i>
          )}
          </div>
        </div>
        {collapse && (
          <Collapse
            in={ppaOpen}
            timeout="auto"
            unmountOnExit
            className={
              ppaOpen ? "subMenuWrapper subMenuWrapper_bottom_border" : ""
            }
          >
            <List component="div" disablePadding>
              <Link to="/ppa" className={isActive("/ppa") ? "active" : ""}>
                <div
                  className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                    isActive("/ppa") ? "border-start-sub" : null
                  }`}
                >
                   Print Payment Advice
                </div>
              </Link>
            </List>
          </Collapse>
        )}

        {/*-------------------------------------Reports---------------------------------*/}
        <div
          onClick={() => {
            handleClickReports();
            setActiveMenu("reports");
          }}
          className={`cursor-pointer collapse-submenu position-relative d-flex ${
            !collapse ? "justify-content-center" : "justify-content-between"
          } align-items-center gap-8px p-3  ${
            activeMenu === "reports" ? "active" : "submenu-hover"
          }`}
        >
          {!collapse && (
            <div className="position-absolute hover-menu d-none h-100 w-200-percent bg-white">
            <Link to="/schemes">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/schemes") ? "border-start-sub" : null
                }`}
              >
                Scheme Balance
              </div>
            </Link>

            <Link to="/budgets">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/budgets") ? "border-start-sub" : null
                }`}
              >
                Budget
              </div>
            </Link>

            <Link to={`/budget-reappropriate`}>
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/budget-reappropriate") ? "border-start-sub" : null
                }`}
              >
                Revised Budget
              </div>
            </Link>

            <Link to="/scheme-subhead">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/scheme-subhead") ? "border-start-sub" : null
                }`}
              >
                Scheme Subhead
              </div>
            </Link>

            <Link to={`/scheme-activity`}>
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/scheme-activity") ? "border-start-sub" : null
                }`}
              >
                Scheme Activity & Sub- Activity
              </div>
            </Link>

            <Link to="/allocate-budgets">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/allocate-budgets") ? "border-start-sub" : null
                }`}
              >
                Budget Allocation
              </div>
            </Link>
            <Link to="certificate">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/certificate") ? "border-start-sub" : null
                }`}
              >
                UC :Utilization Certificate
              </div>
            </Link>
            <Link to="/mpr-report">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/mpr-report") ? "border-start-sub" : null
                }`}
              >
                MPR :Monthly Progress Report
              </div>
            </Link>
            <Link to="/beneficiary-report">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/beneficiary-report") ? "border-start-sub" : null
                }`}
              >
                Vendor/Contractor/Service Provider
              </div>
            </Link>
            {/* <List component="div" disablePadding> */}
            {/* {
                            user_type == 'l1' ?
                                <Link to='/add-beneficiary-reimbursement' className={isActive('/add-beneficiary-reimbursement') ? 'active' : ""}>
                                    <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                        <ListItemText primary="Add Beneficiary Reimbursement" className='sidebarLinkText' />
                                    </ListItemButton>
                                </Link>
                                : ''
                        } */}
            <Link to="/beneficiary-reimbursement">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/beneficiary-reimbursement")
                    ? "border-start-sub"
                    : null
                }`}
              >
                Payments :DBT/Bulk/NACH
              </div>
            </Link>
            {/* </List> */}
            {/* <Link to='/ppa' className={isActive('/ppa') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="TDS-IT" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link> */}
            <Link to="/payroll-salary-payment">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/payroll-salary-payment")
                    ? "border-start-sub"
                    : null
                }`}
              >
                Payments :Payroll / Salary
              </div>
            </Link>
            <Link to="/invoices">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/invoices") ? "border-start-sub" : null
                }`}
              >
                Payments :Invoice
              </div>
            </Link>
            <Link to="/payment-royalty">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/payment-royalty") ? "border-start-sub" : null
                }`}
              >
                Payments :Royalty
              </div>
            </Link>

            <Link>
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/labour-cess") ? "border-start-sub" : null
                }`}
              >
                Payments :Labour Cess
              </div>
            </Link>

            <Link>
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/payment-penalty") ? "border-start-sub" : null
                }`}
              >
                Payments :Penalty
              </div>
            </Link>

            <Link>
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/payment-amount") ? "border-start-sub" : null
                }`}
              >
                Payments : Withheld Amount
              </div>
            </Link>
            <Link to="/tds-it-report">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/tds-it-report") ? "border-start-sub" : null
                }`}
              >
                TDS-IT
              </div>
            </Link>
            <Link to="/gst-report">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/gst-report") ? "border-start-sub" : null
                }`}
              >
                TDS-GST
              </div>
            </Link>
            <Link to="/gis-report">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/gis-report") ? "border-start-sub" : null
                }`}
              >
                GIS
              </div>
            </Link>
            <Link to="/nps-report">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/nps-report") ? "border-start-sub" : null
                }`}
              >
                NPS
              </div>
            </Link>
            <Link>
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/royalty") ? "border-start-sub" : null
                }`}
              >
                Royalty
              </div>
            </Link>
            <Link>
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/labour-cess") ? "border-start-sub" : null
                }`}
              >
                Labour Cess
              </div>
            </Link>
            <Link to="/other-report">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/other-report") ? "border-start-sub" : null
                }`}
              >
                Other Deduction
              </div>
            </Link>
            <Link>
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/penalty") ? "border-start-sub" : null
                }`}
              >
                Penalty
              </div>
            </Link>
            <Link>
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/wihheld-amount") ? "border-start-sub" : null
                }`}
              >
                Withheld Amount
              </div>
            </Link>
            <Link to="/interest">
              <div
                className={`submenu-hover bg-white p-3 text-black fs-14px ${
                  isActive("/interest") ? "border-start-sub" : null
                }`}
              >
                Bank Account Interest
              </div>
            </Link>

            </div>
          )}
          <span className="gap-8px d-flex align-items-center">
            <i className="icon-assignment fs-5"></i>
            {collapse && <span className="fs-14px">Report</span>}
          </span>

          <div className="position-absolute end-0 pe-3">
          {reportsOpen && collapse ? (
            <i className="icon-arrow_drop_down"></i>
          ) : (
            <i className="icon-arrow_right"></i>
          )}
          </div>
        </div>
        {collapse && (
          <Collapse
            in={reportsOpen}
            timeout="auto"
            unmountOnExit
            className={
              reportsOpen ? "subMenuWrapper subMenuWrapper_bottom_border" : ""
            }
          >
            <Link to="/schemes">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/schemes") ? "border-start-sub" : null
                }`}
              >
                Scheme Balance
              </div>
            </Link>

            <Link to="/budgets">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/budgets") ? "border-start-sub" : null
                }`}
              >
                Budget
              </div>
            </Link>

            <Link to={`/budget-reappropriate`}>
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/budget-reappropriate") ? "border-start-sub" : null
                }`}
              >
                Revised Budget
              </div>
            </Link>

            <Link to="/scheme-subhead">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/scheme-subhead") ? "border-start-sub" : null
                }`}
              >
                Scheme Subhead
              </div>
            </Link>

            <Link to={`/scheme-activity`}>
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/scheme-activity") ? "border-start-sub" : null
                }`}
              >
                Scheme Activity & Sub- Activity
              </div>
            </Link>

            <Link to="/allocate-budgets">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/allocate-budgets") ? "border-start-sub" : null
                }`}
              >
                Budget Allocation
              </div>
            </Link>
            <Link to="certificate">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/certificate") ? "border-start-sub" : null
                }`}
              >
                UC :Utilization Certificate
              </div>
            </Link>
            <Link to="/mpr-report">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/mpr-report") ? "border-start-sub" : null
                }`}
              >
                MPR :Monthly Progress Report
              </div>
            </Link>
            <Link to="/beneficiary-report">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/beneficiary-report") ? "border-start-sub" : null
                }`}
              >
                Vendor/Contractor/Service Provider
              </div>
            </Link>
            {/* <List component="div" disablePadding> */}
            {/* {
                            user_type == 'l1' ?
                                <Link to='/add-beneficiary-reimbursement' className={isActive('/add-beneficiary-reimbursement') ? 'active' : ""}>
                                    <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                        <ListItemText primary="Add Beneficiary Reimbursement" className='sidebarLinkText' />
                                    </ListItemButton>
                                </Link>
                                : ''
                        } */}
            <Link to="/beneficiary-reimbursement">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/beneficiary-reimbursement")
                    ? "border-start-sub"
                    : null
                }`}
              >
                Payments :DBT/Bulk/NACH
              </div>
            </Link>
            {/* </List> */}
            {/* <Link to='/ppa' className={isActive('/ppa') ? 'active' : ""}>
                        <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                            <ListItemText primary="TDS-IT" className='sidebarLinkText' />
                        </ListItemButton>
                    </Link> */}
            <Link to="/payroll-salary-payment">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/payroll-salary-payment")
                    ? "border-start-sub"
                    : null
                }`}
              >
                Payments :Payroll / Salary
              </div>
            </Link>
            <Link to="/invoices">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/invoices") ? "border-start-sub" : null
                }`}
              >
                Payments :Invoice
              </div>
            </Link>
            <Link to="/payment-royalty">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/payment-royalty") ? "border-start-sub" : null
                }`}
              >
                Payments :Royalty
              </div>
            </Link>

            <Link>
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/labour-cess") ? "border-start-sub" : null
                }`}
              >
                Payments :Labour Cess
              </div>
            </Link>

            <Link>
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/payment-penalty") ? "border-start-sub" : null
                }`}
              >
                Payments :Penalty
              </div>
            </Link>

            <Link>
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/payment-amount") ? "border-start-sub" : null
                }`}
              >
                Payments : Withheld Amount
              </div>
            </Link>
            <Link to="/tds-it-report">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/tds-it-report") ? "border-start-sub" : null
                }`}
              >
                TDS-IT
              </div>
            </Link>
            <Link to="/gst-report">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/gst-report") ? "border-start-sub" : null
                }`}
              >
                TDS-GST
              </div>
            </Link>
            <Link to="/gis-report">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/gis-report") ? "border-start-sub" : null
                }`}
              >
                GIS
              </div>
            </Link>
            <Link to="/nps-report">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/nps-report") ? "border-start-sub" : null
                }`}
              >
                NPS
              </div>
            </Link>
            <Link>
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/royalty") ? "border-start-sub" : null
                }`}
              >
                Royalty
              </div>
            </Link>
            <Link>
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/labour-cess") ? "border-start-sub" : null
                }`}
              >
                Labour Cess
              </div>
            </Link>
            <Link to="/other-report">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/other-report") ? "border-start-sub" : null
                }`}
              >
                Other Deduction
              </div>
            </Link>
            <Link>
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/penalty") ? "border-start-sub" : null
                }`}
              >
                Penalty
              </div>
            </Link>
            <Link>
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/wihheld-amount") ? "border-start-sub" : null
                }`}
              >
                Withheld Amount
              </div>
            </Link>
            <Link to="/interest">
              <div
                className={`submenu-hover bg-light-blue p-3 text-black fs-14px ${
                  isActive("/interest") ? "border-start-sub" : null
                }`}
              >
                Bank Account Interest
              </div>
            </Link>
            {/* <List component="div" disablePadding>
                        <Link to='/other-report' className={isActive('/other-report') ? 'active' : ""}>
                            <ListItemButton sx={{ pl: 4 }} className='sidbar_linkWrapper'>
                                <ListItemText primary="Other Deduction" className='sidebarLinkText' />
                            </ListItemButton>
                        </Link>
                    </List> */}
          </Collapse>
        )}

        {/* consolidate report */}
        {/* <ListItemButton onClick={handleClickreconciliation}
                    className={reconciliatinOpen ? 'subMenuWrapper subMenuWrapper_top_border' : ''}>
                    <ListItemText primary="Child Reports" className='sidebarLinkText' />
                    {reconciliatinOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton> */}
        {/* <Link to="/department-switch" style={{ textDecoration: 'none' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{
                            display: 'block',
                            margin: '0 auto',
                            padding: '10px 20px',
                            textAlign: 'center',
                            marginTop: '10px',
                            borderRadius: '10px',
                        }}
                    >
                        Switch Department
                    </Button>
                </Link> */}
        <br></br>
      </List>
    </div>
  );
};

export default Sidebar;
