import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import {
    editActivity,
    getActivity,
    updateSchemeBankAccountApproval, get_departments,AddBankAccount
} from '../../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'react-bootstrap'
import AccountPreview from '../Model/AccountPreview';
const AddChildAccount = () => {
    const { id,viewId} = useParams();
    const isReadOnly = viewId;
    const { authData } = useSelector((state) => state.authData);
    const [department, setDepartment] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [formData, setFormData] = useState({
        "id": "",
        "account_name": "",
        "bank_name": "",
        "branch_name": "",
        "ifsc_code": "",
        "ifsc_code_bank": "",
        "account_type": "",
        "account_no": "",
        "account_balance": "",
        "balance_date": "",
        "interest": "",
        "quarter": "",
        "interest_date": "",
        "email":"",
        "mobile":"",
        "l2_approval": "",
        "l3_approval": "",
        "l1_remark": "",
        "l2_remark": "",
        "l3_remark": "",
        "department_id": "",
        'added_by': authData.user.id
    })

    const navigate = useNavigate();
    const previewHandler = (e) => {
        e.preventDefault();
        setShowPreview(true);
    };
    const fetchData = async (id) => {
        const response = await getActivity(id);
        if (response.data.status) {
            setFormData(response.data.result[0])
        }
    }
    useEffect(() => {
        fetchDepartments();
        if (id || viewId) {
            fetchData(id ||viewId);
        }
    }, [id||viewId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // if (id) {
            //     const response = await editActivity({ activity: formData.activity, status: formData.status }, id);
            //     if (response.data.status) {
            //         toast.success(response.data.message, {
            //             position: toast.POSITION.TOP_CENTER
            //         });
            //         navigate('/childAccountList');
            //     } else {
            //         toast.error(response.data.message, {
            //             position: toast.POSITION.TOP_CENTER
            //         });
            //     }
            // }
            // else {
                const response = await AddBankAccount(formData);
                if (response.data.status) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/childAccountList');
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            // }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }
    const onApprove = async (status) => {
        formData.l2_approval = authData.user.user_type == 'l2' ? status : formData.l2_approval;
        formData.l3_approval = authData.user.user_type == 'l3' ? status : formData.l3_approval;
        let approvalData = {
            l2_approval: formData.l2_approval,
            l3_approval: formData.l3_approval,
            l2_remark: formData.l2_remark,
            l3_remark: formData.l3_remark
        }
        const { data } = await updateSchemeBankAccountApproval(approvalData, formData.id);
    }
    const fetchDepartments = async () => {
        try {
            const departments = await get_departments({ department_level: '' });
            if (departments.data.status) {
                setDepartment(departments.data.result);
            }
        } catch (error) {
            console.log("departments");
        }
    }
    return (
        <div>
            <AccountPreview showPreview={showPreview} setShowPreview={setShowPreview} formData={formData} handleSubmit={handleSubmit} department={department}></AccountPreview>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={previewHandler}>
                    <div className="col-md-12">
                        <div className="add_new_user">
                            <h4>{viewId ? 'View Account' : (id ? 'Edit Account' : 'Add Account')}</h4>
                        </div>
                        <div className="card p-3 mt-3">
                            <div className="row">
                                {/* <Form> */}
                                <Container>
                                    <Row>
                                    <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Department<span className='text-danger'>*</span></Form.Label>
                                                <select
                                                    className="form-control "
                                                    id="department_id"
                                                    name='department_id'
                                                    required
                                                    value={formData.department_id}
                                                    onChange={handleChange} readOnly={isReadOnly}>
                                                    <option value="">Select Department Type</option>
                                                    {
                                                        department.map((dept) => {
                                                            return (
                                                                <option value={dept.id}>{dept.name} - {dept.code}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Account Name <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Enter Account Name" name='account_name' value={formData.account_name} onChange={handleChange} required  readOnly={isReadOnly}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Bank Name <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Enter Bank Name" name='bank_name' value={formData.bank_name} onChange={handleChange} required readOnly={isReadOnly}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Bank Branch <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Enter Bank Branch" name='branch_name' value={formData.branch_name} onChange={handleChange} required readOnly={isReadOnly}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Account Type <span className='text-danger'>*</span></Form.Label>
                                                <select
                                                    className="form-control "
                                                    id="account_type"
                                                    name='account_type'
                                                    required
                                                    value={formData.account_type}
                                                    onChange={handleChange} readOnly={isReadOnly}>
                                                    <option value="">Select Account Type</option>
                                                    <option value="saving">Saving Account</option>
                                                    <option value="current">Current Account</option>
                                                </select>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Account No <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="number" placeholder="Enter Account No" name='account_no' value={formData.account_no} onChange={handleChange} required readOnly={isReadOnly}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Account Balance <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Enter Account Balance" name='account_balance' value={formData.account_balance} onChange={handleChange} required readOnly={isReadOnly}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>IFSC Code <span className='text-danger'>*</span></Form.Label>
                                                <input type="text" className="form-control" id="ifsc_code"
                                                    required
                                                    maxLength={11}
                                                    name='ifsc_code'
                                                    value={formData.ifsc_code}
                                                    onChange={handleChange}
                                                    placeholder="Enter IFSC Code" readOnly={isReadOnly}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>IFSC code - (Bank)<span className='text-danger'>*</span></Form.Label>
                                                <input type="text" className="form-control" id="ifsc_code_bank"
                                                    required
                                                    maxLength={11}
                                                    name='ifsc_code_bank'
                                                    value={formData.ifsc_code_bank}
                                                    onChange={handleChange}
                                                    placeholder="Enter IFSC Code" />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Closing Balance Date <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="date" placeholder="Enter Balance Date" name='balance_date'
                                                    value={formData.balance_date} onChange={handleChange}
                                                    required readOnly={isReadOnly}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Interest <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="number" placeholder="Enter Interest" name='interest'
                                                    value={formData.interest} onChange={handleChange} required readOnly={isReadOnly}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Interest Date <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="date" placeholder="Enter Interest Date" name='interest_date'
                                                    value={formData.interest_date} onChange={handleChange} required readOnly={isReadOnly}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Quarter <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="number" placeholder="Enter Quarter" name='quarter'
                                                    value={formData.quarter} onChange={handleChange} required readOnly={isReadOnly}/>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="text" placeholder="Enter Email" name='email'
                                                    value={formData.email} onChange={handleChange} required />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Mobile <span className='text-danger'>*</span></Form.Label>
                                                <Form.Control type="number" placeholder="Enter Mobile" name='mobile'
                                                    value={formData.mobile} onChange={handleChange} required />
                                            </Form.Group>
                                        </Col>
                                        
                                    </Row>
                                    {
                                        authData.user.user_type == 'l1' ?
                                            <>
                                                <label>L1 Remark</label>
                                                <textarea className='form-control' onChange={handleChange} name="l1_remark" readOnly={isReadOnly}></textarea>
                                            </>
                                            : ''
                                    }
                                    <br></br>
                                    {
                                        (formData.l2_approval != 1 || formData.l3_approval != 1) && authData.user.user_type == 'l3' && !viewId ?
                                            <Button variant="primary" type="submit">
                                                {id ? 'Update Account' : 'Add Account'}
                                            </Button>
                                            : ''
                                    }
                                    {
                                        authData.user.user_type == 'l2' && formData.l2_approval == null ?
                                            <>
                                                <label>L2 Remark</label>
                                                <textarea className='form-control' onChange={handleChange} name="l2_remark" readOnly={isReadOnly}></textarea>
                                                <button type="button" className="btn btn-danger w-30 mt-2" onClick={() => onApprove(0)}>Reject</button>
                                                <button type="button" className="btn btn-primary w-30 mt-2" style={{ marginLeft: '10px' }} onClick={() => onApprove(1)}>Approve</button>
                                            </>
                                            : ''
                                    }
                                    {
                                        authData.user.user_type == 'l3' && formData.l3_approval == null ?
                                            <>
                                                <label>L2 Remark</label>
                                                <textarea className='form-control' onChange={handleChange} name="l3_remark" readOnly={isReadOnly}></textarea>
                                                <button type="button" className="btn btn-danger w-30 mt-2" onClick={() => onApprove(0)}>Reject</button>
                                                <button type="button" className="btn btn-primary w-30 mt-2" style={{ marginLeft: '10px' }} onClick={() => onApprove(1)}>Approve</button>
                                            </>
                                            : ''
                                    }
                                </Container>
                                {/* </Form> */}
                            </div>
                        </div>
                    </div>
                    </Form>
                </div>
            </div>
        </div>
    )
};
export default AddChildAccount;