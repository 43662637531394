import React from 'react';
import Tools from "../../../components/Tools/Tools"
import ViewAllocateBudget from '../../../components/Multi/BudgetAllocation/ViewAllocateBudget';
const ViewBudgetPage = () => {
    return (
        <div>
            <ViewAllocateBudget />
        </div>
    )
}

export default ViewBudgetPage
