import React from 'react'
import Tools from '../../../components/Tools/Tools'
import AddVoucherHeadline from '../../../components/AddVoucher/AddVoucherHeadline'

const AddVoucher = () => {
  return (
    <div>
        {/* <Tools/> */}
        <AddVoucherHeadline></AddVoucherHeadline>
    </div>
  )
}

export default AddVoucher