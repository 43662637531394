import React, { useState, useEffect } from 'react';
import './CustomTable.css'; // Create this file for styling

const CustomTable = ({ data, columns, initialItemsPerPageOptions, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(initialItemsPerPageOptions[0]);
    const [sortedData, setSortedData] = useState([...data]);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });
    const [hoveredColumn, setHoveredColumn] = useState(null); // Track which column is hovered

    useEffect(() => {
        setSortedData([...data]);
    }, [data]);

    // Sorting handler
    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        const sorted = [...sortedData].sort((a, b) => {
            if (a[key] < b[key]) return direction === 'ascending' ? -1 : 1;
            if (a[key] > b[key]) return direction === 'ascending' ? 1 : -1;
            return 0;
        });
        setSortConfig({ key, direction });
        setSortedData(sorted);
    };

    // Pagination logic
    const pageCount = Math.ceil(sortedData.length / itemsPerPage);
    const currentTableData = sortedData.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );

    const handlePageClick = (page) => {
        setCurrentPage(page);
        if (onPageChange) {
            onPageChange(page, itemsPerPage); // Call the function passed from the parent
        }
    };

    const handleItemsPerPageChange = (e) => {
        setItemsPerPage(Number(e.target.value));
        setCurrentPage(0);
        if (onPageChange) {
            onPageChange(currentPage, e.target.value); // Call the function passed from the parent
        }
    };

    // Calculate the range of items being displayed
    const startItem = currentPage * itemsPerPage + 1;
    const endItem = Math.min((currentPage + 1) * itemsPerPage, sortedData.length);

    return (
        <div>
           <table className="table w-75 border border-danger rounded-table"> 
    <thead>
        <tr>
            {columns.map((column, index) => (
                <th
                    key={column.accessor}
                    onClick={() => handleSort(column.accessor)}
                    onMouseEnter={() => setHoveredColumn(column.accessor)}
                    onMouseLeave={() => setHoveredColumn(null)}
                    className="bg-white no-border text-black-60 fs-12px"
                    style={{width: index === 0 ? "44px" : column.width, textAlign: 'left' }}>
                    {column.Header}
                    {hoveredColumn === column.accessor && (
                        <span className={`sort-arrow ${sortConfig.key === column.accessor ? (sortConfig.direction === 'ascending' ? 'ascending' : 'descending') : ''}`}>
                            {sortConfig.key === column.accessor && sortConfig.direction === 'ascending' ? '🔼' : '🔽'}
                        </span>
                    )}
                </th>
            ))}
        </tr>
    </thead>
    <tbody>
        {currentTableData.map((row, rowIndex) => (
            <tr key={rowIndex} className="no-border text-left">
                {columns.map((column) => (
                    <td key={column.accessor} style={{textAlign:"left"}} className="ellipsis text-left no-border fs-14px">
                        <div className="ellipsis">
                            {column.Cell ? column.Cell({ row }) : row[column.accessor]}
                        </div>
                    </td>
                ))}
            </tr>
        ))}
    </tbody>
</table>


            <div className="pagination-container">
                {/* Items per page dropdown */}
                <div className="items-per-page">
                    <label>Items per page: </label>
                    <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                        {initialItemsPerPageOptions.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Pagination information */}
                <div className="pagination-info">
                    {startItem}–{endItem} of {sortedData.length}
                    <button
                        onClick={() => handlePageClick(currentPage - 1)}
                        disabled={currentPage === 0}
                        className="pagination__link"
                    >
                        &#8592; {/* Left arrow for Previous */}
                    </button>
                    <button
                        onClick={() => handlePageClick(currentPage + 1)}
                        disabled={currentPage === pageCount - 1}
                        className="pagination__link"
                    >
                        &#8594; {/* Right arrow for Next */}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CustomTable;
