import React, { useState } from 'react'
import './AddUser.css';
import finger_print_img from '../../assets/images/finger_print.png';
import { Alert, Form, Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_USER_START, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL } from '../../RTK/Slices/UserSlice';
import { update_user_by_id, get_user_by_id, get_my_departments, reset_password_code, reset_password_verify } from '../../helper/Api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
const Profile = () => {
    const [showModal, setShowModal] = useState(false);
    const { id } = useParams();
    const { authData } = useSelector((state) => state.authData);
    const { loading } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [formData, setFormData] = useState({
        code: null,
        user_name: null,
        designation: null,
        user_type: null,
        mobile: null,
        email: null,
        password: null,
        added_by: authData.user.id
    });
    const [passwordData, setPasswordData] = useState({
        old_password: '',
        new_password: '',
        confirm_password: '',
        otp: '',
    });
    const [passwordVisibility, setPasswordVisibility] = useState({
        old_password: false,
        new_password: false,
        confirm_password: false
    });
    const [departments, setDepartments] = useState([]);
    const [error, setError] = useState({
        status: false,
        msg: ''
    });

    useEffect(() => {
        get_user_details();
        fetchDepartments();
    }, [id])

    const get_user_details = async () => {
        try {
            const { data } = await get_user_by_id(id);
            const user = data.users[0];
            setFormData({
                code: user.code,
                user_name: user.user_name,
                designation: user.designation,
                user_type: user.user_type,
                mobile: user.mobile,
                email: user.email,
                password: '',
                added_by: authData.user.id
            })
        } catch (error) {
            // console.log(error);
        }
    }
    const fetchDepartments = async () => {
        try {
            const departments = await get_my_departments();
            if (departments.data.status) {
                setDepartments(departments.data.departments);
            }
        } catch (error) {
            console.log("departments", error);
        }
    }
    const handleFormDataChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            alert('Please fill in all fields correctly.');
            return;
        }
        try {
            dispatch(UPDATE_USER_START());
            const { data } = await update_user_by_id(formData, id);

            if (data.status) {
                dispatch(UPDATE_USER_SUCCESS(data.users[0]));
                toast.success(data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                navigate('/users');
            } else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                dispatch(UPDATE_USER_FAIL(data.message));
            }
        } catch (error) {
            // console.log(error);
            toast.error("error getting while update user", {
                position: toast.POSITION.TOP_CENTER
            });
            dispatch(UPDATE_USER_FAIL('error getting while update user'));
        }
    }

    const validateForm = () => {
        // Validate each field individually
        if (!formData.code.trim() ||
            !formData.user_name.trim() ||
            !formData.designation.trim() ||
            !formData.user_type.trim() ||
            !formData.mobile.trim() ||
            !formData.email.trim() ||
            !formData.password.trim()) {
            return false; // If any field is empty, return false
        }
        // Validate email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            return false; // If email format is invalid, return false
        }
        // Validate password format
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordRegex.test(formData.password)) {
            // setPasswordError('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.');
            return false;
        }
        // Add more validation logic as needed
        return true; // All fields are valid
    }

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswordData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };
    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (!formData.new_password ||!formData.old_password ||!formData.otp ||!formData.confirm_password) {
            toast.error('Fields Required', {
                position: toast.POSITION.TOP_CENTER
            });
        }
        if (passwordData.new_password !== passwordData.confirm_password) {
            toast.error('New passwords and confirm Password do not match!', {
                position: toast.POSITION.TOP_CENTER
            });
            return;
        }
        try {
            const { data } = await reset_password_verify(passwordData);
            if (data.status) {
                setShowModal(false);
                setPasswordData({
                    old_password: '',
                    new_password: '',
                    confirm_password: '',
                    otp: '',
                })
                setPasswordVisibility({
                    old_password: false,
                    new_password: false,
                    confirm_password: false
                })
                toast.success(data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
        catch (error) {
            toast.error("error getting while reset password verify otp", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };
    const resetPasword = async () => {
        try {
            const { data } = await reset_password_code();
            if (data.status) {
                setShowModal(true);
                toast.success(data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
        catch (error) {
            toast.error("error getting while reset password", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    const togglePasswordVisibility = (field) => {
        setPasswordVisibility({
            ...passwordVisibility,
            [field]: !passwordVisibility[field]
        });
    };
    return (
        <div>
            <div className="p-3">
                <div className="row">
                    {error.status && (
                        <Alert variant="danger" onClose={() => setError({ status: false, msg: '' })} dismissible>
                            {error.msg}
                        </Alert>
                    )}

                    <Form onSubmit={handleSubmit}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <p>Profile</p>
                                <div>
                                    <button type="button" className="btn btn-primary" onClick={() => resetPasword()}>
                                        Reset Password
                                    </button>
                                </div>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="form-group row p-2">
                                            <label for="inputEmployee" className="col-sm-4 col-form-label">Employee
                                                Code<span className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" id="inputEmployee"
                                                    name="code"
                                                    maxLength={10}
                                                    value={formData.code}
                                                    onChange={handleFormDataChange}
                                                    required
                                                    disabled={location.pathname.includes('profile')}
                                                    placeholder="Enter Employee Code" />
                                            </div>
                                        </div>

                                        <div className="form-group row p-2">
                                            <label for="inputUserid" className="col-sm-4 col-form-label">User Name<span
                                                className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputUserid"
                                                    name="user_name"
                                                    maxLength={30}
                                                    required
                                                    disabled={location.pathname.includes('profile')}
                                                    value={formData.user_name}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter User Name" />
                                            </div>
                                        </div>

                                        <div className="form-group row p-2">
                                            <label for="inputDesignation"
                                                className="col-sm-4 col-form-label">Designation<span
                                                    className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputDesignation"
                                                    maxLength={30}
                                                    name="designation"
                                                    value={formData.designation}
                                                    disabled={location.pathname.includes('profile')}
                                                    onChange={handleFormDataChange}
                                                    required
                                                    placeholder="Enter Designation" />
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputMobile" className="col-sm-4 col-form-label">Mobile
                                                Number<span
                                                    className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputMobile"
                                                    inputMode="numeric"
                                                    pattern="[6789][0-9]{9}"
                                                    name="mobile"
                                                    required
                                                    value={formData.mobile}
                                                    disabled={location.pathname.includes('profile')}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter Mobile Number" />
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputEmail" className="col-sm-4 col-form-label">Email ID<span
                                                className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="email" className="form-control"
                                                    id="inputEmail"
                                                    name="email"
                                                    required
                                                    value={formData.email}
                                                    disabled={location.pathname.includes('profile')}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter Email ID" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card p-3">
                            <p style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>Department User Roles</p>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead style={{ backgroundColor: '#1976d2' }}>
                                    <tr>
                                        <th style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Code</th>
                                        <th style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Name</th>
                                        <th style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Level</th>
                                        <th style={{ textAlign: 'center', color: 'white', padding: '8px' }}>User Role</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {departments.map((dept) => (
                                        <tr key={dept.id}>
                                            <td style={{ textAlign: 'center', padding: '8px' }}>{dept.code}</td>
                                            <td style={{ textAlign: 'center', padding: '8px' }}>{dept.name}</td>
                                            <td style={{ textAlign: 'center', padding: '8px' }}>{dept.department_level}</td>
                                            <td style={{ textAlign: 'center', padding: '8px' }}>{dept.user_type}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Form>
                </div>
                <Modal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    centered
                >
                    <Modal.Header closeButton style={{ backgroundColor: '#1976d2', color: 'white' }}>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ padding: '20px' }}>
                        <Form onSubmit={handlePasswordSubmit}>
                            <div className="form-group" style={{ marginBottom: '15px' }}>
                                <label htmlFor="old_password" style={{ fontWeight: 'bold' }}>Current Password</label>
                                <div className="input-group">
                                    <input
                                        type={passwordVisibility.old_password ? 'text' : 'password'}
                                        className="form-control"
                                        id="old_password"
                                        name="old_password"
                                        value={passwordData.old_password}
                                        onChange={handlePasswordChange}
                                        required
                                        style={{ borderRadius: '5px', padding: '10px' }}
                                    />
                                    <div className="input-group-append">
                                        <span
                                            className="input-group-text"
                                            onClick={() => togglePasswordVisibility('old_password')}
                                            style={{ cursor: 'pointer', background: 'transparent', border: 'none' }}
                                        >
                                            {passwordVisibility.old_password ? <FaEyeSlash  style={{ marginTop: '10px' }}/> : <FaEye style={{ marginTop: '10px' }}/>}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group" style={{ marginBottom: '15px' }}>
                                <label htmlFor="new_password" style={{ fontWeight: 'bold' }}>New Password</label>
                                <div className="input-group">
                                    <input
                                        type={passwordVisibility.new_password ? 'text' : 'password'}
                                        className="form-control"
                                        id="new_password"
                                        name="new_password"
                                        value={passwordData.new_password}
                                        onChange={handlePasswordChange}
                                        required
                                        style={{ borderRadius: '5px', padding: '10px' }}
                                    />
                                    <div className="input-group-append">
                                        <span
                                            className="input-group-text"
                                            onClick={() => togglePasswordVisibility('new_password')}
                                            style={{ cursor: 'pointer', background: 'transparent', border: 'none' }}
                                        >
                                            {passwordVisibility.new_password ? <FaEyeSlash  style={{ marginTop: '10px' }}/> : <FaEye style={{ marginTop: '10px' }}/>}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group" style={{ marginBottom: '15px' }}>
                                <label htmlFor="confirm_password" style={{ fontWeight: 'bold' }}>Confirm New Password</label>
                                <div className="input-group">
                                    <input
                                        type={passwordVisibility.confirm_password ? 'text' : 'password'}
                                        className="form-control"
                                        id="confirm_password"
                                        name="confirm_password"
                                        value={passwordData.confirm_password}
                                        onChange={handlePasswordChange}
                                        required
                                        style={{ borderRadius: '5px', padding: '10px' }}
                                    />
                                    <div className="input-group-append">
                                        <span
                                            className="input-group-text"
                                            onClick={() => togglePasswordVisibility('confirm_password')}
                                            style={{ cursor: 'pointer', background: 'transparent', border: 'none' }}
                                        >
                                            {passwordVisibility.confirm_password ? <FaEyeSlash  style={{ marginTop: '10px' }}/> : <FaEye style={{ marginTop: '10px' }}/>}
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group" style={{ marginBottom: '15px' }}>
                                <label htmlFor="otp" style={{ fontWeight: 'bold' }}>OTP</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="otp"
                                    name="otp"
                                    value={passwordData.otp}
                                    onChange={handlePasswordChange}
                                    required
                                    style={{ borderRadius: '5px', padding: '10px' }}
                                />
                            </div>

                            <Button
                                variant="primary"
                                type="submit"
                                style={{
                                    backgroundColor: '#1976d2',
                                    borderColor: '#1976d2',
                                    padding: '10px 20px',
                                    borderRadius: '5px',
                                }}
                            >
                                Change Password
                            </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}
export default Profile
