import React, { useEffect, useState } from 'react'
import { get_payment_invoices } from '../../helper/Api';
import DataTable from 'react-data-table-component';
import { AddCommasToAmount, CustomPagination } from '../../helper/Utils';
import { useDispatch, useSelector } from 'react-redux';

import { GET_P_INVOICES_START, GET_P_INVOICES_SUCCESS, GET_P_INVOICES_FAIL } from '../../RTK/Slices/PaymentInvoiceSlice';

const PaymentInvoiceList = ({ setSelectedPaymentInvoice, fromDate, toDate, dateColumn, searchStr, isChecked = false }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { payment_invoices, loading } = useSelector((state) => state.payment_invoiceData);
    const { authData } = useSelector((state) => state.authData);
    const dispatch = useDispatch();
    const [data, setData] = useState(payment_invoices);

    const fetchInvoices = async () => {
        dispatch(GET_P_INVOICES_START());
        try {
            const invoices_response = await get_payment_invoices({
                user_type: authData?.user?.user_type?.toUpperCase() || 'L1',
                from_date: fromDate || '',
                to_date: toDate || '',
                isAll: isChecked,
                dateColumn: dateColumn || '',
                search_str: searchStr || '',
                department_id: authData?.user?.department_id || ''
            });
            // console.log(invoices_response)
            if (invoices_response.data.status) {
                dispatch(GET_P_INVOICES_SUCCESS(invoices_response.data.list))
                setData(invoices_response.data.list);
                // console.log('data', data);
                let amt = 0;
                data.forEach(e => {
                    amt += Number(e.payable_amount);
                })
                // console.log('amt', amt);
            } else {
                dispatch(GET_P_INVOICES_FAIL(invoices_response.data.message))
            }
        } catch (error) {
            dispatch(GET_P_INVOICES_FAIL('something went wrong'))
        }
    }
    useEffect(() => {
        fetchInvoices();
    }, [fromDate, toDate, searchStr, dateColumn, isChecked])

    useEffect(() => {
        fetchInvoices();
    }, [dispatch])

    useEffect(() => {
        setData(payment_invoices)
    }, [payment_invoices]);

    const handleSelectedChange = ({ selectedRows }) => {
        setSelectedPaymentInvoice(selectedRows)
    }
    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };
    const rowDisabledCriteria = row => {
        if (authData.user.user_type === 'l2') {
            if (row.payment_status == 1) {
                return true;
            } else {
                return false;
            }
        } else if (authData.user.user_type === 'l3') {
            if (row.l3_payment_status == 1) {
                return true;
            } else {
                return false;
            }
        } else if (authData.user.user_type === 'l1') {
            return true;
        } else {
            return true;
        }
    };

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1); // Reset to the first page when rows per page changes
    };

    const selectedCheck = (batch_id, id, event) => {
        const chk = document.getElementsByClassName(batch_id);
        console.log('chk', chk);
        for (let i = 0; i < chk.length; i++) {
            chk[i].checked = event.target.checked;
        }
        if (event.target.checked === false) {
            setSelectedPaymentInvoice(prevState => {
                return prevState.filter(e => e.batch_id !== batch_id);
            });
        } else {
            const rows = data.filter(e => e.batch_id === batch_id);
            rows.map(e => {
                setSelectedPaymentInvoice(prevState => {
                    return [...prevState, e];
                });
            })
        }
    }
    let batchArray = [];

    const columns = [
        {
            name: '#',
            selector: (row) => (
                <input
                    type="checkbox"
                    className={row.batch_id}
                    onClick={(e) => selectedCheck(row.batch_id, row.id, e)}
                />
            ),
            sortable: true,
            wrap: true,
            width: '50px',
        },
        {
            name: 'Benificiary Name',
            selector: (row) => row.company_name,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Invoice Date',
            selector: (row) => row.invoice_date,
            sortable: true,
            width: "150px",
            wrap: true,
        },
        {
            name: 'Invoice No.',
            selector: (row) => row.invoice_no,
            width: "200px",
            wrap: true,
            sortable: true,
        },
        {
            name: 'Payment Type',
            selector: (row) => row.payment_type === '1' ? "FULL PAYMENT" : 'PART PAYMENT',
            sortable: true,
            wrap: true,
            width: "150px"
        },
        {
            name: 'Invoice Amount',
            selector: (row) => <b>{AddCommasToAmount(row.invoice_value)}</b>,
            sortable: true,
            width: "200px",
            wrap: true,
            center: true,
            sortFunction: (a, b) => {
                a = a.invoice_value;
                b = b.invoice_value;
                const valueA = typeof a === 'string' ? parseFloat(a.replace(/,/g, '')) : a;
                const valueB = typeof b === 'string' ? parseFloat(b.replace(/,/g, '')) : b;

                return valueA - valueB;
            },
        },
        {
            name: 'Sanction Amount',
            selector: (row) => <b>{AddCommasToAmount(row.sanction_amount)}</b>,
            sortable: true,
            width: "200px",
            wrap: true,
            center: true,
            sortFunction: (a, b) => {
                a = a.sanction_amount;
                b = b.sanction_amount;
                const valueA = typeof a === 'string' ? parseFloat(a.replace(/,/g, '')) : a;
                const valueB = typeof b === 'string' ? parseFloat(b.replace(/,/g, '')) : b;
                return valueA - valueB;
            },
            // right: true
        },
        {
            name: 'Payment Amount',
            selector: (row) => <b>{AddCommasToAmount(row.payable_amount)}</b>,
            sortable: true,
            width: "200px",
            wrap: true,
            center: true,
            sortFunction: (a, b) => {
                a = a.sanction_amount;
                b = b.sanction_amount;
                const valueA = typeof a === 'string' ? parseFloat(a.replace(/,/g, '')) : a;
                const valueB = typeof b === 'string' ? parseFloat(b.replace(/,/g, '')) : b;
                return valueA - valueB;
            },
            // right: true
        },
        {
            name: 'Voucher Date',
            selector: (row) => row.voucher_date,
            sortable: true,
            width: "150px",
            wrap: true,
        },
        {
            name: 'Voucher No.',
            selector: (row) => row.voucher_no,
            width: "150px",
            wrap: true,
            // sortable: true,
        },
        {
            name: 'Payment Sanction Order Date',
            selector: (row) => row.sanction_order_date,
            width: "200px",
            wrap: true,
            sortable: true,
        },
        {
            name: 'Payment Sanction Order No.',
            selector: (row) => row.sanction_order_no,
            width: "200px",
            wrap: true,
            sortable: true,
        },
        {
            name: 'Actions (L2)',
            selector: (row) => {
                let status = row.payment_status;
                let l3Status = row.l3_payment_status;
                return (
                    // <b>{status == 1 ? 'COMPLETED' : 'PENDING'}</b>
                    <b>{status==0 ? l3Status == 2 ? '-' : 'PENDING' : status==1?"APPROVED":'REJECTED'}</b>
                );
            },
            sortable: true,
            width: "200px"
        },
        {
            name: 'Actions (L3)',
            selector: (row) => {
                let status = row.l3_payment_status;
                let l2Status = row.payment_status;
                return (
                    // <b>{status == 1 ? 'COMPLETED' : 'PENDING'}</b>
                    <b>{status==0 ? l2Status == 2 ? '-' : 'PENDING' : status==1?"APPROVED":'REJECTED'}</b>
                );
            },
            sortable: true,
            width: "200px"
        },
    ];
    if (authData?.user?.user_type !== 'l3') {
        columns.shift();
    }
    // if(auth)

    // const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    if (authData?.user?.user_type == 'l3') {
        return (
            <div>
                <DataTable
                    className="dataTables_wrapper"
                    customStyles={customStyles}
                    selectableRowDisabled={rowDisabledCriteria}
                    // selectableRows
                    onSelectedRowsChange={handleSelectedChange}
                    progressPending={loading}
                    columns={columns}
                    data={data}
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    pagination
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        )
    } else {
        return (
            <div>
                <DataTable
                    className="dataTables_wrapper"
                    customStyles={customStyles}
                    selectableRowDisabled={rowDisabledCriteria}
                    selectableRows
                    onSelectedRowsChange={handleSelectedChange}
                    progressPending={loading}
                    columns={columns}
                    data={data}
                    fixedHeader
                    fixedHeaderScrollHeight="600px"
                    pagination
                    paginationPerPage={rowsPerPage}
                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </div>
        )
    }



}

export default PaymentInvoiceList
