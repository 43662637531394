import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useLocation } from "react-router-dom";
import { getActivity } from '../../../helper/Api';
import { BiSolidEdit } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import TableWithPagination from './../../ResponsiveDataTable/TableWithPagination';
import { Col } from 'react-bootstrap';

const ActivityList = (filterDate) => {
    const { authData } = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let { state } = useLocation();
    const [data, setData] = useState(state && state.data ? state.data : []);

    useEffect(()=>{
        console.log(data);
    },[])


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Id',
            selector: (row) => row.id,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Activity',
            selector: (row) => row.activity,
            sortable: true,
            // width: "150px",
            right: false
        },
        {
            name: 'Status',
            selector: (row) => row.status === "1" ? "Active" : "Inactive",
            sortable: true,
            // width: "150px",
            right: false
        },
    ];

    if(authData.user.department_id == '1' && authData.user.user_type == 'l3'){
        columns.push({
            name: 'Edit / Update',
            width: "200px",
            selector: (row) => {
                return <>
                    {
                        (authData.user.user_type === 'l1') ? <Link to={`/department/edit-activity/${row.id}`}><BiSolidEdit /></Link> : ''
                    }
                </>
            },
            center: true,
        });
    }

    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    // const handleFilterClick = async () => {
    //     // await fetchBeneficiary(schemeId)
    //     setData([]);
    //     await fetchActivity()
    // };
    // const clearFilter = async () => {
    //     setFromDate(''); // Clear fromDate
    //     setToDate('');
    //     await fetchActivity()
    //     // await fetchBeneficiary(schemeId)
    // };

    const fetchActivity = async () => {
        try {
            const resp = await getActivity();
            if (resp.data.status) {
                setData(resp.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }
    // console.log('fdate', filterDate.fromDate);
    useEffect(() => {
        fetchActivity();
    }, [filterDate.searchStr])



    return (
        <div className='row'>
            {/* <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}
                <div className='col-9'>

            <table className='no-border bg-white rounded-3 text-start w-100'>
                <tr className='text-black-60 fs-12px'>
                    <th className='text-start'>Id</th>
                    <th className='text-start'>Activity</th>
                    <th className='text-start'>Status</th>
                </tr>
                {
                    data.map((item)=>(
                        <>
                <tr className='fs-14px text-start'>
                    <td className='text-start'>{item?.id}</td>
                    <td className='text-start'>{item?.activity}</td>
                    <td className='text-start'>{item?.status == '1' ? "Active" : "Inactive"}</td>
                </tr>
                        </>
                    ))
                }
            </table>
            </div>

                {/* ==================================right side bar===================== */}
            
        <div className=" col-3 px-3">
          <Col sm={12}>
            <div className="dashboardStatCard">
              <h3
                className="bg-success-100 fs-20px text-white px-2 py-3 m-0 d-flex justify-content-between align-items-center"
                style={{ borderRadius: "10px 10px 0 0" }}
              >
                <span className="d-flex flex-column gap-1 fs-12px">
                  Total Bank Balance
                  <span style={{ fontSize: "20px" }}>
                  200,000,000,000
                  </span>
                </span>
                <i
                  className="icon-more_vert cursor-pointer"
                ></i>
              </h3>

              <div>
                <div className="hover-bg-prime-20 p-2 transition ">
                  <h6 className="text-black-60 fs-12px">
                    Current FY Interest Earn
                  </h6>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="fw-600">0</div>
                    <i
                      className="icon-content_copy cursor-pointer"
                      onClick={() =>
                        navigator.clipboard.writeText("0"
                        )
                      }
                    ></i>
                  </div>
                </div>

                <div className="hover-bg-prime-20 p-2 transition ">
                  <h6 className="text-black-60 fs-12px">Accured Interest Q1</h6>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="fw-600">0</div>
                    <i
                      className="icon-content_copy cursor-pointer"
                      onClick={() =>
                        navigator.clipboard.writeText("0"
                        )
                      }
                    ></i>
                  </div>
                </div>

                <div className="hover-bg-prime-20 p-2 transition ">
                  <h6 className="text-black-60 fs-12px">Accured Interest Q2</h6>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="fw-600">0</div>
                    <i
                      className="icon-content_copy cursor-pointer"
                      onClick={() =>
                        navigator.clipboard.writeText( "0"
                        )
                      }
                    ></i>
                  </div>
                </div>

                <div className="hover-bg-prime-20 p-2 transition ">
                  <h6 className="text-black-60 fs-12px">Accured Interest Q3</h6>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="fw-600">0</div>
                    <i
                      className="icon-content_copy cursor-pointer"
                      onClick={() =>
                        navigator.clipboard.writeText("0"
                        )
                      }
                    ></i>
                  </div>
                </div>

                <div className="hover-bg-prime-20 p-2 transition ">
                  <h6 className="text-black-60 fs-12px">Accured Interest Q4</h6>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="fw-600">0</div>
                    <i
                      className="icon-content_copy cursor-pointer"
                      onClick={() =>
                        navigator.clipboard.writeText("0"
                        )
                      }
                    ></i>
                  </div>
                </div>

                <div className="row fs-12px px-2 pb-1">
                  <div className="col-6 d-flex justify-content-between align-items-center">
                    <span>Action (L2)</span>
                    <span>
                     
                        <i className="icon-verified text-success-100"></i>
                 
                 
                    </span>
                  </div>
                  <div className="col-6 d-flex justify-content-between align-items-center">
                    <span>Action (L3)</span>
                    <span>
               
                        <i className="icon-warning text-warning-success-100"></i>
               
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </div>
            
        </div>
    )
}

export default ActivityList
