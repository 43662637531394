// import { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { updateSchemeBankAccountApproval } from '../../helper/Api';

import './sanctionOrderModal.css';

const AddInterestModal = ({ showPreview, setShowPreview, formData, setFormData, handleSubmit, handlePopUp }) => {
    const { authData } = useSelector((state) => state.authData);
    const handleClose = () => {
        handlePopUp(false);
        setShowPreview(false);
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
        console.log("formData==>>",formData);
    }
    const onApprove = async (status) => {
        formData.l2_approval = authData.user.user_type == 'l2' ? status : formData.l2_approval;
        formData.l3_approval = authData.user.user_type == 'l3' ? status : formData.l3_approval;
        let approvalData = {
            l2_approval: formData.l2_approval,
            l3_approval: formData.l3_approval,
            l2_remark: formData.l2_remark,
            l3_remark: formData.l3_remark
        }
        const { data } = await updateSchemeBankAccountApproval(approvalData, formData.id);
        if (data.status) {
            handlePopUp(false, true);
            setShowPreview(false);
        }
    }
    return (
        <div>
            <Modal
                size="lg"
                show={showPreview} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Account Details : </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Account Name <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Enter Account Name" name='account_name' value={formData.account_name} onChange={handleChange} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Bank Name <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Enter Bank Name" name='bank_name' value={formData.bank_name} onChange={handleChange} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Bank Branch <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Enter Bank Branch" name='branch_name' value={formData.branch_name} onChange={handleChange} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Account Type <span className='text-danger'>*</span></Form.Label>
                                        <select
                                            className="form-control "
                                            id="account_type"
                                            name='account_type'
                                            required
                                            value={formData.account_type}
                                            onChange={handleChange}>
                                            <option value="">Select Account Type</option>
                                            <option value="saving">Saving Account</option>
                                            <option value="current">Current Account</option>
                                        </select>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Account No <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="number" placeholder="Enter Account No" name='account_no' value={formData.account_no} onChange={handleChange} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Account Balance <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Enter Account Balance" name='account_balance' value={formData.account_balance} onChange={handleChange} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>IFSC Code - (IFMS)<span className='text-danger'>*</span></Form.Label>
                                        <input type="text" className="form-control" id="ifsc_code"
                                            required
                                            maxLength={11}
                                            name='ifsc_code'
                                            value={formData.ifsc_code}
                                            onChange={handleChange}
                                            placeholder="Enter IFSC Code" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>IFSC code - (Bank)<span className='text-danger'>*</span></Form.Label>
                                        <input type="text" className="form-control" id="ifsc_code_bank"
                                            required
                                            maxLength={11}
                                            name='ifsc_code_bank'
                                            value={formData.ifsc_code_bank}
                                            onChange={handleChange}
                                            placeholder="Enter IFSC Code" />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Closing Balance Date <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="date" placeholder="Enter Balance Date" name='balance_date'
                                            value={formData.balance_date} onChange={handleChange}
                                            required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Interest <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="number" placeholder="Enter Interest" name='interest'
                                            value={formData.interest} onChange={handleChange} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Interest Date <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="date" placeholder="Enter Interest Date" name='interest_date'
                                            value={formData.interest_date} onChange={handleChange} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Quarter <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="number" placeholder="Enter Quarter" name='quarter'
                                            value={formData.quarter} onChange={handleChange} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Email <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="text" placeholder="Enter Email" name='email'
                                            value={formData.email} onChange={handleChange} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Mobile <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control type="number" placeholder="Enter Mobile" name='mobile'
                                            value={formData.mobile} onChange={handleChange} required />
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                (formData.l2_approval != 1 || formData.l3_approval != 1) && authData.user.user_type == 'l1' ?
                                    <Button variant="primary" type="submit">
                                        {formData?.id ? 'Update Account' : 'Add Account'}
                                    </Button>
                                    : ''
                            }
                            {
                                authData.user.user_type == 'l2' && formData.l2_approval == null ?
                                    <>
                                        <label>L2 Remark</label>
                                        <textarea className='form-control' onChange={handleChange} name="l2_remark"></textarea>
                                        <button type="button" className="btn btn-danger w-30 mt-2" onClick={() => onApprove(0)}>Reject</button>
                                        <button type="button" className="btn btn-primary w-30 mt-2" style={{ marginLeft: '10px' }} onClick={() => onApprove(1)}>Approve</button>
                                    </>
                                    : ''
                            }
                            {
                                authData.user.user_type == 'l3' && formData.l3_approval == null ?
                                    <>
                                        <label>L2 Remark</label>
                                        <textarea className='form-control' onChange={handleChange} name="l3_remark"></textarea>
                                        <button type="button" className="btn btn-danger w-30 mt-2" onClick={() => onApprove(0)}>Reject</button>
                                        <button type="button" className="btn btn-primary w-30 mt-2" style={{ marginLeft: '10px' }} onClick={() => onApprove(1)}>Approve</button>
                                    </>
                                    : ''
                            }
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        CLOSE
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default AddInterestModal
