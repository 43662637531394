import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getAllocateBudgetList } from "../../../helper/Api";
import DataTable from "react-data-table-component";
import { AiOutlineEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { FaEdit } from "react-icons/fa";
import "./BudgetAllocationList.css";
import { Accordion } from "react-bootstrap";

const BudgetAllocationList = (listType) => {
  const { authData } = useSelector((state) => state.authData);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [overlay, setOverlay] = useState(true);
  const [overlayDropDown, setOverlayDropDown] = useState(false);
    const [activeUtilizedBudgetChild, setActiveUtilizedBudgetChild] =
      useState("1");
  
   const [activeKeys, setActiveKeys] = useState([
      "0",
      "3",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "17",
      "20",
    ]); // Initially open accordions
    const [paymentAccordian, setpaymentAccordion] = useState(false);
     const [tableChildAccordian, setTableChildAccordian] = useState(false);
       const [subheadAccording, setSubheadAccording] = useState(false);
       const toggleAccordion = (key) => {
        setActiveKeys(
          (prevKeys) =>
            prevKeys.includes(key)
              ? prevKeys.filter((k) => k !== key) // Remove key if it's already open
              : [...prevKeys, key] // Add key if it's closed
        );
      };

  const fetchBudgets = async (payload) => {
    try {
      console.log(payload);
      const { data } = await getAllocateBudgetList(payload);
      setData(data.result);
    } catch (error) {
      setData([]);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchBudgets({
      approval: listType.approval,
      tier: listType.tier,
      department_id: listType.departmentId || authData.user.department_id,
      from_date: listType.fromDate,
      to_date: listType.toDate,
      search_str: listType.searchStr,
    });
  }, [
    listType.tier,
    listType.departmentId,
    listType.fromDate,
    listType.toDate,
    listType.searchStr,
  ]);
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  useEffect(() => {
    console.log(data);
  }, [data]);
  const columns = [
    {
      name: "L2 Approval",
      selector: (row) =>
        row.l2_status == null
          ? "Pending"
          : row.l2_status == 1
          ? "Approved"
          : "Rejected",
      center: true,
    },
    {
      name: "L3 Approval",
      selector: (row) =>
        row.l3_status == null
          ? "Pending"
          : row.l3_status == 1
          ? "Approved"
          : "Rejected",
      center: true,
    },
    {
      name: "Budget Order No",
      selector: (row) => <b>{row.budget_order_no}</b>,
      width: "150px",
      center: true,
    },
    {
      name: "Budget Date",
      selector: (row) => {
        return <b>{row.budget_order_date}</b>;
      },
      sortable: true,
      width: "150px",
      center: true,
    },
    {
      name: "Provision Budget",
      selector: (row) => {
        return <b>{row.total_scheme_provisional_allocation}</b>;
      },
      sortable: true,
      width: "200px",
      center: true,
    },
    {
      name: "Release Budget",
      selector: (row) => {
        return <b>{row.total_scheme_released_allocation}</b>;
      },
      sortable: true,
      width: "200px",
      center: true,
    },
    {
      name: "Scheme Id",
      selector: (row) => row.scheme_id,
      wrap: true,
      sortable: true,
      width: "200px",
      center: true,
    },
    {
      name: "Scheme Name",
      selector: (row) => <b>{row.name}</b>,
      wrap: true,
      width: "300px",
    },
    {
      name: "Total department",
      selector: (row) => <b>{row.total_departments}</b>,
      wrap: true,
      width: "150px",
      center: true,
    },
    {
      name: "Action",
      width: "200px",
      selector: (row) => {
        return (
          <>
            <div className="user_action">
              <span className="pr-4">
                <Link to={`/view-allocate-budget/${row.id}`}>
                  <AiOutlineEye />
                </Link>
              </span>

              {(Number(row.l2_status) === 0 || Number(row.l3_status) === 0) &&
                authData.user.user_type === "l1" && (
                  <span className="pr-4">
                    <Link to={`/edit-allocate-budget/${row.id}`}>
                      <FaEdit />
                    </Link>
                  </span>
                )}
            </div>
          </>
        );
      },
    },
  ];

  // const alertFunction = (id) => {
  //     Swal.fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonColor: "#3085d6",
  //       cancelButtonColor: "#d33",
  //       confirmButtonText: "Yes, delete it!",
  //     }).then(async (result) => {
  //       if (result.isConfirmed) {
  //         await delete_budget(id);
  //       }
  //     });
  //   };

  const [hoveredId, setHoveredId] = useState(null); // Track the hovered ID

  const handleMouseEnter = (id) => {
    setHoveredId(id); // Set the ID of the hovered div
  };

  const handleMouseLeave = () => {
    setHoveredId(null); // Reset when hover ends
  };

  return (
    <div>
      {/* <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}

      <div className="row">
        <div
          className="px-3 col-12 d-flex flex-column gap-3"
          style={{ height: "100vh" }}
        >
          {/* {data.map((val, ind) => (
            <div
              className={`hover-card bg-white`}
              style={{ borderRadius: "8px" }}
              key={val?.id}
              onMouseEnter={() => handleMouseEnter(val?.id)}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className="bg-prime-20 d-flex justify-content-between align-items-center p-2 hover-card-color transition overflow-hidden position-relative"
                style={{ borderRadius: "8px 8px 0 0" }}
              >
              {/* =======================card header====================== */}
          {/* <span
                  className={`fs-14px white-space-nowrap overflow-x-hidden ${hoveredId === val.id && val?.name?.length >= 70 ? null : 'text-overflow-ellipsis'}`}
                  style={{ width: "95%", fontStyle:"italic"}}
                >
                  <span
                    className={`text-secondary-100 text-white-hover transition text-overflow-ellipsis ${
                      hoveredId === val.id && val?.name?.length >= 70 ? "scroll" : null
                    }`}
                    style={{ fontStyle: "italic" }}
                  >
                    Scheme id-
                    {val?.id} {val?.name}
                  </span>
                </span>

                <span className="position-absolute end-0 me-2">
                  <i className="icon-open_in_new cursor-pointer"></i>
                </span>
              </div> */}

          {/* ================== card body ========================= */}
          {/* 
              <div className="row p-2 text-end">
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60">
                    Provisional Budget
                  </span>
                  <span className="fs-14px fw-600 ">
                    {val?.total_scheme_provisional_allocation
                      ? val.total_scheme_provisional_allocation
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.total_scheme_provisional_allocation}
                  </span>
                </div>
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60">Release Budget</span>
                  <span className="fs-14px fw-600">
                    {val?.total_scheme_released_allocation
                      ? val?.total_scheme_released_allocation
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.total_scheme_released_allocation}
                  </span>
                </div>
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60">
                    Previous Year Balance
                  </span>
                  <span className="fs-14px fw-600">
                    {val?.prevYearBalance
                      ? val?.prevYearBalance
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.prevYearBalance}
                  </span>
                </div>
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60">
                    Utilized Balance
                  </span>
                  <span className="fs-14px fw-600">
                    {val?.utilised_budget
                      ? val?.utilised_budget
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.utilised_budget}
                  </span>
                </div>
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60"> Budget Balance</span>
                  <span className="fs-14px fw-600">
                    {val?.budget
                      ? val?.budget
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.budget}
                  </span>
                </div>
              </div> */}

          {/* ===================card footer ==================== */}

          {/* <div className="border mx-2"></div>
              <div className="p-2 d-flex justify-content-between align-items-center ">
                <div className="d-flex align-items-center gap-3 ">
                  <span
                    className={` ${
                      val?.l2_status == 0
                        ? "bg-warning-20"
                        : val?.l2_status == 1
                        ? "bg-success-20"
                        : "bg-danger-20"
                    } rounded-2   d-flex align-items-center px-2   gap-1`}
                  >
                    <span className="fs-12px">L2</span>
                    <span>
                      {val?.l2_status == 0 ? (
                        <i
                          className="icon-warning text-warning-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : val?.l2_status == 1 ? (
                        <i
                          className="icon-verified text-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : (
                        <i
                          className="icon-close_circle_stroke text-danger-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      )}
                    </span>
                  </span>

                  <span
                    className={` ${
                      val?.l3_status == 0
                        ? "bg-warning-20"
                        : val?.l3_status == 1
                        ? "bg-success-20"
                        : "bg-danger-20"
                    } rounded-2   d-flex align-items-center px-2   gap-1`}
                  >
                    <span className="fs-12px">L3</span>
                    <span>
                      {val?.l3_status == 0 ? (
                        <i
                          className="icon-warning text-warning-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : val?.l3_status == 1 ? (
                        <i
                          className="icon-verified text-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : (
                        <i
                          className="icon-close_circle_stroke text-danger-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      )}
                    </span>
                  </span>
                </div> */}

          {/* <div className="d-flex align-items-center gap-3">
                  <i
                    className="icon-remove_red_eye text-info-100 cursor-pointer"
                    onClick={() => navigate(`/view-allocate-budget/${val?.id}`)}
                  ></i> */}

          {/* {show_l1_action_btn(
                    authData.user.user_type,
                    val?.l2_status,
                    val?.l3_status
                  ) ? (
                    <span className="">
                      <Link
                        to={`/edit-budget/${val?.id}`}
                        className="text-decoration-none"
                      >
                        <i className="icon-edit text-success-100 cursor-pointer"></i>
                      </Link>
                    </span>
                  ) : (
                    "" */}

          {/* {((Number(val?.l2_status) === 0 || Number(val?.l3_status) === 0) && authData.user.user_type === 'l1') && (
                                <span className='pr-4'>
                                    <Link to={`/edit-allocate-budget/${val?.id}`} className="text-decoration-none">
                                    <i className="icon-edit text-success-100 cursor-pointer"></i>
                                    </Link>
                                </span>
                            )} */}

          {/* </div>
              </div>
            </div>
          ))}} */}

          <div className="rounded-3 bg-white">
            <div
              className="header bg-prime-20 fs-14px fst-italic p-2"
              style={{ borderRadius: "8px 8px 0 0" }}
            >
              Scheme Code | 2401000012300-जलागम प्रबन्ध निदेशालय/राज्य स्तरीय
              स्प्रिंग एण्ड रिवर रिजुविनेशन प्राधिकरण (सारा) ....
            </div>

            <div className="p-2 m-0">
              {/* ==================================thdata=============================== */}
              <div className="row m-0">
                <div className="col m-0 fs-12px text-black-60 p-0">Sr. No.</div>
                <div className="col m-0 fs-12px text-black-60 p-0">
                  Order Date
                </div>
                <div className="col m-0 fs-12px text-black-60 p-0">
                  Order No
                </div>
                <div className="col m-0 fs-12px text-black-60 p-0">
                  Total Department
                </div>
                <div className="col m-0 fs-12px text-black-60 p-0">
                  Provisioned
                </div>
                <div className="col m-0 fs-12px text-black-60 p-0">
                  Released
                </div>
                <div className="col m-0 fs-12px text-black-60 p-0">Action</div>
              </div>
              {/* =============================== tbdata ======================== */}
              <div className="row m-0 mt-1">
                <div className="col m-0 fs-14px p-0">1</div>
                <div className="col m-0 fs-14px p-0">2,00,000.00</div>
                <div className="col m-0 fs-14px p-0">2,00,000.00</div>
                <div className="col m-0 fs-14px p-0">2,00,000.00</div>
                <div className="col m-0 fs-14px p-0">2,00,000.00</div>
                <div className="col m-0 fs-14px p-0">2,00,000.00</div>
                <div className="col m-0 fs-14px p-0 d-flex gap-2 align-items-center">
                  <button className="px-2 border-0 rounded-2 bg-success-20 text-black-60">
                    L2 <i className="icon-verified text-success-100 "></i>{" "}
                  </button>
                  <button className="px-2 border-0 rounded-2 text-black-60 bg-warning-20">
                    L3 <i className="icon-warning_amber text-warning-100 "></i>{" "}
                  </button>
                  <i
                    className="icon-remove_red_eye text-info-100 cursor-pointer"
                    onClick={() => setOverlay(false)}
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* ================== right side bar ================== */}

        {/* {listType.listType === "list" ? (
          <div className="px-3 col-3">
            <div className="bg-white p-2 d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-1">
                <span className="fs-12px text-black-60">
                  Total Provisioned Budget
                </span>
                <span className="fs-16px fw-600">
                  {" "}
                  {currentFY?.current?.totalProvisionalBudget || '00'}
                </span>
              </div>

              <div className="d-flex flex-column gap-1">
                <span className="fs-12px text-black-60">
                  Total Released Budget
                </span>
                <span className="fs-16px fw-600">
                  {currentFY?.current?.totalBudget || '00'}
                </span>
              </div>

            </div>
          </div>
        ) : null} */}
      </div>

      {/* ==============================overlay====================== */}

      <div className={`overlay ${overlay ? "d-none" : null}  `}></div>

      <div
        className={`overlayContent overflow-auto overflow-x-hidden ${
          overlay ? "left-100" : null
        } `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-secondary-100 position-relative text-white d-flex justify-content-between align-items-center ps-4">
          <div className="fw-600 fs-16px d-flex align-items-center gap-2 ">
            <i
              className="icon-close_circle_fill fs-5  cursor-pointer"
              onClick={() => setOverlay(true)}
            ></i>
            Child Allocation
          </div>

          <div className="d-flex gap-2 align-items-center h-100 py-3 position-relative pe-4">
            <i
              className="icon-arrow_drop_down_circle fs-18px cursor-pointer"
              onClick={() => setOverlayDropDown((prev) => !prev)}
            ></i>

            <div
              className={`position-absolute bg-prime-100 p-2 px-4 ${
                !overlayDropDown ? "d-none" : "d-inline-block"
              }`}
              style={{ top: "100%", right:"0%", zIndex: "10", width:"200px" }}
            >
              <div className="d-flex flex-column gap-4">
                <span className="d-flex align-items-center gap-2 cursor-pointer">
                  <i className="icon-picture_as_pdf"></i> Export PDF
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 d-flex flex-column gap-2">
          {/* ======================card view=========================== */}

          <div className="bg-white border-prime-20">
            {/* ================================card body========================= */}

            <div className="row p-2 m-0">

              <div className="col-4 px-1 mt-2">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Budget Order No.
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>
              <div className="col-4 px-1 mt-2">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Budget Order Date.
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>
              <div className="col-4 px-1 mt-2 d-flex align-items-center">
                <span className="d-flex align-items-center gap-2 p-0 px-1">
                  <i className="icon-picture_as_pdf fs-24px"></i>
                  <i className="icon-link fs-24px text-link"></i>
                  <i className="icon-link fs-24px text-link"></i>
                  <i className="icon-link fs-24px text-link"></i>
                </span>
              </div>
            </div>

            <div className="bg-prime-20 px-2 py-1 fw-600 fs-16px">
              Scheme Detail
            </div>

            <div className="row m-0 p-2">
              <div className="col-3 px-1 ">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Scheme Code* :
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                  />
                </span>
              </div>
              <div className="col-3 px-1">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  Department Name
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                  />
                </span>
              </div>
              <div className="col-3 px-1">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  Type of Scheme
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                  />
                </span>
              </div>
              <div className="col-3 px-1">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  FY
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                  />
                </span>
              </div>
            </div>
            <div className="row m-0 p-2">
              <div className="px-1 col-6">
                <span
                  className="d-flex flex-column p-0 px-1"
                >
                  <label htmlFor="text" className="fs-12px text-prime-100">
                  Scheme Name
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                  />
                </span>
              </div>
              <div className="px-1 col-3">
                  <div className="m-0 p-0">
                    <p className="p-0 m-0 fs-12px text-black-60">Provisional</p>
                    <span className="text-success-100 fs-14px fw-500">2,000,000,000.00</span>
                  </div>
              </div>
              <div className="px-1 col-3">
                  <div className="m-0 p-0">
                    <p className="p-0 m-0 fs-12px text-black-60">Released</p>
                    <span className="text-success-100 fs-14px fw-500">2,000,000,000.00</span>
                  </div>
              </div>
            </div>

            <div className="bg-prime-20 px-2 py-1 fw-600 fs-16px">
              Subhead Details
            </div>

            <div className="row px-2 py-1 m-0">
              <div className="col-3 fs-12px text-black-60">Subhead code</div>
              <div className="col-3 fs-12px text-black-60">subhead Name</div>
              <div className="col-3 fs-12px text-black-60">Provisional</div>
              <div className="col-3 fs-12px text-black-60">Released</div>
            </div>
            <div className="row px-2 py-1 m-0">
              <div className="col-3 fs-14px fw-500">12</div>
              <div className="col-3 fs-14px fw-500">2,000,000,000.00</div>
              <div className="col-3 fs-14px fw-500">2,000,000,000.00</div>
              <div className="col-3 fs-14px text-success-100 fw-500">2,000,000,000.00</div>
            </div>
            <div className="row mx-2 py-1 m-0 bg-info-20">
              <div className="col-3 fs-14px fw-500">Total</div>
              <div className="col-3 fs-14px fw-500">2,000,000,000.00</div>
              <div className="col-3 fs-14px fw-500">2,000,000,000.00</div>
              <div className="col-3 fs-14px text-success-100 fw-500">2,000,000,000.00</div>
            </div>

            <div className="bg-prime-20 py-1 m-0 mt-2 row">
                <span className="fw-600 fs-16px col-8">
                  Allocation Details
                </span>
                <span className="col-4 d-flex gap-2 align-items-center m-0 ps-5">
                  <span className="fs-12px m-0">View All</span>
                  <span className="fs-12px m-0 text-decoration-underline text-link">Subhead</span>
                  <span className="fs-12px m-0 text-decoration-underline text-link">Activity</span>
                  <span className="fs-12px m-0 text-decoration-underline text-link">Subactivity</span>
                </span>
            </div>

            <div className="px-3 pb-2 d-flex flex-column gap-1 mt-2">
                    <Accordion activeKey={activeKeys} style={{ border: "0", }}>
                      <Accordion.Item
                        eventKey="10"
                        style={{ borderRadius: "0", border: "0" }}
                      >
                        <Accordion.Header
                          onClick={() => toggleAccordion("10")}
                          // style={{
                          //   display: "flex",
                          //   justifyContent: "space-between",
                          //   alignItems: "center",
                          //   position: "relative",
                          //   borderRadius: "0",
                          //   padding:"0"
                          // }}
                        >
                          <div className="w-100 d-flex justify-content-between">
                            <span>T2  |  Tehri  |  Tehri graam lok</span>
                            <span
                              className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                              style={{ height: "13.33px", width: "13.33px" }}
                            >
                              {activeKeys.includes("10") ? "-" : "+"}
                            </span>
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <div>
                            {/* ==============================subhead data================================ */}
                            <div className="p-2 d-flex justify-content-between text-secondary-50 px-4">
                    <button
                      onClick={() => setActiveUtilizedBudgetChild("1")}
                      className={` ${
                        activeUtilizedBudgetChild == 1
                          ? "bg-secondary-20"
                          : "bg-white"
                      }  border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1 rounded-4`}
                    >
                      {activeUtilizedBudgetChild == 1 ? (
                        <i className="icon-remove_circle_outline"></i>
                      ) : (
                        <i className="icon-add_circle_outline"></i>
                      )}
                      Sub head
                    </button>
                    <button
                      onClick={() => setActiveUtilizedBudgetChild("2")}
                      className={`${
                        activeUtilizedBudgetChild == 2
                          ? "bg-secondary-20"
                          : "bg-white"
                      } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1  rounded-4`}
                    >
                      {activeUtilizedBudgetChild == 2 ? (
                        <i className="icon-remove_circle_outline"></i>
                      ) : (
                        <i className="icon-add_circle_outline"></i>
                      )}
                      Activity
                    </button>
                    <button
                      onClick={() => setActiveUtilizedBudgetChild("3")}
                      className={`${
                        activeUtilizedBudgetChild == 3
                          ? "bg-secondary-20"
                          : "bg-white"
                      } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1  rounded-4`}
                    >
                      {activeUtilizedBudgetChild == 3 ? (
                        <i className="icon-remove_circle_outline"></i>
                      ) : (
                        <i className="icon-add_circle_outline"></i>
                      )}
                      Sub-Activity
                    </button>
                  </div>

                  <hr className="m-0 my-1" />
                      {
                        activeUtilizedBudgetChild == 1 &&
                  <div className="row">
                      <div className="row">
                        <div className="col-3 fs-12px text-black-60">Subhead code</div>
                        <div className="col-3 fs-12px text-black-60">Subhead Name</div>
                        <div className="col-3 fs-12px text-black-60">Provisional</div>
                        <div className="col-3 fs-12px text-black-60">Released</div>
                      </div>
                      <div className="row">
                        <div className="col-3 fs-14px fw-500">123</div>
                        <div className="col-3 fs-14px fw-500">2,000,000,000.00</div>
                        <div className="col-3 fs-14px fw-500">2,000,000,000.00</div>
                        <div className="col-3 fs-14px fw-500 text-success-100">2,000,000,000.00</div>
                      </div>
                  </div>
                      }
                      {
                        activeUtilizedBudgetChild == 2 &&
                        <div className="row">
                      <div className="row">
                        <div className="col-3 fs-12px text-black-60">Subhead code</div>
                        <div className="col-3 fs-12px text-black-60">Subhead Name</div>
                        <div className="col-3 fs-12px text-black-60">Provisional</div>
                        <div className="col-3 fs-12px text-black-60">Released</div>
                      </div>
                      <div className="row">
                        <div className="col-3 fs-14px fw-500">123</div>
                        <div className="col-3 fs-14px fw-500">2,000,000,000.00</div>
                        <div className="col-3 fs-14px fw-500">2,000,000,000.00</div>
                        <div className="col-3 fs-14px fw-500 text-success-100">2,000,000,000.00</div>
                      </div>
                  </div>
                      }
                      {
                        activeUtilizedBudgetChild == 3 &&
                        <div className="row">
                      <div className="row">
                        <div className="col-3 fs-12px text-black-60">Subhead code</div>
                        <div className="col-3 fs-12px text-black-60">Subhead Name</div>
                        <div className="col-3 fs-12px text-black-60">Provisional</div>
                        <div className="col-3 fs-12px text-black-60">Released</div>
                      </div>
                      <div className="row">
                        <div className="col-3 fs-14px fw-500">123</div>
                        <div className="col-3 fs-14px fw-500">2,000,000,000.00</div>
                        <div className="col-3 fs-14px fw-500">2,000,000,000.00</div>
                        <div className="col-3 fs-14px fw-500 text-success-100">2,000,000,000.00</div>
                      </div>
                  </div>
                      }
                  
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                  </div>

          </div>
        </div>
      </div>
    </div>
  );
};
export default BudgetAllocationList;
