import React, { useState, useEffect } from 'react'
import { Form } from 'react-bootstrap';
import {
    getSubActivity, AddSubActivity,
    getActivity,
    editSubActivity
} from '../../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { ADD_CHALLAN_FAIL, ADD_CHALLAN_START } from "../../../RTK/Slices/ChallanSlice";
import { toast } from 'react-toastify';
const AddSubactivity = () => {
    let jsonData = {};
    const { id, data } = useParams();
    console.log("jsonData", id);
    // if (id && data) {
    //     jsonData = JSON.parse(data);
    // }
    const [activity, setActivity] = useState([]);
    const { authData } = useSelector((state) => state.authData);
    const [formData, setFormData] = useState({
        status: "1",
        activity_id: null,
        subactivity: null,
        created_by: authData && authData.status ? authData.user.id : null
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fetchActivity = async () => {
        const result = await getActivity();
        if (result.data.status) {
            setActivity(result.data.result)
        }
    }
    const handleFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        console.log(name, value);
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const previewHandler = (e) => {
        e.preventDefault();
    };
    const handleToggle = () => {
        setFormData({
            ...formData,
            status: formData.status === "0" ? "1" : "0",
        });
    }
    useEffect(() => {
        if (id) {
            fetchData(id);
        }
    }, [id])
    useEffect(() => {
        fetchActivity();
    }, [])
    const validateForm = () => {
        if (!formData.activity_id || !formData.subactivity) {
            return false;
        }
        return true;
    };
    const handleSubmit = async (e) => {
        if (!validateForm()) {
            alert('Please fill in all fields correctly.');
            return;
        }
        try {
            if (id) {
                const response = await editSubActivity(formData, id);
                if (response.data.status) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/department/subactivity');
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
            else {
                const response = await AddSubActivity(formData);
                if (response.data.status) {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate('/department/subactivity');
                } else {
                    toast.error(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            }
        } catch (error) {
            toast.error(error.message, {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    const fetchData = async (id) => {
        const response = await getSubActivity({ id: id });
        if (response.data.status) {
            if (response.data.result.length) {
                setFormData(response.data.result[0])
                setFormData({ activity_id: response.data.result[0].activity_id, subactivity: response.data.result[0].subactivity, status: response.data.result[0].status })
            }
        }
    }
    return (
        <div>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={previewHandler}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4>{id ? 'Edit Subactivity' : 'Add Subactivity'}</h4>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-8 offset-md-2">
                                        <div className="row">
                                            <div className="col-sm-12 p-2">
                                                <div className="form-group row">
                                                    <label for="activity_id" className="col-sm-4 col-form-label">Select Activity <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control "
                                                            name='activity_id'
                                                            required
                                                            // disabled={type === 'view'}
                                                            value={formData.activity_id}
                                                            onChange={handleFormData}
                                                        >
                                                            <option>Select Activity</option>
                                                            {
                                                                activity.map((st) => {
                                                                    return (
                                                                        <option value={st.id}
                                                                            key={st.id}>{st.activity}</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">Subactivity Name
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input className="form-control"
                                                            name='subactivity'
                                                            value={formData.subactivity}
                                                            required
                                                            onChange={handleFormData}
                                                            placeholder="Enter Subactivity" />
                                                    </div>
                                                </div>
                                            </div>
                                            {id && (
                                                <div className="col-sm-12 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputType" className="col-sm-4 col-form-label">
                                                            Status
                                                            <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id="active"
                                                                    name="status"
                                                                    value={formData.status} onChange={handleToggle}
                                                                    checked={formData.status === "1"}
                                                                />
                                                                <label className="form-check-label" htmlFor="active">
                                                                    Active
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    id="inactive"
                                                                    name="status"
                                                                    value={formData.status}
                                                                    onChange={handleToggle}
                                                                    checked={formData.status === "0"}
                                                                />
                                                                <label className="form-check-label" htmlFor="inactive">
                                                                    Inactive
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="col-sm-12 p-2 text-center">
                                                <Button variant="primary" onClick={handleSubmit}> SUBMIT </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )

};
export default AddSubactivity;