import React, {useState} from 'react'
// import { useSelector } from 'react-redux';
import Tools from "../../../components/Tools/Tools";
import ManageDisbursmentHeader from "../../../components/Disbursment/ManageDisbursmentHeader";
import AccumulatedDeductionList from "../../../components/Reconciliation/Accumulated_deduction/AccumulatedDeduction";


const AccumulatedDeduction = () => {
    // const {authData}=useSelector((state)=>state.authData);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [dateColumn, setDateColumn] = useState('voucher_date');
    const [searchStr, setToSearchStr] = useState('');
    const [departmentId, setDepartmentId] = useState('');


    const handleDateChange = (start, end, dateColumn, searchStr, fyId, tier, departmentId) => {
        setFromDate(start);
        setToDate(end);
        setDateColumn(dateColumn);
        setToSearchStr(searchStr);
        setDepartmentId(departmentId);
    };

    return (
        <div>
            {/* <Tools/> */}
            <div className='addNewScheme' >
                <div className="add_new_user">
                    <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} dateColumnType={dateColumn} type={'Accumulated Deduction'} />
                </div>
            </div>
            <div className='scheme p-3'>
                <AccumulatedDeductionList fromDate={fromDate} toDate={toDate} dateColumn={dateColumn} searchStr={searchStr} departmentId={departmentId}/>
            </div>
        </div>
    )
}

export default AccumulatedDeduction
