import React, {useState} from 'react'
import {useSelector} from 'react-redux';
import InterestList from "../../components/Reports/InterestList";
import Tools from "../../components/Tools/Tools";
import {Col, Container, Form, Row} from "react-bootstrap";
import ManageDisbursmentHeader from "../../components/Disbursment/ManageDisbursmentHeader";


const InterestReport = () => {
    const {authData} = useSelector((state) => state.authData);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    const handleDateChange = (start, end) => {
        setFromDate(start);
        setToDate(end);

    };

    return (
        <div>
            {/* <Tools/> */}
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} type="Interest" />
            <div className='scheme p-3'>
                <InterestList fromDate={fromDate} toDate={toDate}/>
            </div>
        </div>
    )
}

export default InterestReport
