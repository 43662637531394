import React from 'react'
import Tools from '../../components/Tools/Tools'
import EditBeneficiaryForm from '../../components/Beneficiary/EditBeneficiary/EditBeneficiaryForm'

const EditBeneficiary = () => {
  return (
    <div>
      {/* <Tools/> */}
      <EditBeneficiaryForm/>
      
    </div>
  )
}
export default EditBeneficiary
