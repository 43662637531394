import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap';
import {
    get_schemes,
    getHeadOfAccounts,
    addChallan, getChallanDetails, getSchemeAccountBalances
} from '../../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ADD_CHALLAN_FAIL } from "../../../RTK/Slices/ChallanSlice";
import AddChallanPreview from "../../Modal/AddChallanPreview";
import moment from 'moment';

const AddChallanForm = () => {
    const [schemes, setSchemes] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [snaName, setSNAName] = useState({});
    const [headAccount, setHeadAcc] = useState({});
    const [showPreview, setShowPreview] = useState(false);
    const { authData } = useSelector((state) => state.authData);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id, type } = useParams();

    const [formData, setFormData] = useState({
        id: '',
        head_of_account_id: '',
        from_date: "",
        to_date: "",
        account_no: "",
        account_name: "",
        added_by: authData && authData.status ? authData.user.id : null
    });

    const fetch_accounts = async () => {
        const accounts = await getSchemeAccountBalances();
        if (accounts.data.status) {
            setAccountList(accounts.data.result);
        }
    }
    const get_challan_details = async () => {
        try {
            const { data } = await getChallanDetails(id);

            if (data?.status) {
                setFormData(data?.result);
            }

        } catch (error) {

        }
    }

    const fetchLists = async () => {
        try {
            const schemes = await get_schemes();
            const accounts = await getHeadOfAccounts();
            if (schemes.data.status) {
                setSchemes(schemes.data.schemes)
            }
            if (accounts.data.status) {
                if (id) {
                    const headAcc = accounts.data.result.find(el => el.id == formData?.head_of_account_id)
                    setHeadAcc(headAcc)
                }
            }
        } catch (e) {

        }
    }

    useEffect(() => {
        if (id) {
            get_challan_details();
        }
        fetch_accounts();
        fetchLists();
    }, [id])


    const handleChallanFormData = (e) => {
        const { name, value } = e.target;
        let newFormData = {
            ...formData,
            [name]: value
        };
        console.log('name', name);
        if(name === 'head_of_account_id') {
            console.log('value', value);
            const { account_no, account_name } = accountList.find(f => f.id == value);
            console.log(account_name);
            newFormData = {
                ...formData,
                account_no: account_no,
                account_name: account_name,
                [name]: value
            }
            console.log(newFormData);
        }
        if (name === 'from_date' || name === 'to_date') {
            if (newFormData.from_date && newFormData.to_date) {
                const fromDate = new Date(newFormData.from_date);
                const toDate = new Date(newFormData.to_date);
                const timeDiff = Math.abs(toDate - fromDate);
                const dayDiff = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
                if (dayDiff > 89) {
                    alert('The difference between From Date and To Date should not exceed 89 days.');
                    newFormData.from_date = '';
                    newFormData.to_date = '';
                }
            }
        }
        setFormData(newFormData);
    };
    const handleSubmit = async (e) => {
        if (!id) {
            if (e && typeof e.preventDefault === 'function') {
                e.preventDefault();
            }
        }
        try {
            const fromDate = moment(formData.from_date, 'YYYY-MM-DD').format('DD-MMM-YYYY').toUpperCase();
            const toDate = moment(formData.to_date, 'YYYY-MM-DD').format('DD-MMM-YYYY').toUpperCase();
            const response = await addChallan({
                from_date: fromDate,
                to_date: toDate,
                account_no: formData.account_no
            });
            if(response.status === 200) {
                if(response.data.status == true) {
                    setShowPreview(false);
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    navigate(`/challan`);
                }else{
                    toast.error(response.data.result.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    dispatch(ADD_CHALLAN_FAIL(response.data.message));
                }
            }
        } catch (error) {
            dispatch(ADD_CHALLAN_FAIL('error getting while updating challan'));

        }
    }

    const previewHandler = (e) => {
        if (e && typeof e.preventDefault === 'function') {
            e.preventDefault();
        }
        if (formData?.scheme_id) {
            const snaName = schemes.find(el => el.id == formData?.scheme_id)
            setSNAName(snaName);
        }
        setShowPreview(true);

    };

    return (
        <div>
            <AddChallanPreview
                showPreview={showPreview}
                setShowPreview={setShowPreview}
                formData={formData}
                snaName={snaName}
                headAccount={headAccount}
                handleSubmit={handleSubmit} />
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={previewHandler}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4> {type !== 'view' ? 'Add New Challan' : 'View Challan'}</h4>
                                <div>
                                    {
                                        authData.user.user_type === 'l2' ?
                                            <>
                                                {!formData.l2_status ? (<>
                                                    <button type="button" className="btn btn-danger"
                                                        onClick={() => previewHandler(0)}>Reject
                                                    </button>
                                                    &nbsp;
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => previewHandler(1)}>Approve
                                                    </button>
                                                </>) : formData.l2_status == 1 ? (<>
                                                    <button type="button" className="btn btn-primary">Already
                                                        Approved
                                                    </button>
                                                </>) : (<>
                                                    <button type="button" className="btn btn-danger">Already
                                                        Rejected
                                                    </button>
                                                </>)}
                                            </> : authData.user.user_type === 'l3' ? (
                                                <>
                                                    {!formData.l3_status ? (<>
                                                        <button type="button" className="btn btn-danger"
                                                            onClick={() => previewHandler(0)}>Reject
                                                        </button>
                                                        &nbsp;
                                                        <button type="button" className="btn btn-primary"
                                                            onClick={() => previewHandler(1)}>Approve
                                                        </button>
                                                    </>) : formData.l3_status == 1 ? (<>
                                                        <button type="button" className="btn btn-primary">Already
                                                            Approved
                                                        </button>
                                                    </>) : (<>
                                                        <button type="button" className="btn btn-danger">Already
                                                            Rejected
                                                        </button>
                                                    </>)}
                                                </>
                                            ) : type != 'view' ? (
                                                <div>
                                                    <button type="submit" className="btn btn-primary">Search Challans
                                                    </button>
                                                </div>
                                            ) : ''
                                    }
                                </div>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        
                                        <div className="row">
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="inputFinancials"
                                                        className="col-sm-4 col-form-label">
                                                        From Date
                                                        <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8 ">
                                                        <Form.Control
                                                            type="date"
                                                            name="from_date"
                                                            disabled={type === 'view'}
                                                            value={formData.from_date}
                                                            onChange={handleChallanFormData}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="inputFinancials"
                                                        className="col-sm-4 col-form-label">
                                                        To Date
                                                        <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8 ">
                                                        <Form.Control
                                                            type="date"
                                                            name="to_date"
                                                            disabled={type === 'view'}
                                                            value={formData.to_date}
                                                            onChange={handleChallanFormData}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="scheme_id"
                                                        className="col-sm-4 col-form-label">Select
                                                        Bank Account
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control "
                                                            id="head_of_account_id" name='head_of_account_id'
                                                            required
                                                            disabled={type === 'view'}
                                                            value={formData.head_of_account_id}
                                                            onChange={handleChallanFormData}>
                                                            <option>Select Bank</option>
                                                            {
                                                                accountList.map((e) => {
                                                                    return (
                                                                        <option value={e.id}>{e.account_name} [A/c No: {e.account_no}]</option>
                                                                    );
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default AddChallanForm
