import React, { useEffect, useState } from 'react'
import { get_financial_year, get_schemes, get_schemes_by_fy, get_schemes_by_fy_added_pending, getActivity, getSubActivity } from '../../helper/Api';
import { Card, Col, Container, Form, Row, Table, Modal } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import { get_schemes_entered_amount, get_schemes_entered_amount_in_edit_invoice } from '../../helper/Utils';
import { DeleteForever } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
const EditScheme = ({ formData, setFormData, schemeIndex, payable }) => {
  const { authData } = useSelector((state) => state.authData);
  const [scheme, setScheme] = useState({});
  const [clearData, setClearData] = useState(false);
  const [schemeList, setSchemeList] = useState([])
  const [subhead_list, setSubhead_list] = useState([]);
  const [fy_list, set_fy_list] = useState([]);
  const [financial_year, setFinancialYear] = useState(0);
  const [schemeEnteredAmount, setSchemeEnteredAmount] = useState({
    'subheads': {},
    'totalAmount': 0
  })
  const [activity, setActivity] = useState([]);
  const [subActivity, setSubActivity] = useState([]);
  const [selectSubhead, setSelectSubhead] = useState({
    maxSubheadAmount: 0,
    subheadBalance: 0,
    id: null,
    activities: [],
  })
  const [changeAmount, setChangeAmount] = useState(0)
  const fetch_list = async () => {
    const fy = await get_financial_year();
    const schemeResponse = await get_schemes_by_fy_added_pending();
    if (schemeResponse.data.status) {
      setSchemeList(schemeResponse.data.schemes);
    }
    if (fy.data.status) {
      set_fy_list(fy.data.list)
    }
  }

  const get_schemes_details = async (scheme_id, financial_year) => {
    const { data } = await get_schemes_by_fy_added_pending(scheme_id, financial_year);
    if (data.status) {
      if (data.schemes.length > 0) {
        setScheme(data.schemes[0]);
        console.log("setScheme",data.schemes[0]);
        setSubhead_list(data.schemes[0].sub_heads_list)
      }
      // setSchemeList(schemeResponse.data.schemes);
    }
  }

  useEffect(() => {
    // addActivityToSubhead();
    console.log("testing Data==>>", formData);
    if (scheme.id > 0) {
      get_schemes_details(scheme.id, financial_year)
      update_scheme_balance_amount(formData)
    }
  }, [financial_year]);

  useEffect(() => {
    update_scheme_balance_amount();
  }, [changeAmount])


  const update_scheme_balance_amount = (e) => {
    if (scheme.id > 0) {
      let scheme_amount = get_schemes_entered_amount_in_edit_invoice(formData);
      // console.log(scheme_amount)
      if (scheme_amount[scheme.id]) {
        let current_scheme = scheme_amount[scheme.id];
        setSchemeEnteredAmount(current_scheme)
        // console.log(current_scheme)
        // console.log(current_scheme.subheads)
      } else {
        setSchemeEnteredAmount({
          'subheads': {},
          'totalAmount': 0
        })
      }
    }
  }

  const handleScheme = (e) => {
    const id = e.target.value;
    if (id > 0) {
      let scheme = schemeList.filter((v) => v.id == id);
      setScheme(scheme[0]);
      setSubhead_list(scheme[0].sub_heads_list)
    } else {
      setScheme({ name: null });
      setSubhead_list([])
    }
  }
  useEffect(() => {
    fetchActivity();
    fetchSubActivity();
    fetch_list();
  }, [])


  const handleSchemeChange = (e, schemeIndex) => {
    const { name, value } = e.target;
    const schemeIdValue = formData.schemes[schemeIndex]?.scheme_id;
    const financialYearValue = formData.schemes[schemeIndex]?.financial_year;
    if ((schemeIdValue && financialYearValue) && name !== 'scheme_deduction') {
      setClearData(true);
    } else {
      setClearData(false);
    }
    if (name == 'scheme_id') {
      handleScheme(e);
      setFinancialYear(0);
    }
    if (name == 'financial_year') {
      const financialValid = formData.schemes.find(item => item.financial_year === value && item.scheme_id === schemeIdValue);
      if (financialValid) {
        alert('financial-year already selected');
        setFormData((prevState) => {
          const newSchemes = [...prevState.schemes];
          newSchemes[schemeIndex][name] = '';
          return { ...prevState, schemes: newSchemes };;
        });
        return;
      }
      else {
        setFinancialYear(value);
      }
    }
    if (name == 'scheme_deduction') {
      let deductionSum = Number(value);
      formData.schemes.map((e, i) => {
        deductionSum += Number(e.scheme_deduction);
      });
      deductionSum -= formData.schemes[schemeIndex]?.scheme_deduction;
      if (deductionSum > (formData.sanction_amount - payable)) {
        alert('Total scheme deduction exceeds the total deduction amount');
        setFormData((prevState) => {
          const newSchemes = [...prevState.schemes];
          newSchemes[schemeIndex][name] = 0;
          return { ...prevState, schemes: newSchemes };
        });
        return;
      }
    }
    setFormData((prevState) => {
      const newSchemes = [...prevState.schemes];
      newSchemes[schemeIndex][name] = value;
      if (newSchemes[schemeIndex]?.subheads) {
        newSchemes[schemeIndex].subheads = newSchemes[schemeIndex].subheads.map((subhead) => ({
          ...subhead,
          activity: [
            {
              activity_id: '',
              subactivity_id: '',
              provisional_budget: 0,
              budget: 0,
            }
          ]
        }));
      }
      return { ...prevState, schemes: newSchemes };
    });
    console.log("testing Data setFormData==>>", formData);
  };

  const handleSubheadChange = (e, schemeIndex, subheadIndex, sub_heads_id) => {
    const { name, value } = e.target;
    if (name == "sub_head_amount") {
      if (selectSubhead.subheadBalance < value) {
        formData.schemes[schemeIndex].subheads[subheadIndex].sub_head_amount = ''
        return alert("The available balance in the subhead is less than the amount.")
      }
    }
    setFormData((prevState) => {
      const newSchemes = [...prevState.schemes];
      const newSubheads = [...newSchemes[schemeIndex].subheads];
      // Initialize the subhead object if it's undefined
      if (!newSubheads[subheadIndex]) {
        newSubheads[subheadIndex] = {};
      }
      newSubheads[subheadIndex].sub_head_amount = value;
      newSubheads[subheadIndex].sub_heads_id = sub_heads_id;
      newSchemes[schemeIndex].amount = value;
      newSchemes[schemeIndex].subheads = newSubheads;
      return {
        ...prevState,
        schemes: newSchemes,
      };
    });

  };

  const deleteScheme = (schemeIndex) => {
    setFormData((prevState) => {

      const newSchemes = [...prevState.schemes];
      newSchemes.splice(schemeIndex, 1);
      return {
        ...prevState,
        schemes: newSchemes,
      };
    });
  };
  const handleSelectedSubhead = (event, schemeIndex, subheadIndex) => {
    const subhead = subhead_list.find(e => e.id == event.target.value);
    console.log("subhead set value==>", subhead);
    let hasInvalidScheme = '';
    let hasInvalidSubhead = '';
    if (event.target.value != "") {
      formData.schemes.forEach((scheme, index) => {
        if (scheme.scheme_id && hasInvalidScheme == "" && index == 0) {
          hasInvalidScheme = scheme.scheme_id;
        }
        scheme.subheads?.forEach((subhead) => {
          if (subhead.sub_heads_id && hasInvalidSubhead == "" && index == 0) {
            hasInvalidSubhead = subhead.sub_heads_id;
          }
        });
      });
      if (schemeIndex != 0 && hasInvalidScheme && hasInvalidSubhead && hasInvalidScheme == formData.schemes[schemeIndex].scheme_id) {
        if (hasInvalidSubhead != event.target.value) {
          event.target.value = '';
          alert("please select previous subhead")
          return;
        }
      }
      if (schemeIndex == 0 && formData.schemes.length > 1 && hasInvalidScheme && hasInvalidSubhead && hasInvalidScheme == formData.schemes[schemeIndex].scheme_id) {
        if (hasInvalidSubhead != event.target.value) {
          event.target.value = '';
          setClearData(true);
          return;
        }
      }
      setSelectSubhead({
        id: event.target.value,
        subheadBalance: subhead.balance - (schemeEnteredAmount.subheads[subhead.id] > 0 ? schemeEnteredAmount.subheads[subhead.id] : 0),
        maxSubheadAmount: scheme && scheme.balance ? (scheme.balance - parseInt(schemeEnteredAmount.totalAmount)) : 0,
        activities: subhead.activity_budget
      })
      setFormData((prevState) => {
        const newSchemes = [...prevState.schemes];
        const newSubheads = [...newSchemes[schemeIndex].subheads];
        newSubheads[subheadIndex].sub_heads_id = event.target.value;
        newSubheads[subheadIndex].subhead_budget_amount = subhead?.balance;
        newSchemes[schemeIndex].subheads = newSubheads;
        return { ...prevState, schemes: newSchemes };
      });
    }
  }
  const handleActivityChange = (subHeadIndex, activityIndex, e) => {
    const { name, value } = e.target;
    const activityArray = formData.schemes[schemeIndex].subheads[subHeadIndex].activity;
    const activityValid = activityArray.find(item => item.activity_id === activityArray[activityIndex].activity_id && item.subactivity_id === value);
    if (activityValid) {
      return alert('Sub-activity already selected');
    }
    setFormData((prevState) => {
      const newSchemes = [...prevState.schemes];
      const newSubHeads = [...newSchemes[schemeIndex].subheads];
      const newActivities = [...newSubHeads[subHeadIndex].activity];
      let balance = 0;
      if (name == 'subactivity_id') {
        let activity_id = newActivities[activityIndex].activity_id;
        let subactivity_id = value;
        const activityObj = selectSubhead.activities.find(ac => ac.subhead_activity_id == activity_id && ac.subhead_subactivity_id == subactivity_id);
        balance = activityObj?.budget ? activityObj?.budget : 0;
      }
      newActivities[activityIndex] = {
        ...newActivities[activityIndex],
        [name]: value,
        budget: balance
      };
      newSubHeads[subHeadIndex].activity = newActivities;
      return { ...prevState, schemes: newSchemes };
    });
  };
  const handleSubheadCheck = (value) => {
    console.log("selectedSubhead Balance", selectSubhead.subheadBalance);
    if (selectSubhead.subheadBalance < value) {
      return true;
    }
    return false;
  }
  let activitySum = (value, subheadIndex, activityIndex) => {
    const acvitity = formData.schemes[schemeIndex].subheads[subheadIndex].activity;
    console.log("activitySum", acvitity);
    const totalActivityBalance = acvitity.reduce((total, activity, i) => {
      if (i != activityIndex) {
        const activityBalance = Number(activity.balance || 0);
        return total + activityBalance;
      }
      else {
        return total + 0;
      }
    }, 0);
    console.log("totalActivityBalance", totalActivityBalance);
    return value + totalActivityBalance;

  }
  const handleActivityBalance = (subHeadIndex, activityIndex, e, compareBalance, subheadCheck = false) => {
    const { name, value } = e.target;
    if (subheadCheck) {
      const expectedAmount = activitySum(Number(value), subHeadIndex, activityIndex);
      console.log("expectedAmount", expectedAmount);
      const condition = handleSubheadCheck(expectedAmount)
      console.log("alert condition", condition);
      if (handleSubheadCheck(expectedAmount)) {
        let activitytemp = formData.schemes[schemeIndex].subheads[subHeadIndex].activity[activityIndex].balance;
        formData.schemes[schemeIndex].subheads[subHeadIndex].activity[activityIndex].balance = 0;
        formData.schemes[schemeIndex].subheads[subHeadIndex].sub_head_amount -= activitytemp;
        return alert("The available balance in the subhead is less than the amount.")
      }
    }
    else {
      if (compareBalance && Number(compareBalance) < Number(value)) {
        return alert("The available balance in the activity is less than the amount.");
      }
    }
    setFormData((prevState) => {
      const newSchemes = [...prevState.schemes];
      const newSubHeads = [...newSchemes[schemeIndex].subheads];
      const newActivities = [...newSubHeads[subHeadIndex].activity];
      newActivities[activityIndex] = {
        ...newActivities[activityIndex],
        [name]: value,
      };
      const totalActivityBalance = newActivities.reduce((total, activity) => {
        const activityBalance = Number(activity.balance || 0);
        return total + activityBalance;
      }, 0);
      newSchemes[schemeIndex] = {
        ...newSchemes[schemeIndex],
        amount: totalActivityBalance,
      };
      newSubHeads[subHeadIndex].sub_head_amount = totalActivityBalance;
      newSubHeads[subHeadIndex].activity = newActivities;
      return { ...prevState, schemes: newSchemes };
    });
  };
  const addActivity = (subHeadIndex) => {
    setFormData((prevState) => {
      const newSchemes = [...prevState.schemes];
      const newSubHeads = [...newSchemes[schemeIndex].subheads];
      const newActivities = [...newSubHeads[subHeadIndex].activity];
      const newActivityObject = {
        activity_id: '',
        subactivity_id: '',
        provisional_budget: '',
        budget: '',
      };
      newActivities.push(newActivityObject);
      newSubHeads[subHeadIndex] = {
        ...newSubHeads[subHeadIndex],
        activity: newActivities
      };
      newSchemes[schemeIndex] = {
        ...newSchemes[schemeIndex],
        subheads: newSubHeads
      };
      return { ...prevState, schemes: newSchemes };
    });
  };
  const deleteActivity = (subHeadIndex, activityIndex) => {
    setFormData((prevState) => {
      const newSchemes = [...prevState.schemes];
      const newSubHeads = [...newSchemes[schemeIndex].subheads];
      const newActivities = [...newSubHeads[subHeadIndex].activity];
      newActivities.splice(activityIndex, 1);
      newSubHeads[subHeadIndex].activity = newActivities;
      newSchemes[schemeIndex].subheads = newSubHeads;
      return { ...prevState, schemes: newSchemes };
    });
  };
  const fetchActivity = async () => {
    try {
      const resp = await getActivity();
      if (resp.data.status) {
        setActivity(resp.data.result);
      }
    } catch (error) {
      setActivity([]);
    }
  }
  const fetchSubActivity = async () => {
    try {
      const resp = await getSubActivity({});
      if (resp.data.status) {
        setSubActivity(resp.data.result);
      }
    } catch (error) {
      setSubActivity([]);
    }
  }
  const findSubActivitiesById = (id) => {
    let listData = subActivity;
    return listData.filter(subActivity => subActivity.activity_id === id);
  };
  const handleConfirm = (value) => {
    setClearData(false);
  }
  const confirmSubmit = () => {
    setScheme({});
    setFinancialYear('');
    setSelectSubhead({
      maxSubheadAmount: 0,
      subheadBalance: 0,
      id: null,
      activities: []
    })
    setFormData((prevState) => {
      const newSchemes = [
        {
          scheme_id: "",
          amount: '',
          subheads: [
            {
              id: "",
              sub_heads_id: "",
              sub_head_amount: "",
              activity: [
                {
                  activity_id: "",
                  subactivity_id: "",
                  provisional_budget: "",
                  budget: "",
                  balance: "",
                }
              ]
            }
          ],
          financial_year: ""
        }
      ];
      return { ...prevState, schemes: newSchemes };
    });
    //console.log("clear Data==>>", scheme);
    setClearData(false);
    //console.log("testing condition", formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex]);
  }
  return (
    <>
      {schemeIndex > 0 && (
        <div className='pt-2'>
          <button className='btn btn-danger btn-sm' onClick={() => deleteScheme(schemeIndex)}>Scheme {schemeIndex + 1} <FaTrash /></button>
        </div>
      )}
      <Container fluid>
        <Row className='pt-2'>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Scheme Code :</Form.Label>
              <Form.Select name="scheme_id"
                onChange={(e) => handleSchemeChange(e, schemeIndex)}
                required
                value={formData.schemes[schemeIndex].scheme_id}>
                <option value=''>--- Select Scheme Code ---</option>
                {
                  schemeList.map((scheme) => {
                    return (
                      <option value={scheme.id} key={scheme.id}>{scheme.code}</option>
                    );
                  })
                }
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Financial Year <span className='text-danger'>*</span></Form.Label>
              <Form.Select id="financial_year" name='financial_year'
                value={formData.schemes[schemeIndex].financial_year}
                onChange={(e) => handleSchemeChange(e, schemeIndex)}
                required>
                <option value="">---select year---</option>
                {
                  fy_list.map((fy) => {
                    return (
                      <option value={fy.id} key={fy.id}>{fy.year}</option>
                    );
                  })
                }
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Card className='p-3 scheme_card'>
          <h6><b>Scheme Name:</b></h6>
          <p> {scheme && scheme.name ? scheme.name : ''} </p>
          <h6><b>Available Budget Balance:</b></h6>
          {/* <p>₹ {scheme && scheme.balance ? (scheme.balance - parseInt(schemeEnteredAmount.totalAmount)) : 0}</p> */}
          <p>{scheme && scheme.balance ? scheme.balance : 0}</p>
          {/* <div className='w-50'>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Amount:</Form.Label>
              <Form.Control type="number" max={scheme.balance} placeholder="Enter Amount"
                onChange={(e) => { handleSchemeChange(e, schemeIndex); setChangeAmount(!changeAmount); }}
                name="amount" value={formData.schemes[schemeIndex].amount} required readOnly />
            </Form.Group>
          </div> */}
          <Row className='pt-2'>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Amount:</Form.Label>
                <Form.Control type="number" max={scheme.balance} placeholder="Enter Amount"
                  onChange={(e) => { handleSchemeChange(e, schemeIndex); setChangeAmount(!changeAmount); }}
                  name="amount" value={formData.schemes[schemeIndex].amount} required readOnly />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Scheme Deduction:</Form.Label>
                <Form.Control type="number" max={scheme.balance} placeholder="Enter Deduction"
                  onChange={(e) => { handleSchemeChange(e, schemeIndex) }}
                  name="scheme_deduction" value={formData.schemes.length > 1 ? formData.schemes[schemeIndex].scheme_deduction : formData.sanction_amount - payable} required readOnly={formData.schemes.length > 1 ? false : true} />
              </Form.Group>
            </Col>
          </Row>
          {formData.schemes[schemeIndex].subheads.map((subHead, i) => (<div class="card p-3">
            <Table bordered>
              <thead className='bg-light' striped>
                <tr>
                  <td colSpan={3}><small className='text-danger'><b>Amount Should be less than equal to balance amount*</b></small></td>
                </tr>
                <tr>
                  <th>Expendature Head</th>
                  <th>Available Balance</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <select name="sub_head_id" onChange={(e) => {
                      handleSelectedSubhead(e, schemeIndex, i)
                    }
                    } value={formData.schemes[schemeIndex].subheads[i].sub_heads_id}>
                      <option value="">-- select subhead --</option>
                      {
                        subhead_list.map((sub, j) => {
                          return <option key={j} value={sub.id}>{sub.name}</option>
                        })
                      }
                    </select>
                  </td>
                  <td>{formData.schemes[schemeIndex].subheads[i].subhead_budget_amount}</td>
                  <td>
                    <input type="number" name="sub_head_amount" max={selectSubhead.maxSubheadAmount} placeholder='Enter Amount' className='form-control'
                      onChange={(e) => {
                        handleSubheadChange(e, schemeIndex, i, selectSubhead.id);
                        setChangeAmount(!changeAmount);
                      }
                      }
                      value={subHead.sub_head_amount ? subHead.sub_head_amount : ""} readOnly={
                        scheme.is_activity_active === "1" &&
                        !(authData.user.department_details.department_level === "T1" && scheme.monitoring_level === "3")
                      } />
                  </td>
                </tr>
              </tbody>
            </Table>
            {scheme.is_activity_active == "1" && (scheme.monitoring_level == "3" && authData.user.department_details.department_level != 'T1') || (scheme.monitoring_level != "3") && <div className="row">
              <table className="table table-bordered">
                <thead className="text-center-custom">
                  <tr>
                    <th scope="col" width="150px">Activity</th>
                    <th scope="col" width="150px">Subactivity</th>
                    <th scope="col" width="300px">Available Balance</th>
                    <th scope="col" width="300px">Amount</th>
                    <th scope="col" width="150px">Action</th>
                  </tr>
                </thead>
                <tbody className="text-center-custom">
                  {
                    subHead?.activity && subHead?.activity.map((activityData, index) => (
                      <tr key={index}>
                        <td>
                          <select
                            className="form-control"
                            name='activity_id'
                            value={activityData.activity_id}
                            onChange={(e) => handleActivityChange(i, index, e)}
                          >
                            <option value="">---Select---</option>
                            {activity.map(activity => {
                              if (selectSubhead?.activities && selectSubhead?.activities.length) {
                                const exists = selectSubhead?.activities.find(e => e.subhead_activity_id == activity.id);
                                if (exists && (scheme.monitoring_level == "1" || (scheme.monitoring_level == "3" && authData.user.department_details.department_level != "T1"))) {
                                  return (
                                    <option key={activity.id} value={activity.id}>{activity.activity}</option>
                                  )
                                }
                              }
                              else {
                                if (scheme.monitoring_level == "2") {
                                  return (
                                    <option key={activity.id} value={activity.id}>{activity.activity}</option>
                                  )
                                }
                              }
                            })}
                          </select>
                        </td>
                        <td>
                          <select
                            className="form-control"
                            name='subactivity_id'
                            value={activityData.subactivity_id}
                            onChange={(e) => handleActivityChange(i, index, e)}
                          >
                            <option value="">---Select---</option>
                            {

                              findSubActivitiesById(activityData.activity_id).map(e => {
                                // console.log('selectSubhead', selectSubhead);
                                if (selectSubhead?.activities && selectSubhead?.activities.length) {
                                  const exists = selectSubhead?.activities.find(ac => ac.subhead_subactivity_id == e.id);
                                  if (exists && (scheme.monitoring_level == "1" || (scheme.monitoring_level == "3" && authData.user.department_details.department_level != "T1"))) {
                                    return (
                                      <option key={e.id} value={e.id}>{e.subactivity}</option>
                                    )
                                  }
                                }
                                else {
                                  if (scheme.monitoring_level == "2") {
                                    return (
                                      <option key={e.id} value={e.id}>{e.subactivity}</option>
                                    )
                                  }
                                }
                              })
                            }
                          </select>
                        </td>
                        <td>
                          {activityData?.budget}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name='balance'
                            placeholder="Enter Release Budget"
                            value={activityData?.balance}
                            disabled={!activityData.subactivity_id}
                            onChange={(e) => handleActivityBalance(i, index, e, activityData?.budget, scheme.monitoring_level == '2' ? true : false)}
                          />
                        </td>
                        <td>
                          <button type="button" className="btn btn-primary mx-2" onClick={(e) => addActivity(i)}
                          ><AddIcon />
                          </button>
                          {
                            (index !== 0) ? <button type="button" className="btn btn-danger mx-2" onClick={(e) => deleteActivity(i, index)} ><DeleteForever /></button> : ''
                          }

                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>}
          </div>))}
        </Card>
      </Container>
      <Modal show={clearData} onHide={() => handleConfirm(false)} centered>
        <Modal.Header closeButton={() => handleConfirm(false)}>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header >
        <Modal.Body>
          <p>If you want to change the scheme, you will lose any filled data. Are you sure you want to continue?</p>
        </Modal.Body>
        <Modal.Footer >
          <Button style={{
            backgroundColor: '#007bff',
            borderColor: '#007bff',
            color: 'white',
            marginRight: '10px'
          }} onClick={() => confirmSubmit()}>
            YES
          </Button>
          <Button style={{
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            color: 'white',
          }} onClick={() => handleConfirm(false)} >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default EditScheme
