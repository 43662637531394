import React, { useState} from 'react'
import DataTable from 'react-data-table-component';

export default function BeneficiaryTable({ data }) {
    const [rowsPerPage, setRowsPerPage] = useState(10);


    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
    };


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Beneficiary Name',
            selector: (row) => row.name,
            wrap: true,
            width: "200px",
            center: true
        },
        {
            name: 'Mobile',
            selector: (row) => row.mobile,
            wrap: true,
            width: "150px",
            center: true
        },
        {
            name: 'Email',
            selector: (row) => row.email,
            wrap: true,
            width: "250px",
            center: true
        },
        {
            name: 'Address',
            selector: (row) => row.address,
            wrap: true,
            width: "300px",
            center: true
        },
        {
            name: 'IFSC',
            selector: (row) => row.ifsc,
            wrap: true,
            width: "100px",
            center: true
        },
        {
            name: 'Account No',
            selector: (row) => row.account_no,
            wrap: true,
            width: "250px",
            center: true
        },
        {
            name: 'Gross Amount',
            selector: (row) => row.gross_amount,
            wrap: true,
            width: "150px",
            center: true
        },
        {
            name: 'Deduction Amount',
            selector: (row) => row.deduction_amount,
            wrap: true,
            width: "150px",
            center: true
        },
        {
            name: 'Net Amount',
            selector: (row) => row.net_amount,
            wrap: true,
            width: "150px",
            center: true
        },
    ]

    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />
        </div>
    )
}