import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Radio } from '@mui/material';
import LoginBg from '../../../assets/images/login_bg.png';
import login_logo from '../../../assets/images/wecd_bg.jpg';
import './Forget.css';
import { useState, useEffect } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux';
import { LOGIN_START, LOGIN_SUCCESS, LOGIN_FAIL } from '../../../RTK/Slices/AuthSlice';
import { login_user, send_otp_forget_password, forget_password_via_verify_otp, setAuthToken } from '../../../helper/Api';
import { toast } from 'react-toastify';
import ReCAPTCHA from "react-google-recaptcha";

export default function ForgetPassword() {
    const [error, setError] = React.useState(null);
    const [formData, setformData] = useState({
        "email": null,
        "mobile_otp": null,
        "email_otp": null,
        "password": null,
        "confirmPassword": null
    });
    const [isDisabled, setDisabled] = useState(false);
    const [show_password, setShow_password] = useState(false);
    const [send_otp, setSend_otp] = useState(false);
    const [captchaValue, setCaptchaValue] = useState('');
    const recaptchaRef = React.createRef();
    const [passwordError, setPasswordError] = useState('');
    const [seconds, setSeconds] = useState(0);

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const startTimer = () => {
        setSeconds(60);
    };

    useEffect(() => {
        if (seconds > 0) {
            // Timer logic
            const timer = setInterval(() => {
                setSeconds(prevSeconds => prevSeconds - 1);
            }, 1000);

            // Cleanup function
            return () => clearInterval(timer);
        }
    }, [seconds]);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setformData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!passwordRegex.test(formData.password)) {
            setPasswordError('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.');
            return false;
        }
        return true; // All fields are valid
    };

    const handleSubmit = async (e) => {
        setDisabled(true)
        e.preventDefault();
        if (send_otp) {
            await verify_otp_for_login()
        }
        else {
            // if (!validateForm()) {
            //     toast.error("Invalid email or password", {
            //         position: toast.POSITION.TOP_CENTER
            //     });
            //     setDisabled(false)
            //     return;
            // }
            if (formData.email) {
                dispatch(LOGIN_START);
                setDisabled(true)
                console.log(formData);
                await send_otp_for_login(formData.email);
            } else {
                toast.error("All fields Required.!", {
                    position: toast.POSITION.TOP_CENTER
                });
                setDisabled(false)
            }
        }
    }

    const verify_otp_for_login = async () => {
        if (formData.mobile_otp && formData.email_otp && formData.password && formData.confirmPassword) {
            if (!captchaValue) {
                alert('Please complete the CAPTCHA');
                setDisabled(false)
                return;
            }
            if (!validateForm()) {
                toast.error("Invalid password", {
                    position: toast.POSITION.TOP_CENTER
                });
                setDisabled(false)
                return;
            }
            if (formData.password != formData.confirmPassword) {
                toast.error("New Password and Confirm Password must be the same.", {
                    position: toast.POSITION.TOP_CENTER
                });
                setDisabled(false)
                return false;
            }
            setDisabled(true)
            const loginResponse = await forget_password_via_verify_otp(formData);
            if (loginResponse.data.status) {
                toast.success(loginResponse.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                dispatch(LOGIN_SUCCESS(loginResponse.data));
                setSend_otp(false);
                navigate('/login')
                setDisabled(false)
            } else {
                toast.error(loginResponse.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                setDisabled(false)
            }
        } else {
            toast.error("All the field required", {
                position: toast.POSITION.TOP_CENTER
            });
            setDisabled(false)
        }
    }

    const send_otp_for_login = async (email) => {
        if (!email && formData?.email) {
            email = formData.email
        }
        // console.log(formData);return false;
        if (email) {
            let post_data = {
                "email": email,
                // "user_type": formData.user_type,
                "identifier": "forget_password"
            };
            console.log("send Otp payload=>>", post_data);
            setDisabled(true)
            const loginResponse = await send_otp_forget_password(post_data);
            if (loginResponse.data.status) {
                setSend_otp(true)
                setDisabled(false)
                setTimeout(startTimer, 500);
                toast.success(loginResponse.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            } else {
                setDisabled(false)
                toast.error(loginResponse.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }

        } else {
            toast.error("All fields required", {
                position: toast.POSITION.TOP_CENTER
            });
            setDisabled(false)
        }
    }
    const handleSendOtpStatus = async () => {
        setSend_otp(true);
    };
    const handleSendOTP = async () => {
        // Call send_otp_for_login function with the mobile value
        const mobile = formData?.mobile; // Replace with your mobile value
        await send_otp_for_login(mobile);
    };

    return (
        <Grid container component="div">

            <Grid item component='div'>
                <div component="main">
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'end',
                            justifyContent: "end",
                        }}
                    >
                        {/* <img src={login_logo} alt="login_logo" className='login_logo' /> */}
                        {/* <h2>Directorate Women Welfare Uttarakhand</h2> */}
                        <Box component="form" noValidate sx={{ scale:'0.9' }} onSubmit={handleSubmit} className='form_box d-flex flex-column m-lg-2' >
                            {/* <Typography component="h1" variant="h5">
              Login
            </Typography>             */}
                            <Typography component="p" variant="p" sx={{
                                color: "red",
                                fontWeight: "bold",
                                paddingLeft: "10px",
                                paddingTop: "10px",
                            }}>
                                {error}
                            </Typography>
                            {send_otp ? <Typography component="p" variant="p" sx={{
                                color: "blue",
                                fontWeight: "bold",
                                paddingLeft: "10px",
                                paddingTop: "10px",
                                cursor: "pointer"
                            }}>
                                Set New Password
                            </Typography> : ""}
                            {
                                // forgetPassword ? (
                                //     <div>
                                //         <span className='password_wrapper'>
                                //             <TextField
                                //                 onChange={handleChange}
                                //                 margin="normal"
                                //                 required
                                //                 fullWidth
                                //                 name="password"
                                //                 label="New Password"
                                //                 type={show_password ? "text" : "password"}
                                //                 id="password"
                                //                 autoComplete="current-password"
                                //                 value={formData.password}
                                //             />

                                //             <span className='view_password' onClick={() => setShow_password(!show_password)}>
                                //                 {show_password ? <AiFillEye /> : <AiFillEyeInvisible />}
                                //             </span>
                                //         </span>
                                //     </div>
                                // ) :
                                send_otp ? (
                                    <div>
                                        <span className='password_wrapper'>
                                            <TextField
                                                onChange={handleChange}
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="password"
                                                label="New Password"
                                                type={show_password ? "text" : "password"}
                                                id="password"
                                                autoComplete="current-password"
                                                value={formData.password}
                                            />

                                            <span className='view_password' onClick={() => setShow_password(!show_password)}>
                                                {show_password ? <AiFillEye /> : <AiFillEyeInvisible />}
                                            </span>
                                        </span>
                                        <span className='password_wrapper'>
                                            <TextField
                                                onChange={handleChange}
                                                margin="normal"
                                                required
                                                fullWidth
                                                name="confirmPassword"
                                                label="Confirm Password"
                                                type={show_password ? "text" : "password"}
                                                id="confirmPassword"
                                                autoComplete="current-password"
                                                value={formData.confirmPassword}
                                            />
                                            <span className='view_password' onClick={() => setShow_password(!show_password)}>
                                                {show_password ? <AiFillEye /> : <AiFillEyeInvisible />}
                                            </span>
                                        </span>
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="email_otp"
                                            label="Email OTP"
                                            type="number"
                                            name="email_otp"
                                            autoComplete="email_otp"
                                            autoFocus
                                            onChange={handleChange}
                                            value={formData.email_otp}
                                        />
                                        <TextField
                                            margin="normal"
                                            required
                                            fullWidth
                                            id="mobile_otp"
                                            label="Mobile OTP"
                                            type="number"
                                            name="mobile_otp"
                                            autoComplete="mobile_otp"
                                            autoFocus
                                            onChange={handleChange}
                                            value={formData.mobile_otp}
                                        />
                                        <ReCAPTCHA
                                            sitekey="6Lei810pAAAAAL-HaSHvdad5f5VBl-aiil_YTJUS"
                                            ref={recaptchaRef}
                                            onChange={(value) => setCaptchaValue(value)}
                                        />

                                        <Grid item xs>
                                            {seconds > 0 ?

                                                <div>
                                                    <p>Resend OTP after {seconds}s</p>
                                                </div>
                                                :
                                                <Link variant="body2">
                                                    <div>
                                                        <p onClick={handleSendOTP}>Resend OTP</p>
                                                    </div>
                                                </Link>
                                            }

                                        </Grid>
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                id="email"
                                                label="Email"
                                                name="email"
                                                autoComplete="email"
                                                autoFocus
                                                onChange={handleChange}
                                                value={formData.email}
                                            />
                                        </div>
                                        <ReCAPTCHA
                                            sitekey="6Lei810pAAAAAL-HaSHvdad5f5VBl-aiil_YTJUS"
                                            ref={recaptchaRef}
                                            onChange={(value) => setCaptchaValue(value)}
                                        />
                                    </>
                                )
                            }
                            {/* {!send_otp ? (<>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Radio
                                        checked={formData.user_type === 'l1'}
                                        onChange={handleChange}
                                        value="l1"
                                        name="user_type"
                                    >
                                    </Radio> L1
                                    <Radio
                                        checked={formData.user_type === 'l2'}
                                        onChange={handleChange}
                                        value="l2"
                                        name="user_type"
                                        label="Outlined"

                                    />
                                    L2
                                    <Radio
                                        checked={formData.user_type === 'l3'}
                                        onChange={handleChange}
                                        value="l3"
                                        name="user_type"
                                        label="Outlined"

                                    />
                                    L3
                                </Box>
                            </>) : ""} */}
                            {send_otp ?
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    disabled={isDisabled}
                                    sx={{ mt: 3, mb: 2 }}
                                >Verify OTP and Reset Password
                                </Button> :
                                <>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        disabled={isDisabled}
                                        sx={{ mt: 3, mb: 0 }}
                                    >Send OTP and Set Password
                                    </Button>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        disabled={isDisabled}
                                        sx={{ mt: 3, mb: 2 }}
                                        onClick={handleSendOtpStatus}>Verify OTP and Set Password
                                    </Button>
                                </>
                            }

                        </Box>
                    </Box>
                </div>
            </Grid>
        </Grid>

    );
}
