import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Tools from "../../../components/Tools/Tools";
import ManageDisbursmentHeader from "../../../components/Disbursment/ManageDisbursmentHeader";
import GSTList from "../../../components/Reconciliation/GST/gstList";


const GST = () => {
    const {authData}=useSelector((state)=>state.authData);
    const [selectedTDS,setSelectednvoice]=useState([])
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');
    const [dateColumn, setDateColumn] = useState('voucher_date');


    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setToSearchStr(searchStr);
        setDateColumn(dateColumn);
    };

    return (
        <div>
            {/* <Tools/> */}
            <div className='addNewScheme' >
                <div className="add_new_user">
                    <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} searchStr={searchStr} type={'GST'} />
                    <div>
                        <>
                            {selectedTDS.length>0 ? (
                                <Link to="/add-gst" state={{data: selectedTDS }}>
                                    <button type="button" className="btn btn-primary">Add Challan</button>
                                </Link>
                            ):(
                                <button type="button" disabled={true} className="btn btn-primary">Add Challan</button>
                            )}
                        </>
                    </div>
                </div>
            </div>
            <div className='scheme p-3'>
                <GSTList selectedInvoice={selectedTDS} setSelectednvoice={setSelectednvoice} dateColumn={dateColumn} fromDate={fromDate} toDate={toDate} searchStr={searchStr}/>
            </div>
        </div>
    )
}

export default GST
