import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useLocation } from "react-router-dom";
import { getSchemeBankAccountList } from '../../../helper/Api';
import { BiSolidEdit } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import ResponsiveDataTable from '../../ResponsiveDataTable/ResponsiveDataTable';

const SchemeAccountList = (filterDate) => {
    const { authData } = useSelector((state) => state.authData);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    let { state } = useLocation();
    const [data, setData] = useState(state && state.data ? state.data : []);
    const [vendor, setVendorData] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    
    // const customStyles = {
    //     headCells: {
    //         style: {
    //             fontWeight: "bold",
    //             fontSize: "14px"
    //         },
    //     },
    // };

    // const columnss = [
    //     {
    //         name: 'Department Name',
    //         selector: (row) => row.department_name,
    //         sortable: true,
    //         width: "180px"
    //     },
    //     {
    //         name: 'Department Code',
    //         selector: (row) => row.department_code,
    //         sortable: true,
    //         width: "180px",
    //         right: false
    //     },
    //     {
    //         name: 'Department Level',
    //         selector: (row) => row.department_level,
    //         sortable: true,
    //         width: "180px",
    //         right: false
    //     },
    //     {
    //         name: 'Scheme Name',
    //         selector: (row) => row.scheme_name,
    //         sortable: true,
    //         wrap: true,
    //         width: "300px",
    //         right: false
    //     },
    //     {
    //         name: 'Scheme No',
    //         selector: (row) => row.scheme_code,
    //         sortable: true,
    //         width: "180px",
    //         right: false
    //     },
    //     {
    //         name: 'Account Name',
    //         selector: (row) => row.account_name,
    //         sortable: true,
    //         wrap: true,
    //         width: "180px",
    //         right: false
    //     },
    //     {
    //         name: 'Account No',
    //         selector: (row) => row.account_no,
    //         sortable: true,
    //         width: "180px",
    //         right: false
    //     },
    // ];
    const columns = useMemo(()=> [
        {
            title: 'Department Name',
        },
        {
            title: 'Department Code',
        },
        {
            title: 'Department Level',
        },
        {
            title: 'Scheme Name',
        },
        {
            title: 'Scheme No',
        },
        {
            title: 'Account Name',
        },
        {
            title: 'Account No',
        },
    ],[]);

      const datas = useMemo(
        () =>
          data.map((item) => [
            item?.department_name,
            item?.department_code,
            item?.department_level,
            item?.scheme_name,
            item?.scheme_code,
            item?.account_name,
            item?.account_no,
          ]),
        [data]
      );


    const handleChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
        setRowsPerPage(currentRowsPerPage);
        setCurrentPage(1);
    };

    const handleFilterClick = async () => {
        // await fetchBeneficiary(schemeId)
        setData([]);
        await fetchList()
    };
    const clearFilter = async () => {
        setFromDate(''); // Clear fromDate
        setToDate('');
        await fetchList()
        // await fetchBeneficiary(schemeId)
    };

    const fetchList = async () => {
        try {
            const resp = await getSchemeBankAccountList();
            if (resp.data.status) {
                setData(resp.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }
    // console.log('fdate', filterDate.fromDate);
    useEffect(() => {
        fetchList();
    }, [filterDate.searchStr])

    // console.log(data)

    return (
        <div>
            {/* <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columnss}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}

            <ResponsiveDataTable data={datas} columns={columns} />
        </div>
    )
}

export default SchemeAccountList
