import React, {useState} from 'react'
import {useSelector} from 'react-redux';
import Tools from "../../components/Tools/Tools";
import OTHERReportList from "../../components/Reports/OtherReport";
import ManageDisbursmentHeader from "../../components/Disbursment/ManageDisbursmentHeader";


const OTHERReport = () => {
    const {authData} = useSelector((state) => state.authData);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');


    const handleDateChange = (start, end) => {
        setFromDate(start);
        setToDate(end);
    };

    return (
        <div>
            {/* <Tools/> */}
            {/*<div className='addNewScheme'>
                <div className="add_new_user">
                    <h4>GIS</h4>
                </div>
            </div>*/}

            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} type="OTHER" />
            <div className='scheme p-3'>
                <OTHERReportList fromDate={fromDate} toDate={toDate}/>
            </div>
        </div>
    )
}

export default OTHERReport
