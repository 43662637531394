import React, { useEffect, useState, useRef } from 'react'
import { get_financial_year, get_schemes, get_schemes_by_fy, get_schemes_by_fy_added_pending, getActivity, getSubActivity } from '../../helper/Api';
import { Card, Col, Container, Form, Row, Table, Modal } from 'react-bootstrap';
import { get_schemes_entered_amount } from '../../helper/Utils';
import { ContactSupportOutlined, DeleteForever } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import {  FaTrash } from 'react-icons/fa';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
//after Code
const Scheme = ({ handleInput, formData, setFormData, voucherIndex, invoiceIndex, schemeIndex, payable }) => {
  const [selectSubhead, setSelectSubhead] = useState({
    maxSubheadAmount: 0,
    subheadBalance: 0,
    id: null,
    activities: [],
  })
  // console.log('ggg', formData.vouchers[voucherIndex]?.invoices[invoiceIndex].sanction_amount);
  // console.log('klkl', payable);
  // const payableRef = useRef(formData.vouchers[voucherIndex]?.invoices[invoiceIndex].sanction_amount - payable);
  const { authData } = useSelector((state) => state.authData);
  const [clearData, setClearData] = useState(false);
  const [scheme, setScheme] = useState({});
  const [schemeList, setSchemeList] = useState([])
  const [subhead_list, setSubhead_list] = useState([]);
  const [fy_list, set_fy_list] = useState([]);
  const [financial_year, setFinancialYear] = useState(0);
  const [activity, setActivity] = useState([]);
  const [subActivity, setSubActivity] = useState([]);
  const [selectedYear, setSelectedYear] = useState({});
  const [schemeEnteredAmount, setSchemeEnteredAmount] = useState({
    'subheads': {},
    'totalAmount': 0
  })
  const [changeAmount, setChangeAmount] = useState(0);

  const schemesByFyId = async (id, fy_id) => {
    const result = await get_schemes({
      fy_id: fy_id,
      id: id
    });
    if (result.data.status) {
      return result.data.schemes
    }
  }

  const fetch_list = async () => {
    const fy = await get_financial_year();
    const schemeResponse = await get_schemes_by_fy_added_pending();
    if (schemeResponse.data.status) {
      setSchemeList(schemeResponse.data.schemes);
    }
    if (fy.data.status) {
      const result = {};
      fy.data.list.forEach((item, index) => {
        result[`year${index}`] = "";
      });
      setSelectedYear(result);
      set_fy_list(fy.data.list)
    }
  }

  const handleSelectedSubhead = (event, voucherIndex, invoiceIndex, schemeIndex, subheadIndex) => {
    //console.log('m in');
    const subhead = subhead_list.find(e => e.id == event.target.value);
    //console.log('subheadList', subhead);
    let hasInvalidScheme = '';
    let hasInvalidSubhead = '';
    if (event.target.value != "") {
      formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes.forEach((scheme,index) => {
        if (scheme.scheme_id && hasInvalidScheme == "" && index==0) {
          hasInvalidScheme = scheme.scheme_id;
        }
        scheme.subheads?.forEach((subhead) => {
          if (subhead.sub_heads_id && hasInvalidSubhead == "" && index==0) {
            hasInvalidSubhead = subhead.sub_heads_id;
          }
        });
      });
      if (schemeIndex!=0 && hasInvalidScheme && hasInvalidSubhead && hasInvalidScheme == formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].scheme_id) {
        if (hasInvalidSubhead != event.target.value) {
          event.target.value = '';
          alert("please select previous subhead")
          return;
        }
      }
      if (schemeIndex==0 && formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes.length>1 && hasInvalidScheme && hasInvalidSubhead && hasInvalidScheme == formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].scheme_id) {
        if (hasInvalidSubhead != event.target.value) {
          event.target.value = '';
          setClearData(true);
          return;
        }
      }
      setSelectSubhead({
        id: event.target.value,
        subheadBalance: subhead.balance - (schemeEnteredAmount.subheads[subhead.id] > 0 ? schemeEnteredAmount.subheads[subhead.id] : 0),
        maxSubheadAmount: scheme && scheme.balance ? (scheme.balance - parseInt(schemeEnteredAmount.totalAmount)) : 0,
        activities: subhead.activity_budget
      })
      setFormData((prevState) => {
        const newVouchers = [...prevState.vouchers];
        const newInvoices = [...newVouchers[voucherIndex].invoices];
        const newSchemes = [...newInvoices[invoiceIndex].schemes];
        const newSubheads = [...newSchemes[schemeIndex].subheads];
        newSubheads[subheadIndex].sub_heads_id = event.target.value;
        newSubheads[subheadIndex].subhead_budget_amount = subhead?.balance;
        newSchemes[schemeIndex].subheads = newSubheads;
        newInvoices[invoiceIndex].schemes = newSchemes;
        newVouchers[voucherIndex].invoices = newInvoices;
        return { ...prevState, vouchers: newVouchers };
      });
    }
  }

  const get_schemes_details = async (scheme_id, financial_year) => {
    const { data } = await get_schemes_by_fy_added_pending(scheme_id, financial_year);
    if (data.status) {
      if (data.schemes.length > 0) {
        setScheme(data.schemes[0]);
        setSubhead_list(data.schemes[0].sub_heads_list)
      }
      // setSchemeList(schemeResponse.data.schemes);
    }
  }

  useEffect(() => {
    if (scheme.id > 0) {
      get_schemes_details(scheme.id, financial_year)
      update_scheme_balance_amount(formData)
    }
  }, [financial_year]);

  useEffect(() => {
    update_scheme_balance_amount();
  }, [changeAmount])


  const update_scheme_balance_amount = (e) => {
    if (scheme.id > 0) {
      let scheme_amount = get_schemes_entered_amount(formData);
      if (scheme_amount[scheme.id + "|" + financial_year]) {
        let current_scheme = scheme_amount[scheme.id + "|" + financial_year];
        setSchemeEnteredAmount(current_scheme)
      } else {
        setSchemeEnteredAmount({
          'subheads': {},
          'totalAmount': 0
        })
      }
    }
  }

  const handleScheme = (e) => {
    const id = e.target.value;
    // console.log(id)
    if (id > 0) {
      let scheme = schemeList.filter((v) => v.id == id);
      // console.log(scheme)
      setScheme(scheme[0]);
      setFormData((prevState) => {
        const newVouchers = [...prevState.vouchers];
        const newInvoices = [...newVouchers[voucherIndex].invoices];
        const newSchemes = [...newInvoices[invoiceIndex].schemes];
        newSchemes[schemeIndex] = {
          ...newSchemes[schemeIndex],
          available_balance: scheme[0].balance,
          scheme_deduction: 0,
        };
        newInvoices[invoiceIndex].schemes = newSchemes;
        newVouchers[voucherIndex].invoices = newInvoices;
        return { ...prevState, vouchers: newVouchers };
      });
      setSubhead_list(scheme[0].sub_heads_list)
    } else {
      setScheme({ name: null });
      setSubhead_list([])
    }
  }
  useEffect(() => {
    fetchActivity();
    fetchSubActivity();
    fetch_list();
    //console.log(formData);
  }, [])


  const handleSchemeChange = (e, voucherIndex, invoiceIndex, schemeIndex) => {
    const { name, value } = e.target;
    const schemeIdValue = formData.vouchers[voucherIndex]?.invoices[invoiceIndex]?.schemes[schemeIndex]?.scheme_id;
    const financialYearValue = formData.vouchers[voucherIndex]?.invoices[invoiceIndex]?.schemes[schemeIndex]?.financial_year;
    if ((schemeIdValue && financialYearValue) && name !== 'scheme_deduction') {
      setClearData(true);
      return;
    } else {
      setClearData(false);
      // return;
    }
    if (name == 'scheme_id') {
      handleScheme(e);
      setFinancialYear(0);
    }
    if (name == 'financial_year') {
      const financialArray = formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes;
      const financialValid = financialArray.find(item => item.financial_year === value && item.scheme_id === schemeIdValue);
      if (financialValid) {
        alert('financial-year already selected');
        setFormData((prevState) => {
          const newVouchers = [...prevState.vouchers];
          const newInvoices = [...newVouchers[voucherIndex].invoices];
          const newSchemes = [...newInvoices[invoiceIndex].schemes];
          newSchemes[schemeIndex][name] = '';
          newInvoices[invoiceIndex].schemes = newSchemes;
          newVouchers[voucherIndex].invoices = newInvoices;
          return { ...prevState, vouchers: newVouchers };
        });
        return;
      }
      else
      {
        setFinancialYear(value);
      }
    }
    if(name == 'scheme_deduction') {
      let deductionSum = Number(value);
      formData.vouchers[voucherIndex]?.invoices[invoiceIndex]?.schemes.map((e, i) => {
        deductionSum += Number(e.scheme_deduction);
      });
      deductionSum -= formData.vouchers[voucherIndex]?.invoices[invoiceIndex]?.schemes[schemeIndex]?.scheme_deduction;
      if(deductionSum > (formData.vouchers[voucherIndex]?.invoices[invoiceIndex].sanction_amount - payable)) {
        alert('Total scheme deduction exceeds the total deduction amount');
        setFormData((prevState) => {
          const newVouchers = [...prevState.vouchers];
          const newInvoices = [...newVouchers[voucherIndex].invoices];
          const newSchemes = [...newInvoices[invoiceIndex].schemes];
          newSchemes[schemeIndex][name] = 0;
          newInvoices[invoiceIndex].schemes = newSchemes;
          newVouchers[voucherIndex].invoices = newInvoices;
          return { ...prevState, vouchers: newVouchers };
        });
        return;
      } 
      // else {
      //   setFormData((prevState) => {
      //     const newVouchers = [...prevState.vouchers];
      //     const newInvoices = [...newVouchers[voucherIndex].invoices];
      //     const newSchemes = [...newInvoices[invoiceIndex].schemes];
      //     newSchemes[schemeIndex][name] = value;
      //     newInvoices[invoiceIndex].schemes = newSchemes;
      //     newVouchers[voucherIndex].invoices = newInvoices;
      //     return { ...prevState, vouchers: newVouchers };
      //   });
      // }
    }
    setFormData((prevState) => {
      const newVouchers = [...prevState.vouchers];
      const newInvoices = [...newVouchers[voucherIndex].invoices];
      const newSchemes = [...newInvoices[invoiceIndex].schemes];
      newSchemes[schemeIndex][name] = value;
      newInvoices[invoiceIndex].schemes = newSchemes;
      newVouchers[voucherIndex].invoices = newInvoices;
      return { ...prevState, vouchers: newVouchers };
    });
    // setFinancialYear(0);
    console.log("schemeData==>>",formData)
  };
  const handleSubheadCheck = (value) => {
    console.log("selectedSubhead Balance",selectSubhead.subheadBalance);
    if (selectSubhead.subheadBalance < value) {
      return true;
    }
    return false;
  }
  const handleSubheadChange = async (e, voucherIndex, invoiceIndex, schemeIndex, subheadIndex, sub_heads_id, dropdownName) => {
    const { name, value } = e.target;
    if (name == "sub_head_amount") {
      if (selectSubhead.subheadBalance < value) {
        formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].subheads[subheadIndex].sub_head_amount = ''
        return alert("The available balance in the subhead is less than the amount.")
      }
    }
    // console.log(voucherIndex,invoiceIndex,schemeIndex,subheadIndex)
    // let selectedSchemeId;
    setFormData((prevState) => {
      const newVouchers = [...prevState.vouchers];
      const newInvoices = [...newVouchers[voucherIndex].invoices];
      const newSchemes = [...newInvoices[invoiceIndex].schemes];
      // console.log('newSchemes==>',newSchemes);
      // selectedSchemeId = newSchemes[schemeIndex].scheme_id;
      const newSubheads = [...newSchemes[schemeIndex].subheads];
      // Initialize the subhead object if it's undefined
      if (!newSubheads[subheadIndex]) {
        newSubheads[subheadIndex] = {};
      }
      newSubheads[subheadIndex].sub_head_amount = value;
      newSubheads[subheadIndex].sub_heads_id = selectSubhead.id;
      newSchemes[schemeIndex].amount = value;
      newSchemes[schemeIndex].subheads = newSubheads;
      newInvoices[invoiceIndex].schemes = newSchemes;
      newVouchers[voucherIndex].invoices = newInvoices;
      return { ...prevState, vouchers: newVouchers };
    });

    // if(name == 'financial_year') {

    //   //  here we can get subhead year balance
    //   setTimeout(async () => {
    //     // console.log('ssddfff2', selectedSchemeId);
    //     const updatedSchemeList = await schemesByFyId(selectedSchemeId, value);
    //     const subheadObj = updatedSchemeList[0].sub_head_list.find(sbh => sbh.id === sub_heads_id);
    //     // console.log(updatedSchemeList);
    //     selectSubhead.subheadBalance = subheadObj.balance;
    //   }, 0)


    // }

  };

  const deleteScheme = (voucherIndex, invoiceIndex, schemeIndex) => {
    setFormData((prevState) => {
      const newVouchers = [...prevState.vouchers];
      const newInvoices = [...newVouchers[voucherIndex].invoices];
      const newSchemes = [...newInvoices[invoiceIndex].schemes];
      newSchemes.splice(schemeIndex, 1);
      newInvoices[invoiceIndex].schemes = newSchemes;
      newVouchers[voucherIndex].invoices = newInvoices;
      return { ...prevState, vouchers: newVouchers };
    });
  };
  const fetchActivity = async () => {
    try {
      const resp = await getActivity();
      if (resp.data.status) {
        setActivity(resp.data.result);
      }
    } catch (error) {
      setActivity([]);
    }
  }
  const fetchSubActivity = async () => {
    try {
      const resp = await getSubActivity({});
      if (resp.data.status) {
        setSubActivity(resp.data.result);
      }
    } catch (error) {
      setSubActivity([]);
    }
  }
  const findSubActivitiesById = (id) => {
    let listData = subActivity;
    return listData.filter(subActivity => subActivity.activity_id === id);
  };
  const handleActivityChange = (subHeadIndex, activityIndex, e) => {
    const { name, value } = e.target;
    const activityArray = formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].subheads[subHeadIndex].activity;
    const activityValid = activityArray.find(item => item.activity_id === activityArray[activityIndex].activity_id && item.subactivity_id === value);
    if (activityValid) {
      return alert('Sub-activity already selected');
    }
    setFormData((prevState) => {
      const newVouchers = [...prevState.vouchers];
      const newInvoices = [...newVouchers[voucherIndex].invoices];
      const newSchemes = [...newInvoices[invoiceIndex].schemes];
      const newSubHeads = [...newSchemes[schemeIndex].subheads];
      const newActivities = [...newSubHeads[subHeadIndex].activity];
      let balance = 0;
      if (name == 'subactivity_id') {
        let activity_id = newActivities[activityIndex].activity_id;
        let subactivity_id = value;
        // console.log('vbghnb', selectSubhead.activities);
        // console.log(activity_id, subactivity_id);
        const activityObj = selectSubhead.activities.find(ac => ac.subhead_activity_id == activity_id && ac.subhead_subactivity_id == subactivity_id);
        // console.log('activity_id ==> ', activityObj);
        balance = activityObj?.budget ? activityObj?.budget : 0 ;
      }
      newActivities[activityIndex] = {
        ...newActivities[activityIndex],
        [name]: value,
        budget: balance
      };
      newSubHeads[subHeadIndex].activity = newActivities;
      newInvoices[invoiceIndex].schemes = newSchemes;
      newVouchers[voucherIndex].invoices = newInvoices;
      return { ...prevState, vouchers: newVouchers };
    });
    // console.log('formData===>', formData);
  };
  let activitySum=(value,subheadIndex,activityIndex)=>
  {
    const acvitity=formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].subheads[subheadIndex].activity;
    console.log("activitySum",acvitity);
    const totalActivityBalance = acvitity.reduce((total, activity,i) => {
      if(i!=activityIndex)
      {
        const activityBalance = Number(activity.balance || 0);
        return total + activityBalance;
      }
      else{
        return total + 0;
      }
    }, 0);
    console.log("totalActivityBalance",totalActivityBalance);
    return value+totalActivityBalance;

  }
  const handleActivityBalance = (subHeadIndex, activityIndex, e, compareBalance,subheadCheck=false) => {
    const { name, value } = e.target;
    console.log("compareBalance", compareBalance, "value", value, "<condition>", compareBalance < value);
    if(subheadCheck)
    {
      const expectedAmount=activitySum(Number(value),subHeadIndex,activityIndex);
      console.log("expectedAmount",expectedAmount);
      const condition=handleSubheadCheck(expectedAmount)
      console.log("alert condition",condition);
      if(handleSubheadCheck(expectedAmount))
      {
        let activitytemp=formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].subheads[subHeadIndex].activity[activityIndex].balance;
        formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].subheads[subHeadIndex].activity[activityIndex].balance=0;
        formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].subheads[subHeadIndex].sub_head_amount-=activitytemp;
        return alert("The available balance in the subhead is less than the amount.")
      }
      // setFormData((prevState) => {
      //   const newVouchers = [...prevState.vouchers];
      //   const newInvoices = [...newVouchers[voucherIndex].invoices];
      //   const newSchemes = [...newInvoices[invoiceIndex].schemes];
      //   const newSubHeads = [...newSchemes[schemeIndex].subheads];
      //   const newActivities = [...newSubHeads[subHeadIndex].activity];
      //   newActivities[activityIndex] = {
      //     ...newActivities[activityIndex],
      //     [name]: value,
      //   };
      //   const totalActivityBalance = newActivities.reduce((total, activity) => {
      //     const activityBalance = Number(activity.balance || 0);
      //     return total + activityBalance;
      //   }, 0);
      //   newSchemes[schemeIndex] = {
      //     ...newSchemes[schemeIndex],
      //     amount: totalActivityBalance,
      //   };
      //   // console.log('Total Activity Balance:', totalActivityBalance);
      //   newSubHeads[subHeadIndex].sub_head_amount = totalActivityBalance;
      //   newSubHeads[subHeadIndex].activity = newActivities;
      //   newInvoices[invoiceIndex].schemes = newSchemes;
      //   newVouchers[voucherIndex].invoices = newInvoices;
      //   return { ...prevState, vouchers: newVouchers };
      // });
    }
    else{
      if (compareBalance && Number(compareBalance) < Number(value)) {
        return alert("The available balance in the activity is less than the amount.");
      }
    }
    setFormData((prevState) => {
      const newVouchers = [...prevState.vouchers];
      const newInvoices = [...newVouchers[voucherIndex].invoices];
      const newSchemes = [...newInvoices[invoiceIndex].schemes];
      const newSubHeads = [...newSchemes[schemeIndex].subheads];
      const newActivities = [...newSubHeads[subHeadIndex].activity];
      newActivities[activityIndex] = {
        ...newActivities[activityIndex],
        [name]: value,
      };
      const totalActivityBalance = newActivities.reduce((total, activity) => {
        const activityBalance = Number(activity.balance || 0);
        return total + activityBalance;
      }, 0);
      newSchemes[schemeIndex] = {
        ...newSchemes[schemeIndex],
        amount: totalActivityBalance,
      };
      // console.log('Total Activity Balance:', totalActivityBalance);
      newSubHeads[subHeadIndex].sub_head_amount = totalActivityBalance;
      newSubHeads[subHeadIndex].activity = newActivities;
      newInvoices[invoiceIndex].schemes = newSchemes;
      newVouchers[voucherIndex].invoices = newInvoices;
      return { ...prevState, vouchers: newVouchers };
    });
    //console.log(formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].subheads);
  };
  const addActivity = (subHeadIndex) => {
    setFormData((prevState) => {
      const newVouchers = [...prevState.vouchers];
      const newInvoices = [...newVouchers[voucherIndex].invoices];
      const newSchemes = [...newInvoices[invoiceIndex].schemes];
      const newSubHeads = [...newSchemes[schemeIndex].subheads];
      const newActivities = [...newSubHeads[subHeadIndex].activity];
      const newActivityObject = {
        activity_id: '',
        subactivity_id: '',
        provisional_budget: '',
        budget: '',
      };
      newActivities.push(newActivityObject);
      newSubHeads[subHeadIndex] = {
        ...newSubHeads[subHeadIndex],
        activity: newActivities
      };
      newSchemes[schemeIndex] = {
        ...newSchemes[schemeIndex],
        subheads: newSubHeads
      };
      newInvoices[invoiceIndex] = {
        ...newInvoices[invoiceIndex],
        schemes: newSchemes
      };
      newVouchers[voucherIndex] = {
        ...newVouchers[voucherIndex],
        invoices: newInvoices
      };
      return { ...prevState, vouchers: newVouchers };
    });
  };
  const deleteActivity = (subHeadIndex, activityIndex) => {
    setFormData((prevState) => {
      const newVouchers = [...prevState.vouchers];
      const newInvoices = [...newVouchers[voucherIndex].invoices];
      const newSchemes = [...newInvoices[invoiceIndex].schemes];
      const newSubHeads = [...newSchemes[schemeIndex].subheads];
      const newActivities = [...newSubHeads[subHeadIndex].activity];
      newActivities.splice(activityIndex, 1);
      newSubHeads[subHeadIndex].activity = newActivities;
      newSchemes[schemeIndex].subheads = newSubHeads;
      newInvoices[invoiceIndex].schemes = newSchemes;
      newVouchers[voucherIndex].invoices = newInvoices;
      return { ...prevState, vouchers: newVouchers };
    });
  };
  const addSubHead = () => {
    setFormData((prevState) => {
      const newVouchers = [...prevState.vouchers];
      const newInvoices = [...newVouchers[voucherIndex].invoices];
      const newSchemes = [...newInvoices[invoiceIndex].schemes];
      const newSubHeads = [...newSchemes[schemeIndex].subheads];;
      newSubHeads.push({
        sub_heads_id: selectSubhead.id,
        sub_head_amount: 0,
        financial_year: null,
        activity: [
          {
            activity_id: "",
            subactivity_id: "",
            provisional_budget: 0,
            budget: 0
          }
        ]
      })
      newSchemes[schemeIndex] = {
        ...newSchemes[schemeIndex],
        subheads: newSubHeads
      };
      newInvoices[invoiceIndex] = {
        ...newInvoices[invoiceIndex],
        schemes: newSchemes
      };
      newVouchers[voucherIndex] = {
        ...newVouchers[voucherIndex],
        invoices: newInvoices
      };
      return { ...prevState, vouchers: newVouchers };
    });
  };
  const deleteSubHead = (index) => {
    setFormData((prevState) => {
      const newVouchers = [...prevState.vouchers];
      const newInvoices = [...newVouchers[voucherIndex].invoices];
      const newSchemes = [...newInvoices[invoiceIndex].schemes];
      const newSubHeads = [...newSchemes[schemeIndex].subheads];;
      newSubHeads.splice(index, 1)
      newSchemes[schemeIndex] = {
        ...newSchemes[schemeIndex],
        subheads: newSubHeads
      };
      newInvoices[invoiceIndex] = {
        ...newInvoices[invoiceIndex],
        schemes: newSchemes
      };
      newVouchers[voucherIndex] = {
        ...newVouchers[voucherIndex],
        invoices: newInvoices
      };
      return { ...prevState, vouchers: newVouchers };
    });
  };
  const getOptions = (dropdownName, type) => {
    if (type == 'financial_year') {
      const selectedValuesArray = Object.values(selectedYear)
      return fy_list.filter(
        (option) =>
          !selectedValuesArray.includes(option.id) ||
          selectedYear[dropdownName] === option.id
      );
    }
  };
  const handleConfirm = (value) => {
    setClearData(false);
  }
  const confirmSubmit = () => {
    setScheme({});
    setFinancialYear('');
    setSelectSubhead({
      maxSubheadAmount: 0,
      subheadBalance: 0,
      id: null,
      activities: []
    })
    setFormData((prevState) => {
      const newVouchers = [...prevState.vouchers];
      const newInvoices = [...newVouchers[voucherIndex].invoices];
      newInvoices[invoiceIndex].schemes = [
        {
          scheme_id: "",
          amount: null,
          subheads: [
            {
              id: "",
              sub_heads_id: "",
              sub_head_amount: "",
              activity: [
                {
                  activity_id: "",
                  subactivity_id: "",
                  provisional_budget: "",
                  budget: "",
                  balance: "",
                }
              ]
            }
          ],
          financial_year: ""
        }
      ];
      newVouchers[voucherIndex].invoices = newInvoices;
      return { ...prevState, vouchers: newVouchers };
    });
    //console.log("clear Data==>>", scheme);
    setClearData(false);
    //console.log("testing condition", formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex]);
  }
  return (
    <>
      {schemeIndex > 0 && (
        <div className='pt-2'>
          <button className='btn btn-danger btn-sm' onClick={() => deleteScheme(voucherIndex, invoiceIndex, schemeIndex)}>Scheme {schemeIndex + 1} <FaTrash /></button>
        </div>
      )}

      <Container fluid>
        <Row className='pt-2'>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Scheme Code :</Form.Label>
              <Form.Select name="scheme_id"
                onChange={(e) => handleSchemeChange(e, voucherIndex, invoiceIndex, schemeIndex)}
                required
                value={formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].scheme_id}>
                <option value="">--- Select Scheme Code ---</option>
                {
                  schemeList.map((scheme) => {
                    return (
                      <option value={scheme.id} key={scheme.id}>{scheme.code}</option>
                    );
                  })
                }
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="">
              <Form.Label>Financial Year <span className='text-danger'>*</span></Form.Label>
              <Form.Select id="financial_year" name='financial_year'
                value={formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].financial_year}
                onChange={(e) => handleSchemeChange(e, voucherIndex, invoiceIndex, schemeIndex)}
                required>
                <option value="">---select year---</option>
                {
                  fy_list.map((fy) => {
                    return (
                      <option value={fy.id} key={fy.id}>{fy.year}</option>
                    );
                  })
                }
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Card className='p-3 scheme_card'>
          <h6><b>Scheme Name:</b></h6>
          <p> {scheme && scheme.name ? scheme.name : ''} </p>
          <h6><b>Available Budget Balance:</b></h6>
          <p>₹ {scheme && scheme.balance ? scheme.balance : 0}</p>
          <Row className='pt-2'>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Amount:</Form.Label>
                <Form.Control type="number" max={scheme.balance} placeholder="Enter Amount"
                  onChange={(e) => { handleSchemeChange(e, voucherIndex, invoiceIndex, schemeIndex); setChangeAmount(!changeAmount); }}
                  name="amount" value={formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].amount ? formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].amount : 0} required readOnly />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="">
                <Form.Label>Scheme Deduction:</Form.Label>
                <Form.Control type="number" max={scheme.balance} placeholder="Enter Deduction"
                  onChange={(e) => { handleSchemeChange(e, voucherIndex, invoiceIndex, schemeIndex); }}
                  name="scheme_deduction" value={formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes.length > 1 ? formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].scheme_deduction : formData.vouchers[voucherIndex]?.invoices[invoiceIndex].sanction_amount - payable} required readOnly={formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes.length > 1 ? false : true} />
              </Form.Group>
            </Col>
          </Row>
          {/* <div className='w-50'>
            
          </div>
          <div className='w-50'>
            
          </div> */}
          {formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].subheads.map((subHead, i) => (<div class="card p-3">
            <Table bordered>
              <thead className='bg-light' striped>
                <tr>
                  <td colSpan={3}><small className='text-danger'><b>Amount Should be less than equal to balance amount*</b></small></td>
                </tr>
                <tr>
                  <th>Expendature Head</th>
                  {/* <th>Financial Year</th> */}
                  <th>Available Balance</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <select name="sub_head_id" onChange={(e) => {
                      handleSelectedSubhead(e, voucherIndex, invoiceIndex, schemeIndex, i)
                    }
                    } value={formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].subheads[i].sub_heads_id}>
                      <option value="">-- select subhead --</option>
                      {
                        subhead_list.map((sub, j) => {
                          return <option key={j} value={sub.id}>{sub.name}</option>
                        })
                      }
                    </select>
                  </td>
                  <td>{selectSubhead.subheadBalance}</td>
                  <td>
                    <input type="number" name="sub_head_amount" max={selectSubhead.maxSubheadAmount} placeholder='Enter Amount' className='form-control'
                      onChange={(e) => {
                        handleSubheadChange(e, voucherIndex, invoiceIndex, schemeIndex, i, selectSubhead.id);
                        setChangeAmount(!changeAmount);
                      }
                      }
                      value={subHead.sub_head_amount ? subHead.sub_head_amount :""} readOnly={
                        scheme.is_activity_active === "1" && 
                        !(authData.user.department_details.department_level === "T1" && scheme.monitoring_level === "3")
                      }/>
                  </td>
                </tr>
              </tbody>
            </Table>
            {scheme.is_activity_active == "1" && ((scheme.monitoring_level=="3" && authData.user.department_details.department_level != 'T1') || (scheme.monitoring_level!="3"))&& <div className="row">
              <table className="table table-bordered">
                <thead className="text-center-custom">
                  <tr>
                    <th scope="col" width="150px">Activity</th>
                    <th scope="col" width="150px">Subactivity</th>
                    <th scope="col" width="300px">Available Balance</th>
                    <th scope="col" width="300px">Amount</th>
                    <th scope="col" width="150px">Action</th>
                  </tr>
                </thead>

                <tbody className="text-center-custom">
                  {
                    subHead?.activity.map((activityData, index) => (
                      <tr key={index}>
                        <td>
                          <select
                            className="form-control"
                            name='activity_id'
                            value={activityData.activity_id}
                            onChange={(e) => handleActivityChange(i, index, e)}
                          >
                            <option value="">---Select---</option>
                            {activity.map(activity => {
                              if (selectSubhead?.activities && selectSubhead?.activities.length) {
                                const exists = selectSubhead?.activities.find(e => e.subhead_activity_id == activity.id);
                                if (exists && (scheme.monitoring_level=="1"||(scheme.monitoring_level=="3" && authData.user.department_details.department_level!="T1"))) {
                                  return (
                                    <option key={activity.id} value={activity.id}>{activity.activity}</option>
                                  )
                                }
                              }
                              else{
                                  if(scheme.monitoring_level=="2")
                                  {
                                    return (
                                      <option key={activity.id} value={activity.id}>{activity.activity}</option>
                                    )
                                  }
                                  // else if(scheme.monitoring_level=="3" && authData.user.department_details.department_level == 'T1')
                                  // {

                                  // }
                              }
                            })}
                          </select>
                        </td>
                        <td>
                          <select
                            className="form-control"
                            name='subactivity_id'
                            value={activityData.subactivity_id}
                            onChange={(e) => handleActivityChange(i, index, e)}
                          >
                            <option value="">---Select---</option>
                            {
                              
                            findSubActivitiesById(activityData.activity_id).map(e => {
                              // console.log('selectSubhead', selectSubhead);
                              if (selectSubhead?.activities && selectSubhead?.activities.length) {
                                const exists = selectSubhead?.activities.find(ac => ac.subhead_subactivity_id == e.id);
                                if(exists && (scheme.monitoring_level=="1"||(scheme.monitoring_level=="3" && authData.user.department_details.department_level!="T1"))) {
                                  return (
                                    <option key={e.id} value={e.id}>{e.subactivity}</option>
                                  )
                                }
                              }
                              else{
                                if(scheme.monitoring_level=="2")
                                  {
                                    return (
                                      <option key={e.id} value={e.id}>{e.subactivity}</option>
                                    )
                                  }
                              }
                              })
                            }
                          </select>
                        </td>
                        <td>
                          {activityData?.budget}
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            name='balance'
                            placeholder="Enter Release Budget"
                            value={activityData?.balance}
                            disabled={!activityData.subactivity_id}
                            onChange={(e) => handleActivityBalance(i, index, e, activityData?.budget,scheme.monitoring_level == '2' ? true : false)}
                          />
                        </td>
                        <td>
                          <button type="button" className="btn btn-primary mx-2" onClick={(e) => addActivity(i)}
                          ><AddIcon />
                          </button>
                          {
                            (index !== 0) ? <button type="button" className="btn btn-danger mx-2" onClick={(e) => deleteActivity(i, index)} ><DeleteForever /></button> : ''
                          }

                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>}
            {/* {scheme.name && <Col md={6} className='pt-2'>
              {
                <>
                  {
                    check_is_equal_sanction_subhead_amount(formData.sanction_order_value, formData.vouchers[voucherIndex].invoices[invoiceIndex].schemes[schemeIndex].subheads) ? "" : <Button variant="outlined" onClick={addSubHead}><FaPlus />&nbsp; Add Subhead</Button>
                  }
                  {i > 0 && <Button
                    variant="outlined"
                    className="ml-2"
                    style={{ marginLeft: '10px', color: "#e06868", borderColor: "#e06868" }}
                    onClick={(e) => deleteSubHead(i)}>
                    <FaTrash />&nbsp; Delete Subhead
                  </Button>}
                </>
              }
            </Col>} */}
          </div>))}
        </Card>
      </Container>
      <Modal show={clearData} onHide={() => handleConfirm(false)} centered>
        <Modal.Header closeButton={() => handleConfirm(false)}>
          <Modal.Title>Confirm</Modal.Title>
        </Modal.Header >
        <Modal.Body>
          <p>If you want to change the scheme, you will lose any filled data. Are you sure you want to continue?</p>
        </Modal.Body>
        <Modal.Footer >
          <Button style={{
            backgroundColor: '#007bff',
            borderColor: '#007bff',
            color: 'white',
            marginRight: '10px'
          }} onClick={() => confirmSubmit()}>
            YES
          </Button>
          <Button style={{
            backgroundColor: '#dc3545',
            borderColor: '#dc3545',
            color: 'white',
          }} onClick={() => handleConfirm(false)} >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Scheme
