import React, { useEffect, useRef, useState } from "react";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Dropdown from "react-bootstrap/Dropdown";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import DepartmentOtp from "../../pages/Auth/Login/DepartmentOtp";
import Tooltip from '@mui/material/Tooltip';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Tools = ({ toggleButton }) => {
  const [data, setData] = useState(null);
  const [departmentName, setDepartmentName] = useState(null);
  const [userName, setUserName] = useState(null);
  const [hamberger, setHamberger] = useState(true);
  const [dropdownValue, setDropdownValue] = useState("Username");
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [departmentId, setDepartmentId] = useState(null);
  const [userType, setUserType] = useState(null);
  const [userLevel, setUserLevel] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const storedData = JSON.parse(sessionStorage.getItem("userDetails"));
    setData(storedData);
    setDepartmentName(storedData.user.department_details.name);
    setUserName(storedData.user.user_name);
    setDepartmentId(storedData?.user?.department_details?.id);
    setUserType(storedData?.user?.user_type);
    setUserLevel(storedData?.user?.user_type);
    // console.log(storedData)
  }, []);
  /*const navigate = useNavigate();
    const isMounted = useRef(true);
    const [scriptAdded, setScriptAdded] = useState(false);
    
    useEffect(() => {
        // Function to initialize Google Translate script
        const initGoogleTranslateScript = () => {
            if (!scriptAdded) {
                const existingScript = document.getElementById('google-translate-script');
                if (!existingScript) {
                    const script = document.createElement('script');
                    script.id = 'google-translate-script';
                    script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
                    script.async = true;
                    script.onload = () => {
                        // Initialize the Google Translate element
                        window.googleTranslateElementInit = () => {
                            new window.google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element');
                        };
                    };

                    // Append the script to the body
                    document.body.appendChild(script);

                    // Update state to indicate that the script has been added
                    setScriptAdded(true);
                }
            }
        };

        // Initialize Google Translate script on component mount
        initGoogleTranslateScript();

        // Cleanup the script when the component unmounts
        return () => {
            if (isMounted.current) {
                const existingScript = document.getElementById('google-translate-script');
                if (existingScript) {
                    existingScript.parentNode.removeChild(existingScript);
                }
            }
        };
    }, [navigate, scriptAdded]);

    useEffect(() => {
        // Set isMounted to false when the component is unmounted
        return () => {
            isMounted.current = false;
        };
    }, []);*/
  /*useEffect(() => {
        // Check if the SpeechSynthesis API is available
        if ('speechSynthesis' in window) {
            const synthesis = window.speechSynthesis;
            const voices = synthesis.getVoices();

            // Example function to speak a given text
            const speakText = (text) => {
                const utterance = new SpeechSynthesisUtterance(text);
                utterance.voice = voices[0]; // Use the first available voice
                synthesis.speak(utterance);
            };

            // Example usage
            const handleTextToSpeechClick = () => {
                const textToSpeak = 'Hello, this is a sample text for text-to-speech.';
                speakText(textToSpeak);
            };

            // Attach the event listener to the text-to-speech icon
            const textToSpeechIconElement = document.getElementById('text_to_speech_icon');
            if (textToSpeechIconElement) {
                textToSpeechIconElement.addEventListener('click', handleTextToSpeechClick);
            }
        }
    }, []);*/

  // const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  const handleDropdownSelect = (eventKey) => {
    setDropdownValue(eventKey);
  };

  const handleLogout = () => {
    // Clear all session storage
    sessionStorage.clear();
    localStorage.clear()
    // toast.success("Logged out successfully!");
  };

  const navigate = useNavigate();

  const otp = "007";

  const addElipsis = (str, limit, elip = "...") => {
    return str && str.length > limit
      ? str.substring(0, limit) + `${elip}`
      : str;
  };

  return (
    <>
      <nav className="h-60px d-flex justify-content-between align-items-center bg-secondary-100 px-2 poppins-regular">
        <div className="d-flex gap-3 align-items-center ">
          <div className={`${hamberger ? null : "rotate"} transition`}>
            {toggleButton}
          </div>

          {/* <span
            style={{ border: "2px solid #ebebeb" }}
            className="p-1 rounded-3 d-flex justify-content-center align-items-center"
          >
            <SearchIcon className="text-white"/>
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              className="text-white "
              placeholder="search scheme"
            />
          </span> */}

          <div className="fw-medium text-white pb-1">
            SNA - Financial Management System
          </div>
        </div>

        <div className="d-flex gap-2 align-items-center">
          <span className="fw-medium text-white">{departmentName}</span>
          <i className="icon-assignment cursor-pointer fs-5 transition text-white submenu-hover-collpase p-2 rounded-2 submenu-active"></i>

          <i
            className="icon-notifications_none cursor-pointer transition icons-hover fs-5 p-2 rounded-2 text-white submenu-hover-collpase submenu-active"
            onClick={handleClick}
          ></i>

          <i
            className="icon-shuffle cursor-pointer fs-5 transition text-white submenu-hover-collpase p-2 rounded-2 submenu-active"
            onClick={handleClickOpen}
          ></i>

          <div className="d-flex align-items-center gap-8px">
            <Dropdown onSelect={handleDropdownSelect}>
              <Dropdown.Toggle
                id="dropdown-basic"
                className="text-white py-1 px-2 d-flex align-items-center gap-3 bg-transparent"
                style={{ border: "1px solid #fff" }}
              >
                {/* <Avatar
                alt="Travis Howard"
                src=""
                sx={{ height: "30px", width: "30px" }}
              /> */}
                  <Tooltip title={userName} arrow>
                <div className="d-flex flex-column">
                  <p className="fs-14px m-0 p-0">{addElipsis(userName, 11)}</p>
                  <span className="m-0 p-0 fw-500 fs-10px text-start">
                    {userLevel}
                  </span>
                </div>
                  </Tooltip>
              </Dropdown.Toggle>

              {/* <Dropdown.Menu className="w-100">
              <Dropdown.Item eventKey="Option 1" className="p-1">
                Option 1
              </Dropdown.Item>
              <Dropdown.Item eventKey="Option 2" className="p-1">
                Option 2
              </Dropdown.Item>
              <Dropdown.Item eventKey="Option 3" className="p-1">
                Option 3
              </Dropdown.Item>
            </Dropdown.Menu> */}
            </Dropdown>
            <i
              className="icon-power_settings_new fs-4 transition submenu-hover-collpase submenu-active text-white p-2 rounded-2 cursor-pointer"
              onClick={() => {
                handleLogout();
                navigate("/login");
              }}
            ></i>
          </div>
        </div>
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >
          {/* <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText> */}
          {/* </DialogContent> */}
          <DepartmentOtp
            switchFormClose={handleClose}
            colFull={true}
            departmentId={departmentId}
            otp={otp}
            userType={userType}
          />

          {/* <DialogActions> */}
          {/* <Button onClick={handleClose}>Close</Button> */}
          {/* </DialogActions> */}
        </Dialog>
      </nav>
    </>
  );
};

export default Tools;
