import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import {AddCommasToAmount} from "../../../helper/Utils";
import DataTable from "react-data-table-component";
import Tools from "../../../components/Tools/Tools";
import {Form} from "react-bootstrap";
import {ADD_CHALLAN_FAIL, ADD_CHALLAN_START} from "../../../RTK/Slices/ChallanSlice";
import {
    addGISChallan, addOtherChallan,
    addTDSChallan, get_schemes,
    get_tds_codes, getHeadOfAccounts,
} from "../../../helper/Api";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";


const AddOtherChallan = () => {

    let {state} = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [data] = useState(state && state?.data ? state.data : []);
    const [showPreview, setShowPreview] = useState(false);
    const [schemes, setSchemes] = useState([]);
    const [headAccount, setHeadAcc] = useState({});

    const [headAccounts, setHeadAccounts] = useState([]);

    const [formData, setFormData] = useState({
        id: '',
        invoices: [],
        ref_doc_no: '',
        amount: '',
        challan_date: '',
        challan_url: '',
        attachments: [],
        head_of_account_id: ''
    });

    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
        {
            name: 'Month/Invoice No',
            selector: (row) => <b>{row.invoice_no}</b>,
            wrap: true,
            sortable: true,
            width: "200px",
        },
        {
            name: 'Date',
            selector: (row) => <b>{row.invoice_date}</b>,
            wrap: true,
            sortable: true,
            width: "200px",

        },
        {
            name: 'Beneficiary\n' +
                'Name',
            selector: (row) => <b>{row.company_name || ''}</b>,
            sortable: true,
            wrap: true,
            width: "200px"
        },
        {
            name: 'Approval\n' +
                'Type',
            selector: (row) =>
                <b>{row.payment_type === '1' ? "FULL PAYMENT" : row.payment_type === '2' ? 'PART PAYMENT' : ''}</b>,
            sortable: true,
            width: "250px",
            wrap: true,
        },
        {
            name: 'Voucher No',
            selector: (row) => <b>{row.voucher_no}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Voucher\n' +
                'Date',
            selector: (row) => <b>{row.voucher_date}</b>,
            sortable: true,
            width: "150px",
            right: false
        }, {
            name: 'Transaction\n' +
                'Date',
            selector: (row) => <b>{row.amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Taxable\n' +
                'Amount',
            selector: (row) => <b>{row.taxable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'INVOICE\n' +
                'AMOUNT',
            selector: (row) => <b>{row.payable_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        }, {
            name: 'INVOICE\n' +
                'Amount\n' +
                'Sanctioned',
            selector: (row) => <b>{row.sanction_amount || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        }, {
            name: 'Other Deduction',
            selector: (row) => <b>{row?.other_deduction || ''}</b>,
            sortable: true,
            width: "150px",
            right: false
        },
        {
            name: 'Details',
            width: "200px",
            selector: (row) => row?.invoice ?
                <a href={row?.invoice} target="_blank" rel="noreferrer">Attachment</a> : '',
            sortable: false,
        },
    ];


    const calculateTotalAmount = (propertyName) => {
        let total = 0;
        data.forEach((row) => {
            const depositAmount = Number(parseFloat(row[propertyName]));
            if (!isNaN(depositAmount)) {
                total += depositAmount;
            }
        });
        return total.toFixed(2);
    };

    const totalInvoiceAmount = calculateTotalAmount('other_deduction');

    const footerRow = {
        isFooter: true,
        selectableRowsSingle: false,
        invoice_no: <b>Total</b>,
        other_deduction: <b>{AddCommasToAmount(totalInvoiceAmount)}</b>,
    };

    //const paginatedData = data.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    const updatedData = [...data, footerRow];

    const fetchLists = async () => {

        try {

            const schemes = await get_tds_codes();
            if (schemes.data.status) {
                setSchemes(schemes.data.list)

            }
        } catch (e) {

        }


    }

    useEffect(() => {
        fetchLists();
    }, []);

    useEffect(() => {
        fetchListsAccounts();
    }, [])

    const handleAttachmentChange = (event) => {
        const files = event.target.files;
        setFormData({
            ...formData,
            attachment: files
        });
    };
    const handleChallanFormData = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        if (name === 'head_of_account_id') {
            const headAcc = headAccounts.find(el => el.id === value)
            setHeadAcc(headAcc)
        }
        setFormData({...formData, [name]: value})
    }

    const fetchListsAccounts = async () => {

        try {

            const accounts = await getHeadOfAccounts();

            if (accounts.data.status) {
                setHeadAccounts(accounts.data.result)
            }
        } catch (e) {

        }


    }

    const handleSubmit = async (e) => {
        try {
            // console.log('dataaaa', data);
            const invoices = [];

            data.forEach(el => {
                invoices.push(el.id);
            });
            const post_data = new FormData();
            for (let i = 0; i < formData.attachment.length; i++) {
                post_data.append('attachment[]', formData.attachment[i]);
            }
            post_data.append('invoices', invoices.join(','));
            post_data.append('ref_doc_no', formData.ref_doc_no);
            post_data.append('challan_date', formData.challan_date);
            post_data.append('amount', totalInvoiceAmount);
            post_data.append('head_of_account_id', formData.head_of_account_id);
            // post_data.append('challan_url', formData.challan_url);
            dispatch(ADD_CHALLAN_START());
            const response = await addOtherChallan(post_data);
            let addChallanResponseData = response.data;
            if (addChallanResponseData.status) {
                setShowPreview(false);
                // dispatch(ADD_CHALLAN_SUCCESS(addChallanResponseData.list[0]));
                navigate('/other');
            } else {
                toast.error(addChallanResponseData.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                dispatch(ADD_CHALLAN_FAIL(addChallanResponseData.message));
            }
        } catch (error) {
            dispatch(ADD_CHALLAN_FAIL('error getting while updating challan'));

        }
    }

    const getAccountNo = () => {
        if (formData?.head_of_account_id) {
            const headAcc = headAccounts.find(el => el.id == formData.head_of_account_id)

            return headAcc?.account_no || ''
        }

    }

    return (
        <div>
            {/* <Tools/> */}
            <div className='addNewScheme'>
                <div className="add_new_user">
                    <h4>Add Challan</h4>
                    <div>

                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>Add Challan
                        </button>
                    </div>
                </div>
            </div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={updatedData}
                fixedHeader
                fixedHeaderScrollHeight="600px"
            />


            <div>
                <div className="p-3">
                    <div className="row">
                        <Form>
                            <div className="col-md-12">

                                <div className="card p-3 mt-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputScheme"
                                                               className="col-sm-4 col-form-label">Reference Doc No
                                                            <span className="text-danger">*</span> :</label>
                                                        <div className="col-sm-8">
                                                            <input type="text" className="form-control" id="ref_doc_no"
                                                                   name='ref_doc_no'
                                                                   required
                                                                   value={formData.ref_doc_no}
                                                                   onChange={handleChallanFormData}
                                                                   placeholder="Enter Ref Doc No"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="inputFinancials"
                                                               className="col-sm-4 col-form-label">
                                                             Date
                                                            <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8 ">
                                                            <Form.Control
                                                                type="date"
                                                                name="challan_date"
                                                                value={formData.challan_date}
                                                                onChange={handleChallanFormData}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 p-2">
                                                    <div className="form-group row">
                                                        <label htmlFor="head_of_account_id"
                                                               className="col-sm-4 col-form-label">
                                                            Head of Account
                                                            <span className="text-danger">*</span> :
                                                        </label>
                                                        <div className="col-sm-8">
                                                            <select className="form-control "
                                                                    id="type" name='head_of_account_id'
                                                                    required
                                                                    value={formData.head_of_account_id}
                                                                    onChange={handleChallanFormData}>
                                                                <option value="">Select Account</option>
                                                                {
                                                                    headAccounts.map((st) => {
                                                                        return (
                                                                            <option value={st.id}
                                                                                    key={st.id}>{st.name}</option>
                                                                        );
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-sm-12 p-2">
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="input-group">
                                                                <label className="input-group-btn my-0 mt-2 mr-2">
                                                                    Upload Document<span className='text-danger'>*</span>:
                                                                </label>
                                                            </div>
                                                            {
                                                                !formData?.attachments?.length ? (
                                                                    <label className='p-1'>
                                                                        <input id="" type="file"
                                                                               className='form-control'
                                                                               onChange={handleAttachmentChange}
                                                                               name='attachment'
                                                                               accept=".pdf,.jpeg,.jpg,.png"
                                                                               multiple/>
                                                                    </label>
                                                                ) : (
                                                                    formData.attachments.map((att, i) => {
                                                                        return (
                                                                            <a className="mt-2 mx-2" href={att.file_url}
                                                                               target='_blank'>Attachment {i + 1}</a>);
                                                                    })
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AddOtherChallan
