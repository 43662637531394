import React, {useEffect, useState} from 'react'
import {getLabourCessChallan} from '../../../helper/Api';
import DataTable from 'react-data-table-component';
import {useLocation} from "react-router-dom";
import {AiOutlineEye} from 'react-icons/ai';
import {Link} from 'react-router-dom';

const LabourCessReportList = ({fromDate, toDate, searchStr}) => {
    const rowsPerPage = 10;
    let {state} = useLocation();
    const [data, setData] = useState(state && state.data ? state.data : []);

    const fetchChallans = async (fromDate, toDate, searchStr) => {
        try {
            const data = await getLabourCessChallan({
                from_date: fromDate,
                to_date: toDate,
                searchStr
            });
            if (data.data.status) {
                setData(data.data.result);
            }
        } catch (error) {
            setData([]);
        }
    }

    useEffect(() => {
        fetchChallans(fromDate, toDate, searchStr);
    }, [fromDate, toDate, searchStr])


    const customStyles = {
        headCells: {
            style: {
                fontWeight: "bold",
                fontSize: "14px"
            },
        },
    };

    const columns = [
            {
                name: 'Payment Order No',
                selector: (row) => row.payment_order_no,
                wrap: true,
                sortable: true,
                width: "150px",
            },
            {
                name: 'Payment Order Date',
                selector: (row) => row.payment_order_date,
                wrap: true,
                sortable: true,
                width: "150px",
            },
            {
                name: 'Authority Name',
                selector: (row) => <b>{row.name || ''}</b>,
                sortable: true,
                wrap: true,
                width: "200px"
            },
            {
                name: 'Account No',
                selector: (row) => row.account_no,
                sortable: true,
                width: "150px",
                right: false
            },
            {
                name: 'IFSC Code',
                selector: (row) => row.ifsc_code,
                sortable: true,
                width: "150px",
                right: false
            }, {
                name: 'Amount',
                selector: (row) => row.amount,
                sortable: true,
                width: "150px",
                right: false
            },
            {
                name: 'Action',
                selector: (row) => {
                    return (
                        <>
                            <span className='pr-4'><Link to={`/view-scheme/${row.id}`}><AiOutlineEye/></Link></span>
                        </>
                    )
                },
                sortable: true,
                width: "150px",
                right: false
            }
        ];

    return (
        <div>
            <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
            />
        </div>
    )
}

export default LabourCessReportList
