import React, { useEffect, useState, useRef, useCallback } from "react";
import { useSelector } from "react-redux";
import { delete_budget_by_id, get_budget_list } from "../../../helper/Api";
import DataTable from "react-data-table-component";
import { AddCommasToAmount, show_l1_action_btn } from "../../../helper/Utils";
import { AiOutlineEye } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import { FaEdit } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";
import Swal from "sweetalert2";

const BudgetList = (listType) => {
  const { authData } = useSelector((state) => state.authData);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const currentFY = useRef({});
  const navigate = useNavigate();
  const [activeCardChild, setActiveCardChild] = useState("1");
  const [overlay, setOverlay] = useState(true);
  const [overlayDropDown, setOverlayDropDown] = useState(false);

  const fetchBudgets = useCallback(
    async (fromDate, toDate, searchStr, fyId, listType, department_id) => {
      try {
        const post_data = new FormData();
        post_data.append("from_date", fromDate || "");
        post_data.append("to_date", toDate || "");
        post_data.append("search_str", searchStr || "");
        post_data.append("fy_id", fyId || "");
        post_data.append("department_id", department_id || "");
        const { data } = await get_budget_list(post_data, "");
        if (data.budgets) {
          if (listType === "list") {
            setData(data.budgets);
          } else if (
            listType === "actionList" &&
            authData.user.user_type === "l2"
          ) {
            const list = data.budgets.filter(
              (el) => Number(el.l2_status) === 0
            );
            setData(list);
          } else if (
            listType === "actionList" &&
            authData.user.user_type === "l3"
          ) {
            const list = data.budgets.filter(
              (el) => Number(el.l3_status) === 0
            );
            setData(list);
          }
        }
      } catch (error) {
        setData([]);
      }
    },
    [authData, setData]
  );

  useEffect(() => {
    fetchBudgets(
      listType.fromDate,
      listType.toDate,
      listType.searchStr,
      listType.fyId,
      listType.listType,
      listType.departmentId
    );
  }, [
    fetchBudgets,
    listType.fromDate,
    listType.toDate,
    listType.searchStr,
    listType.fyId,
    listType.listType,
    listType.departmentId,
  ]);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
      },
    },
  };
  const columns = [
    {
      name: "Scheme code",
      selector: (row) => row.scheme_code,
      wrap: true,
      sortable: true,
      width: "200px",
    },
    {
      name: "Scheme Name",
      selector: (row) => <b>{row.scheme_name}</b>,
      wrap: true,
      // sortable: true,
      width: "300px",
    },
    {
      name: "Subhead Code",
      selector: (row) => <b>{row.subhead_code}</b>,
      wrap: true,
      // sortable: true,
      width: "150px",
      center: true,
    },
    {
      name: "Financial Year",
      selector: (row) => {
        return <b>{row.start_year + "-" + row.end_year}</b>;
      },
      sortable: true,
      wrap: true,
      width: "150px",
    },
    {
      name: "Budget Release Date",
      selector: (row) => row.budget_date,
      sortable: true,
      width: "150px",
      wrap: true,
    },
    {
      name: "Provisi. Budget",
      selector: (row) => (
        <b>{AddCommasToAmount(row.subhead_provisional_budget)}</b>
      ),
      width: "200px",
      wrap: true,
      center: true,
      sortable: true,
      sortFunction: (a, b) => {
        a = a.provisional_budget;
        b = b.provisional_budget;
        const valueA =
          typeof a === "string" ? parseFloat(a.replace(/,/g, "")) : a;
        const valueB =
          typeof b === "string" ? parseFloat(b.replace(/,/g, "")) : b;

        return valueA - valueB;
      },
    },
    {
      name: "Budget",
      selector: (row) => <b>{AddCommasToAmount(row.budget)}</b>,
      sortable: true,
      width: "150px",
      wrap: true,
      center: true,
      sortFunction: (a, b) => {
        a = a.budget;
        b = b.budget;
        const valueA =
          typeof a === "string" ? parseFloat(a.replace(/,/g, "")) : a;
        const valueB =
          typeof b === "string" ? parseFloat(b.replace(/,/g, "")) : b;

        return valueA - valueB;
      },
    },
    {
      name: "Actions (L2)",
      selector: (row) =>
        Number(row.l2_status) === 0
          ? "Pending"
          : Number(row.l2_status) === 1
          ? "Approved"
          : "Rejected",
      sortable: true,
      width: "150px",
      right: false,
    },
    {
      name: "Actions (L3)",
      selector: (row) =>
        Number(row.l3_status) === 0
          ? "Pending"
          : Number(row.l3_status) === 1
          ? "Approved"
          : "Rejected",
      sortable: true,
      width: "150px",
      right: false,
    },
    {
      name: "Action",
      width: "200px",
      selector: (row) => {
        return (
          <>
            <div className="user_action">
              <span className="pr-4">
                <Link to={`/view-budget/${row.id}`}>
                  <AiOutlineEye />
                </Link>
              </span>
              {show_l1_action_btn(
                authData.user.user_type,
                row.l2_status,
                row.l3_status,
                row.is_default
              ) ? (
                <span className="pr-4">
                  <Link to={`/edit-budget/${row.id}`}>
                    <FaEdit />
                  </Link>
                </span>
              ) : (
                ""
              )}
              {show_l1_action_btn(
                authData.user.user_type,
                row.l2_status,
                row.l3_status,
                row.is_default
              ) ? (
                <span
                  className="pr-4 text-danger"
                  onClick={() => delete_budget(row.id)}
                >
                  <MdDelete></MdDelete>
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        );
      },
    },
  ];
  const delete_budget = async (budget_id) => {
    if (budget_id) {
      try {
        let formData = {
          budget_id: budget_id,
        };
        const budget_response = await delete_budget_by_id(formData);
        if (budget_response.data.status) {
          let index = data.findIndex((u) => u.id === budget_id);

          if (index >= 0) {
            fetchBudgets(
              listType.fromDate,
              listType.toDate,
              listType.searchStr,
              listType.fyId,
              listType.listType,
              listType.departmentId
            );
          }
          toast.success(budget_response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.error(budget_response.data.message, {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      } catch (error) {
        toast.error("getting error while delete user.", {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } else {
      toast.error("budget id required.", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const alertFunction = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await delete_budget(id);
      }
    });
  };

  const [hoveredId, setHoveredId] = useState(null); // Track the hovered ID

  const handleMouseEnter = (id) => {
    setHoveredId(id); // Set the ID of the hovered div
  };

  const handleMouseLeave = () => {
    setHoveredId(null); // Reset when hover ends
  };
  return (
    <div>
      {/* <DataTable
                customStyles={customStyles}
                className="dataTables_wrapper"
                columns={columns}
                data={data}
                fixedHeader
                fixedHeaderScrollHeight="600px"
                pagination
                paginationPerPage={rowsPerPage}
                paginationTotalRows={data.length}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            /> */}

      <div className="row">
        <div
          className="px-3 col-9 d-flex flex-column gap-3 overflow-y-auto overflow-x-hidden"
          style={{ height: "100vh" }}
        >
          {/* {data.map((val, ind) => (
            <div
              className={`hover-card bg-white`}
              style={{ borderRadius: "8px" }}
              key={val?.id}
              onMouseEnter={() => handleMouseEnter(val?.id)}
              onMouseLeave={handleMouseLeave}
            >
              <div
                className="bg-prime-20 d-flex justify-content-between align-items-center p-2 hover-card-color transition overflow-hidden position-relative"
                style={{ borderRadius: "8px 8px 0 0" }}
              >
              {/* =======================card header====================== */}
          {/* <span
                  className={`fs-14px white-space-nowrap overflow-x-hidden ${hoveredId === val.id && val?.scheme_name?.length >= 70 ? null : 'text-overflow-ellipsis'}`}
                  style={{ width: "95%", fontStyle:"italic"}}
                >
                  <span
                    className={`text-secondary-100 text-white-hover transition text-overflow-ellipsis ${
                      hoveredId === val.id && val?.scheme_name?.length >= 70 ? "scroll" : null
                    }`}
                    style={{ fontStyle: "italic" }}
                  >
                    Scheme code-
                    {val?.scheme_code} {val?.scheme_name}
                  </span>
                </span>

                <span className="position-absolute end-0 me-2">
                  <i className="icon-open_in_new cursor-pointer"></i>
                </span>
              </div> */}

          {/* ================== card body ========================= */}

          {/* <div className="row p-2 text-end">
                
              <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60">
                  Subhead No
                  </span>
                  <span className="fs-14px fw-600">
                    {val?.subhead_code
                      ? val?.subhead_code
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.subhead_code}
                  </span>
                </div>

                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60">
                  Order No.
                  </span>
                  <span className="fs-14px fw-600">
                    {val?.budget_order_no
                      ? val?.budget_order_no
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.budget_order_no || 0}
                  </span>
                </div>
               
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60"> Order Date</span>
                  <span className="fs-14px fw-600">
                    {val?.budget_order_date
                      ? val?.budget_order_date
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.budget_order_date || 0}
                  </span>
                </div>

                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60">
                    Provisional Budget
                  </span>
                  <span className="fs-14px fw-600 ">
                    {val?.provisional_budget
                      ? val.provisional_budget
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.provisional_budget}
                  </span>
                </div>
                <div className="col d-flex flex-column">
                  <span className="fs-12px text-black-60">Release Budget</span>
                  <span className="fs-14px fw-600">
                    {val?.budget
                      ? val?.budget
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : val?.budget}
                  </span>
                </div>
              </div> */}

          {/* ===================card footer ==================== */}

          {/* <div className="border mx-2"></div>
              <div className="p-2 d-flex justify-content-between align-items-center ">
                <div className="d-flex align-items-center gap-3 ">
                  <span
                    className={` ${
                      val?.l2_status == 0
                        ? "bg-warning-20"
                        : val?.l2_status == 1
                        ? "bg-success-20"
                        : "bg-danger-20"
                    } rounded-2   d-flex align-items-center px-2   gap-1`}
                  >
                    <span className="fs-12px">L2</span>
                    <span>
                      {val?.l2_status == 0 ? (
                        <i
                          className="icon-warning text-warning-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : val?.l2_status == 1 ? (
                        <i
                          className="icon-verified text-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : (
                        <i
                          className="icon-close_circle_stroke text-danger-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      )}
                    </span>
                  </span>

                  <span
                    className={` ${
                      val?.l3_status == 0
                        ? "bg-warning-20"
                        : val?.l3_status == 1
                        ? "bg-success-20"
                        : "bg-danger-20"
                    } rounded-2   d-flex align-items-center px-2   gap-1`}
                  >
                    <span className="fs-12px">L3</span>
                    <span>
                      {val?.l3_status == 0 ? (
                        <i
                          className="icon-warning text-warning-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : val?.l3_status == 1 ? (
                        <i
                          className="icon-verified text-success-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      ) : (
                        <i
                          className="icon-close_circle_stroke text-danger-100"
                          style={{ height: "14px", width: "14px" }}
                        ></i>
                      )}
                    </span>
                  </span>
                </div>

                <div className="d-flex align-items-center gap-3">
                  <i
                    className="icon-remove_red_eye text-info-100 cursor-pointer"
                    onClick={() => navigate(`/view-budget/${val?.id}`)}
                  ></i>

                  {show_l1_action_btn(
                    authData.user.user_type,
                    val?.l2_status,
                    val?.l3_status
                  ) ? (
                    <span className="">
                      <Link
                        to={`/edit-budget/${val?.id}`}
                        className="text-decoration-none"
                      >
                        <i className="icon-edit text-success-100 cursor-pointer"></i>
                      </Link>
                    </span>
                  ) : (
                    ""
                  )}

                  {show_l1_action_btn(
                    authData.user.user_type,
                    val?.l2_status,
                    val?.l3_status
                  ) ? (
                    <span
                      className="pr-4 text-danger"
                      onClick={() => alertFunction(val?.id)}
                    >
                      <i className="icon-delete_outline text-danger-100 cursor-pointer"></i>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div> */}
          {/* ))} } */}

          <div
            className={`hover-card bg-white`}
            style={{ borderRadius: "8px" }}
            // key={val?.id}
            // onMouseEnter={() => handleMouseEnter(val?.id)}
            // onMouseLeave={handleMouseLeave}
          >
            <div
              className="bg-prime-20 d-flex justify-content-between align-items-center p-2 hover-card-color transition overflow-hidden position-relative"
              style={{ borderRadius: "8px 8px 0 0" }}
            >
              {/* =======================card header====================== */}
              <span
                className={`fs-14px white-space-nowrap overflow-x-hidden text-overflow-ellipsis`}
                style={{ width: "95%", fontStyle: "italic" }}
              >
                <span
                  className={`text-secondary-100 text-white-hover transition text-overflow-ellipsis `}
                  style={{ fontStyle: "italic" }}
                >
                  Scheme code- 126546545 schemename
                </span>
              </span>

              <span className="position-absolute end-0 me-2">
                <i className="icon-grading cursor-pointer" onClick={() => setOverlay(false)}></i>
              </span>
            </div>

            {/* ================== card body ========================= */}

            <div className="px-2 m-0">
              <div className="p-2 d-flex justify-content-between text-secondary-50 px-4">
                <button
                  onClick={() => setActiveCardChild("1")}
                  className={` ${
                    activeCardChild == 1 ? "bg-secondary-20" : "bg-white"
                  }  border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1 rounded-4`}
                >
                  {activeCardChild == 1 ? (
                    <i className="icon-remove_circle_outline"></i>
                  ) : (
                    <i className="icon-add_circle_outline"></i>
                  )}
                  Sub head
                </button>
                <button
                  onClick={() => setActiveCardChild("2")}
                  className={`${
                    activeCardChild == 2 ? "bg-secondary-20" : "bg-white"
                  } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1  rounded-4`}
                >
                  {activeCardChild == 2 ? (
                    <i className="icon-remove_circle_outline"></i>
                  ) : (
                    <i className="icon-add_circle_outline"></i>
                  )}
                  Activity
                </button>
                <button
                  onClick={() => setActiveCardChild("3")}
                  className={`${
                    activeCardChild == 3 ? "bg-secondary-20" : "bg-white"
                  } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1  rounded-4`}
                >
                  {activeCardChild == 3 ? (
                    <i className="icon-remove_circle_outline"></i>
                  ) : (
                    <i className="icon-add_circle_outline"></i>
                  )}
                  Sub-Activity
                </button>
              </div>
            </div>

            {/* ===================card footer ==================== */}

            <div className="border mx-2"></div>

            <div className="row p-2 text-start">
              <div className="col d-flex flex-column">
                <span className="fs-12px text-black-60">Sub Head code</span>
                <span className="fs-14px fw-600">-</span>
              </div>

              <div className="col d-flex flex-column">
                <span className="fs-12px text-black-60">Order No./Date</span>
                <span className="fs-14px fw-600">-</span>
              </div>

              <div className="col d-flex flex-column">
                <span className="fs-12px text-black-60"> Provisional</span>
                <span className="fs-14px fw-600">-</span>
              </div>

              <div className="col d-flex flex-column">
                <span className="fs-12px text-black-60">Released</span>
                <span className="fs-14px fw-600 ">-</span>
              </div>
              <div className="col d-flex flex-column">
                <span className="fs-12px text-black-60">Action</span>
                <span className="fs-14px fw-600 mt-1">
                  <div className="d-flex justify-content-between align-items-center ">
                    <div className="d-flex align-items-center gap-1 ">
                      <span
                        className={`rounded-2 bg-success-20   d-flex align-items-center px-2   gap-1`}
                      >
                        <span className="fs-12px text-black-60">L2</span>
                        <span>
                          <i
                            className="icon-verified text-success-100"
                            style={{ height: "14px", width: "14px" }}
                          ></i>
                        </span>
                      </span>

                      <span
                        className={` ${"bg-danger-20"} rounded-2   d-flex align-items-center px-2   gap-1`}
                      >
                        <span className="fs-12px text-black-60">L3</span>
                        <span>
                          <i
                            className="icon-warning text-warning-success-100"
                            style={{ height: "14px", width: "14px" }}
                          ></i>
                        </span>
                      </span>

                      <span>
                        <i
                          className="icon-remove_red_eye text-info-100 cursor-pointer"
                          onClick={() => setOverlay(false)}
                        ></i>
                      </span>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* ================== right side bar ================== */}

        {listType.listType === "list" ? (
          <div className="px-3 col-3">
            <div className="bg-white p-2 d-flex flex-column gap-3">
              <div className="d-flex flex-column gap-1">
                <span className="fs-12px text-black-60">
                  Total Provisioned Budget
                </span>
                <span className="fs-16px fw-600">
                  {" "}
                  {currentFY?.current?.totalProvisionalBudget || "00"}
                </span>
              </div>

              <div className="d-flex flex-column gap-1">
                <span className="fs-12px text-black-60">
                  Total Released Budget
                </span>
                <span className="fs-16px fw-600">
                  {currentFY?.current?.totalBudget || "00"}
                </span>
              </div>
            </div>
          </div>
        ) : null}
      </div>

      {/* ==============================overlay====================== */}

      <div className={`overlay ${overlay ? "d-none" : null}  `}></div>

      <div
        className={`overlayContent overflow-auto ${
          overlay ? "left-100" : null
        } `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-secondary-100 position-relative text-white d-flex justify-content-between align-items-center ps-4">
          <div className="fw-600 fs-16px d-flex align-items-center gap-2 ">
            <i
              className="icon-close_circle_fill fs-5  cursor-pointer"
              onClick={() => setOverlay(true)}
            ></i>
            Budget
          </div>

          <div className="d-flex gap-2 align-items-center h-100 py-3 position-relative pe-4">
            <span>As on date (date)</span>
            <i
              className="icon-arrow_drop_down_circle fs-18px cursor-pointer"
              onClick={() => setOverlayDropDown((prev) => !prev)}
            ></i>

            <div
              className={`position-absolute bg-prime-100 w-100 p-3 ${
                !overlayDropDown ? "d-none" : "d-inline-block"
              }`}
              style={{ top: "100%", zIndex: "10" }}
            >
              <div className="d-flex flex-column gap-4">
                <span className="d-flex align-items-center gap-2 cursor-pointer">
                  <i className="icon-picture_as_pdf"></i> Export PDF
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 d-flex flex-column gap-2">
          {/* ======================card view=========================== */}

          <div className="bg-white border-prime-20">
            {/* ================================card body========================= */}

            <div className="row p-2 m-0">
              <div className="px-1 col-12">
                <span
                  className="d-flex flex-column p-0 px-1"
                  style={{ background: "#F3F3F3" }}
                >
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Financial Year* :
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    placeholder="Data here"
                    style={{ background: "#F3F3F3" }}
                    disabled
                  />
                </span>
              </div>

              <div className="col-4 px-1 mt-2">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Budget Order No. :
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>
              <div className="col-4 px-1 mt-2">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Budget Order Date. :
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>
              <div className="col-4 px-1 mt-2">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Release Budget Date* :
                  </label>
                  <input
                    type="text"
                    placeholder="Data here"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                  />
                </span>
              </div>
            </div>

            <div className="row m-0 p-2">
              <div className="col-4 px-1 ">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Scheme Code* :
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                  />
                </span>
              </div>
              <div className="col-8 px-1">
                <span className="d-flex flex-column p-0 px-1">
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Scheme Name
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                  />
                </span>
              </div>
            </div>
            <div className="row m-0 p-2">
              <div className="px-1 col-4">
                <span
                  className="d-flex flex-column p-0 px-1"
                  style={{ background: "#F3F3F3" }}
                >
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Sub Head Code* 
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                    style={{ background: "#F3F3F3" }}
                  />
                </span>
              </div>
              <div className="px-1 col-8">
                <span
                  className="d-flex flex-column p-0 px-1"
                  style={{ background: "#F3F3F3" }}
                >
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Sub Head Name* 
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                    style={{ background: "#F3F3F3" }}
                  />
                </span>
              </div>
            </div>

            <div className="row m-0 p-2">
              <div className="px-1 col-4">
                <span
                  className="d-flex flex-column p-0 px-1"
                  style={{ background: "#E2F6ED" }}
                >
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Provisional Budget* :
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                    style={{ background: "#E2F6ED" }}
                  />
                </span>
              </div>
              <div className="px-1 col-4">
                <span
                  className="d-flex flex-column p-0 px-1"
                  style={{ background: "#E2F6ED" }}
                >
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    Release Budget*:
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                    style={{ background: "#E2F6ED" }}
                  />
                </span>
              </div>
            </div>

            <div className="row m-0 p-2">
              <div className="px-1 col-4">
                <span
                  className="d-flex flex-column p-0 px-1"
                  style={{ background: "#F8F8F8" }}
                >
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    L2 Remark:
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                    style={{ background: "#F8F8F8" }}
                  />
                </span>

                <div
                  className="fs-12px text-success-100 px-1"
                  style={{ background: "#F8F8F8" }}
                >
                  L2 approved | date & time
                </div>
              </div>
              <div className="px-1 col-4">
                <span
                  className="d-flex flex-column p-0 px-1"
                  style={{ background: "#F8F8F8" }}
                >
                  <label htmlFor="text" className="fs-12px text-prime-100">
                    L3 Remark
                  </label>
                  <input
                    type="text"
                    className="border-bottom-only unactive-border fs-14px"
                    disabled
                    placeholder="Data here"
                    style={{ background: "#F8F8F8" }}
                  />
                </span>
                <div
                  className="fs-12px text-warning-100 px-1"
                  style={{ background: "#F8F8F8" }}
                >
                  L3 pending
                </div>
              </div>
            </div>
          </div>
        </div>


          {/* =========================footer============= */}

    <div className="bg-success-100 position-absolute bottom-0 w-100 d-flex justify-content-between align-items-center p-2 m-0">
                <div className="row m-0 w-100">
                  <div className="col-9 d-flex flex-column align-items-end pe-5 text-white">
                    <span>Total Provisional</span>
                    <span>20000000000</span>              
                  </div>
                  <div className="col-3 d-flex flex-column text-white">
                  <span>Total Provisional</span>
                  <span>20000000000</span>
                  </div>
                </div>
    </div>
      </div>
    </div>
  );
};

export default BudgetList;
