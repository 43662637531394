import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { get_schemes } from '../../helper/Api';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Scheme Bar Graph',
    },
    legend: {
      display: true,
      position: 'top',
    },
  },
  responsive: true,
  maintainAspectRatio: false, // Allows you to customize height and make the chart responsive
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: false,
      title: {
        display: true,
        text: 'Schemes',
      },
    },
    y: {
      stacked: false,
      title: {
        display: true,
        text: 'Budget (in units)',
      },
    },
  },
};

export default function SchemeBarGraph() {
  const [schemes, setSchemes] = useState([]);
  
  useEffect(() => {
    fetch_schemedata();
  }, []);
  
  const fetch_schemedata = async () => {
    try {
      const { data } = await get_schemes();
      if (data.status) {
        const updated_schemes = data.schemes.filter((scheme) => {
          return scheme.l2_status === '1' && scheme.l3_status === '1';
        });
        setSchemes(updated_schemes);
      } else {
        setSchemes([]);
      }
    } catch (error) {
      setSchemes([]);
    }
  };
  
  let labels = [];
  let budget = [];
  let utilised_budget = [];
  let payable_expanses = [];
  
  schemes.forEach((scheme) => {
    labels.push(scheme.code);
    budget.push(parseInt(scheme.total_budget));
    utilised_budget.push(parseInt(scheme.utilised_budget));
    payable_expanses.push(parseInt(scheme.payable_expanses));
  });

  const data = {
    labels,
    datasets: [
      {
        label: 'Scheme Budget',
        data: budget,
        backgroundColor: '#58CEA7',
        stack: 'Stack 0',
      },
      {
        label: 'Utilized Budget',
        data: utilised_budget,
        backgroundColor: '#EC8D87',
        stack: 'Stack 1',
      },
      {
        label: 'Payable Expenses',
        data: payable_expanses,
        backgroundColor: '#6E94F3',
        stack: 'Stack 2',
      },
    ],
  };

  return (
    <div style={{ height: '350px', width: '100%' }}>
      <Bar options={options} data={data} />
    </div>
  );
}
