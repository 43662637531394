import React, { useState } from 'react'
import Tools from '../../../components/Tools/Tools'
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import ManageDisbursmentHeader from "../../../components/Disbursment/ManageDisbursmentHeader";
import BudgetAllocationApprovalList from '../../../components/Multi/BudgetAllocation/BudgetAllocationApprovalList';
import { useNavigate } from 'react-router-dom';

const AllocateApprovalListPage = () => {
    const navigate = useNavigate();
    const { authData } = useSelector((state) => state.authData);
    const [key, setKey] = useState('list');
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');
    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setToSearchStr(searchStr);
    };

    const handleTabSelect = (key) => {
        if (key == 'allocationList') {
            navigate('/allocate-budgets/list');
        }
    };

    return (
        <div>
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} type="Allocate Budget Approval" />
            <div className='scheme p-3'>
                <div className='scheme p-2'>
                    <div className='all_tabs'>
                        <Tabs
                            defaultActiveKey="Allocate Budget Approval"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={key}
                            onSelect={handleTabSelect}
                        >
                            <Tab eventKey="list" title="Budget Limit Approval & Allotment" className='p-2'>
                                <BudgetAllocationApprovalList listType={key} fromDate={fromDate} toDate={toDate} searchStr={searchStr}/>
                            </Tab>
                            <Tab eventKey="allocationList" title="Child Budget Limit Allocations" className='p-2'></Tab>
                        </Tabs>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default AllocateApprovalListPage
