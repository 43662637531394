import React, { useState } from 'react'
import Tools from '../../../components/Tools/Tools'
import BudgetList from '../../../components/BudgetAllocation/Schemes/BudgetList';
import { Tab, Tabs } from "react-bootstrap";
import { useSelector } from "react-redux";
import ManageDisbursmentHeader from "../../../components/Disbursment/ManageDisbursmentHeader";
import BudgetPaymentList from '../../../components/Multi/BudgetAllocation/BudgetPaymentList';
const BudgetPaymentListPage = () => {
    const { authData } = useSelector((state) => state.authData);
    const [key, setKey] = useState('list');


    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');



    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setToSearchStr(searchStr);
    };

    return (
        <div>
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} type="Payment Budgets" />
            <div className='scheme p-3'>
                <div className='scheme p-2'>
                    <div className='all_tabs'>
                        <Tabs
                            // defaultActiveKey="InvoiceList"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            activeKey={key}
                            onSelect={(k) => setKey(k)}
                        >
                            <Tab eventKey="list" title="Payment Budgets List" className='p-2'>
                                <BudgetPaymentList listType={key} fromDate={fromDate} toDate={toDate} searchStr={searchStr} />
                            </Tab>
                            {authData.user.user_type != 'l1' ?
                                <Tab eventKey="actionList" title="Pending Action" className='p-2'>
                                    <BudgetPaymentList listType={key} fromDate={fromDate} toDate={toDate} searchStr={searchStr} />
                                </Tab> : ''}
                        </Tabs>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default BudgetPaymentListPage
