import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import './sanctionOrderModal.css';

const ApprovalLabourCessPreview = ({ showPreview, setShowPreview, formData, handleSubmit, invoiceList, authorities, authorityAccounts }) => {
    const handleClose = () => setShowPreview(false);
    return (
        <div>
            <Modal
                size="lg"
                show={showPreview} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>LobourCess Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className='previewModalBody'>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Voucher No</th>
                                <th>Voucher Date</th>
                                {/* <th>Transaction Date</th> */}
                                <th>Invoice No</th>
                                <th>Invoice Date</th>
                                <th>Beneficiary Name</th>
                                <th>Approval Type</th>
                                <th>Taxable Amount</th>
                                <th>Invoice Amount</th>
                                <th>Invoice Amount Sanctioned</th>
                                <th>Royalty Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoiceList.map((invoice, index) => (
                                <tr key={index}>
                                    <td>{invoice.voucher_no}</td>
                                    <td>{invoice.voucher_date}</td>
                                    {/* <td>{invoice.amount}</td> */}
                                    <td>{invoice.invoice_no}</td>
                                    <td>{invoice.invoice_date}</td>
                                    <td>{invoice.company_name}</td>
                                    <td>{invoice.payment_type === '1' ? "FULL PAYMENT" : invoice.payment_type === '2' ? 'PART PAYMENT' : ''}</td>
                                    <td>{invoice.taxable_amount}</td>
                                    <td>{invoice.payable_amount}</td>
                                    <td>{invoice.sanction_amount}</td>
                                    <td>{invoice.royalty_amount}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Payment Order No</th>
                                <th>Payment Order Date</th>
                                <th>Authority Name</th>
                                <th>Authority A/c</th>
                                <th>Challan Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{formData.payment_order_no}</td>
                                <td>{formData.payment_order_date}</td>
                                <td><select className="form-control"
                                    id="type" name='authority_id'
                                    required
                                    value={formData.authority_id}
                                    disabled>
                                    {
                                        authorities.map((st) => {
                                            return (
                                                <option value={st.id} key={st.id}>{st.name}</option>
                                            );
                                        })
                                    }
                                </select></td>
                                <td>
                                    <select className="form-control"
                                        id="type" name='authority_account_id'
                                        required
                                        value={formData.authority_account_id}
                                        disabled>
                                        {
                                            authorityAccounts.map((st) => {
                                                return (
                                                    <option value={st.id} key={st.id}>
                                                        {st.bank_name} [{st.account_no}]
                                                    </option>
                                                );
                                            })
                                        }
                                    </select>
                                </td>
                                <td>{formData.amount}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        Change
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        SUBMIT
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default ApprovalLabourCessPreview
