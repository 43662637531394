import React, { useState, useEffect } from "react";
import { Col, Container, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  downloadExcel,
  get_financial_year,
  get_departments,
} from "../../helper/Api";
// import moment from 'moment';

const ManageDisbursmentHeader = ({ onDateChange, type, heading }) => {
  // const defaultDateColumn = type === 'Depository' ? 'approval_date' : 'voucher_date';
  const [fy_list, set_fy_list] = useState([]);
  const { authData } = useSelector((state) => state.authData);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchStr, setToSearchStr] = useState("");
  const [fy_id, setFyId] = useState(2);
  const [tier, setTier] = useState("");
  const [dateColumn, setDateColumn] = useState(
    type === "Depository" ? "approval_date" : "voucher_date"
  );
  const [searchPlaceholder, setPlaceHolder] = useState("Enter to search ...");
  const [department, setDepartment] = useState([]);
  const [departmentId, setDepartmentId] = useState("");
  
  const globalDateId = localStorage.getItem("globalDateId");
  
  const fetchFinancialYears = async () => {
    const fy = await get_financial_year();
    if (fy.data.status) { 
      set_fy_list(fy.data.list);
    }
  };
  
  useEffect(() => {
    fetchFinancialYears();
    switch (type) {
      case "Schemes":
        setPlaceHolder("Enter scheme code to search");
        break;
      case "Budgets":
        setPlaceHolder("Enter scheme code to search");
        break;
      case "Beneficiaries":
        setPlaceHolder("Enter Beneficiary Name, Contact Person, GST No, Pan");
        break;
      case "OTHER":
        setPlaceHolder("Enter Beneficiary Name");
        break;
      case "NPS":
        setPlaceHolder("Enter Beneficiary Name");
        break;
      case "GIS":
        setPlaceHolder("Enter Beneficiary Name");
        break;
      case "GST":
        setPlaceHolder("Enter Beneficiary Name");
        break;
      case "Depository":
        setPlaceHolder("Enter Beneficiary Name or Description");
        break;
    }
  }, []);

  const fetchDepartments = async (level) => {
    try {
      const departments = await get_departments({
        department_level: level,
        parent_department_id: authData.user.department_id,
      });
      if (departments.data.status) {
        setDepartment(departments.data.result);
      }
    } catch (error) {
      console.log("get_departments error");
    }
  };

  const handleInput = async (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === "fromDate") {
      setFromDate(value);
    } else if (name === "dateColumn") {
      setDateColumn(value);
    } else if (name === "search_str") {
      setToSearchStr(value);
    } else if (name === "fy_id") {
      setFyId(value);
    } else if (name === "tier") {
      await fetchDepartments(value);
      setTier(value);
    } else if (name === "department_id") {
      setDepartmentId(value);
    } else {
      setToDate(value);
    }
  };

  const handleFilterClick = () => {
    if (onDateChange) {
      onDateChange(
        fromDate,
        toDate,
        dateColumn,
        searchStr,
        fy_id,
        tier,
        departmentId
      );
    }
  };
  // const clearFilter = () => {
  //   setFromDate(""); // Clear fromDate
  //   setToDate("");
  //   setDateColumn("voucher_date");
  //   setToSearchStr("");
  //   setTier("");
  //   setDepartmentId("");
  //   setFyId(2);
  //   if (onDateChange) {
  //     onDateChange("", "", "", "", 2, "", "");
  //   }
  // };

  const printHeading = (type) => {
    if (type && type === "Beneficiaries") {
      return "Vendor's/Contract/Service Provider's";
    } else if (type && type === "Disbursments") {
      return "Vendor's/Contract/Service Provider's Payments";
    } else if (type && type === "Beneficiary Reimbursement") {
      return "DBT/Bulk/NACH Payments";
    } else if (type && type === "Allocate Budget Approval") {
      return "Budget Limit Approval & Allotment";
    } else if (type && type === "Allocate Budgets") {
      return "Child Budget Limit Allocations";
    } else if (type && type === "Budget Reappropriation") {
      return "Revision Budget & Limit Allocation";
    } else if (type && type === "User") {
      return "Users";
    } else if (type && type === "Activity") {
      return "Budget Activity";
    } else if (type && type === "Subactivity") {
      return "Budget Subactivity";
    } else if (type) {
      return type;
    } else {
      return "Invoices";
    }
  };

  const download_xml_as_zip_file = async () => {
    let apiURL = "schemes/download_xml_as_zip";
    if (type === "Schemes") {
      apiURL = "schemes/download_excel";
    } else if (type == "Budgets") {
      apiURL = "schemes/download_excel_budget_list";
    } else if (type === "Beneficiaries") {
      apiURL = "beneficiary/download_beneficiary_excel";
    } else if (type === "Invoices" || type === "Disbursments") {
      apiURL = "sanctions_order/download_excel_disbursment_invoice";
    }

    try {
      const response = await downloadExcel(apiURL, {
        from_date: fromDate,
        to_date: toDate,
        dateColumn,
        search_str: searchStr,
      });
      if (response?.data?.status) {
        const aEle = document.createElement("a");
        aEle.href = response.data.excel_url; // TODO Add complete File url here
        aEle.target = "_blank"; // Open the API URL in a new tab/window
        // document.body.appendChild(aEle);
        aEle.click();
        // document.body.removeChild(aEle);
      }
    } catch (e) {
      console.log("Something went wrong", e);
    }
  };


  useEffect(() => {
    // Initialize fy_id with globalDateId when the component mounts
    if (globalDateId) {
      setFyId(globalDateId);
    }
  }, [globalDateId]);

  const handleInputDate = (event) => {
    setFyId(event.target.value); // Update fy_id when user changes the dropdown
  };
  
  useEffect(()=>{
    handleFilterClick()
  },[fy_id])

  const location = useLocation();

  function formatUrlText(url) {
    return url
      .split("/") // Split by "/"
      .filter(Boolean) // Remove empty elements
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
      .join("-"); // Join with "-"
  }

  return (
    <div className="addNewScheme w-75">
      {/* <div className="add_new_user"> */}
        {/* <Container fluid> */}
          {/* <Row> */}
            {/* <Col sm={4}> */}
              {/* <h4>{heading ? heading : printHeading(type)}</h4> */}
              {/* {type ? <h4>{type}</h4> : <h4>Invoices</h4>} */}
            {/* </Col> */}
          {/* </Row> */}
        {/* </Container> */}

        {/* <div>
          {type === "Schemes" ||
          type == "Budgets" ||
          type === "Beneficiaries" ||
          type === "Invoices" ||
          type === "Disbursments" ? (
            <button
              type="button"
              onClick={download_xml_as_zip_file}
              className="btn btn-md btn-primary m-2"
            >
              Download Excel
            </button>
          ) : (
            ""
          )}
          {type !== "Child Scheme Accounts" &&
          type !== "Child Bank Accounts" &&
          type != "Payment Budgets" &&
          type != "Allocate Budgets" &&
          type !== "Subactivity" &&
          type !== "Activity" &&
          type !== "User" &&
          type !== "Department" &&
          type !== "OTHER" &&
          type !== "GST" &&
          type !== "NPS" &&
          type !== "GIS" &&
          type !== "TDS-IT" &&
          type !== "Schemes" &&
          type !== "Budgets" &&
          type !== "Beneficiaries" &&
          type !== "Depository" &&
          type !== "Interests" &&
          type !== "Accumulated Deduction" &&
          type !== "Budget Reappropriation" &&
          type !== "Beneficiary Reimbursement" &&
          type !== "PPA" &&
          type !== "Royalty" &&
          authData.user.user_type == "l1" ? (
            <Link to="/add-sanction-order">
              <button type="button" className="btn btn-primary">
                Add New Payments
              </button>
            </Link>
          ) : (
            ""
          )}
          {type === "Beneficiary Reimbursement" ? (
            authData.user.user_type === "l1" ? (
              <Link to="/add-beneficiary-reimbursement">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "200px" }}
                >
                  Add New Payments
                </button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {type === "User" ? (
            authData.user.user_type === "l3" ? (
              <Link to="/add-user">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "150px" }}
                >
                  Add User
                </button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {type === "Department" ? (
            authData.user.user_type === "l3" ? (
              <Link to="/add-department">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "150px" }}
                >
                  Add Department
                </button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {type === "Subactivity" ? (
            authData.user.user_type === "l1" ? (
              <Link to="/department/add-subactivity">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "150px" }}
                >
                  Add Subactivity
                </button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {type === "Activity" ? (
            authData.user.user_type === "l1" ? (
              <Link to="/department/add-activity">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "150px" }}
                >
                  Add Activity
                </button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {type === "Child Bank Accounts" ? (
            authData.user.user_type === "l3" &&
            authData.user.department_details.department_level === "T1" ? (
              <Link to="/addchildAccount">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "200px" }}
                >
                  Add Child Bank Account
                </button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {type === "Child Scheme Accounts" ? (
            authData.user.user_type === "l3" &&
            authData.user.department_details.department_level === "T1" ? (
              <Link to="/addchildSchemeAccount">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "200px" }}
                >
                  Add Scheme Account
                </button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {type === "Allocate Budgets" ? (
            authData.user.user_type === "l1" ? (
              <Link to="/allocate-budget">
                <button
                  type="button"
                  className="btn btn-primary"
                  style={{ width: "200px" }}
                >
                  Add Allocate Budget
                </button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
          {type === "Schemes" &&
          authData.user.user_type === "l1" &&
          authData.user.department_details.department_level === "T1" ? (
            <Link to="/add-scheme">
              <button type="button" className="btn btn-primary">
                Add New Scheme
              </button>
            </Link>
          ) : (
            ""
          )}
          {type === "Budgets" &&
          authData.user.user_type === "l1" &&
          authData.user.department_details.department_level === "T1" ? (
            <Link to="/add-budget">
              <button type="button" className="btn btn-primary">
                Add Budget
              </button>
            </Link>
          ) : (
            ""
          )}
          {type === "Budget Reappropriation" &&
          authData.user.user_type === "l1" ? (
            <Link to="/add-budget-reappropriate">
              <button type="button" className="btn btn-primary">
                Add New Revision
              </button>
            </Link>
          ) : (
            ""
          )}

          {type === "Beneficiaries" && authData.user.user_type == "l1" ? (
            <Link to="/add-beneficiary">
              <button type="button" className="btn btn-primary">
                Add New Vendor/Contractor/Service Provider
              </button>
            </Link>
          ) : (
            ""
          )}
        </div> */}
      {/* </div> */}

      {/* =================filter===================== */}

      <div className="mt-2" style={{background:"transparent"}}>
        <Container fluid >
          <div className="d-flex gap-1 align-items-center">
            <div>
              <h5 className=" fs-20px">{formatUrlText(location.pathname)}</h5>
            </div>
            {/* {type !== "Beneficiaries" &&
            type !== "User" &&
            type !== "Schemes" &&
            type !== "Department" &&
            type !== "Subactivity" &&
            type !== "Activity" &&
            type !== "Child Scheme Accounts" &&
            type !== "Child Bank Accounts" ? (
              <>
                {type !== "Budgets" &&
                type !== "OTHER" &&
                type !== "NPS" &&
                type !== "GIS" &&
                type !== "PPA" &&
                type !== "GST" &&
                type !== "TDS IT REPORT" &&
                type !== "Allocate Budgets" &&
                type !== "Allocate Budget Approval" ? (
                  <Col sm={4}>
                    <Form.Group className="" controlId="">
                      <select
                        name="dateColumn"
                        className="form-control mb-2"
                        onChange={handleInput}
                      >
                        {type == "Depository" ? (
                          <>
                            <option
                              value="approval_date"
                              selected={dateColumn === "approval_date"}
                            >
                              Approval Date
                            </option>
                            <option
                              value="valid_from"
                              selected={dateColumn === "valid_from"}
                            >
                              Valid From
                            </option>
                            <option
                              value="valid_to"
                              selected={dateColumn === "valid_to"}
                            >
                              Valid To
                            </option>
                          </>
                        ) : type == "Beneficiary Reimbursement" ? (
                          <>
                            <option
                              value="reimbursement_order_date"
                              selected={
                                dateColumn === "reimbursement_order_date"
                              }
                            >
                              Order Date
                            </option>
                            <option
                              value="created_at"
                              selected={dateColumn === "created_at"}
                            >
                              Creation Date
                            </option>
                          </>
                        ) : (
                          <>
                            <option
                              value="voucher_date"
                              selected={dateColumn === "voucher_date"}
                            >
                              Voucher Date
                            </option>
                            <option
                              value="invoice_date"
                              selected={dateColumn === "invoice_date"}
                            >
                              Invoice Date
                            </option>
                            <option
                              value="sanction_order_date"
                              selected={dateColumn === "sanction_order_date"}
                            >
                              Payment Sanction Order Date
                            </option>
                            <option
                              value="invoice_payment.added_on"
                              selected={
                                dateColumn === "invoice_payment.added_on"
                              }
                            >
                              Transaction Success Date
                            </option>
                          </>
                        )}
                      </select>
                    </Form.Group>
                  </Col>
                ) : (
                  ""
                )}
                <Col sm={4}>
                  <Form.Group className="" controlId="">
                    <Form.Control
                      type="date"
                      name="fromDate"
                      value={fromDate}
                      onChange={handleInput}
                    />
                  </Form.Group>
                </Col>
                <Col sm={4}>
                  <Form.Group className="" controlId="">
                    <Form.Control
                      type="date"
                      name="toDate"
                      value={toDate}
                      onChange={handleInput}
                    />
                  </Form.Group>
                </Col>
              </>
            ) : (
              ""
            )} */}
            {/* fy year filter starts */}
            {type == "Schemes" || type == "Budgets" ? (
              <div>
                  <select
                    name="fy_id"
                    className="mb-2 bg-transparent border-0 text-prime-100 fs-20px"
                    onChange={handleInputDate}
                  >
                    <option value="">-select year-</option>
                    {fy_list.map((fy) => {
                      return (
                        <option
                          value={fy.id}
                          key={fy.id}
                          selected={fy.id == fy_id ? true : false}
                        >
                          {fy.year}
                        </option>
                      );
                    })}
                  </select>
              </div>
            ) : (
              ""
            )}
            {/* fy year filter ends */}

            {/* { type !== 'Beneficiaries' ? */}
            {/* <Col sm={4}>
              <Form.Group controlId="">
                <Form.Control
                  type="text"
                  name="search_str"
                  onChange={handleInput}
                  value={searchStr}
                  placeholder={searchPlaceholder}
                />
              </Form.Group>
            </Col> */}

            {/* {type !== "Budgets" &&
            type !== "Subactivity" &&
            type !== "OTHER" &&
            type !== "NPS" &&
            type !== "GIS" &&
            type !== "PPA" &&
            type !== "GST" &&
            type !== "PPA" &&
            type !== "TDS IT REPORT" &&
            type !== "Allocate Budget Approval" ? (
              <Col sm={4}>
                <Form.Group controlId="">
                  <select
                    className="form-control"
                    name="tier"
                    onChange={handleInput}
                  >
                    <option value="">Select Department Tier</option>
                    {authData.user.department_details.department_level ===
                      "T1" && (
                      <option
                        value="T1"
                        selected={tier === "T1" ? true : false}
                      >
                        T1
                      </option>
                    )}

                    {(authData.user.department_details.department_level ===
                      "T1" ||
                      authData.user.department_details.department_level ===
                        "T2") && (
                      <option
                        value="T2"
                        selected={tier === "T2" ? true : false}
                      >
                        T2
                      </option>
                    )}

                    {(authData.user.department_details.department_level ===
                      "T1" ||
                      authData.user.department_details.department_level ===
                        "T2" ||
                      authData.user.department_details.department_level ===
                        "T3") && (
                      <option
                        value="T3"
                        selected={tier === "T3" ? true : false}
                      >
                        T3
                      </option>
                    )}

                    {(authData.user.department_details.department_level ===
                      "T1" ||
                      authData.user.department_details.department_level ===
                        "T2" ||
                      authData.user.department_details.department_level ===
                        "T3" ||
                      authData.user.department_details.department_level ===
                        "T4") && (
                      <option
                        value="T4"
                        selected={tier === "T4" ? true : false}
                      >
                        T4
                      </option>
                    )}
                  </select>
                </Form.Group>
              </Col> */}
            {/* ) : (
              ""
            )} */}

            {/* {type === "Schemes" ||
            type === "Allocate Budgets" ||
            type === "Disbursments" ||
            type === "User" ? (
              <Col sm={4}>
                <Form.Group controlId="">
                  <select
                    className="form-control"
                    name="department_id"
                    onChange={handleInput}
                  >
                    <option value="">Select Department</option>
                    {department.map((option) => (
                      <option
                        key={option.id}
                        value={option.id}
                        selected={departmentId === option.id ? true : false}
                      >
                        {option.name}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </Col>
            ) : (
              ""
            )}
            <Col sm={12}>
              <div className="d-flex mt-3">
                <div className="text-start mx-2">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    onClick={clearFilter}
                  >
                    Clear
                  </button>
                </div>
                <div className="text-start">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleFilterClick}
                  >
                    Filter
                  </button>
                </div>
              </div>
            </Col> */}
          </div>
        </Container>
      </div>
    </div>
  );
};

export default ManageDisbursmentHeader;
