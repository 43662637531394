import React from 'react'
import Tools from "../../components/Tools/Tools"
import AddUserForm from '../../components/Users/AddUserForm'

const AddUser = () => {
  return (
    <div>
      {/* <Tools></Tools> */}
      <AddUserForm></AddUserForm>
    </div>
  )
}

export default AddUser