import React, {useState} from 'react'
import {useSelector} from 'react-redux';
import Tools from "../../components/Tools/Tools";
import GISReportList from "../../components/Reports/GISReportList";
import ManageDisbursmentHeader from "../../components/Disbursment/ManageDisbursmentHeader";


const GISReport = () => {
    const {authData} = useSelector((state) => state.authData);

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [searchStr, setToSearchStr] = useState('');
    const [dateColumn, setDateColumn] = useState('voucher_date');


    const handleDateChange = (start, end, dateColumn, searchStr) => {
        setFromDate(start);
        setToDate(end);
        setToSearchStr(searchStr);
        setDateColumn(dateColumn);
    };

    return (
        <div>
            {/* <Tools/> */}
            <ManageDisbursmentHeader onDateChange={handleDateChange} startDate={fromDate} endDate={toDate} searchStr={searchStr} dateColumn={dateColumn} type="GIS" />
            <div className='scheme p-3'>
                <GISReportList fromDate={fromDate} toDate={toDate} searchStr={searchStr} dateColumn={dateColumn}/>
            </div>
        </div>
    )
}

export default GISReport
