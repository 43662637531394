import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import './BudgetAnalysis.css'
import AddInterestModal from '../Modal/AddInterestModal';
import { 
  add_account_balance_interest,
  get_financial_year, 
  update_account_balance_interest,
  get_departments
} from '../../helper/Api';
import { toast } from 'react-toastify';
import { Col, Container, Form, Row } from 'react-bootstrap'
import moment from 'moment';

const BudgetAnalysis = ({handleYearDropdown, isShowPopup, previewBankAccountData, handlePopUpState, handleFilter , setDivider}) => {
  const {authData}=useSelector((state)=>state.authData);
  const [showPreview,setShowPreview]=useState(isShowPopup);
  const [formData,setFormData]=useState({
    "id": "",
    "account_name": "",
    "bank_name": "",
    "branch_name": "",
    "ifsc_code": "",
    "ifsc_code_bank": "",
    "account_type": "",
    "account_no":"",
    "account_balance":"",
    "balance_date":"",
    "interest":"",
    "quarter":"",
    "interest_date":"",
    "email":"",
    "mobile":"",
    "l2_approval": "",
    "l3_approval": "",
    "l2_remark": "",
    "l3_remark": "",
    'added_by':authData.user.id,
    "department_id":authData.user.department_id
  });
  const [fy_list, set_fy_list] = useState([]);
  const [tier, setTier] = useState('');
  const [department, setDepartment] = useState([]);
  const [departmentId, setDepartmentId] = useState('');
  const interest_modal=(e)=>{
    e.preventDefault();
    setFormData({
      "account_name": "",
      "bank_name": "",
      "branch_name": "",
      "ifsc_code": "",
      "account_type": "",
      "account_no":"",
      "account_balance":"",
      "balance_date":"",
      "ifsc_code_bank": "",
      "interest":"",
      "interest_date":"",
      "email":"",
      "mobile":"",
      'added_by':authData.user.id,
      "department_id":authData.user.department_id
    });
    setShowPreview(true);
  }

  const fetchDepartments = async (level) => {
          try {
              const departments = await get_departments({ department_level: level, parent_department_id:  authData.user.department_id});
              if (departments.data.status) {
                  setDepartment(departments.data.result);
              }
          } catch (error) {
              console.log("get_departments error");
          }
      }

      const get_balance_interest = async () => {
        try {
          const fy = await get_financial_year();
          if (fy.data.status) {
            set_fy_list(fy.data.list);
          }
        } catch (error) {
          console.error("Error fetching financial year:", error); // Log the error
        }
      };
      
      const globDateSetting = () => {
        const date = new Date();
        const currentYear = date.getFullYear();
        const currentMonth = date.getMonth() + 1; // Month index starts from 0, so +1
      
        if (currentYear === 2025) {
          localStorage.setItem("globalDateId", JSON.stringify(currentMonth >= 4 ? 3 : 2));
        } else if (currentYear === 2026) {
          localStorage.setItem("globalDateId", JSON.stringify(currentMonth >= 4 ? 4 : 3));
        } else if (currentYear === 2027) {
          localStorage.setItem("globalDateId", JSON.stringify(currentMonth >= 4 ? 5 : 4));
        } else if (currentYear === 2028) {
          localStorage.setItem("globalDateId", JSON.stringify(currentMonth >= 4 ? 6 : 7));
        }
      };
  
  useEffect(() => {
    globDateSetting();
  }, []);

  useEffect(() => {
    get_balance_interest();
    setShowPreview(isShowPopup);
    setFormData(previewBankAccountData)
  }, [isShowPopup])

  const handleSubmit=async(e)=>{
    e.preventDefault();
    // console.log(authData.user)
    try {
      let res;
      if(isShowPopup) {
        formData.l2_approval = null;
        formData.l3_approval = null;
         const {data} = await update_account_balance_interest(formData, previewBankAccountData.id);
         handlePopUpState(false);
        res = data;
      } else {
        const {data} =await add_account_balance_interest(formData);
        res = data;
      }
      // console.log('res', res);
      if(res.status){
        setShowPreview(false);
        toast.success(res.message, {
            position: toast.POSITION.TOP_CENTER
        });
        get_balance_interest();
      }else{
        toast.error(res.message, {
            position: toast.POSITION.TOP_CENTER
        });
      }
    } catch (error) {
      console.log(error)
      toast.error("System Error. Try Later!", {
          position: toast.POSITION.TOP_CENTER
      });
    }
  }

  const clearFilter = () => {
    setTier('');
    setDepartmentId('');
  };
  const handleInput = async (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (name === 'tier') {
        await fetchDepartments(value);
        setTier(value);
    } else if (name === 'department_id') {
        setDepartmentId(value);
    }
  }

  const [globalDateId, setGlobalDateId] = useState(null);
  const [divisionNumber, setDivisionNumber] = useState(1);
  useEffect(()=>{
    setDivider(divisionNumber);
  },[divisionNumber])
  useEffect(() => {
    const storedGlobalDateId = localStorage.getItem('globalDateId');
    setGlobalDateId(storedGlobalDateId); // Store it in state
  }, [fy_list]); // Re-run if `fy_list` changes

  useEffect(()=>{
    handleFilter(departmentId, tier)
  },[departmentId, tier])

  const [aprilMonth, setAprilMonth] = useState('')
  const [month, setMonth] = useState([])

  useEffect(()=>{
    const monthNames = moment.months();
    setMonth(monthNames)
  },[])

  useEffect(() => {
    const monthName = moment().format("MMMM"); // Output: "April"
    if (monthName === "April") {
      setAprilMonth("4");
    } else {
      setAprilMonth(monthName);
    }
  }, []);
  useEffect(()=>{
    console.log(aprilMonth)
    console.log(month[4-1])
  },[aprilMonth, month])


  return (
    <>
    <div className="budget_analysis p-3 borer border-2 border-success position-relative" style={{backgroundColor: '#F1F1F1'}}>

      <AddInterestModal showPreview={showPreview} setShowPreview={setShowPreview} formData={formData} setFormData={setFormData} handleSubmit={handleSubmit} handlePopUp={handlePopUpState}></AddInterestModal>

        <h1 className='p-0 m-0'>Dashboard - Financial Year</h1>

        <div className="d-flex gap-8px justify-content-start align-items-center" style={{width:"13%"}} >
        {/* {authData.user.user_type==='l1'?(
          <button type="button" className="btn btn-primary" onClick={interest_modal}>Add Scheme Bank Account</button>
        ):""} */}
        <select 
          id="financial_year"
          name="financial_year_id"
          onChange={handleYearDropdown}
          style={{ width: "100%" }}
          >
          <option value="">---select year---</option>
            {fy_list.map((fy) => {
          const currentMonth = moment().month() + 1; // Get current month (1-12)
          const selectedYear = currentMonth >= 4 ? fy.start_year : fy.end_year; // April (4) and after → start_year, before April → end_year

          return (
            <option 
              value={fy.id}
              key={fy.id}
              selected={selectedYear === moment().format("YYYY")}
            >
              {fy.year}
            </option>
          );
  })}
</select>
        </div>

        <p className='position-absolute end-0 pe-3 translate-50 fs-12px'>All value in Indian
            <select name="rupe" id="rupe" style={{width:"fit-content"}} className='bg-transparent border-0 text-prime-100' onChange={(e)=>setDivisionNumber(e.target.value)}>
              <option value="1" selected> Rupees</option>
              <option value="1000"> Thousands Rupees </option>
              <option value="100000"> Lakhs Rupees </option>
              <option value="10000000"> Crores Rupees </option>
            </select>
        </p>

        
    </div>
    {/* =========================================filters============================================== */}
    {/* <div className="add_new_user mt-2 mx-3 rounded-3 box-shadow-filter">
          <Container fluid>
            <Row>
              <Col sm={4}>
                <Form.Group controlId="">
                    <div className='fs-12px text-prime-100'>Department Tier</div>
                    <select
                        className="form-control border-top-0 border-end-0 border-start-0 p-0 py-1 rounded-0"
                        name="tier"
                        onChange={handleInput}
                    >
                        <option value="">Select Department Tier</option>
                        {
                            authData.user.department_details.department_level === 'T1' && <option value="T1" selected={tier === 'T1' ? true : false}>T1</option>
                        }

                        {
                            (authData.user.department_details.department_level === 'T1' || authData.user.department_details.department_level === 'T2') && <option value="T2" selected={tier === 'T2' ? true : false}>T2</option>
                        }

                        {
                            (authData.user.department_details.department_level === 'T1' || authData.user.department_details.department_level === 'T2' || authData.user.department_details.department_level === 'T3') && <option value="T3" selected={tier === 'T3' ? true : false}>T3</option>
                        }

                        {
                            (authData.user.department_details.department_level === 'T1' || authData.user.department_details.department_level === 'T2' || authData.user.department_details.department_level === 'T3' || authData.user.department_details.department_level === 'T4') && <option value="T4" selected={tier === 'T4' ? true : false}>T4</option>
                        }
                    </select>
                </Form.Group>
              </Col>
              <Col sm={4}>
                <Form.Group controlId="">
                        <div className='fs-12px text-prime-100'>Department</div>
                  <select
                      className="form-control border-top-0 border-end-0 border-start-0 p-0 py-1 rounded-0"
                      name="department_id"
                      onChange={handleInput}
                  >
                      <option value="">Select Department</option>
                      {department.map(option => (
                          <option 
                          key={option.id} 
                          value={option.id}
                          selected={departmentId === option.id ? true : false}
                          >{option.name}</option>
                      ))}
                  </select>
                </Form.Group>
              </Col>
              <Col sm={4}>
                  <div className="d-flex align-items-center mt-1"> 
                        <button type="button" className='rounded-1 bg-white p-2' style={{border:"1px solid #2490EA", width:"64px", height:"44px"}}
                            onClick={clearFilter}><i className='icon-autorenew text-prime-100'></i>
                        </button>
                  </div>
              </Col>
            </Row>
          </Container>
      </div> */}
    </>
  )
}

export default BudgetAnalysis