import React, { useEffect, useState } from 'react'
import './AddSchemeForm.css';
import { Form, InputGroup } from 'react-bootstrap';
import {
    get_financial_year,
    get_scheme_type,
    get_schemes_details_by_id,
    update_scheme_status,
    getSchemeAccountBalances,
    getActivity, getSubActivity, get_schemes_flags
} from '../../../helper/Api';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AddCommasToAmount } from '../../../helper/Utils';
import { toast } from 'react-toastify';
import ApprovalSchemePreview from '../../Modal/ApprovalSchemePreview';

const ViewScheme = () => {
    const [fy_list, set_fy_list] = useState([]);
    const [st_list, set_st_list] = useState([]);
    const [activity, setActivity] = useState([]);
    const [subActivity, setSubActivity] = useState([]);
    const [accountList, setAccountList] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [schemeStatus, setSchemeStatus] = useState(false);
    const [schemeFlag, setSchemeFlag] = useState([]);
    // const [l2remarks, setl2Remarks] = useState(data.l2remarks ? data.l2remarks : '');
    const { authData } = useSelector((state) => state.authData);
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState({
        name: '',
        code: '',
        type: '',
        l2remarks: '',
        l3remarks: '',
        grant_code: '',
        department: '',
        financial_year: '',
        attachments: [],
        sub_head_list: [
            { name: '', code: '', financial_year: '', budget: '' },
        ],
        account_id: '',
        is_activity_active: '',
        monitoring_level: "",
        added_by: authData && authData.status ? authData.user.id : null
    });
    const fetchSchemeFlag = async () => {
        try {
            const resp = await get_schemes_flags();
            if (resp.data.status) {
                setSchemeFlag(resp.data.result);
            }
        } catch (error) {
            setSchemeFlag([]);
        }
    }
    const fetch_list = async () => {
        const fy = await get_financial_year();
        const st = await get_scheme_type();
        const accounts = await getSchemeAccountBalances();
        if (fy.data.status) {
            set_fy_list(fy.data.list)
        }
        if (st.data.status) {
            set_st_list(st.data.list)
        }
        if (accounts.data.status) {
            setAccountList(accounts.data.result);
        }
    }


    const handleSubmit = () => {

    }
    const get_scheme_details = async () => {
        try {
            console.log("id call this");
            const { data } = await get_schemes_details_by_id(id);
            // console.log(data)
            if (data.schemes.length > 0) {
                setFormData(data.schemes[0]);
            } else {
                setFormData({
                    name: '',
                    code: '',
                    type: '',
                    l2remarks: '',
                    l3remarks: '',
                    grant_code: '',
                    department: '',
                    financial_year: '',
                    attachments: [],
                    sub_head_list: [],
                    account_id: '',
                })
            }
        } catch (error) {

        }
    }
    useEffect(() => {
        fetchSchemeFlag();
        fetchSubActivity();
        fetchActivity();
        fetch_list();
        get_scheme_details();
    }, [id])


    const total_scheme_budget = () => {
        const subheads = formData.sub_head_list;
        let total_budget = 0;
        subheads.map((sub) => {
            if (sub.budget) {
                total_budget = total_budget + parseFloat(sub?.default_budget?.budget)
            }
            return {}
        })
        return AddCommasToAmount(total_budget.toFixed(2));
    }

    const total_provisional_budget = () => {
        const subheads = formData.sub_head_list;
        let total_budget = 0;
        subheads.map((sub) => {
            if (sub.provisional_budget) {
                total_budget = total_budget + parseFloat(sub?.default_budget?.provisional_budget)
            }
            return {}
        })
        return AddCommasToAmount(total_budget.toFixed(2));
    }

    const update_scheme_status_by_id = async () => {
        if (formData.id && schemeStatus) {
            let post_data = {};
            if (authData.user.user_type === 'l3') {
                post_data = {
                    "l3_status": schemeStatus,
                    "scheme_id": formData.id,
                    "l3remarks": formData.l3remarks
                };
            } else if (authData.user.user_type === 'l2') {
                post_data = {
                    "l2_status": schemeStatus,
                    "scheme_id": formData.id,
                    "l2remarks": formData.l2remarks
                };
            }
            //debugger
            const { data } = await update_scheme_status(post_data);
            if (data.status) {
                toast.success(data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
                navigate('/schemes');
            } else {
                toast.error(data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        }
    }

    const previewhandler = (type) => {
        // console.log(type);
        setShowPreview(true);
        setSchemeStatus(type);
    };

    const handleInvoiceChange = (e) => {
        let { name, value } = e.target;
        // console.log(name, value)
        setFormData({ ...formData, [name]: value });
    };
    const fetchActivity = async () => {
        try {
            const resp = await getActivity();
            if (resp.data.status) {
                setActivity(resp.data.result);
            }
        } catch (error) {
            setActivity([]);
        }
    }
    const fetchSubActivity = async () => {
        try {
            const resp = await getSubActivity();
            if (resp.data.status) {
                setSubActivity(resp.data.result);
            }
        } catch (error) {
            setSubActivity([]);
        }
    }
    const findSubActivitiesById = (id) => {
        let listData = subActivity;
        return listData.filter(subActivity => subActivity.activity_id === id);
    };
    return (
        <div>
            <ApprovalSchemePreview showPreview={showPreview} setShowPreview={setShowPreview} formData={formData}
                handleSubmit={update_scheme_status_by_id} activity={activity} subActivity={subActivity} schemeFlag={schemeFlag}></ApprovalSchemePreview>
            <div className="p-3">
                <div className="row">
                    <Form onSubmit={handleSubmit}>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <h4>View Scheme</h4>
                                <div>
                                    {
                                        authData.user.user_type === 'l2' ?
                                            <>
                                                {formData.l2_status == 0 ? (<>
                                                    <button type="button" className="btn btn-danger"
                                                        onClick={() => previewhandler(2)}>Reject
                                                    </button>
                                                    &nbsp;
                                                    <button type="button" className="btn btn-primary"
                                                        onClick={() => previewhandler(1)}>Approve
                                                    </button>
                                                </>) : formData.l2_status == 1 ? (<>
                                                    <button type="button" className="btn btn-primary">Already Approved
                                                    </button>
                                                </>) : (<>
                                                    <button type="button" className="btn btn-danger">Already Rejected
                                                    </button>
                                                </>)}
                                            </> : authData.user.user_type === 'l3' ? (
                                                <>
                                                    {formData.l3_status === "0" ? (<>
                                                        <button type="button" className="btn btn-danger"
                                                            onClick={() => previewhandler(2)}>Reject
                                                        </button>
                                                        &nbsp;
                                                        <button type="button" className="btn btn-primary"
                                                            onClick={() => previewhandler(1)}>Approve
                                                        </button>
                                                    </>) : formData.l3_status === "1" ? (<>
                                                        <button type="button" className="btn btn-primary">Already Approved
                                                        </button>
                                                    </>) : (<>
                                                        <button type="button" className="btn btn-danger">Already Rejected
                                                        </button>
                                                    </>)}
                                                </>
                                            ) : (
                                                ""
                                            )
                                    }
                                </div>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="row">
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputDepartment"
                                                        className="col-sm-4 col-form-label">L2 Status
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input type="text"
                                                            className="form-control"
                                                            value={formData.l2_status == 0 ? "Pending" : formData.l2_status == 1 ? "Approved" : "Rejected"}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputDepartment"
                                                        className="col-sm-4 col-form-label">L3 Status
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input type="text"
                                                            className="form-control" id="department"
                                                            name='department'
                                                            value={formData.l3_status == 0 ? "Pending" : formData.l3_status == 1 ? "Approved" : "Rejected"}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputDepartment"
                                                        className="col-sm-4 col-form-label">Department
                                                        Name
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input type="text"
                                                            className="form-control" id="department"
                                                            name='department'
                                                            value={formData.department}
                                                            readOnly
                                                            placeholder="Enter Department Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">Type of
                                                        Scheme
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input type="text" name="" id="" className='form-control'
                                                            value={formData.type} readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputScheme" className="col-sm-4 col-form-label">Scheme
                                                        Name
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input type="text" className="form-control" id="name"
                                                            name='name'

                                                            value={formData.name}
                                                            readOnly
                                                            placeholder="Enter Scheme Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputGrant" className="col-sm-4 col-form-label">Grant
                                                        Code
                                                        <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <input type="text" className="form-control" id="grant_code"
                                                            value={formData.grant_code}
                                                            readOnly
                                                            name='grant_code'
                                                            placeholder="Enter Grant Code" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputFinancial"
                                                        className="col-sm-4 col-form-label">
                                                        Financial Year
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input type="text" name="" id="" className='form-control'
                                                            value={formData.financial_year} readOnly />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputSchemee" className="col-sm-4 col-form-label">Scheme
                                                        Code <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <input type="text" className="form-control" id="code"
                                                            name='code'
                                                            value={formData.code}
                                                            readOnly

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">Bank Account
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control " name='account_id'
                                                            required
                                                            // readOnly={true}
                                                            disabled
                                                        // onChange={handleSchemFormData}
                                                        >
                                                            <option value="">---Select---</option>
                                                            {
                                                                accountList.map(e => {
                                                                    return <option value={e.id}
                                                                        selected={formData.account_id == e.id ? true : false}
                                                                    >{e.account_name} [A/c No: {e.account_no}]</option>
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">Activity wise Budgeting
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control "
                                                            id="activity_wise" name='is_activity_active'
                                                            // readOnly
                                                            disabled
                                                            required>
                                                            <option value="">---Select---</option>
                                                            <option value="1" selected={formData.is_activity_active == 1 ? true : false}>YES</option>
                                                            <option value="0" selected={formData.is_activity_active == 0 ? true : false}>NO</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {formData.is_activity_active == "1" && <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">Monitor/Control Level
                                                        <span className="text-danger">*</span> :</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control"
                                                            id="activity_wise" name='monitoring_level'
                                                            required
                                                            value={formData.monitoring_level} disabled={true}>
                                                            <option value="">---Select---</option>
                                                            {schemeFlag.map((option) => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className="col-sm-12 p-2">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="input-group">
                                                            <label className="input-group-btn my-0">
                                                                Upload Scheme Document
                                                            </label>
                                                            {
                                                                formData.attachments.map((att, i) => {
                                                                    return (<a href={att.file_url}
                                                                        target='_blank'>Attachment {i + 1}</a>);
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label for="inputType" className="col-sm-4 col-form-label">Change Payment Flow
                                                        <span className="text-danger">*</span>:</label>
                                                    <div className="col-sm-8">
                                                        <select className="form-control"
                                                            id="activity_wise" name='change_reimbursement_payment_flow'
                                                            required
                                                            value={formData.change_reimbursement_payment_flow}>
                                                            <option value="">---Select---</option>
                                                            <option value="1">YES</option>
                                                            <option value="0">NO</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            {formData.change_reimbursement_payment_flow == "1" && <div className="col-sm-6 p-2">
                                                <div className="form-group row">
                                                    <label htmlFor="inputType" className="col-sm-4 col-form-label">
                                                        Payment Levels <span className="text-danger">*</span> :
                                                    </label>
                                                    <div className="col-sm-8">
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                type="checkbox"
                                                                id="T1"
                                                                name="reimbursement_payment_levels"
                                                                value="T1"
                                                                checked={formData.reimbursement_payment_levels.includes('T1')}
                                                                className="form-check-input"
                                                            />
                                                            <label className="form-check-label" htmlFor="T1">
                                                                T1
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                type="checkbox"
                                                                id="T2"
                                                                name="reimbursement_payment_levels"
                                                                value="T2"
                                                                checked={formData.reimbursement_payment_levels.includes('T2')}
                                                                className="form-check-input"
                                                            />
                                                            <label className="form-check-label" htmlFor="T2">
                                                                T2
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-check-inline">
                                                            <input
                                                                type="checkbox"
                                                                id="T3"
                                                                name="reimbursement_payment_levels"
                                                                value="T3"
                                                                checked={formData.reimbursement_payment_levels.includes('T3')}
                                                                className="form-check-input"
                                                            />
                                                            <label className="form-check-label" htmlFor="T3">
                                                                T3
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            {/* total_provisional_budget */}
                                            <div className="col-sm-6 p-2">
                                                <div className="border mt-3">
                                                    <div className="p-1">
                                                        <p><span>Total Provisional Budget </span>: <span
                                                            className='scheme_budget'>{total_provisional_budget()}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 p-2">
                                                <div className="border mt-3">
                                                    <div className="p-1">
                                                        <p><span>Total Released Budget </span>: <span
                                                            className='scheme_budget'>{total_scheme_budget()}</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                formData.sub_head_list.map((subHead, i) => {
                                                    return (
                                                        <>
                                                            <div className='container-fluid'>
                                                                <div className='row eachsubheadBlock'>
                                                                    <div className="col-sm-6 p-2">
                                                                        <div className="form-group row">
                                                                            <label for="inputSub"
                                                                                className="col-sm-4 col-form-label">Sub
                                                                                Head Name {i + 1}
                                                                                <span className="text-danger">*</span> :
                                                                            </label>
                                                                            <div className="col-sm-8">
                                                                                <input type="text"
                                                                                    className="form-control"
                                                                                    id="name"
                                                                                    value={subHead.name}
                                                                                    readOnly
                                                                                    placeholder="Enter Sub Head Name" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 p-2">
                                                                        <div className="form-group row">
                                                                            <label for="inputFinancials"
                                                                                className="col-sm-4 col-form-label">
                                                                                Budget Date {i + 1}
                                                                                <span className="text-danger">*</span> :</label>
                                                                            <div className="col-sm-8 ">
                                                                                <Form.Control
                                                                                    type="date"
                                                                                    name="budget_date"
                                                                                    value={subHead.budget_date}
                                                                                    readOnly
                                                                                />

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 p-2">
                                                                        <div className="form-group row">
                                                                            <label for="inputHead"
                                                                                className="col-sm-4 col-form-label">Sub
                                                                                Head Code {i + 1}
                                                                                <span className="text-danger">*</span> :</label>
                                                                            <div className="col-sm-8">
                                                                                <input type="text"
                                                                                    required
                                                                                    className="form-control"
                                                                                    id="code"
                                                                                    value={subHead.code}
                                                                                    readOnly
                                                                                    placeholder="Enter Sub Head Code" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 p-2">
                                                                        {/* <div className="form-group row">
                                                                            <label for="inputBudget"
                                                                                className="col-sm-4 col-form-label">Budget {i + 1}
                                                                                <span className="text-danger">*</span> :</label>
                                                                            <div className="col-sm-8">
                                                                                <input type="number"
                                                                                    className="form-control"
                                                                                    id="budget"
                                                                                    required
                                                                                    value={subHead.budget}
                                                                                    readOnly
                                                                                    placeholder="₹ Enter Budget Amount" />
                                                                            </div>
                                                                        </div> */}
                                                                    </div>
                                                                    <div className="col-sm-6 p-2">
                                                                        <div className="form-group row">
                                                                            <label for="inputBudget"
                                                                                className="col-sm-4 col-form-label">Provisional
                                                                                Budget {i + 1}
                                                                                <span className="text-danger">*</span> :</label>
                                                                            <div className="col-sm-8">
                                                                                <input type="number"
                                                                                    className="form-control"
                                                                                    id="provisional_budget"
                                                                                    required
                                                                                    value={subHead?.default_budget?.provisional_budget}
                                                                                    readOnly
                                                                                    placeholder="₹ Enter Provisional Budget Amount" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 p-2">
                                                                        <div className="form-group row">
                                                                            <label for="inputBudget"
                                                                                className="col-sm-4 col-form-label">Released Budget {i + 1}
                                                                                <span className="text-danger">*</span> :</label>
                                                                            <div className="col-sm-8">
                                                                                <input type="number"
                                                                                    className="form-control"
                                                                                    id="budget"
                                                                                    required
                                                                                    value={subHead?.default_budget?.budget}
                                                                                    readOnly
                                                                                    placeholder="₹ Enter Budget Amount" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {formData.is_activity_active == "1" && formData.monitoring_level == '1' && <div className="row">
                                                                        <table className="table table-bordered">
                                                                            <thead className="text-center-custom">
                                                                                <tr>
                                                                                    <th scope="col" width="150px">Activity</th>
                                                                                    <th scope="col" width="150px">Subactivity</th>
                                                                                    <th scope="col" width="300px">Opening Provision</th>
                                                                                    <th scope="col" width="300px">Opening Release</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="text-center-custom">
                                                                                {subHead?.default_budget && subHead?.default_budget.activities.map((activityData, index) => (
                                                                                    <tr key={index}>
                                                                                        <td>
                                                                                            <select
                                                                                                className="form-control"
                                                                                                name='activity_id'
                                                                                                value={activityData.subhead_activity_id} disabled={true}>
                                                                                                <option value="">---Select---</option>
                                                                                                {activity.map(e => (
                                                                                                    <option key={e.id} value={e.id}>{e.activity}</option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </td>
                                                                                        <td>
                                                                                            <select
                                                                                                className="form-control"
                                                                                                name='subactivity_id'
                                                                                                value={activityData.subhead_subactivity_id}
                                                                                                disabled={true}>
                                                                                                <option value="">---Select---</option>
                                                                                                {findSubActivitiesById(activityData.subhead_activity_id).map(e => (
                                                                                                    <option key={e.id} value={e.id}>{e.subactivity}</option>
                                                                                                ))}
                                                                                            </select>
                                                                                        </td>
                                                                                        <td>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                name="provisional_budget"
                                                                                                value={activityData.provisional_budget || ''}
                                                                                                disabled={true}

                                                                                            />
                                                                                        </td>
                                                                                        <td>
                                                                                            <input
                                                                                                type="number"
                                                                                                className="form-control"
                                                                                                name="budget"
                                                                                                value={activityData.budget || ''}
                                                                                                disabled={true}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>}
                                                                </div>
                                                            </div>
                                                        </>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
}

export default ViewScheme
