// import { useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { updateSchemeBankAccountApproval } from '../../../helper/Api';

// import './sanctionOrderModal.css';

const AccountPreview = ({ showPreview, setShowPreview, formData, handleSubmit, department }) => {
    const { authData } = useSelector((state) => state.authData);
    const isReadOnly = showPreview;
    const handleClose = () => {
        setShowPreview(false);
    }
    const onApprove = async (status) => {
        formData.l2_approval = authData.user.user_type == 'l2' ? status : formData.l2_approval;
        formData.l3_approval = authData.user.user_type == 'l3' ? status : formData.l3_approval;
        let approvalData = {
            l2_approval: formData.l2_approval,
            l3_approval: formData.l3_approval,
            l2_remark: formData.l2_remark,
            l3_remark: formData.l3_remark
        }
        const { data } = await updateSchemeBankAccountApproval(approvalData, formData.id);
        if (data.status) {
            setShowPreview(false);
        }
    }
    return (
        <div>
            <Modal size="lg" show={showPreview} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{formData?.id ? 'Update Account Details' : 'Add Account Details'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Container>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="accountName">
                                        <Form.Label>Account Name <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Account Name"
                                            name='account_name'
                                            value={formData.account_name}
                                            required
                                            readOnly={isReadOnly}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="bankName">
                                        <Form.Label>Bank Name <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Bank Name"
                                            name='bank_name'
                                            value={formData.bank_name}
                                            required
                                            readOnly={isReadOnly}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="branchName">
                                        <Form.Label>Bank Branch <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Bank Branch"
                                            name='branch_name'
                                            value={formData.branch_name}
                                            required
                                            readOnly={isReadOnly}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="accountType">
                                        <Form.Label>Account Type <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            as="select"
                                            name='account_type'
                                            required
                                            value={formData.account_type}
                                            disabled={isReadOnly}
                                        >
                                            <option value="">Select Account Type</option>
                                            <option value="saving">Saving Account</option>
                                            <option value="current">Current Account</option>
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="accountNo">
                                        <Form.Label>Account No <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter Account No"
                                            name='account_no'
                                            value={formData.account_no}
                                            required
                                            readOnly={isReadOnly}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="accountBalance">
                                        <Form.Label>Account Balance <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Account Balance"
                                            name='account_balance'
                                            value={formData.account_balance}
                                            required
                                            readOnly={isReadOnly}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="ifscCode">
                                        <Form.Label>IFSC Code <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            required
                                            maxLength={11}
                                            name='ifsc_code'
                                            value={formData.ifsc_code}
                                            placeholder="Enter IFSC Code"
                                            readOnly={isReadOnly}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="balanceDate">
                                        <Form.Label>Closing Balance Date <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="Enter Balance Date"
                                            name='balance_date'
                                            value={formData.balance_date}
                                            required
                                            readOnly={isReadOnly}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="interest">
                                        <Form.Label>Interest <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter Interest"
                                            name='interest'
                                            value={formData.interest}
                                            readOnly={isReadOnly}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="interestDate">
                                        <Form.Label>Interest Date <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="date"
                                            placeholder="Enter Interest Date"
                                            name='interest_date'
                                            value={formData.interest_date}
                                            required
                                            readOnly={isReadOnly}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="quarter">
                                        <Form.Label>Quarter <span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Enter Quarter"
                                            name='quarter'
                                            value={formData.quarter}
                                            required
                                            readOnly={isReadOnly}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-3" controlId="department_id">
                                        <Form.Label>Department<span className='text-danger'>*</span></Form.Label>
                                        <Form.Control
                                            as="select"
                                            name='department_id'
                                            required
                                            value={formData.department_id}
                                            disabled={isReadOnly}
                                        >
                                            <option value="">Select Department Type</option>
                                            {
                                                department.map((dept) => {
                                                    return (
                                                        <option value={dept.id}>{dept.name}</option>
                                                    );
                                                })
                                            }
                                        </Form.Control>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {
                                authData.user.user_type == 'l1' ?
                                    <>
                                        <label>L1 Remark</label>
                                        <textarea className='form-control' name="l1_remark" readOnly={isReadOnly} value={formData.l1_remark}></textarea>
                                    </>
                                    : ''
                            }
                            <br></br>
                            {
                                (formData.l2_approval !== 1 || formData.l3_approval !== 1) && authData.user.user_type === 'l3' ?
                                    <Button variant="primary" type="submit">
                                        {formData?.id ? 'Update Account' : 'Add Account'}
                                    </Button>
                                    : ''
                            }
                            {
                                authData.user.user_type === 'l2' && formData.l2_approval == null ?
                                    <>
                                        <label>L2 Remark</label>
                                        <textarea className='form-control' name="l2_remark" readOnly={isReadOnly} value={formData.l2_remark}></textarea>
                                        {!isReadOnly &&
                                            <>
                                                <button type="button" className="btn btn-danger w-30 mt-2" onClick={() => onApprove(0)}>Reject</button>
                                                <button type="button" className="btn btn-primary w-30 mt-2" style={{ marginLeft: '10px' }} onClick={() => onApprove(1)}>Approve</button>
                                            </>
                                        }
                                    </>
                                    : ''
                            }
                            {
                                authData.user.user_type === 'l3' && formData.l3_approval == null ?
                                    <>
                                        <label>L2 Remark</label>
                                        <textarea className='form-control' name="l3_remark" readOnly={isReadOnly} value={formData.l3_remark}></textarea>
                                        {!isReadOnly &&
                                            <>
                                                <button type="button" className="btn btn-danger w-30 mt-2" onClick={() => onApprove(0)}>Reject</button>
                                                <button type="button" className="btn btn-primary w-30 mt-2" style={{ marginLeft: '10px' }} onClick={() => onApprove(1)}>Approve</button>
                                            </>
                                        }
                                    </>
                                    : ''
                            }
                        </Container>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleClose}>
                        CLOSE
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}
export default AccountPreview
