import React, { useState, useRef, useEffect, useMemo } from "react";
import "./DashboardBox.css";
import { AddCommasToAmount } from "../../helper/Utils";
import { Col, Container, Row } from "react-bootstrap";
import DashboardBarGraph from "../DashboardBarGraph/DashboardBarGraph";

import {
  get_beneficiary_reimbursement,
  get_departments,
  get_financial_year,
  get_fund_invoices,
  get_payment_invoices,
  get_schemes,
  get_single_beneficiary_reimbursement,
  getAccomulateDeduction,
  getAllocateBudgetList,
  getReconcilation,
  getSchemeAccountBalances,
  getUtilizedBudget,
} from "../../helper/Api";
import ResponsiveDataTable from "../ResponsiveDataTable/ResponsiveDataTable";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import BankBalanceOverlay from "../overlays/BankBalanceOverlay";
import { UtilizedBudgetOverlayActivity } from "./DashboardComponents/UtilizedBudgetOverlayActivity";

const DashboardBox = ({ data, showPopupFun, listReload, dividerNumber }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isUtilized, setUtilized] = useState(false);
  const [schemaBalance, setSchemaBalance] = useState(false);
  const [approvals, setApprovals] = useState(false);
  const [utlizedBudget, setUtilizedBudget] = useState(false);
  const [accumulated, setAccumulated] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const contentRef = useRef(null);
  const contentRefUtlized = useRef(null);
  const schemaBalanceRef = useRef(null);
  const approvalRef = useRef(null);
  const utilizedBudgetRef = useRef(null);
  const accumulatedRef = useRef(null);
  const [overlay, setOverlay] = useState(true);
  const [schemebalance, setSchemebalance] = useState(true);
  const [budgetoverlay, setbudgetoverlay] = useState(true);
  const [utilizedBudgetData, setUtilizedBudgetData] = useState([]);
  const [bankBalanceOverlay, setBankBalanceOverlay] = useState(true);
  const monthArrayValues = [
    {
      month: "April",
      monthNumber: "04"
    },
    {
      month: "May",
      monthNumber: "05"
    },
    {
      month: "June",
      monthNumber: "06"
    },
    {
      month: "July",
      monthNumber: "07"
    },
    {
      month: "August",
      monthNumber: "08"
    },
    {
      month: "September",
      monthNumber: "09"
    },
    {
      month: "October",
      monthNumber: "10"
    },
    {
      month: "November",
      monthNumber: "11"
    },
    {
      month: "December",
      monthNumber: "12"
    },
    {
      month: "January",
      monthNumber: "01"
    },
    {
      month: "February",
      monthNumber: "02"
    },
    {
      month: "March",
      monthNumber: "03"
    }
  ];
  const [activeUtilizedBudgetChild, setActiveUtilizedBudgetChild] = useState(
    {}
  );
  useEffect(() => {
    if (utilizedBudgetData?.subheads?.length > 0) {
      const initialState = utilizedBudgetData?.subheads?.reduce(
        (acc, val, index) => {
          acc[val.id] = index == 0 ? "1" : null; // Ensure first subhead is open
          return acc;
        },
        {}
      );

      setActiveUtilizedBudgetChild(initialState);
      console.log("Initial State Set:", initialState); // Debugging log
    }
  }, [utilizedBudgetData]);

  const handleButtonClickBudgetChild = (cardId, buttonType) => {
    setActiveUtilizedBudgetChild((prev) => ({
      ...prev,
      [cardId]: prev[cardId] === buttonType ? null : buttonType,
    }));
  };

  const [overlayDropDown, setOverlayDropDown] = useState(false);
  const [bankOverlayDropDown, setBankOverlayDropDown] = useState(false);
  const [approvalsOverlay, setApprovalsOverlay] = useState(false);
  const [accumulatedDeductionOverlay, setAccumulatedDeductionOverlay] =
    useState(false);
  const [reconciliationOverlay, setReconciliationOverlay] = useState(false);
  const [tableAccordian, setTableAccordian] = useState(false);
  const [paymentAccordian, setpaymentAccordion] = useState(false);
  const [dbtNachApproval, setDbtNachApproval] = useState(false);
  const [dbtNachPayment, setDbtNachPayment] = useState(false);
  const [childAllo, setChildAllo] = useState(false);
  const [tableChildAccordian, setTableChildAccordian] = useState(false);
  const [tableSubChildAccordian, setTableSubChildAccordian] = useState(false);
  const [tableSubGrandChildAccordian, setTableSubGrandChildAccordian] =
    useState(false);

  const [utlizedMonth, setUtilizedMonth] = useState(null);

  const [expandedAccordion, setExpandedAccordion] = useState([]);

  const storedGlobalDateId = localStorage.getItem("globalDateId");

  const [fy_list, set_fy_list] = useState([]);
  const [schemes, setSchemesList] = useState([]);

  const [filteredSchemes, setFilteredSchemes] = useState([]);
  const [listview, setListview] = useState(true);

  // useEffect(() => {
  //   console.log("schemes type", typeof schemes[0].sub_head_list[0].activity_budget[0].prev_balance    );
  // },[schemes])

  // const {invoices}=useSelector((state)=>state.invoiceData);
  const { authData } = useSelector((state) => state.authData);
  const userDetails = sessionStorage.getItem("userDetails");
  const user = JSON.parse(userDetails);

  const [activeCardChild, setActiveCardChild] = useState({});
  const [activeScBalanceCardChild, setActiveScBalanceCardChild] = useState({});

  const [subheadAccording, setSubheadAccording] = useState({});

  const toggleSubheadAccordion = (id) => {
    setSubheadAccording((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const [subheadAccordingdbtAcd, setSubheadAccordingdbtAcd] = useState({});
  const toggleAccordionacd = (index) => {
    setSubheadAccordingdbtAcd((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle specific accordion
    }));
  };
  const [vendorPaymentData, setVendorPaymentData] = useState([]);
  const [invoices, setInvoice] = useState([]);

  const [dbtData, setDbtData] = useState([]);
  const [childAllocationData, setChildAllocationData] = useState([]);

  const [subheadAccordingdbt, setSubheadAccordingdbt] = useState({});

  // Toggle function
  // ==========================================bank balance overlayData=================================================

  const [bankBalanceData, setBankBalanceData] = useState([]);

  const fetchBankBalance = (data) => {
    setBankBalanceData(data);
  }
  const totalAccountBalance = bankBalanceData.reduce((acc, val) => {
    return acc + Number(val.account_balance);
  }, 0);
  const totalQuartlyInterest = bankBalanceData?.reduce((acc, val) => {
    return acc + Number(val?.interest);
  }, 0);

  useEffect(()=>{
    console.log("bank data =", bankBalanceData);
    console.log("bank data totalQuartlyInterest =", totalQuartlyInterest);
  },[bankBalanceData])

  // ===========================================================================================

  useEffect(() => {
    if (dbtData.length > 0) {
      const initialState = dbtData?.reduce((acc, _, index) => {
        acc[index] = true; // Set `true` to collapse all rows initially
        return acc;
      }, {});
      setSubheadAccordingdbt(initialState);
    }
  }, [dbtData]);

  const toggleSubheadAccording = (index) => {
    setSubheadAccordingdbt((prev) => ({
      ...prev,
      [index]: !prev[index], // Toggle only for the specific row
    }));
  };

  // =========================total Budget Allocation=======================

  const lengthchildAllocationData = childAllocationData.length;

  const provisonReduct = childAllocationData?.map(
    (val, ind) => val.total_scheme_provisional_allocation
  );
  const provisonReleased = childAllocationData?.map(
    (val, ind) => val.total_scheme_released_allocation
  );

  const totalprovisonReduct = provisonReduct?.reduce(
    (sum, num) => sum + Number(num),
    0
  );
  const totalprovisonReleased = provisonReleased?.reduce(
    (sum, num) => sum + Number(num),
    0
  );

  const fetchBudgets = async (payload) => {
    try {
      const { data } = await getAllocateBudgetList(payload);
      const filterdata = data.result.filter(
        (val, ind) => val?.l2_status == null && val?.l3_status == null
      );
      setChildAllocationData(filterdata);
    } catch (error) {
      setChildAllocationData([]);
      console.log(error);
    }
  };
  useEffect(() => {
    fetchBudgets({
      pending: true,
      department_id: authData.user.department_id,
    });
  }, []);

  // ==================================dbt/nach=====================================

  const fetchChallans = async () => {
    try {
      setDbtData([]);
      const resp = await get_beneficiary_reimbursement({
        // from_date: fromDate || '',
        // to_date: toDate || '',
        // search_str: filterData?.searchStr || '',
        // dateColumn: filterData?.dateColumn || '',
        department_id: user.user.department_id || "",
        // listType: listType == 'dbt_payment' ? '' : listType,
        listType: "dbt_payment",
        // listType: 'approval'
      });
      if (resp.data.status) {
        // const filteredData = resp.data.result.filter(
        //   (val, inc) =>
        //     val.l2_payment_approval == 0 && val?.l3_payment_approval == 0
        // );
        setDbtData(resp.data.result);
      }
    } catch (error) {
      setDbtData([]);
    }
  };

  // ===================================== reconciliation ====================================

  const [accumulatedDeduction, setAccumulatedDeductoin] = useState([]);
  const [accumulatedDeductionDbt, setAccumulatedDeductoinDbt] = useState([]);
  const totalReimbursementOrderValue = accumulatedDeductionDbt?.reduce(
    (acc, val) => acc + Number(val?.reimbursement_order_value || 0),
    0
  );

    const [openIndex, setOpenIndex] = useState(null);
  
    const toggleAccordions = (index) => {
      setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    };

  const totalTdsIts = accumulatedDeduction?.reduce(
    (sum, val) => sum + Number(val?.tds_it_amount || 0),
    0
  );
  const totalGst = accumulatedDeduction?.reduce(
    (sum, val) =>
      sum +
      Number(val?.c_gst_amount || 0) +
      Number(val?.i_gst_amount || 0) +
      Number(val?.s_gst_amount || 0),
    0
  );
  const totalRoyaltys = accumulatedDeduction?.reduce(
    (sum, val) => sum + Number(val?.royalty_amount || 0),
    0
  );
  const totalLabour = accumulatedDeduction?.reduce(
    (sum, val) => sum + Number(val?.labour_amount || 0),
    0
  );
  const totalOtherDeduction = accumulatedDeduction?.reduce(
    (sum, val) => sum + Number(val?.other_deduction || 0),
    0
  );
  const totalOverallAccDeduction =
    totalTdsIts + totalGst + totalRoyaltys + totalLabour + totalOtherDeduction;

  const [settlementsInProgress, setSettlementsInProgress] = useState([]);
  const [withHeldAndPenalty, setWithHeldAndPenalty] = useState([]);

  const totalWithheld = withHeldAndPenalty?.reduce(
    (sum, val) => sum + Number(val?.withheld_amount || 0),
    0
  );
  const totalPenalty = withHeldAndPenalty?.reduce(
    (sum, val) => sum + Number(val?.penalty_amount || 0),
    0
  );
  const totalOverall = totalWithheld + totalPenalty;

  const fetchgetReconcilation = async (id) => {
    try {
      const res = await getReconcilation(id);

      console.log(res.data.result);
      setAccumulatedDeductoin(res?.data?.result?.accumulatedDeduction?.vendor);
      setAccumulatedDeductoinDbt(res?.data?.result?.accumulatedDeduction?.DBT);
      setSettlementsInProgress(res?.data?.result?.settlementsInProgress);
      setWithHeldAndPenalty(res?.data?.result?.withHeldAndPenalty);
    } catch (err) {
      console.log(err.message);
    }
  };

  useEffect(() => {
    fetchChallans();
    fetchgetReconcilation(storedGlobalDateId);
  }, []);

  const fetchVendorPaymentInvoices = async () => {
    // dispatch(GET_P_INVOICES_START());
    try {
      const invoices_response = await get_payment_invoices({
        user_type: authData?.user?.user_type?.toUpperCase() || "L1",
        from_date: null || "",
        to_date: null || "",
        // isAll: isChecked,
        // dateColumn: dateColumn || '',
        // search_str: searchStr || '',
        department_id: authData?.user?.department_id || "",
      });
      // console.log(invoices_response)
      if (invoices_response.data.status) {
        // dispatch(GET_P_INVOICES_SUCCESS(invoices_response.data.list))
        // const approvedInvoices = invoices_response.data.list.filter(
        //   (val) => val?.approval_status != 1 && val?.l3_approval_status != 1
        // );
        setVendorPaymentData(invoices_response.data.list);

        // console.log('data', data);
        // let amt = 0;
        // data.forEach(e => {
        //     amt += Number(e.payable_amount);
        // })
        // console.log('amt', amt);
      } else {
        // dispatch(GET_P_INVOICES_FAIL(invoices_response.data.message))
      }
    } catch (error) {
      // dispatch(GET_P_INVOICES_FAIL('something went wrong'))
    }
  };

  useEffect(() => {
    fetchVendorPaymentInvoices();
  }, []);

  // Get the count
  const vendorApprovalTotal = invoices.length;
  const vendorPaymentTotal = vendorPaymentData.length;

  const vendorApprovalReduce = invoices?.map((val, ind) => val.sanction_amount);
  const vendorPaymentReduce = vendorPaymentData?.map(
    (val, ind) => val.payable_amount
  );
  const totalVendorApprovalReduce = vendorApprovalReduce?.reduce(
    (sum, num) => sum + Number(num),
    0
  );
  const totalVendorPaymentReduce = vendorPaymentReduce?.reduce(
    (sum, num) => sum + Number(num),
    0
  );

  useEffect(() => {
    console.log(utlizedMonth);
  }, [utlizedMonth]);

  const formatNumber = (number, style = "international") => {
    number = parseFloat(number).toFixed(2); // Ensure input is a float with two decimal places

    if (style === "indian") {
      return number.replace(/\B(?=(\d{3})(\d{2})*(?!\d))/g, ",");
    } else {
      return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  };

  useEffect(() => {
    if (schemes && schemes.length > 0) {
      const initialState = schemes?.reduce((acc, val) => {
        acc[val.id] = "1"; // Set "Sub Head" as default active
        return acc;
      }, {});
      setActiveCardChild(initialState);
    }
  }, [schemes]); // Runs whenever `data` changes

  const handleButtonClick = (cardId, buttonType) => {
    setActiveCardChild((prev) => ({
      ...prev,
      [cardId]: prev[cardId] === buttonType ? null : buttonType,
    }));
  };

  useEffect(() => {
    if (schemes && schemes.length > 0) {
      const initialState = schemes?.reduce((acc, val) => {
        acc[val.id] = "4"; // Set "Sub Head" as default active
        return acc;
      }, {});
      setActiveScBalanceCardChild(initialState);
    }
  }, [schemes]); // Runs whenever `data` changes

  const handleScBalanceButtonClick = (cardId, buttonType) => {
    setActiveScBalanceCardChild((prev) => ({
      ...prev,
      [cardId]: prev[cardId] === buttonType ? null : buttonType,
    }));
  };

  const addElipsis = (str, limit, elip = "...") => {
    return str.length > limit ? str.substring(0, limit) + `${elip}` : str;
  };

  const fetchInvoices = async () => {
    // dispatch(GET_INVOICES_START());
    try {
      const invoices_response = await get_fund_invoices({
        user_type: authData?.user?.user_type?.toUpperCase() || "L1",
        from_date: null || "",
        to_date: null || "",
        dateColumn: null || "",
        search_str: null || "",
        isAll: false,
      });
      // console.log(invoices_response)
      if (invoices_response.data.status) {
        // dispatch(GET_INVOICES_SUCCESS(invoices_response.data.list))
        // return console.log(val?.approval_status == 0 ? val?.l3_approval_status == 2 ? "-" : "Pending" : val?.approval_status == 1 ? "Approved" : "Rejected")
        // setInvoice(invoices_response.data.list);
        // const approvedInvoicesdata = invoices_response.data.list.filter(val => val?.approval_status != 1 && val?.l3_approval_status != 1)
        const approvedInvoicesdata = invoices_response.data.list.filter(
          (val) => val?.approval_status != 2 && val?.l3_approval_status != 2
        );
        setInvoice(approvedInvoicesdata);
      } else {
        // dispatch(GET_INVOICES_FAIL(invoices_response.data.message))
      }
    } catch (error) {
      // dispatch(GET_INVOICES_FAIL('something went wrong'))
    }
  };
  useEffect(() => {
    fetchInvoices();
  }, []);
  // useEffect(() => {
  //     console.log("vendor pending for approval  = ", invoices)
  // }, [invoices])

  const fetchSchemes = async () => {
    try {
      const schemes = await get_schemes({
        search_str: null || "",
        fy_id: storedGlobalDateId || "",
        department_id: user?.user?.department_id || "",
      });
      if (schemes.data.status) {
        if (schemes.data.schemes) {
          setSchemesList(schemes.data.schemes);
        }
      } else {
        // dispatch(GET_SCHEME_FAIL(schemes.data.message));
      }
    } catch (error) {
      // dispatch(GET_SCHEME_FAIL('something went wrong'));
    }
  };

  useEffect(() => {
    fetchSchemes();
  }, []);

  useEffect(() => {
    if (schemes?.length) {
      const filtered = schemes.filter(
        (val) => val?.financial_year_id <= storedGlobalDateId
      );
      if (filtered.length === 0) {
        setFilteredSchemes("No data found");
      } else {
        setFilteredSchemes(filtered);
      }
    }
  }, [storedGlobalDateId, schemes]); // added schemes dependency

  const handleYearDropdown = (e) => {
    console.log(e.target.value);
  };

  const get_balance_interest_date = async () => {
    try {
      const fy = await get_financial_year();
      if (fy.data.status) {
        set_fy_list(fy.data.list);
      }
    } catch (error) {
      console.log(error.name);
    }
  };

  useEffect(() => {
    get_balance_interest_date();
  }, [storedGlobalDateId]);

  const navigate = useNavigate();

  let columnss = useMemo(() => [
    { title: "Name" },
    {
      title: "Account Number",
      cell: (row) => (
        <div
          style={{
            border: "1px solid #ddd",
            padding: "10px",
            borderRadius: "8px",
            backgroundColor: "#f9f9f9",
            textAlign: "left",
          }}
        >
          <div>
            <strong>Age:</strong> age
          </div>
          <div>
            <strong>Email:</strong> email
          </div>
          <div>
            <strong>Address:</strong> address
          </div>
        </div>
      ),
      grow: 2,
    },
    { title: "Balance" },
    { title: "Interest" },
  ]);

  const switchHeight = (value) => {
    console.log('m here with', value);
    setSubheadAccording((prev) => !prev);
  }

  useEffect(() => {
    setOverlay(true);
    setApprovalsOverlay(true);
    setAccumulatedDeductionOverlay(true);
    setReconciliationOverlay(true);
  }, []);

  const [accumulateHeight, setAccumulatedHeight] = useState(false);
  const [reconHeight, setReconHeight] = useState(false);

  const toggleExpand = (value) => {
    if (value === "schemeBudget") {
      setIsExpanded((prev) => !prev);
    } else if (value === "utilizedBudget") {
      setUtilized((prev) => !prev);
    } else if (value === "schemaBalance") {
      setSchemaBalance((prev) => !prev);
    } else if (value === "utlizedBudgetn") {
      setUtilizedBudget((prev) => !prev);
    } else if (value === "approvals") {
      setApprovals((prev) => !prev);
    } else if (value === "accumulated") {
      setAccumulatedHeight((prev) => !prev);
    } else if (value === "recon") {
      setReconHeight((prev) => !prev);
    }
  };

  const openBankBalanceOverlay = (value) => {
    setBankBalanceOverlay(value);
  };

  // =========================responsivetable data ======================


  const date = [
    {
      id: "1",
      code: "2",
      department: "Scheme",
      total_provisional_budget: "200000",
      total_budget: "200000",
      prevYearBalance: "200000",
      utilised_budget: "200000",
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion((prev) =>
      isExpanded ? [...prev, panel] : prev.filter((p) => p !== panel)
    );
  };

  const [activeKeys, setActiveKeys] = useState([
    "0",
    "3",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "17",
    "20",
  ]); // Initially open accordions

  const toggleAccordion = (key) => {
    setActiveKeys(
      (prevKeys) =>
        prevKeys.includes(key)
          ? prevKeys.filter((k) => k !== key) // Remove key if it's already open
          : [...prevKeys, key] // Add key if it's closed
    );
  };

  const getUtilizedBudgets = async (fyId) => {
    try {
      const utilizedBudget = await getUtilizedBudget(fyId, utlizedMonth);
      if (utilizedBudget.data.status) {
        setUtilizedBudgetData(utilizedBudget.data.result);
      }
    } catch (error) {
      console.log(error.name);
    }
  };
  const [accumulationVendorData, setAccumulationVendorData] = useState([]);
  const [accumulationDBTData, setAccumulationDBTVendorData] = useState([]);

  const totalDeductions = accumulationDBTData?.reduce(
    (acc, item) => acc + Number(item.deduction_amount),
    0
  );
  const totalGrossAmount = accumulationDBTData?.reduce(
    (acc, item) => acc + Number(item.reimbursement_order_value),
    0
  );

  // =============total data for accumulated vendor==================

  const accDedutionTdsItReduct = accumulationVendorData?.map(
    (val, ind) => val.tds_it_amount
  );
  const accDedutionRoyaltyAmountReduct = accumulationVendorData?.map(
    (val, ind) => val.royalty_amount
  );
  const acclaboutCess = accumulationVendorData?.map(
    (val, ind) => val.labour_amount
  );
  const accOther = accumulationVendorData?.map((val, ind) =>
    val.other_deduction == null ? 0 : val?.other_deduction
  );
  const totalSanctionAmountDbt = dbtData?.reduce(
    (sum, val) => sum + Number(val?.reimbursement_order_value || 0),
    0
  );
  const dbtLength = dbtData.length;

  const accTotalTdsGst = accumulationVendorData?.map(
    (val, ind) =>
      Number(val?.c_gst_amount) +
      Number(val?.i_gst_amount) +
      Number(val?.s_gst_amount)
  );

  const totalTdsIt = accDedutionTdsItReduct?.reduce(
    (sum, num) => sum + Number(num),
    0
  );
  const totalRoyalty = accDedutionRoyaltyAmountReduct?.reduce(
    (sum, num) => sum + Number(num),
    0
  );
  const totalacclaboutCess = acclaboutCess?.reduce(
    (sum, num) => sum + Number(num),
    0
  );
  const totalaccOther = accOther?.reduce((sum, num) => sum + Number(num), 0);
  const totalaccTotalTdsGst = accTotalTdsGst?.reduce(
    (sum, num) => sum + Number(num),
    0
  );

  const getAccomulateDeductions = async (fyId) => {
    try {
      const getAccomulate = await getAccomulateDeduction(fyId);
      if (getAccomulate.data.status) {
        setAccumulationVendorData(getAccomulate.data.result[0]);
        setAccumulationDBTVendorData(getAccomulate.data.result[1]);
      }
    } catch (error) {
      console.log(error.name);
    }
  };

  useEffect(() => {
    getAccomulateDeductions(storedGlobalDateId);
  }, []);

  useEffect(() => {
    console.log("vendorAccumulatoin data vendor", accumulationVendorData);
  }, []);

  useEffect(() => {
    getUtilizedBudgets(storedGlobalDateId);
  }, [storedGlobalDateId, utlizedMonth]);

  const [childAllocationEyeData, setChildAllocationEyeData] = useState([]);
  const fetchChildEyeData = async (id) => {
    try {
      const { data } = await getAllocateBudgetList({ id });
      setChildAllocationEyeData(data?.result[0]?.budget_details);
      console.log(data?.result[0]?.budget_details);
    } catch (err) {
      console.log(err.name);
    }
  };

  const [singleBeneficiarys, setSingleBeneficiarys] = useState([]);
  const getSingleBeneficiaryReimbursements = async (id) => {
    try {
      const { data } = await get_single_beneficiary_reimbursement(id);
      setSingleBeneficiarys(data.result.beneficiaries);
      console.log(data.result.beneficiaries);
    } catch (err) {
      console.log(err.name);
    }
  };

  useEffect(() => {
    console.log(singleBeneficiarys);
  }, [singleBeneficiarys]);


  return (
    <>
      <div className=" row p-3 overflow-x-hidden">
        <div className="col-9">
          <Row>
            <Col xs={12} sm={6} md={6} lg={4}>
              <div className="dashboardStatCard ">
                <h3
                  className="bg-black-100 text-white p-2 m-0 d-flex justify-content-between align-items-center"
                  style={{ borderRadius: "10px 10px 0 0" }}
                >
                  Scheme Budget
                  <i
                    className="icon-open_in_new cursor-pointer transition-3s hover-scale-1-2"
                    onClick={() => setOverlay(false)}
                  ></i>
                </h3>

                <div
                  ref={contentRef}
                  className={`add-transition  ${isExpanded ? "p-2" : "p-0"}`}
                  style={{
                    height: isExpanded
                      ? `${contentRef.current.scrollHeight}px`
                      : "0px ",
                    overflow: "hidden",
                    transition: "height 0.3s ease",
                  }}
                >
                  <h6 className="text-black-60 fs-12px">Provisioned Budget</h6>
                  <p className="fw-600">
                    {formatNumber(
                      data.total_provisional_budget.provisional_budget,
                      "indian"
                    )}
                  </p>
                  <h6 className="text-black-60 fs-12px">Released Budget</h6>
                  <p className="fw-600">
                    {formatNumber(
                      Number(data.total_provisional_budget.released_budget),
                      "indian"
                    )}
                  </p>
                  <h6 className="text-black-60 fs-12px">Prev. Year Balance</h6>
                  <p className="fw-600">
                    {formatNumber(Number(data.prevYearBalance), "indian")}
                  </p>
                </div>

                <div className="p-2 border-top d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="fs-12px">Total Budget</h4>
                    <h4 style={{ fontSize: "16px", fontWeight: "600" }}>
                      {formatNumber(
                        (Number(data.prevYearBalance) +
                          Number(
                            data.total_provisional_budget.released_budget
                          )) /
                          dividerNumber,
                        "indian"
                      )}
                    </h4>
                  </div>
                  <div>
                    {!isExpanded ? (
                      <i
                        className="icon-add_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("schemeBudget")}
                      ></i>
                    ) : (
                      <i
                        className="icon-remove_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("schemeBudget")}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4}>
              <div className="dashboardStatCard">
                <h3
                  className="bg-black-10 p-2 m-0 d-flex justify-content-between align-items-center"
                  style={{ borderRadius: "10px 10px 0 0" }}
                >
                  Utilized Budget
                  <i
                    className="icon-open_in_new cursor-pointer transition-3s hover-scale-1-2"
                    onClick={() => setbudgetoverlay(false)}
                  ></i>
                </h3>

                <div
                  ref={contentRefUtlized}
                  className={`add-transition  ${isUtilized ? "p-2" : "p-0"}`}
                  style={{
                    height: isUtilized
                      ? `${contentRefUtlized.current.scrollHeight}px`
                      : "0px ",
                    overflow: "hidden",
                    transition: "height 0.3s ease",
                  }}
                >
                  <h6 className="text-black-60 fs-12px">Current FY</h6>
                  <p className="fw-600">
                    {formatNumber(Number(data.currenyFyUtilization), "indian")}
                  </p>
                  <h6 className="text-black-60 fs-12px">Prev. Year Balance</h6>
                  <p className="fw-600">
                    {formatNumber(Number(data.prevFyUtilization), "indian")}
                  </p>
                  <span
                    className="fw-600 py-2 d-flex justify-content-between"
                    style={{ fontSize: "16px" }}
                  >
                    For Month
                    <select
                      name="month"
                      id="month"
                      className="border-0 overflow-auto text-prime-100"
                    >
                      <option value="" selected>
                        {" "}
                        select month{" "}
                      </option>
                      <option value="january">January</option>
                      <option value="january">February</option>
                      <option value="january">March</option>
                      <option value="january">April</option>
                    </select>
                  </span>
                  <h6 className="text-black-60 mt-2 fs-12px">Current FY</h6>
                  <p className="fw-600">
                    {formatNumber(
                      Number(data.currentMonthUtilize_cfy),
                      "indian"
                    )}
                  </p>
                  <h6 className="text-black-60 fs-12px">Current Month</h6>
                  <p className="fw-600">
                    {formatNumber(
                      Number(data.currentMonthUtilize_pfy),
                      "indian"
                    )}
                  </p>
                </div>

                <div className="p-2 border-top d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="fs-12px">FY 2024-25 Utilized</h4>
                    <h4 style={{ fontSize: "16px", fontWeight: "600" }}>
                      {formatNumber(
                        Number(
                          data.currenyFyUtilization + data.prevFyUtilization
                        ) / dividerNumber,
                        "indian"
                      )}
                    </h4>
                  </div>
                  <div>
                    {!isUtilized ? (
                      <i
                        className="icon-add_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("utilizedBudget")}
                      ></i>
                    ) : (
                      <i
                        className="icon-remove_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("utilizedBudget")}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4}>
              <div className="dashboardStatCard">
                <h3
                  className="bg-black-10 p-2 m-0 d-flex justify-content-between align-items-center"
                  style={{ borderRadius: "10px 10px 0 0" }}
                >
                  Scheme Balance
                  <i
                    className="icon-open_in_new cursor-pointer transition-3s hover-scale-1-2"
                    onClick={() => setSchemebalance(false)}
                  ></i>
                </h3>

                <div
                  ref={schemaBalanceRef}
                  className={`add-transition  ${schemaBalance ? "p-2" : "p-0"}`}
                  style={{
                    height: schemaBalance
                      ? `${contentRefUtlized.current.scrollHeight}px`
                      : "0px ",
                    overflow: "hidden",
                    transition: "height 0.3s ease",
                  }}
                >
                  <h6 className="text-black-60 fs-12px">Current Fy</h6>
                  <p className="fw-600">
                    {formatNumber(
                      Number(
                        data.total_provisional_budget.released_budget -
                          data.currenyFyUtilization
                      ),
                      "indian"
                    )}
                  </p>
                  <h6 className="text-black-60 fs-12px">
                    From Prev. Year Balance
                  </h6>
                  <p className="fw-600">
                    {formatNumber(
                      Number(data.prevYearBalance - data.prevFyUtilization),
                      "indian"
                    )}
                  </p>
                </div>

                <div className="p-2 border-top d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="fs-12px">Total Balance</h4>
                    <h4 style={{ fontSize: "16px", fontWeight: "600" }}>
                      {formatNumber(
                        (Number(data.prevYearBalance) +
                          Number(
                            data.total_provisional_budget.released_budget
                          ) -
                          Number(
                            data.currenyFyUtilization + data.prevFyUtilization
                          )) /
                          dividerNumber,
                        "indian"
                      )}
                    </h4>
                  </div>
                  <div>
                    {!schemaBalance ? (
                      <i
                        className="icon-add_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("schemaBalance")}
                      ></i>
                    ) : (
                      <i
                        className="icon-remove_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("schemaBalance")}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4}>
              <div className="dashboardStatCard">
                <h3
                  className="bg-black-10 p-2 m-0 d-flex justify-content-between align-items-center"
                  style={{ borderRadius: "10px 10px 0 0" }}
                >
                  Approvals
                  <i
                    className="icon-open_in_new cursor-pointer transition-3s hover-scale-1-2"
                    onClick={() => setApprovalsOverlay(false)}
                  ></i>
                </h3>

                <div
                  ref={approvalRef}
                  className={`add-transition ${approvals ? "p-2" : "p-0"}`}
                  style={{
                    height: approvals
                      ? `${contentRefUtlized.current.scrollHeight}px`
                      : "0px ",
                    overflow: "hidden",
                    transition: "height 0.3s ease",
                  }}
                >
                  {/* <h6 className="text-black-60 fs-12px">
                    <span>{data.pending_invoices_count}</span> Invoices for
                    approval
                  </h6> */}
                  {/* <p className="fw-600">{AddCommasToAmount(data.sanctioned_amount)}</p> */}
                  <h6 className="text-black-60 fs-12px p-0 my-1">
                  {/* <span>{data?.stats[0]?.invoice_pending_count + " "}</span> */}
                    Vendor Invoice for Approval
                  </h6>
                  <p className="fw-600 p-0 m-0 mb-1">
                    {formatNumber(data?.sanctioned_amount, "indian")}
                  </p>
                  <h6 className="text-black-60 fs-12px ">
                  {/* <span>{data?.stats[0]?.invoice_payment_pending_count + " "}</span> */}
                    Vendor Invoice for payment
                  </h6>
                  <p className="fw-600 ">
                    {data?.payable_expanses > 0
                      ? formatNumber(
                          data?.payable_expanses,
                          "indian"
                        )
                      : "-"}
                  </p>
                  <h6 className="text-black-60 fs-12px">
                        {/* <span>{data?.stats[0]?.dbt_pending_approval_count + " "}</span> */}
                     DBT/Nach for Approval
                  </h6>
                  <p className="fw-600">
                    {data?.reimbursement_details[0]?.under_approval > 0
                      ? formatNumber(
                          data?.reimbursement_details[0]?.under_approval,
                          "indian"
                        )
                      : "-"}
                  </p>
                  <h6 className="text-black-60 fs-12px">
                        {/* <span>{data?.stats[0]?.dbt_pending_payment_approval + " "}</span> */}
                    DBT/Nach for Payment
                  </h6>
                  <p className="fw-600">
                    {data?.reimbursement_details[1]?.payment_approval > 0
                      ? formatNumber(
                          data?.reimbursement_details[1]?.payment_approval,
                          "indian"
                        )
                      : "-"}
                  </p>
                  <h6 className="text-black-60 fs-12px">
                        {/* <span>{data?.stats[0]?.dbt_pending_payment_approval + " "}</span> */}
                    Budget Allocatoin for Approval
                  </h6>
                  <p className="fw-600">
                    {data?.pending_budget_allocation > 0
                      ? formatNumber(
                          data?.pending_budget_allocation,
                          "indian"
                        )
                      : "-"}
                  </p>
                </div>
                <div className="p-2 border-top d-flex justify-content-between align-items-center">
                  <div>
                    {/* <h4 className="fs-12px">
                      {data.pending_invoices_count} Invoices for approval
                    </h4> */}
                    <h4 style={{ fontSize: "16px", fontWeight: "600" }}>
                      {formatNumber(
                        (
                          Number(data.sanctioned_amount) + 
                          Number(data?.payable_expanses) +
                          Number(data?.reimbursement_details[0]?.under_approval) +
                          Number(data?.reimbursement_details[1]?.payment_approval) +
                          Number(data?.pending_budget_allocation)
                        ) / dividerNumber,
                        "indian"
                      )}
                    </h4>
                  </div>
                  <div>
                    {!approvals ? (
                      <i
                        className="icon-add_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("approvals")}
                      ></i>
                    ) : (
                      <i
                        className="icon-remove_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("approvals")}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4}>
              <div className="dashboardStatCard">
                <h3
                  className="bg-black-10 p-2 m-0 d-flex justify-content-between align-items-center"
                  style={{ borderRadius: "10px 10px 0 0" }}
                >
                  Accumulated Deduction
                  <i
                    className="icon-open_in_new cursor-pointer transition-3s hover-scale-1-2"
                    onClick={() => setAccumulatedDeductionOverlay(false)}
                  ></i>
                </h3>

                <div
                  ref={approvalRef}
                  className={`add-transition  ${
                    accumulateHeight ? "p-2" : "p-0"
                  }`}
                  style={{
                    height: accumulateHeight
                      ? `${contentRef.current.scrollHeight}px`
                      : "0px ",
                    overflow: "hidden",
                    transition: "height 0.3s ease",
                  }}
                >
                  {/* <h6 className="text-black-60 fs-12px">
                    <span>{data.pending_invoices_count}</span> Invoices for
                    approval
                  </h6> */}
                  {/* <p className="fw-600">{AddCommasToAmount(data.sanctioned_amount)}</p> */}
                  <h6 className="text-black-60 fs-12px">Total Vendor</h6>
                  <p className="fw-600">{formatNumber(data?.accumulated?.vendor, "indian")}</p>
                  <h6 className="text-black-60 fs-12px">Total DBT/NACH</h6>
                  <p className="fw-600">{formatNumber(data?.accumulated?.dbt, "indian")}</p>
                  <h6 className="text-black-60 fs-12px">Total Salary</h6>
                  <p className="fw-600">{formatNumber(data?.accumulated?.salary, "indian")}</p>
                </div>
                <div className="p-2 border-top d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="fs-12px">Total Deduction</h4>
                    <h4 style={{ fontSize: "16px", fontWeight: "600" }}>
                      {formatNumber(
                        (
                          Number(data?.accumulated?.vendor) +
                          Number(data?.accumulated?.dbt) +
                          Number(data?.accumulated?.salary)
                        ) / dividerNumber || "-",
                        "indian"
                      )}
                    </h4>
                  </div>
                  <div>
                    {!accumulateHeight ? (
                      <i
                        className="icon-add_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("accumulated")}
                      ></i>
                    ) : (
                      <i
                        className="icon-remove_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("accumulated")}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} sm={6} md={6} lg={4}>
              <div className="dashboardStatCard">
                <h3
                  className="bg-black-10 p-2 m-0 d-flex justify-content-between align-items-center"
                  style={{ borderRadius: "10px 10px 0 0" }}
                >
                  Reconciliation
                  <i
                    className="icon-open_in_new cursor-pointer transition-3s hover-scale-1-2"
                    onClick={() => setReconciliationOverlay(false)}
                  ></i>
                </h3>

                <div
                  ref={approvalRef}
                  className={`add-transition  ${reconHeight ? "p-2" : "p-0"}`}
                  style={{
                    height: reconHeight
                      ? `${contentRef.current.scrollHeight}px`
                      : "0px ",
                    overflow: "hidden",
                    transition: "height 0.3s ease",
                  }}
                >
                  {/* <h6 className="text-black-60 fs-12px">
                    <span>{data.pending_invoices_count}</span> Invoices for
                    approval
                  </h6> */}
                  {/* <p className="fw-600">{AddCommasToAmount(data.sanctioned_amount)}</p> */}
                  <h6 className="text-black-60 fs-12px">Total Accumulated</h6>
                  <p className="fw-600">
                    -
                  </p>
                  <h6 className="text-black-60 fs-12px">
                    Total Settlements In Progress
                  </h6>
                  <p className="fw-600">
                    -
                  </p>
                  <h6 className="text-black-60 fs-12px">
                    Total With held and penalty
                  </h6>
                  <p className="fw-600">
                  {totalOverall == 0
                        ? "-"
                        : formatNumber(totalOverall / dividerNumber, "india")}
                  </p>
                </div>
                <div className="p-2 border-top d-flex justify-content-between align-items-center">
                  <div>
                    <h4 className="fs-12px">Total Reconciliation</h4>
                    <h4 style={{ fontSize: "16px", fontWeight: "600" }}>
                      {formatNumber(100000 / dividerNumber, "indian")}
                    </h4>
                  </div>
                  <div>
                    {!reconHeight ? (
                      <i
                        className="icon-add_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("recon")}
                      ></i>
                    ) : (
                      <i
                        className="icon-remove_circle_outline cursor-pointer"
                        onClick={() => toggleExpand("recon")}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* <DashboardBarGraph setOverlay={setOverlay} /> */}
        </div>

        {/* ===============================right side bar ================================= */}

        <div className=" col-3">
          <Col sm={12}>
            <div className="dashboardStatCard">
              <h3
                className="bg-success-100 fs-20px text-white px-2 py-3 m-0 d-flex justify-content-between align-items-center"
                style={{ borderRadius: "10px 10px 0 0" }}
              >
                <span className="d-flex flex-column gap-1 fs-12px">
                  Total Bank Balance
                  <span style={{ fontSize: "20px" }}>
                    {formatNumber(
                      totalAccountBalance,
                      "indian"
                    )}
                  </span>
                </span>
                <i
                  className="icon-more_vert cursor-pointer"
                  onClick={() => openBankBalanceOverlay(false)}
                ></i>
              </h3>

              <div>
                <div className="hover-bg-prime-20 p-2 transition ">
                  <h6 className="text-black-60 fs-12px">
                    Current FY Interest Earn
                  </h6>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="fw-600">{totalQuartlyInterest == 0 ? "-" : formatNumber(totalQuartlyInterest / dividerNumber, "indian")}</div>
                  </div>
                </div>

                    {
                      bankBalanceData?.map((val, ind) => (
                        <div className="hover-bg-prime-20 p-2 transition ">
                  <h6 className="text-black-60 fs-12px">Accured Interest Q{ind}</h6>

                  <div className="d-flex justify-content-between align-items-center">
                    <div className="fw-600">{val?.interest == 0 ? "-" : formatNumber(val?.interest / dividerNumber, "indian") }</div>
                  </div>
                </div>
                      ))
                    }
             
             {/* ==============action btn=========== */}
                    <div className="d-flex">

                <div className="row fs-12px px-1 pb-1 m-0 gap-2 d-flex flex-column w-25">
                    {
                      bankBalanceData?.map((val, ind) => (
                        val?.l2_approval == null &&
                    <button className="fs-12px border-0 rounded-1 px-2 py-1 d-flex gap-1 align-items-center bg-danger-20">
                      <span className="d-flex align-items-center justify-content-between gap-1 w-100">
                      <span>
                        l2
                      </span>
                        <i className="icon-close_circle_stroke fs-14px m-0 p-0 text-danger-100"></i>
                      </span>
                    </button>
                  )
                )}
                </div>
                <div className="row fs-12px px-1 pb-1 m-0 gap-2 d-flex flex-column w-25">
                    {
                      bankBalanceData?.map((val, ind) => (
                        val?.l3_approval == null &&
                    <button className="fs-12px border-0 rounded-1 px-2 py-1 d-flex gap-1 align-items-center bg-danger-20">
                      <span className="d-flex align-items-center justify-content-between gap-1 w-100">
                        l3
                        <i className="icon-close_circle_stroke fs-14px m-0 p-0 text-danger-100"></i>
                      </span>
                    </button>
                  )
                )}
                </div>

                </div>
                  {
                    bankBalanceData?.map((val, ind) => (
                      val?.l3_approval == null && val?.l2_approval == null &&
                        <div className="d-flex px-1">
                          <span className="fs-12px text-black-50">{val?.account_no}</span>
                        </div>
                    ))
                  }

              </div>

            </div>
          </Col>
        </div>
        {/* <InterestFromBankBox handleEditAccount={showPopupFun} reloadList={listReload} /> */}
      </div>

      {/* =====================overlay========================== */}

      <div className={`overlay ${overlay ? "d-none" : null}  `}></div>

      <div
        className={`overlayContent overflow-auto ${
          overlay ? "left-100" : null
        } `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-secondary-100 position-relative text-white d-flex justify-content-between align-items-center ps-4 position-sticky top-0 z-200">
          <div className="fw-600 fs-16px d-flex align-items-center gap-2 z-3">
            <i
              className="icon-close_circle_fill fs-5  cursor-pointer"
              onClick={() => setOverlay(true)}
            ></i>
            Scheme Budget FY
            <select
              name="financial_year_id"
              onChange={handleYearDropdown}
              className="bg-transparent border-0 text-white fw-500 fs-16px select"
              disabled
            >
              <option value="" className="text-black">
                select year
              </option>
              {fy_list?.map((fy) => {
                return (
                  <option
                    value={storedGlobalDateId}
                    key={fy.id}
                    className="text-black"
                    selected={
                      String(fy.id) === storedGlobalDateId ||
                      (!storedGlobalDateId &&
                        fy.start_year === moment().format("YYYY"))
                    }
                  >
                    {fy.year}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="d-flex gap-2 align-items-center h-100 py-3 position-relative pe-4">
            <span>
              As on date{" "}
              {
                <select
                  name="financial_year_id"
                  className="bg-transparent border-0 text-white fw-500 fs-16px select"
                  disabled
                >
                  <option value="" className="text-black">
                    select year
                  </option>
                  {fy_list?.map((fy) => {
                    return (
                      <option
                        value={storedGlobalDateId}
                        key={fy.id}
                        className="text-black"
                        selected={
                          String(fy.id) === storedGlobalDateId ||
                          (!storedGlobalDateId &&
                            fy.start_year === moment().format("YYYY"))
                        }
                      >
                        {fy.end_year === moment().format("YYYY")
                          ? moment().format("Do MMM YYYY")
                          : "31 Mar " + fy.end_year}
                      </option>
                    );
                  })}
                </select>
              }
            </span>
            <i
              className="icon-arrow_drop_down_circle fs-18px cursor-pointer"
              onClick={() => setOverlayDropDown((prev) => !prev)}
            ></i>

            <div
              className={`position-absolute bg-prime-100 w-100 p-3 ${
                !overlayDropDown ? "d-none" : "d-inline-block"
              }`}
              style={{ top: "100%", zIndex: "10" }}
            >
              <div className="d-flex flex-column gap-4">
                <span className="d-flex align-items-center gap-2 cursor-pointer">
                  {" "}
                  <i className="icon-featured_play_list"></i> Export Excel
                </span>
                <span className="d-flex align-items-center gap-2 cursor-pointer">
                  {" "}
                  <i className="icon-picture_as_pdf"></i> Export PDF
                </span>
                <span
                  className="d-flex align-items-center gap-2 cursor-pointer"
                  onClick={() => setListview(false)}
                >
                  {" "}
                  <i className="icon-view_list"></i> List View
                </span>
                <span
                  className="d-flex align-items-center gap-2 cursor-pointer"
                  onClick={() => setListview(true)}
                >
                  {" "}
                  <i className="icon-grid_on"></i> Grid View
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 d-flex flex-column gap-2">
          {/* ==========================table view===================================== */}

          {!listview === false ? null : (
            <div className="w-100 overflow-x-auto">
              <div>
                {/* =======================heading========================= */}
                <div className="d-flex justify-content-between border-0 text-center">
                  <div className="fw-bold border px-1" style={{ width: "20%" }}>
                    Scheme Code
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "20%" }}>
                    Scheme Name
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "20%" }}>
                    Provisional FY {fy_list[storedGlobalDateId]?.year}
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "20%" }}>
                    Release Fy {fy_list[storedGlobalDateId]?.year}
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "20%" }}>
                    Previous FY Balance
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "20%" }}>
                    Total Balance
                  </div>
                </div>

                {/* ===========================datas========================= */}

                <div
                  className="d-flex border-0  text-center flex-column position-relative overflow-hidden"
                  style={tableAccordian ? null : { height: "34px" }}
                >
                  <div className="d-flex ">
                    <div className="border p-1" style={{ width: "20%" }}>
                      {!tableAccordian ? (
                        <i
                          className="icon-arrow_right cursor-pointer"
                          onClick={() => setTableAccordian(true)}
                        ></i>
                      ) : (
                        <i
                          className="icon-arrow_drop_down cursor-pointer"
                          onClick={() => setTableAccordian(false)}
                        ></i>
                      )}
                      1353
                    </div>
                    <div className="border p-1" style={{ width: "20%" }}>
                      radhe radhe
                    </div>
                    <div className="border p-1" style={{ width: "20%" }}>
                      20000000000
                    </div>
                    <div className="border p-1" style={{ width: "20%" }}>
                      20000000000
                    </div>
                    <div className="border p-1" style={{ width: "20%" }}>
                      20000000000
                    </div>
                    <div className="border p-1" style={{ width: "20%" }}>
                      20000000000
                    </div>
                  </div>

                  {/* ====================child datas================================== */}
                  <div
                    className=""
                    style={{
                      background: "silver",
                      height: tableChildAccordian ? null : "59px",
                    }}
                  >
                    <div className="d-flex justify-content-between text-center">
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "20%" }}
                      >
                        Sub Head
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "20%" }}
                      >
                        Provisional FY {fy_list[storedGlobalDateId]?.year}
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "20%" }}
                      >
                        Release Fy {fy_list[storedGlobalDateId]?.year}
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "20%" }}
                      >
                        Previous FY Balance
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "20%" }}
                      >
                        Total Balance
                      </div>
                    </div>

                    <div className="d-flex justify-content-between">
                      <div className="border p-1" style={{ width: "20%" }}>
                        {!tableChildAccordian ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setTableChildAccordian(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() => setTableChildAccordian(false)}
                          ></i>
                        )}
                        1353
                      </div>
                      <div className="border p-1" style={{ width: "20%" }}>
                        radhe radhe
                      </div>
                      <div className="border p-1" style={{ width: "20%" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "20%" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "20%" }}>
                        20000000000
                      </div>
                    </div>

                    {/* ==============================sub child============================== */}

                    <div
                      className="bg-success"
                      style={{ height: tableSubChildAccordian ? null : "59px" }}
                    >
                      <div className="d-flex justify-content-between text-center">
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "20%" }}
                        >
                          Sub Activity
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "20%" }}
                        >
                          Provisional FY {fy_list[storedGlobalDateId]?.year}
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "20%" }}
                        >
                          Release Fy {fy_list[storedGlobalDateId]?.year}
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "20%" }}
                        >
                          Previous FY Balance
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "20%" }}
                        >
                          Total Balance
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="border p-1" style={{ width: "20%" }}>
                          {!tableSubChildAccordian ? (
                            <i
                              className="icon-arrow_right cursor-pointer"
                              onClick={() => setTableSubChildAccordian(true)}
                            ></i>
                          ) : (
                            <i
                              className="icon-arrow_drop_down cursor-pointer"
                              onClick={() => setTableSubChildAccordian(false)}
                            ></i>
                          )}
                          1353
                        </div>
                        <div className="border p-1" style={{ width: "20%" }}>
                          radhe radhe
                        </div>
                        <div className="border p-1" style={{ width: "20%" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "20%" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "20%" }}>
                          20000000000
                        </div>
                      </div>

                      {/* ========================================grand child================================= */}

                      <div className="bg-primary">
                        <div className="d-flex justify-content-between text-center">
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "20%" }}
                          >
                            Sub Activity
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "20%" }}
                          >
                            Provisional FY {fy_list[storedGlobalDateId]?.year}
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "20%" }}
                          >
                            Release Fy {fy_list[storedGlobalDateId]?.year}
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "20%" }}
                          >
                            Previous FY Balance
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "20%" }}
                          >
                            Total Balance
                          </div>
                        </div>

                        <div className="d-flex justify-content-between">
                          <div className="border p-1" style={{ width: "20%" }}>
                            1353
                          </div>
                          <div className="border p-1" style={{ width: "20%" }}>
                            radhe radhe
                          </div>
                          <div className="border p-1" style={{ width: "20%" }}>
                            20000000000
                          </div>
                          <div className="border p-1" style={{ width: "20%" }}>
                            20000000000
                          </div>
                          <div className="border p-1" style={{ width: "20%" }}>
                            20000000000
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* ======================card view=========================== */}

          {!listview == true ? null : filteredSchemes === "No data found" ? (
            <span>No Data Found</span>
          ) : (
            filteredSchemes?.map((val, ind) => (
              <div
                className="bg-white border-Prime-100"
                style={{ borderRadius: "8px" }}
                key={val?.id}
              >
                <div
                  className="bg-prime-20 d-flex justify-content-between align-items-center p-2 transition overflow-hidden"
                  style={{ borderRadius: "8px 8px 0 0" }}
                >
                  {/* ======================card header================== */}
                  <span
                    className="fs-14px white-space-nowrap overflow-x-hidden"
                    style={{ width: "95%", fontStyle: "italic" }}
                  >
                    {/* =======================card header====================== */}
                    <span
                      className="fs-14px white-space-nowrap overflow-x-hidden "
                      style={{ width: "95%", fontStyle: "italic" }}
                    >
                      {val?.code} {val?.name}
                    </span>
                  </span>

                  <span>
                    <i className="icon-remove_red_eye cursor-pointer"></i>
                  </span>
                </div>

                {/* ================== card body ========================= */}

                <div className="row py-2 px-3">
                  <div className="col d-flex flex-column">
                    <span className="fs-12px text-black-60">
                      Provisional FY {fy_list[storedGlobalDateId]?.year}
                    </span>
                    <span className="fs-14px fw-600 ">
                      {val?.total_provisional_budget == 0
                        ? "-"
                        : formatNumber(
                            val.total_provisional_budget / dividerNumber,
                            "indian"
                          )}
                    </span>
                  </div>
                  <div className="col d-flex flex-column">
                    <span className="fs-12px text-black-60">
                      Release FY {fy_list[storedGlobalDateId]?.year}
                    </span>
                    <span className="fs-14px fw-600">
                      {val?.total_budget == 0
                        ? "-"
                        : formatNumber(
                            val?.total_budget / dividerNumber,
                            "indian"
                          )}
                    </span>
                  </div>
                  <div className="col d-flex flex-column">
                    <span className="fs-12px text-black-60 d-flex justify-content-between align-items-center">
                      Previous FY Balance
                      <span>
                        <Tooltip
                          title={val?.prevYearBalance}
                          placement="top"
                          arrow
                        >
                          <i className="icon-info cursor-pointer"></i>
                        </Tooltip>
                      </span>
                    </span>
                    <span className="fs-14px fw-600">
                      {val?.prevYearBalance == 0
                        ? "-"
                        : formatNumber(
                            val?.prevYearBalance / dividerNumber,
                            "indian"
                          )}
                    </span>
                  </div>
                  <div className="col d-flex flex-column success-100 bg-success-20 py-1">
                    <span className="fs-12px text-black-60 d-flex justify-content-between align-items-center">
                      Total Balance
                      <Tooltip
                        title={
                          Number(val?.prevYearBalance) +
                          Number(val?.total_budget)
                        }
                        placement="top"
                        arrow
                      >
                        <i className="icon-info cursor-pointer"></i>
                      </Tooltip>
                    </span>
                    <span className="fs-14px fw-600 text-success-100">
                      {(
                        val?.total_provisional_budget +
                        val?.total_budget +
                        val?.prevYearBalance 
                      ) == 0 ? "-"
                        : formatNumber(
                          (
                        val?.total_provisional_budget +
                        val?.total_budget +
                        val?.prevYearBalance 
                      ) / dividerNumber,
                            "indian"
                          )}
                    </span>
                  </div>
                </div>

                {/* ===================card footer ==================== */}
                <div className="border mx-2"></div>

                {/* =======================buttons ====================== */}

                <div className="p-2 d-flex justify-content-between text-secondary-50 px-4">
                  <button
                    onClick={() => handleButtonClick(val?.id, "1")}
                    className={` ${
                      activeCardChild[val?.id] == 1
                        ? "bg-secondary-20"
                        : "bg-white"
                    }  border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1 rounded-4`}
                  >
                    {activeCardChild[val?.id] == 1 ? (
                      <i className="icon-remove_circle_outline"></i>
                    ) : (
                      <i className="icon-add_circle_outline"></i>
                    )}
                    Sub head
                  </button>

                  {val?.is_activity_active != "0" &&
                    val?.monitoring_level != null && (
                      <>
                        <button
                          onClick={() => handleButtonClick(val.id, "2")}
                          className={`${
                            activeCardChild[val?.id] == 2
                              ? "bg-secondary-20"
                              : "bg-white"
                          } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1  rounded-4`}
                        >
                          {activeCardChild[val?.id] == 2 ? (
                            <i className="icon-remove_circle_outline"></i>
                          ) : (
                            <i className="icon-add_circle_outline"></i>
                          )}
                          Activity
                        </button>
                        <button
                          onClick={() => handleButtonClick(val.id, "3")}
                          className={`${
                            activeCardChild[val?.id] == 3
                              ? "bg-secondary-20"
                              : "bg-white"
                          } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1  rounded-4`}
                        >
                          {activeCardChild[val?.id] == 3 ? (
                            <i className="icon-remove_circle_outline"></i>
                          ) : (
                            <i className="icon-add_circle_outline"></i>
                          )}
                          Sub-Activity
                        </button>
                      </>
                    )}
                </div>

                {/* ===========================contents============================== */}

                {activeCardChild[val?.id] === "1" && (
                  <>
                    <div className="row px-3" style={{ marginLeft: "2%" }}>
                      <div
                        className="col d-flex flex-column p-0 py-1"
                        style={{ width: "20%" }}
                      >
                        <span className="fs-12px text-black-60 p-0">
                          Sub Head
                        </span>
                      </div>
                      <div
                        className="col d-flex flex-column p-0 py-1"
                        style={{ width: "20%" }}
                      >
                        <span className="fs-12px text-black-60 p-0">
                          Provisional Budget
                          {fy_list[storedGlobalDateId]?.year}
                        </span>
                      </div>
                      <div
                        className="col d-flex flex-column p-0 py-1"
                        style={{ width: "20%" }}
                      >
                        <span className="fs-12px text-black-60 p-0">
                          Release FY {fy_list[storedGlobalDateId]?.year}
                        </span>
                      </div>
                      <div
                        className="col d-flex flex-column p-0 py-1"
                        style={{ width: "20%" }}
                      >
                        <span className="fs-12px text-black-60 p-0">
                          Previous Year Balance
                        </span>
                      </div>
                      <div
                        className="col d-flex flex-column p-0 py-1"
                        style={{ width: "20%" }}
                      >
                        <span className="fs-12px text-black-60 p-0">
                          Total Balance
                        </span>
                      </div>
                    </div>

                    {val?.sub_head_list?.map((val) => (
                      <div className="row px-3" style={{ marginLeft: "2%" }}>
                        <div
                          className="col fs-14px fw-500 p-0 py-1"
                          style={{ width: "20%" }}
                        >
                          {val.name}
                        </div>
                        <div
                          className="col fs-14px fw-500 p-0 py-1"
                          style={{ width: "20%" }}
                        >
                          { val?.provisional_budget == 0 ? "-" : formatNumber(
                            val.provisional_budget / dividerNumber,
                            "indian"
                          )}
                        </div>
                        <div
                          className="col fs-14px fw-500 p-0 py-1"
                          style={{ width: "20%" }}
                        >
                          { val?.budget == 0 ? "-" : formatNumber(val.budget / dividerNumber, "indian")}
                        </div>
                        <div
                          className="col fs-14px fw-500 p-0 py-1"
                          style={{ width: "20%" }}
                        >
                          { val.pervious_year_budget == 0 ? "-" : formatNumber(
                            val.pervious_year_budget / dividerNumber,
                            "indian"
                          )}
                        </div>
                        <div
                          className="col fs-14px fw-500 p-0 py-1 text-success-100"
                          style={{ width: "20%" }}
                        >
                          { (val.pervious_year_budget + val.budget ) ==  0 ? "-" : formatNumber(
                            Number(val.pervious_year_budget) +
                              Number(val.budget) / dividerNumber,
                            "indian"
                          )}
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {/* =====================activity======================== */}

                <div className="row py-2 px-3">
                  {activeCardChild[val?.id] === "2" && (
                    <div className="d-flex flex-column gap-1">
                      {val?.sub_head_list?.map((val, ind) => {

                        const totalProvisionalBudget = val?.activity_budget?.reduce(
                         (acc, curr) => acc + (Number(curr?.provisional_budget) || 0),
                          0);
                        const totalReleasedBudget = val?.activity_budget?.reduce(
                         (acc, curr) => acc + (Number(curr?.budget) || 0),
                          0);
                        const totalPrevFyBalance = val?.activity_budget?.reduce(
                         (acc, curr) => acc + (Number(curr?.prev_balance) || 0),
                          0);

                      return ( <Accordion activeKey={activeKeys}>
                          <Accordion.Item
                            eventKey={val?.id}
                            style={{ borderRadius: "0", border: "0" }}
                          >
                            <Accordion.Header
                              onClick={() => toggleAccordion(val?.id)}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                borderRadius: "0",
                              }}
                            >
                              <div className="w-100 d-flex justify-content-between">
                                <span>{val?.name}</span>
                                <span
                                  className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                                  style={{
                                    height: "13.33px",
                                    width: "13.33px",
                                  }}
                                >
                                  {activeKeys.includes(val?.id) ? "-" : "+"}
                                </span>
                              </div>
                            </Accordion.Header>

                            <Accordion.Body className="p-2">
                              <div className="row p-0">
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Activity
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Provisional Budget{" "}
                                    {fy_list[storedGlobalDateId]?.year}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Released FY{" "}
                                    {fy_list[storedGlobalDateId]?.year}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Previous FY Balance
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Total
                                  </p>
                                </div>
                              </div>
                              {(() => {
                                // Filter and sum budgets for "(5)- वेतन"
                                const salaryActivities =
                                  val?.activity_budget?.filter(
                                    (item) => item?.activity === "(5)- वेतन"
                                  );

                                const summedSalaryData =
                                  salaryActivities?.reduce(
                                    (acc, curr) => ({
                                      provisional_budget:
                                        Number(acc.provisional_budget) +
                                        (Number(curr?.provisional_budget) || 0),
                                      budget:
                                        Number(acc.budget) +
                                        (Number(curr?.budget) || 0),
                                      prev_balance:
                                        Number(acc.prev_balance) +
                                        (Number(curr?.prev_balance) || 0),
                                    }),
                                    { provisional_budget: 0, budget: 0, prev_balance: 0 }
                                  );

                                // Creating a new dataset with summed salary data
                                let modifiedData = val?.activity_budget?.filter(
                                  (item) => item?.activity !== "(5)- वेतन"
                                );

                                if (salaryActivities.length > 0) {
                                  modifiedData = [
                                    {
                                      activity: "(5)- वेतन",
                                      provisional_budget:
                                        summedSalaryData.provisional_budget,
                                      budget: summedSalaryData.budget,
                                      prev_balance: summedSalaryData.prev_balance,
                                    },
                                    ...modifiedData, // Add remaining activities
                                  ];
                                }

                                // Mapping through modified data
                                return modifiedData?.map((item, ind) => (
                                  <div className="row p-0" key={ind}>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {item?.activity}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {item?.provisional_budget === 0
                                          ? "-"
                                          : formatNumber(
                                              item?.provisional_budget /
                                                dividerNumber,
                                              "indian"
                                            )}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {item?.budget === 0
                                          ? "-"
                                          : formatNumber(
                                              item?.budget / dividerNumber,
                                              "indian"
                                            )}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                      {item?.prev_balance == 0
                                          ? "-"
                                          : formatNumber(
                                              Number(item?.prev_balance) / dividerNumber,
                                              "indian"
                                            )}
                                            {/* <span>number {item?.prev_balance}</span> */}
                                      </p>
                                    </div>
                                    <div className="col text-success-100">
                                      <p className="fs-14px fw-500 m-0 py-1">
                                        {
                                          (Number(item?.provisional_budget) +
                                          Number(item?.budget) +
                                          Number(item?.prev_balance)) == 0 ? "-" :
                                          formatNumber(
                                            (Number(item?.provisional_budget) +
                                          Number(item?.budget) +
                                          Number(item?.prev_balance)) / dividerNumber, "indian"
                                          )
                                        }
                                      </p>
                                    </div>
                                  </div>
                                ));
                              })()}

                              <div className="row p-0 bg-info-20">
                                <div className="col">
                                  <p className="fs-14px text-info fw-500 py-1 m-0">
                                    Total
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-14px text-black fw-500 py-1 m-0">
                                    {totalProvisionalBudget == 0 ? "-" : formatNumber(totalProvisionalBudget / dividerNumber, "indian")}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-14px text-black fw-500 py-1 m-0">
                                    {totalReleasedBudget == 0 ? "-" : formatNumber(totalReleasedBudget / dividerNumber, "indian")}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-14px text-black fw-500 py-1 m-0">
                                    {totalPrevFyBalance == 0 ? "-" : formatNumber(totalReleasedBudget / dividerNumber, "indian")}
                                  </p>
                                </div>
                                <div className="col text-success-100">
                                  <p className="fs-14px fw-500 py-1 m-0">
                                    {
                                      (totalProvisionalBudget +
                                      totalReleasedBudget +
                                      totalPrevFyBalance) == 0 ? "-" :
                                      formatNumber(
                                        (totalProvisionalBudget +
                                        totalReleasedBudget +
                                        totalPrevFyBalance) / dividerNumber, "indian"
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>)
                       } )}
                    </div>

                  )}
                </div>

                {/* ===================sub activity============= */}

                <div className="row pb-2 px-3">
                  {activeCardChild[val?.id] === "3" && (
                    <div className="d-flex flex-column gap-1">
                    {(()=>{
                      const combinedActivityBudget = val?.sub_head_list?.flatMap(subHead => subHead?.activity_budget || []);
                      return combinedActivityBudget?.map((val, ind)=>(
                        <Accordion activeKey={activeKeys} key={val?.id || ind}>
                          <Accordion.Item
                            eventKey={val?.id || ind}
                            style={{ borderRadius: "0", border: "0" }}
                          >
                            <Accordion.Header
                              onClick={() => toggleAccordion(val?.id || ind)}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                borderRadius: "0",
                              }}
                            >
                              <div className="w-100 d-flex justify-content-between">
                                <span>{val?.activity} {ind + 1}</span>
                                <span
                                  className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                                  style={{
                                    height: "13.33px",
                                    width: "13.33px",
                                  }}
                                >
                                  {activeKeys.includes(val?.id || ind) ? "-" : "+"}
                                </span>
                              </div>
                            </Accordion.Header>

                            <Accordion.Body className="p-2">
                              <div className="row p-0">
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Activity
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Provisional Budget{" "}
                                    {fy_list[storedGlobalDateId]?.year}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Released FY{" "}
                                    {fy_list[storedGlobalDateId]?.year}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Previous FY Balance
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Total
                                  </p>
                                </div>
                              </div>

                            
                                  <div className="row p-0">
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {val?.subactivity}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {val?.provisional_budget === 0
                                          ? "-"
                                          : formatNumber(
                                              val?.provisional_budget /
                                                dividerNumber,
                                              "indian"
                                            )}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {val?.budget === 0
                                          ? "-"
                                          : formatNumber(
                                              val?.budget / dividerNumber,
                                              "indian"
                                            )}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {val?.prev_balance === 0
                                          ? "-"
                                          : formatNumber(
                                              val?.prev_balance / dividerNumber,
                                              "indian"
                                            )}
                                      </p>
                                    </div>
                                    <div className="col text-success-100">
                                      <p className="fs-14px fw-500 m-0 py-1">
                                      {
                                          (Number(val?.provisional_budget) +
                                          Number(val?.budget) +
                                          Number(val?.prev_balance)) == 0 ? "-" :
                                          formatNumber(
                                            (Number(val?.provisional_budget) +
                                          Number(val?.budget) +
                                          Number(val?.prev_balance)) / dividerNumber, "indian"
                                          )
                                        }
                                      </p>
                                    </div>
                                  </div>
                          

                              {/* <div className="row p-0 bg-info-20">
                                <div className="col">
                                  <p className="fs-14px text-info fw-500 py-1 m-0">
                                    Total
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-14px text-black fw-500 py-1 m-0">
                                    2,000,000,000.00
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-14px text-black fw-500 py-1 m-0">
                                    2,000,000,000.00
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-14px text-black fw-500 py-1 m-0">
                                    2,000,000,000.00
                                  </p>
                                </div>
                                <div className="col text-success-100">
                                  <p className="fs-14px fw-500 py-1 m-0">
                                    2,000,000,000.00
                                  </p>
                                </div>
                              </div> */}
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ))
                     
                     
                  })()}
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* =====================utilized budget overlay========================== */}

      <div className={`overlay ${budgetoverlay ? "d-none" : null}  `}></div>

      <div
        className={`overlayContent overflow-auto overflow-x-hidden ${
          budgetoverlay ? "left-100" : null
        } `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-secondary-100 position-relative text-white d-flex justify-content-between align-items-center p-3 position-sticky top-0 z-3">
          <div className="fw-600 fs-16px d-flex align-items-center gap-1 ">
            <i
              className="icon-close_circle_fill fs-5  cursor-pointer"
              onClick={() => setbudgetoverlay(true)}
            ></i>
            Utilized Budget FY
            <select
              name="financial_year_id"
              onChange={handleYearDropdown}
              className="bg-transparent border-0 text-white fw-500 fs-16px select"
              disabled
            >
              <option value="" className="text-black">
                select year
              </option>
              {fy_list?.map((fy) => {
                return (
                  <option
                    value={storedGlobalDateId}
                    key={fy.id}
                    className="text-black"
                    selected={
                      String(fy.id) === storedGlobalDateId ||
                      (!storedGlobalDateId &&
                        fy.start_year === moment().format("YYYY"))
                    }
                  >
                    {fy.year}
                  </option>
                );
              })}
            </select>
            {listview && (
              <>
                |
                <select
                  name="date"
                  id="date"
                  className="bg-transparent fw-500 fs-16px text-warning-100 border-b-warning-100"
                  onChange={(e) => setUtilizedMonth(e.target.value)}
                >
                  <option value="" className="text-black">
                    -- Select Month --
                  </option>
                  {monthArrayValues?.map((month) => (
                    <option
                      value={month.monthNumber}
                      key={month.monthNumber}
                      // selected={month === utilizedMonth}
                    >
                      {month.month}
                    </option>
                  ))}
                  
                </select>
              </>
            )}
          </div>
            {/* need to understand code use */}
          <div className="d-flex gap-2 align-items-center ">
            <span>
              As on date{" "}
              {
                <select
                  name="financial_year_id"
                  className="bg-transparent border-0 text-white fw-500 fs-16px select"
                  disabled
                >
                  <option value="" className="text-black">
                    select year
                  </option>
                  {fy_list?.map((fy) => {
                    return (
                      <option
                        value={storedGlobalDateId}
                        key={fy.id}
                        className="text-black"
                        selected={
                          String(fy.id) === storedGlobalDateId ||
                          (!storedGlobalDateId &&
                            fy.start_year === moment().format("YYYY"))
                        }
                      >
                        {fy.end_year === moment().format("YYYY")
                          ? moment().format("Do MMM YYYY")
                          : "31 Mar " + fy.end_year}
                      </option>
                    );
                  })}
                </select>
              }
            </span>
            <i
              className="icon-arrow_drop_down_circle fs-18px cursor-pointer"
              onClick={() => setOverlayDropDown((prev) => !prev)}
            ></i>

            <div
              className={`position-absolute bg-prime-100 w-100 p-3 ${
                !overlayDropDown ? "d-none" : "d-inline-block"
              }`}
              style={{ top: "100%", zIndex: "10" }}
            >
              <div className="d-flex flex-column gap-4">
                <span className="d-flex align-items-center gap-2">
                  <i className="icon-featured_play_list"></i> Export Excel
                </span>
                <span className="d-flex align-items-center gap-2">
                  <i className="icon-picture_as_pdf"></i> Export PDF
                </span>
                <span
                  className="d-flex align-items-center gap-2 cursor-pointer"
                  onClick={() => setListview(false)}
                >
                  <i className="icon-view_list"></i> List View
                </span>
                <span
                  className="d-flex align-items-center gap-2 cursor-pointer"
                  onClick={() => setListview(true)}
                >
                  <i className="icon-grid_on"></i> Grid View
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 d-flex flex-column gap-2">
          {/* unused code starts */}
          {!listview === false ? null : (
            <div className="w-100 overflow-x-auto overflow-y-hidden">
              <div>
                {/* =======================heading========================= */}
                <div
                  className="d-flex justify-content-between text-center"
                  style={{ width: "2000px" }}
                >
                  <div className="fw-bold border px-1" style={{ width: "25%" }}>
                    Scheme Code/Name
                  </div>
                  <div
                    className="fw-bold border px-1"
                    style={{ width: "25%" }}
                  ></div>
                  <div className="fw-bold border px-1" style={{ width: "25%" }}>
                    Vendor
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "25%" }}>
                    DBT/Nach
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "25%" }}>
                    Salary
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "25%" }}>
                    Child_Allocation
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "25%" }}>
                    Total
                  </div>
                </div>

                {/* ===========================datas========================= */}

                <div
                  className="d-flex border-0 text-center flex-column position-relative"
                  style={
                    tableAccordian ? null : { height: "77px", width: "2000px" }
                  }
                >
                  <div className="d-flex " style={{ width: "2000px" }}>
                    <div className="border" style={{ width: "25%" }}>
                      {!tableAccordian ? (
                        <i
                          className="icon-arrow_right cursor-pointer"
                          onClick={() => setTableAccordian(true)}
                        ></i>
                      ) : (
                        <i
                          className="icon-arrow_drop_down cursor-pointer"
                          onClick={() => setTableAccordian(false)}
                        ></i>
                      )}
                      1353 - radhe_radhe
                    </div>
                    <div className="" style={{ width: "25%" }}>
                      <div className="border">Current</div>
                      <div className="border">Previous</div>
                      <div className="border">Total</div>
                    </div>
                    <div className="" style={{ width: "25%" }}>
                      <div className="border">20000000000</div>
                      <div className="border">20000000000</div>
                      <div className="border">20000000000</div>
                    </div>
                    <div className="" style={{ width: "25%" }}>
                      <div className="border">20000000000</div>
                      <div className="border">20000000000</div>
                      <div className="border">20000000000</div>
                    </div>
                    <div className="" style={{ width: "25%" }}>
                      <div className="border">20000000000</div>
                      <div className="border">20000000000</div>
                      <div className="border">20000000000</div>
                    </div>
                    <div className="" style={{ width: "25%" }}>
                      <div className="border">20000000000</div>
                      <div className="border">20000000000</div>
                      <div className="border">20000000000</div>
                    </div>
                    <div className="" style={{ width: "25%" }}>
                      <div className="border">20000000000</div>
                      <div className="border">20000000000</div>
                      <div className="border">20000000000</div>
                    </div>
                  </div>

                  {/* ====================child datas================================== */}

                  <div
                    className="overflow-hidden"
                    style={{
                      background: "#e6f2ff",
                      width: "2000px",
                      height: tableChildAccordian ? null : "59px",
                    }}
                  >
                    <div className="d-flex flex-wrap text-center">
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        {" "}
                        Sub head
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Vendor
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        DBT/Nach
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Salary
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Budget Allocation
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Total
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="border p-1" style={{ width: "200px" }}>
                        {!tableChildAccordian ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setTableChildAccordian(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() => setTableChildAccordian(false)}
                          ></i>
                        )}
                        1353
                      </div>
                      <div
                        className="border p-1"
                        style={{ width: "200px" }}
                      ></div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        radhe radhe
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                    </div>

                    {/* ==============================sub child============================== */}

                    <div className="d-flex">
                      <div className="border">
                        <div className="" style={{ width: "200px" }}></div>
                        <div className="" style={{ width: "200px" }}></div>
                        <div className="" style={{ width: "200px" }}></div>
                      </div>
                      <div className="border">
                        <div className="border p-1" style={{ width: "200px" }}>
                          Current
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          Previous
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          Total
                        </div>
                      </div>
                      <div className="border">
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                      </div>
                      <div className="border">
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                      </div>
                      <div className="border">
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                      </div>
                      <div className="border">
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                      </div>
                      <div className="border">
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "200px" }}>
                          20000000000
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      background: "#cce5ff",
                      width: "2000px",
                      height: tableSubChildAccordian ? null : "59px",
                    }}
                  >
                    <div className="d-flex text-center">
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Activity
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Vendor
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        DBT/Nach
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Salary
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Budget Allocation
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Total
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="border p-1" style={{ width: "200px" }}>
                        {!tableSubChildAccordian ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setTableSubChildAccordian(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() => setTableSubChildAccordian(false)}
                          ></i>
                        )}
                        1353
                      </div>
                      <div
                        className="border p-1"
                        style={{ width: "200px" }}
                      ></div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        radhe radhe
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                    </div>

                    {/* ========================================grand child================================= */}

                    <div>
                      <div className="d-flex">
                        <div className="border">
                          <div className="" style={{ width: "200px" }}></div>
                          <div className="" style={{ width: "200px" }}></div>
                          <div className="" style={{ width: "200px" }}></div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            Current
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            Previous
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            Total
                          </div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className=""
                    style={{
                      background: "#b3d7ff",
                      width: "2000px",
                      height: tableSubGrandChildAccordian ? null : "59px",
                    }}
                  >
                    <div className="d-flex text-center">
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        sub Activity
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      ></div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Vendor
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        DBT/Nach
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Salary
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Budget Allocation
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "200px" }}
                      >
                        Total
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="border p-1" style={{ width: "200px" }}>
                        {!tableSubGrandChildAccordian ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setTableSubGrandChildAccordian(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() =>
                              setTableSubGrandChildAccordian(false)
                            }
                          ></i>
                        )}
                        1353
                      </div>
                      <div
                        className="border p-1"
                        style={{ width: "200px" }}
                      ></div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        radhe radhe
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "200px" }}>
                        20000000000
                      </div>
                    </div>

                    {/* ========================================grand child================================= */}

                    <div>
                      <div className="d-flex">
                        <div className="border">
                          <div className="" style={{ width: "200px" }}></div>
                          <div className="" style={{ width: "200px" }}></div>
                          <div className="" style={{ width: "200px" }}></div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            Current
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            Previous
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            Total
                          </div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                        </div>
                        <div className="border">
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                          <div
                            className="border p-1"
                            style={{ width: "200px" }}
                          >
                            20000000000
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* unused code ends */}
          {/* ======================== card view utilized budget ========================== */}

          {listview &&
            utilizedBudgetData?.map((val, ind) => (
              <div
                className="bg-white border-Prime-100 "
                style={{ borderRadius: "8px" }}
                key={val?.id}
              >
                <div
                  className="bg-prime-20 d-flex justify-content-between align-items-center p-2 transition overflow-hidden"
                  style={{ borderRadius: "8px 8px 0 0" }}
                >
                  {/* ======================card header================== */}
                  <span
                    className="fs-14px white-space-nowrap overflow-x-hidden"
                    style={{ width: "95%", fontStyle: "italic" }}
                  >
                    {/* =======================card header====================== */}
                    <span
                      className="fs-14px white-space-nowrap overflow-x-hidden "
                      style={{ width: "95%", fontStyle: "italic" }}
                    >
                      {val?.code}- {val?.name}
                    </span>
                  </span>

                  <span>
                    <i className="icon-remove_red_eye cursor-pointer"></i>
                  </span>
                </div>

                {/* ================== card body ========================= */}
                 {/* modification required in quartly data */}
                <div className="row py-2 px-3">
                  <div className="col-3 px-2">
                    <div className="border border-primary rounded-2 p-1">
                      <div className="fs-12px text-black-60 p-1">Quarter 1</div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "04" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">April</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[0]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[0]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "05" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">May</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[1]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[1]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "06" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">June</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[2]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[2]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-1 bg-success-20">
                        <span className="fw-500 fs-14px">Total</span>
                        <span className="fw-500 fs-14px text-success-100">
                          {Number(val?.monthlyData?.[0]?.amount) +
                            Number(val?.monthlyData?.[1]?.amount) +
                            Number(val?.monthlyData?.[2]?.amount) ==
                          0
                            ? "-"
                            : formatNumber(
                                (Number(val?.monthlyData?.[0]?.amount) +
                                  Number(val?.monthlyData?.[1]?.amount) +
                                  Number(val?.monthlyData?.[2]?.amount)) /
                                  dividerNumber,
                                "india"
                              )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-3 px-2">
                    <div className="border border-primary rounded-2 p-1">
                      <div className="fs-12px text-black-60 p-1">Quarter 2</div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "07" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">July</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[3]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[3]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "08" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">August</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[4]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[4]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "09" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">September</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[5]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[5]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-1 bg-success-20">
                        <span className="fw-500 fs-14px">Total</span>
                        <span className="fw-500 fs-14px text-success-100">
                          {Number(val?.monthlyData?.[3]?.amount) +
                            Number(val?.monthlyData?.[4]?.amount) +
                            Number(val?.monthlyData?.[5]?.amount) ==
                          0
                            ? "-"
                            : formatNumber(
                                (Number(val?.monthlyData?.[3]?.amount) +
                                  Number(val?.monthlyData?.[4]?.amount) +
                                  Number(val?.monthlyData?.[5]?.amount)) /
                                  dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-3 px-2">
                    <div className="border border-primary rounded-2 p-1">
                      <div className="fs-12px text-black-60 p-1">Quarter 3</div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "10" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">October</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[6]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[6]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "11" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">November</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[7]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[7]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "12" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">December</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[8]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[8]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-1 bg-success-20">
                        <span className="fw-500 fs-14px">Total</span>
                        <span className="fw-500 fs-14px text-success-100">
                          {Number(val?.monthlyData?.[6]?.amount) +
                            Number(val?.monthlyData?.[7]?.amount) +
                            Number(val?.monthlyData?.[8]?.amount) ==
                          0
                            ? "-"
                            : formatNumber(
                                (Number(val?.monthlyData?.[6]?.amount) +
                                  Number(val?.monthlyData?.[7]?.amount) +
                                  Number(val?.monthlyData?.[8]?.amount)) /
                                  dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-3 px-2">
                    <div className="border border-primary rounded-2 p-1">
                      <div className="fs-12px text-black-60 p-1">Quarter 4</div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "01" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">January</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[9]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[9]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "02" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">February</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[10]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[10]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div
                        className={`d-flex justify-content-between align-items-center p-1 ${
                          utlizedMonth == "03" && "bg-secondary-20"
                        } `}
                      >
                        <span className="fw-500 fs-14px">March</span>
                        <span className="fw-500 fs-14px">
                          {val?.monthlyData[11]?.amount == 0
                            ? "-"
                            : formatNumber(
                                val?.monthlyData[11]?.amount / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div className="d-flex justify-content-between align-items-center p-1 bg-success-20">
                        <span className="fw-500 fs-14px">Total</span>
                        <span className="fw-500 fs-14px text-success-100">
                          {Number(val?.monthlyData?.[9]?.amount) +
                            Number(val?.monthlyData?.[10]?.amount) +
                            Number(val?.monthlyData?.[11]?.amount) ==
                          0
                            ? "-"
                            : formatNumber(
                                (Number(val?.monthlyData?.[9]?.amount) +
                                  Number(val?.monthlyData?.[10]?.amount) +
                                  Number(val?.monthlyData?.[11]?.amount)) /
                                  dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="py-2 px-3">
                  <div className="row fs-12px text-black-60 p-1">
                    <div className="col-2"></div>
                    <div className="col-2">Vendor</div>
                    <div className="col-2">DBT/Nach</div>
                    <div className="col-2">Salary</div>
                    <div className="col-2">Budget Allocation</div>
                    <div className="col-2">Total</div>
                  </div>
                  <div className="row fs-14px p-1 fw-500">
                    <div className="col-2 text-prime-100 fs-12px">
                      FY {fy_list[storedGlobalDateId]?.year}
                    </div>
                    <div className="col-2">
                      {val?.currentFy?.vendor == 0
                        ? "-"
                        : formatNumber(
                            val?.currentFy?.vendor / dividerNumber,
                            "indian"
                          )}
                    </div>
                    <div className="col-2">
                      {val?.currentFy?.dbt == 0
                        ? "-"
                        : formatNumber(
                            val?.currentFy?.dbt / dividerNumber,
                            "indian"
                          )}
                    </div>
                    <div className="col-2">-</div>
                    <div className="col-2">
                      {val?.currentFy?.child == 0
                        ? "-"
                        : formatNumber(
                            val?.currentFy?.child / dividerNumber,
                            "indian"
                          )}
                    </div>
                    <div className="col-2 text-success-100">
                      {Number(val?.currentFy?.vendor) +
                        Number(val?.currentFy?.dbt) +
                        Number(val?.currentFy?.child) ==
                      0
                        ? "-"
                        : formatNumber(
                            Number(
                              val?.currentFy?.vendor +
                                Number(val?.currentFy?.dbt) +
                                Number(val?.currentFy?.child)
                            ) / dividerNumber,
                            "indian"
                          )}
                    </div>
                  </div>
                  <div className="row fs-14px p-1 fw-500">
                    <div className="col-2 text-prime-100 fs-12px">
                      Previous Year Balance
                    </div>
                    <div className="col-2">
                      {val?.prevYear?.vendor == 0
                        ? "-"
                        : formatNumber(
                            val?.prevYear?.vendor / dividerNumber,
                            "indian"
                          )}
                    </div>
                    <div className="col-2">
                      {val?.prevYear?.dbt == 0
                        ? "-"
                        : formatNumber(
                            val?.prevYear?.dbt / dividerNumber,
                            "indian"
                          )}
                    </div>
                    <div className="col-2">-</div>
                    <div className="col-2">
                      {val?.prevYear?.child == 0
                        ? "-"
                        : formatNumber(
                            val?.prevYear?.child / dividerNumber,
                            "indian"
                          )}
                    </div>
                    <div className="col-2 text-success-100">
                      {Number(val?.prevYear?.vendor) +
                        Number(val?.prevYear?.dbt) +
                        Number(val?.prevYear?.child) ==
                      0
                        ? "-"
                        : formatNumber(
                            (Number(val?.prevYear?.vendor) +
                              Number(val?.prevYear?.dbt) +
                              Number(val?.prevYear?.child)) /
                              dividerNumber,
                            "indian"
                          )}
                    </div>
                  </div>
                  <div className="row fs-14px bg-info-20 py-1 px-1 fw-500">
                    <div className="col-2 text-prime-100 fs-12px">Total</div>
                    <div className="col-2">
                      {Number(val?.currentFy?.vendor) +
                        Number(val?.prevYear?.vendor) ==
                      0
                        ? "-"
                        : formatNumber(
                            (Number(val?.currentFy?.vendor) +
                              Number(val?.prevYear?.vendor)) /
                              dividerNumber,
                            "indian"
                          )}
                    </div>

                    <div className="col-2">
                      {Number(val?.currentFy?.dbt) +
                        Number(val?.prevYear?.dbt) ==
                      0
                        ? "-"
                        : formatNumber(
                            (Number(val?.currentFy?.dbt) +
                              Number(val?.prevYear?.dbt)) /
                              dividerNumber,
                            "indian"
                          )}
                    </div>
                    <div className="col-2">-</div>
                    <div className="col-2">
                      {Number(val?.currentFy?.child) +
                        Number(val?.prevYear?.child) ==
                      0
                        ? "-"
                        : formatNumber(
                            (Number(val?.currentFy?.child) +
                              Number(val?.prevYear?.child)) /
                              dividerNumber,
                            "indian"
                          )}
                    </div>
                    <div className="col-2 text-success-100">
                      {Number(val?.currentFy?.vendor) +
                        Number(val?.currentFy?.dbt) +
                        Number(val?.currentFy?.child) +
                        Number(val?.prevYear?.vendor) +
                        Number(val?.prevYear?.dbt) +
                        Number(val?.prevYear?.child) ==
                      0
                        ? "-"
                        : formatNumber(
                            (Number(val?.currentFy?.vendor) +
                              Number(val?.currentFy?.dbt) +
                              Number(val?.currentFy?.child) +
                              Number(val?.prevYear?.vendor) +
                              Number(val?.prevYear?.dbt) +
                              Number(val?.prevYear?.child)) /
                              dividerNumber,
                            "indian"
                          )}
                    </div>
                  </div>

                  {/* ===================card footer ==================== */}
                  <div className="border mx-2 mt-2"></div>

                  {/* =======================buttons ====================== */}

                  <div className="p-2 d-flex justify-content-between text-secondary-50 px-4">
                    <button
                      onClick={() => handleButtonClickBudgetChild(val?.id, "1")}
                      className={` ${
                        activeUtilizedBudgetChild[val?.id] == 1
                          ? "bg-secondary-20"
                          : "bg-white"
                      }  border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1 rounded-4`}
                    >
                      {activeUtilizedBudgetChild[val?.id] == 1 ? (
                        <i className="icon-remove_circle_outline"></i>
                      ) : (
                        <i className="icon-add_circle_outline"></i>
                      )}
                      Sub head
                    </button>

                    {val?.is_activity_active && (
                        <>
                          <button
                            onClick={() =>
                              handleButtonClickBudgetChild(val?.id, "2")
                            }
                            className={`${
                              activeUtilizedBudgetChild[val?.id] == 2
                                ? "bg-secondary-20"
                                : "bg-white"
                            } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1  rounded-4`}
                          >
                            {activeUtilizedBudgetChild[val?.id] == 2 ? (
                              <i className="icon-remove_circle_outline"></i>
                            ) : (
                              <i className="icon-add_circle_outline"></i>
                            )}
                            Activity
                          </button>

                          <button
                            onClick={() =>
                              handleButtonClickBudgetChild(val?.id, "3")
                            }
                            className={`${
                              activeUtilizedBudgetChild[val?.id] == 3
                                ? "bg-secondary-20"
                                : "bg-white"
                            } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1  rounded-4`}
                          >
                            {activeUtilizedBudgetChild[val?.id] == 3 ? (
                              <i className="icon-remove_circle_outline"></i>
                            ) : (
                              <i className="icon-add_circle_outline"></i>
                            )}
                            Sub-Activity
                          </button>
                        </>
                      )}
                  </div>

                  {/* ==============================subhead data================================ */}
                  {
                    activeUtilizedBudgetChild[val?.id] == "1" &&

                  <div className="row fs-12px text-black-60 p-1 mt-1">
                    <div className="col-2">Sub Head </div>
                    <div className="col-2">Vendor</div>
                    <div className="col-2">DBT/Nach</div>
                    <div className="col-2">Salary</div>
                    <div className="col-2">Budget Allocation</div>
                    <div className="col-2">Total</div>
                  </div>
                  }

                  {activeUtilizedBudgetChild[val?.id] == "1" &&
                    val?.subheads?.map((vals, ind) => (
                      <div key={vals?.id}>
                        <div
                          className="row fs-14px p-1 fw-500 overflow-hidden"
                          style={{
                            background: "#F8F8F8",
                            height: subheadAccording[vals.id]
                              ? "fit-content"
                              : "35px",
                          }}
                        >
                          <div className="col-2 d-flex align-items-center">
                            {subheadAccording ? (
                              <i
                                className="icon-keyboard_arrow_right
                               cursor-pointer"
                                onClick={() => toggleSubheadAccordion(vals?.id)}
                              ></i>
                            ) : (
                              <i
                                className="icon-keyboard_arrow_down cursor-pointer"
                                onClick={() => toggleSubheadAccordion(vals?.id)}
                              ></i>
                            )}

                            <span>{addElipsis(vals?.name, 13)}</span>
                          </div>
                          <div className="col-2">
                            {Number(vals?.current_fy?.vendor) +
                              Number(vals.from_prev_fy?.vendor) ==
                            0
                              ? "-"
                              : formatNumber(
                                  (Number(vals?.current_fy?.vendor) +
                                    Number(vals.from_prev_fy?.vendor)) /
                                    dividerNumber,
                                  "indian"
                                )}
                          </div>

                          <div className="col-2">
                            {Number(vals.current_fy?.dbt) +
                              Number(vals.from_prev_fy?.dbt) ==
                            0
                              ? "-"
                              : formatNumber(
                                  (Number(vals.current_fy?.dbt) +
                                    Number(vals.from_prev_fy?.dbt)) /
                                    dividerNumber,
                                  "indian"
                                )}
                          </div>

                          <div className="col-2">-</div>
                          <div className="col-2">
                            {Number(vals?.current_fy?.child) +
                              Number(vals?.from_prev_fy?.child) ==
                            0
                              ? "-"
                              : formatNumber(
                                  (Number(vals?.current_fy?.child) +
                                    Number(vals?.from_prev_fy?.child)) /
                                    dividerNumber,
                                  "indian"
                                )}
                          </div>

                          <div className="col-2 text-success-100">
                            {Number(vals?.current_fy?.vendor) +
                              Number(vals?.current_fy?.dbt) +
                              Number(vals?.current_fy?.child) +
                              Number(vals?.from_prev_fy?.vendor) +
                              Number(vals?.from_prev_fy?.dbt) +
                              Number(vals?.from_prev_fy?.child) ==
                            0
                              ? "-"
                              : formatNumber(
                                  (Number(vals?.current_fy?.vendor) +
                                    Number(vals?.current_fy?.dbt) +
                                    Number(vals?.current_fy?.child) +
                                    Number(vals?.from_prev_fy?.vendor) +
                                    Number(vals?.from_prev_fy?.dbt) +
                                    Number(vals?.from_prev_fy?.child)) /
                                    dividerNumber,
                                  "indian"
                                )}
                          </div>
                          <div className="mt-1">
                            <div className="row fs-14px p-1 fw-500">
                              <div className="col-2 text-prime-100 fs-12px">
                                FY {fy_list[storedGlobalDateId]?.year}
                              </div>
                              <div className="col-2">
                                {vals?.current_fy?.vendor == 0
                                  ? "-"
                                  : formatNumber(
                                      vals?.current_fy?.vendor / dividerNumber,
                                      "indian"
                                    )}
                              </div>
                              <div className="col-2">
                                {vals.current_fy?.dbt == 0
                                  ? "-"
                                  : formatNumber(
                                      vals.current_fy?.dbt / dividerNumber,
                                      "indian"
                                    )}
                              </div>
                              <div className="col-2">-</div>
                              <div className="col-2">
                                {vals?.current_fy?.child == 0
                                  ? "-"
                                  : formatNumber(
                                      vals?.current_fy?.child / dividerNumber,
                                      "indian"
                                    )}
                              </div>
                              <div className="col-2 text-success-100">
                                {Number(vals?.current_fy?.vendor) +
                                  Number(vals?.current_fy?.dbt) +
                                  Number(vals?.current_fy?.child) ==
                                0
                                  ? "-"
                                  : formatNumber(
                                      (Number(vals?.current_fy?.vendor) +
                                        Number(vals?.current_fy?.dbt) +
                                        Number(vals?.current_fy?.child)) /
                                        dividerNumber,
                                      "indian"
                                    )}
                              </div>
                            </div>
                            {/* =======================previous year balance =================== */}
                            <div className="row fs-14px p-1 fw-500">
                              <div className="col-2 text-prime-100 fs-12px">
                                Previous Year Balance
                              </div>
                              <div className="col-2">
                                {vals?.from_prev_fy?.vendor == 0
                                  ? "-"
                                  : formatNumber(
                                      vals?.from_prev_fy?.vendor /
                                        dividerNumber,
                                      "indian"
                                    )}
                              </div>
                              <div className="col-2">
                                {vals?.from_prev_fy?.dbt == 0
                                  ? "-"
                                  : formatNumber(
                                      vals?.from_prev_fy?.dbt / dividerNumber,
                                      "indian"
                                    )}
                              </div>
                              <div className="col-2">-</div>
                              <div className="col-2">
                                {vals?.from_prev_fy?.child == 0
                                  ? "-"
                                  : formatNumber(
                                      vals?.from_prev_fy?.child / dividerNumber,
                                      "indian"
                                    )}
                              </div>
                              <div className="col-2 text-success-100">
                                {formatNumber(
                                  Number(vals?.from_prev_fy?.vendor) +
                                    Number(vals?.from_prev_fy?.dbt) +
                                    Number(vals?.from_prev_fy?.child)
                                ) == 0
                                  ? "-"
                                  : formatNumber(
                                      (Number(vals?.from_prev_fy?.vendor) +
                                        Number(vals?.from_prev_fy?.dbt) +
                                        Number(vals?.from_prev_fy?.child)) /
                                        dividerNumber,
                                      "indian"
                                    )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    {
                      activeUtilizedBudgetChild[val?.id] == "1" &&
                  <div className="row fs-14px bg-info-20 py-1 px-1 fw-500">
                    <div className="col-2 text-prime-100 fs-12px">Total</div>
                    <div className="col-2">
                      {Number(val?.currentFy?.vendor) +
                        Number(val?.prevYear?.vendor) ==
                      0
                        ? "-"
                        : formatNumber(
                            (Number(val?.currentFy?.vendor) +
                              Number(val?.prevYear?.vendor)) /
                              dividerNumber,
                            "indian"
                          )}
                    </div>

                    <div className="col-2">
                      {Number(val?.currentFy?.dbt) +
                        Number(val?.prevYear?.dbt) ==
                      0
                        ? "-"
                        : formatNumber(
                            (Number(val?.currentFy?.dbt) +
                              Number(val?.prevYear?.dbt)) /
                              dividerNumber,
                            "indian"
                          )}
                    </div>
                    <div className="col-2">-</div>
                    <div className="col-2">
                      {Number(val?.currentFy?.child) +
                        Number(val?.prevYear?.child) ==
                      0
                        ? "-"
                        : formatNumber(
                            (Number(val?.currentFy?.child) +
                              Number(val?.prevYear?.child)) /
                              dividerNumber,
                            "indian"
                          )}
                    </div>
                    <div className="col-2 text-success-100">
                      {Number(val?.currentFy?.vendor) +
                        Number(val?.currentFy?.dbt) +
                        Number(val?.currentFy?.child) +
                        Number(val?.prevYear?.vendor) +
                        Number(val?.prevYear?.dbt) +
                        Number(val?.prevYear?.child) ==
                      0
                        ? "-"
                        : formatNumber(
                            (Number(val?.currentFy?.vendor) +
                              Number(val?.currentFy?.dbt) +
                              Number(val?.currentFy?.child) +
                              Number(val?.prevYear?.vendor) +
                              Number(val?.prevYear?.dbt) +
                              Number(val?.prevYear?.child)) /
                              dividerNumber,
                            "indian"
                          )}
                    </div>
                  </div>
                    }

                  {/* ==============================utilizedBudget Fy ============================== */}

                  {activeUtilizedBudgetChild[val?.id] == "2" && (
                      val?.subheads?.map((val, ind)=>(
                        <Accordion activeKey={activeKeys} key={val?.id} style={{ border: "0" }}>
                      <Accordion.Item
                        eventKey={val?.id}
                        style={{ borderRadius: "0", border: "0" }}
                      >
                        <Accordion.Header
                          onClick={() => toggleAccordion(val?.id)}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "relative",
                            borderRadius: "0",
                          }}
                        >
                          <div className="w-100 d-flex justify-content-between">
                            <span>{val?.name}</span>
                            <span
                              className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                              style={{ height: "13.33px", width: "13.33px" }}
                            >
                              {activeKeys.includes("6") ? "-" : "+"}
                            </span>
                          </div>
                        </Accordion.Header>
                          <UtilizedBudgetOverlayActivity fy_list={fy_list} setSubheadAccording={switchHeight} subheadAccording={subheadAccording} storedGlobalDateId={storedGlobalDateId} activities={val?.activities}/>
                      </Accordion.Item>
                    </Accordion>
                      ))
                  )}
                  {activeUtilizedBudgetChild[val?.id] == "3" && (
                    <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                      <Accordion.Item
                        eventKey="6"
                        style={{ borderRadius: "0", border: "0" }}
                      >
                        <Accordion.Header
                          onClick={() => toggleAccordion("6")}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "relative",
                            borderRadius: "0",
                          }}
                        >
                          <div className="w-100 d-flex justify-content-between">
                            <span>Sub head 01 | Activity Name 01</span>
                            <span
                              className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                              style={{ height: "13.33px", width: "13.33px" }}
                            >
                              {activeKeys.includes("6") ? "-" : "+"}
                            </span>
                          </div>
                        </Accordion.Header>

                        <Accordion.Body className="p-2">
                          <div className="row fs-12px text-black-60 p-1">
                            <div className="col-2">Activity</div>
                            <div className="col-2">Vendor</div>
                            <div className="col-2">DBT</div>
                            {/* <div className="col-2">Salary</div> */}
                            <div className="col-2">Budget Allocation</div>
                            <div className="col-2">Total</div>
                          </div>

                          <div
                            className="row fs-14px p-1 fw-500 overflow-hidden"
                            style={{
                              background: "#F8F8F8",
                              height: subheadAccording ? "35px" : "fit-content",
                            }}
                          >
                            <div className="col-2 d-flex align-items-center">
                              {subheadAccording ? (
                                <i
                                  className="icon-keyboard_arrow_right cursor-pointer"
                                  onClick={() =>
                                    setSubheadAccording((prev) => !prev)
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="icon-keyboard_arrow_down cursor-pointer"
                                  onClick={() =>
                                    setSubheadAccording((prev) => !prev)
                                  }
                                ></i>
                              )}
                              <span>name</span>
                            </div>
                            <div className="col-2">Bla bla bla</div>
                            <div className="col-2">2,000,000,000.00</div>
                            {/* <div className="col-2">2,000,000,000.00</div> */}
                            <div className="col-2">2,000,000,000.00</div>
                            <div className="col-2 text-success-100">
                              2,000,000,000.00
                            </div>
                            <div className="mt-1">
                              <div className="row fs-14px p-1 fw-500">
                                <div className="col-2 text-prime-100 fs-12px">
                                  FY {fy_list[storedGlobalDateId]?.year}
                                </div>
                                <div className="col-2">Bla bla bla</div>
                                <div className="col-2">2,000,000,000.00</div>
                                {/* <div className="col-2">2,000,000,000.00</div> */}
                                <div className="col-2">2,000,000,000.00</div>
                                <div className="col-2 text-success-100">
                                  2,000,000,000.00
                                </div>
                              </div>
                              <div className="row fs-14px p-1 fw-500">
                                <div className="col-2 text-prime-100 fs-12px">
                                  Previous Year Balance
                                </div>
                                <div className="col-2">Bla bla bla</div>
                                <div className="col-2">2,000,000,000.00</div>
                                <div className="col-2">2,000,000,000.00</div>
                                {/* <div className="col-2">2,000,000,000.00</div> */}
                                <div className="col-2 text-success-100">
                                  2,000,000,000.00
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row fs-14px p-1 fw-500 bg-info-20">
                            <div className="col-2 text-prime-100 fs-12px">
                              Total
                            </div>
                            <div className="col-2">Bla bla bla</div>
                            <div className="col-2">2,000,000,000.00</div>
                            {/* <div className="col-2">2,000,000,000.00</div> */}
                            <div className="col-2">2,000,000,000.00</div>
                            <div className="col-2 text-success-100">
                              2,000,000,000.00
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* =====================scheme balance overlay========================== */}

      <div className={`overlay   ${schemebalance ? "d-none" : null}  `}></div>

      <div
        className={`overlayContent overflow-auto overflow-x-hidden ${
          schemebalance ? "left-100" : null
        } `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-secondary-100 position-relative text-white d-flex justify-content-between align-items-center p-3 position-sticky top-0 z-3">
          <div className="fw-600 fs-16px d-flex align-items-center gap-2 ">
            <i
              className="icon-close_circle_fill fs-5  cursor-pointer"
              onClick={() => setSchemebalance(true)}
            ></i>
            Scheme Balance Budget FY
            <select
              name="financial_year_id"
              onChange={handleYearDropdown}
              className="bg-transparent border-0 text-white fw-500 fs-16px select"
              disabled
            >
              <option value="" className="text-black">
                select year
              </option>
              {fy_list?.map((fy) => {
                return (
                  <option
                    value={storedGlobalDateId}
                    key={fy.id}
                    className="text-black"
                    selected={
                      String(fy.id) === storedGlobalDateId ||
                      (!storedGlobalDateId &&
                        fy.start_year === moment().format("YYYY"))
                    }
                  >
                    {fy.year}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="d-flex gap-2 align-items-center ">
            <span>
              As on date{" "}
              {
                <select
                  name="financial_year_id"
                  className="bg-transparent border-0 text-white fw-500 fs-16px select"
                  disabled
                >
                  <option value="" className="text-black">
                    select year
                  </option>
                  {fy_list?.map((fy) => {
                    return (
                      <option
                        value={storedGlobalDateId}
                        key={fy.id}
                        className="text-black"
                        selected={
                          String(fy.id) === storedGlobalDateId ||
                          (!storedGlobalDateId &&
                            fy.start_year === moment().format("YYYY"))
                        }
                      >
                        {fy.end_year === moment().format("YYYY")
                          ? moment().format("Do MMM YYYY")
                          : "31 Mar " + fy.end_year}
                      </option>
                    );
                  })}
                </select>
              }
            </span>
            <i
              className="icon-arrow_drop_down_circle fs-18px cursor-pointer"
              onClick={() => setOverlayDropDown((prev) => !prev)}
            ></i>

            <div
              className={`position-absolute bg-prime-100 w-100 p-3 ${
                !overlayDropDown ? "d-none" : "d-inline-block"
              }`}
              style={{ top: "100%", zIndex: "10" }}
            >
              <div className="d-flex flex-column gap-4">
                <span className="d-flex align-items-center gap-2">
                  {" "}
                  <i className="icon-featured_play_list"></i> Export Excel
                </span>
                <span className="d-flex align-items-center gap-2">
                  {" "}
                  <i className="icon-picture_as_pdf"></i> Export PDF
                </span>
                <span
                  className="d-flex align-items-center gap-2 cursor-pointer"
                  onClick={() => setListview(false)}
                >
                  {" "}
                  <i className="icon-view_list"></i> List View
                </span>
                <span
                  className="d-flex align-items-center gap-2 cursor-pointer"
                  onClick={() => setListview(true)}
                >
                  {" "}
                  <i className="icon-grid_on"></i> Grid View
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 d-flex flex-column gap-2">
          {!listview === false ? null : (
            <div className="w-100 overflow-x-auto">
              <div>
                {/* =======================heading========================= */}
                <div className="d-flex justify-content-between border-0 text-center">
                  <div className="fw-bold border px-1" style={{ width: "20%" }}>
                    Scheme Code
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "20%" }}>
                    Scheme Name
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "20%" }}>
                    FY {fy_list[storedGlobalDateId]?.year}
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "20%" }}>
                    Previous FY
                  </div>
                  <div className="fw-bold border px-1" style={{ width: "20%" }}>
                    Total Balance
                  </div>
                </div>

                {/* ===========================datas========================= */}

                <div
                  className="d-flex border-0  text-center flex-column position-relative overflow-hidden"
                  style={tableAccordian ? null : { height: "34px" }}
                >
                  <div className="d-flex ">
                    <div className="border p-1" style={{ width: "20%" }}>
                      {!tableAccordian ? (
                        <i
                          className="icon-arrow_right cursor-pointer"
                          onClick={() => setTableAccordian(true)}
                        ></i>
                      ) : (
                        <i
                          className="icon-arrow_drop_down cursor-pointer"
                          onClick={() => setTableAccordian(false)}
                        ></i>
                      )}
                      1353
                    </div>
                    <div className="border p-1" style={{ width: "20%" }}>
                      radhe radhe
                    </div>
                    <div className="border p-1" style={{ width: "20%" }}>
                      20000000000
                    </div>
                    {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                    <div className="border p-1" style={{ width: "20%" }}>
                      20000000000
                    </div>
                    <div className="border p-1" style={{ width: "20%" }}>
                      20000000000
                    </div>
                  </div>

                  {/* ====================child datas================================== */}
                  <div
                    className=""
                    style={{
                      background: "silver",
                      height: tableChildAccordian ? null : "59px",
                    }}
                  >
                    <div className="d-flex justify-content-between text-center">
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "25%" }}
                      >
                        Sub Head
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "25%" }}
                      >
                        Release Fy {fy_list[storedGlobalDateId]?.year}
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "25%" }}
                      >
                        Previous FY Balance
                      </div>
                      <div
                        className="fw-bold border px-1"
                        style={{ width: "25%" }}
                      >
                        Total Balance
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="border p-1" style={{ width: "25%" }}>
                        {!tableChildAccordian ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setTableChildAccordian(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() => setTableChildAccordian(false)}
                          ></i>
                        )}
                        1353
                      </div>
                      <div className="border p-1" style={{ width: "25%" }}>
                        radhe radhe
                      </div>
                      <div className="border p-1" style={{ width: "25%" }}>
                        20000000000
                      </div>
                      <div className="border p-1" style={{ width: "25%" }}>
                        20000000000
                      </div>
                    </div>

                    {/* ==============================sub child============================== */}

                    <div
                      className="bg-success"
                      style={{ height: tableSubChildAccordian ? null : "59px" }}
                    >
                      <div className="d-flex justify-content-between text-center">
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Activity
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          FY {fy_list[storedGlobalDateId]?.year}
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Previous FY Balance
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Total Balance
                        </div>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div className="border p-1" style={{ width: "25%" }}>
                          {!tableSubChildAccordian ? (
                            <i
                              className="icon-arrow_right cursor-pointer"
                              onClick={() => setTableSubChildAccordian(true)}
                            ></i>
                          ) : (
                            <i
                              className="icon-arrow_drop_down cursor-pointer"
                              onClick={() => setTableSubChildAccordian(false)}
                            ></i>
                          )}
                          1353
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          radhe radhe
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                      </div>

                      {/* ========================================grand child================================= */}

                      <div className="bg-primary">
                        <div className="d-flex justify-content-between text-center">
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "25%" }}
                          >
                            Sub Activity
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "25%" }}
                          >
                            Current Fy {fy_list[storedGlobalDateId]?.year}
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "25%" }}
                          >
                            Previous FY Balance
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "25%" }}
                          >
                            Total Balance
                          </div>
                        </div>

                        <div className="d-flex justify-content-between">
                          <div className="border p-1" style={{ width: "25%" }}>
                            1353
                          </div>
                          <div className="border p-1" style={{ width: "25%" }}>
                            radhe radhe
                          </div>
                          <div className="border p-1" style={{ width: "25%" }}>
                            20000000000
                          </div>
                          <div className="border p-1" style={{ width: "25%" }}>
                            20000000000
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* ========================card view ========================== */}
          {listview === true && filteredSchemes === "No data found" ? (
            <span>No Data Found</span>
          ) : (
            filteredSchemes?.map((val, ind) => (
              <div
                className="bg-white border-Prime-100"
                style={{ borderRadius: "8px" }}
                key={val?.id}
              >
                <div
                  className="bg-prime-20 d-flex justify-content-between align-items-center p-2 transition overflow-hidden"
                  style={{ borderRadius: "8px 8px 0 0" }}
                >
                  {/* ======================card header================== */}
                  <span
                    className="fs-14px white-space-nowrap overflow-x-hidden"
                    style={{ width: "95%", fontStyle: "italic" }}
                  >
                    {/* =======================card header====================== */}
                    <span
                      className="fs-14px white-space-nowrap overflow-x-hidden "
                      style={{ width: "95%", fontStyle: "italic" }}
                    >
                      {val?.code}-{val?.name}
                    </span>
                  </span>

                  <span>
                    <i className="icon-remove_red_eye cursor-pointer"></i>
                  </span>
                </div>

                {/* ================== card body ========================= */}

                <div className="row py-2 px-3">
                  <div className="col d-flex flex-column">
                    <span className="fs-12px text-black-60">
                      FY {fy_list[storedGlobalDateId]?.year}
                    </span>
                    <span className="fs-14px fw-600 ">
                      {val?.total_budget
                        ? val.total_budget == 0
                          ? "-"
                          : formatNumber(
                              val.total_budget / dividerNumber,
                              "indian"
                            )
                        : val.total_budget == 0
                        ? "-"
                        : formatNumber(
                            val.total_budget / dividerNumber,
                            "indian"
                          )}
                    </span>
                  </div>
                  <div className="col d-flex flex-column">
                    <span className="fs-12px text-black-60 d-flex justify-content-between align-items-center">
                      Previous FY
                      <Tooltip
                        title={val?.prevYearBalance}
                        placement="top"
                        arrow
                      >
                        <i className="icon-info cursor-pointer"></i>
                      </Tooltip>
                    </span>
                    <span className="fs-14px fw-600">
                      {val?.prevYearBalance
                        ? val.prevYearBalance == 0
                          ? "-"
                          : formatNumber(
                              val.prevYearBalance / dividerNumber,
                              "indian"
                            )
                        : val.prevYearBalance == 0
                        ? "-"
                        : formatNumber(
                            val.prevYearBalance / dividerNumber,
                            "indian"
                          )}
                    </span>
                  </div>
                  <div className="col d-flex flex-column success-100 bg-success-20 py-1">
                    <span className="fs-12px text-black-60">Total Balance</span>
                    <span className="fs-14px fw-600 text-success-100">
                      {val?.prevYearBalance + val?.total_budget == 0
                        ? "-"
                        : formatNumber(
                            (val?.prevYearBalance + val?.total_budget) /
                              dividerNumber,
                            "indian"
                          )}
                    </span>
                  </div>
                </div>

                {/* ===================card footer ==================== */}

                {/* ============border============== */}
                <div className="border mx-2"></div>

                {/* =======================buttons ====================== */}

                <div className="p-2 d-flex justify-content-between text-secondary-50 px-4">
                  <button
                    onClick={() => handleScBalanceButtonClick(val?.id, "4")}
                    className={` ${
                      activeScBalanceCardChild[val?.id] == 4
                        ? "bg-secondary-20"
                        : "bg-white"
                    }  border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1 rounded-4`}
                  >
                    {activeScBalanceCardChild[val?.id] == 4 ? (
                      <i className="icon-remove_circle_outline"></i>
                    ) : (
                      <i className="icon-add_circle_outline"></i>
                    )}
                    Sub head
                  </button>
                  {val?.is_activity_active != "0" &&
                    val?.monitoring_level != null && (
                      <>
                        <button
                          onClick={() =>
                            handleScBalanceButtonClick(val?.id, "5")
                          }
                          className={`${
                            activeScBalanceCardChild[val?.id] == 5
                              ? "bg-secondary-20"
                              : "bg-white"
                          } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1  rounded-4`}
                        >
                          {activeScBalanceCardChild[val?.id] == 5 ? (
                            <i className="icon-remove_circle_outline"></i>
                          ) : (
                            <i className="icon-add_circle_outline"></i>
                          )}
                          Activity
                        </button>
                        <button
                          onClick={() =>
                            handleScBalanceButtonClick(val?.id, "6")
                          }
                          className={`${
                            activeScBalanceCardChild[val?.id] == 6
                              ? "bg-secondary-20"
                              : "bg-white"
                          } border-0 text-secondary-50 d-flex align-items-center gap-1 fs-12px text-secondary-50 px-2 py-1  rounded-4`}
                        >
                          {activeScBalanceCardChild[val?.id] == 6 ? (
                            <i className="icon-remove_circle_outline"></i>
                          ) : (
                            <i className="icon-add_circle_outline"></i>
                          )}
                          Sub-Activity
                        </button>
                      </>
                    )}
                </div>

                {/* ===========================contents============================== */}

                {activeScBalanceCardChild[val?.id] === "4" && (
                  <>
                    <div className="row pt-2 pb-1 px-3">
                      <div className="col d-flex flex-column pt-1">
                        <span className="fs-12px text-black-60">Sub Head</span>
                      </div>
                      <div className="col d-flex flex-column pt-1">
                        <span className="fs-12px text-black-60">
                          FY {fy_list[storedGlobalDateId]?.year}
                        </span>
                      </div>
                      <div className="col d-flex flex-column pt-1">
                        <span className="fs-12px text-black-60 d-flex justify-content-between align-items-center">
                          Previous FY Balance
                          <Tooltip
                            title={val?.prevYearBalance}
                            placement="top"
                            arrow
                          >
                            <i className="icon-info cursor-pointer"></i>
                          </Tooltip>
                        </span>
                      </div>
                      <div className="col d-flex flex-column pt-1">
                        <span className="fs-12px text-black-60">
                          Total Balance
                        </span>
                      </div>
                    </div>
                    {val?.sub_head_list?.map((sub) => (
                      <div className="row px-3 py-1">
                        <div className="col-3 fw-500 fs-14px">{sub?.name}</div>
                        <div className="col-3 fw-500 fs-14px">
                          {sub?.budget == 0
                            ? "-"
                            : formatNumber(
                                sub?.budget / dividerNumber,
                                "indian"
                              )}
                        </div>
                        <div className="col-3 fw-500 fs-14px">
                          {Number(sub?.budget - sub?.utilised_budget) == 0
                            ? "-"
                            : formatNumber(
                                Number(sub?.budget - sub?.utilised_budget) /
                                  dividerNumber,
                                "indian"
                              )}
                        </div>
                        <div className="col-3 fw-500 fs-14px text-success-100">
                          {Number(
                            (Number(sub?.budget) || 0) -
                              (Number(sub?.utilised_budget) || 0) +
                              (Number(sub?.budget) || 0)
                          ) == 0
                            ? "-"
                            : formatNumber(
                                Number(
                                  (Number(sub?.budget) || 0) -
                                    (Number(sub?.utilised_budget) || 0) +
                                    (Number(sub?.budget) || 0)
                                ) / dividerNumber,
                                "indian"
                              )}
                        </div>
                      </div>
                    ))}
                  </>
                )}

                {/* ============================================activity======================================== */}
                <div className="row py-2 px-3">
                  {activeScBalanceCardChild[val?.id] === "5" && (
                    <div className="d-flex flex-column gap-1">
                      {val?.sub_head_list?.map((val, ind) => {

                        const totalProvisionalBudget = val?.activity_budget?.reduce(
                         (acc, curr) => acc + (Number(curr?.provisional_budget) || 0),
                          0);
                        const totalReleasedBudget = val?.activity_budget?.reduce(
                         (acc, curr) => acc + (Number(curr?.budget) || 0),
                          0);
                        const totalPrevFyBalance = val?.activity_budget?.reduce(
                         (acc, curr) => acc + (Number(curr?.prev_balance) || 0),
                          0);

                      return ( <Accordion activeKey={activeKeys}>
                          <Accordion.Item
                            eventKey={val?.id}
                            style={{ borderRadius: "0", border: "0" }}
                          >
                            <Accordion.Header
                              onClick={() => toggleAccordion(val?.id)}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                borderRadius: "0",
                              }}
                            >
                              <div className="w-100 d-flex justify-content-between">
                                <span>{val?.name}</span>
                                <span
                                  className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                                  style={{
                                    height: "13.33px",
                                    width: "13.33px",
                                  }}
                                >
                                  {activeKeys.includes(val?.id) ? "-" : "+"}
                                </span>
                              </div>
                            </Accordion.Header>

                            <Accordion.Body className="p-2">
                              <div className="row p-0">
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Activity
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Provisional Budget{" "}
                                    {fy_list[storedGlobalDateId]?.year}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Released FY{" "}
                                    {fy_list[storedGlobalDateId]?.year}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Previous FY Balance
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Total
                                  </p>
                                </div>
                              </div>
                              {(() => {
                                // Filter and sum budgets for "(5)- वेतन"
                                const salaryActivities =
                                  val?.activity_budget?.filter(
                                    (item) => item?.activity === "(5)- वेतन"
                                  );

                                const summedSalaryData =
                                  salaryActivities?.reduce(
                                    (acc, curr) => ({
                                      provisional_budget:
                                        Number(acc.provisional_budget) +
                                        (Number(curr?.provisional_budget) || 0),
                                      budget:
                                        Number(acc.budget) +
                                        (Number(curr?.budget) || 0),
                                      prev_balance:
                                        Number(acc.prev_balance) +
                                        (Number(curr?.prev_balance) || 0),
                                    }),
                                    { provisional_budget: 0, budget: 0, prev_balance: 0 }
                                  );

                                // Creating a new dataset with summed salary data
                                let modifiedData = val?.activity_budget?.filter(
                                  (item) => item?.activity !== "(5)- वेतन"
                                );

                                if (salaryActivities.length > 0) {
                                  modifiedData = [
                                    {
                                      activity: "(5)- वेतन",
                                      provisional_budget:
                                        summedSalaryData.provisional_budget,
                                      budget: summedSalaryData.budget,
                                      prev_balance: summedSalaryData.prev_balance,
                                    },
                                    ...modifiedData, // Add remaining activities
                                  ];
                                }

                                // Mapping through modified data
                                return modifiedData?.map((item, ind) => (
                                  <div className="row p-0" key={ind}>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {item?.activity}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {item?.provisional_budget === 0
                                          ? "-"
                                          : formatNumber(
                                              item?.provisional_budget /
                                                dividerNumber,
                                              "indian"
                                            )}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {item?.budget === 0
                                          ? "-"
                                          : formatNumber(
                                              item?.budget / dividerNumber,
                                              "indian"
                                            )}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                      {item?.prev_balance == 0
                                          ? "-"
                                          : formatNumber(
                                              Number(item?.prev_balance) / dividerNumber,
                                              "indian"
                                            )}
                                            {/* <span>number {item?.prev_balance}</span> */}
                                      </p>
                                    </div>
                                    <div className="col text-success-100">
                                      <p className="fs-14px fw-500 m-0 py-1">
                                        {
                                          (Number(item?.provisional_budget) +
                                          Number(item?.budget) +
                                          Number(item?.prev_balance)) == 0 ? "-" :
                                          formatNumber(
                                            (Number(item?.provisional_budget) +
                                          Number(item?.budget) +
                                          Number(item?.prev_balance)) / dividerNumber, "indian"
                                          )
                                        }
                                      </p>
                                    </div>
                                  </div>
                                ));
                              })()}

                              <div className="row p-0 bg-info-20">
                                <div className="col">
                                  <p className="fs-14px text-info fw-500 py-1 m-0">
                                    Total
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-14px text-black fw-500 py-1 m-0">
                                    {totalProvisionalBudget == 0 ? "-" : formatNumber(totalProvisionalBudget / dividerNumber, "indian")}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-14px text-black fw-500 py-1 m-0">
                                    {totalReleasedBudget == 0 ? "-" : formatNumber(totalReleasedBudget / dividerNumber, "indian")}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-14px text-black fw-500 py-1 m-0">
                                    {totalPrevFyBalance == 0 ? "-" : formatNumber(totalReleasedBudget / dividerNumber, "indian")}
                                  </p>
                                </div>
                                <div className="col text-success-100">
                                  <p className="fs-14px fw-500 py-1 m-0">
                                    {
                                      (totalProvisionalBudget +
                                      totalReleasedBudget +
                                      totalPrevFyBalance) == 0 ? "-" :
                                      formatNumber(
                                        (totalProvisionalBudget +
                                        totalReleasedBudget +
                                        totalPrevFyBalance) / dividerNumber, "indian"
                                      )
                                    }
                                  </p>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>)
                       } )}
                    </div>
                  )}
                </div>

                {/* =======================================sub activity====================== */}

                <div className="row pb-2 px-3">
                  {activeScBalanceCardChild[val?.id] === "6" && (
                    <div className="d-flex flex-column gap-1">
                    {(()=>{
                      const combinedActivityBudget = val?.sub_head_list?.flatMap(subHead => subHead?.activity_budget || []);
                      return combinedActivityBudget?.map((val, ind)=>(
                        <Accordion activeKey={activeKeys} key={val?.id || ind}>
                          <Accordion.Item
                            eventKey={val?.id || ind}
                            style={{ borderRadius: "0", border: "0" }}
                          >
                            <Accordion.Header
                              onClick={() => toggleAccordion(val?.id || ind)}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                position: "relative",
                                borderRadius: "0",
                              }}
                            >
                              <div className="w-100 d-flex justify-content-between">
                                <span>{val?.activity} {ind + 1}</span>
                                <span
                                  className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                                  style={{
                                    height: "13.33px",
                                    width: "13.33px",
                                  }}
                                >
                                  {activeKeys.includes(val?.id || ind) ? "-" : "+"}
                                </span>
                              </div>
                            </Accordion.Header>

                            <Accordion.Body className="p-2">
                              <div className="row p-0">
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Activity
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Provisional Budget{" "}
                                    {fy_list[storedGlobalDateId]?.year}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Released FY{" "}
                                    {fy_list[storedGlobalDateId]?.year}
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Previous FY Balance
                                  </p>
                                </div>
                                <div className="col">
                                  <p className="fs-12px text-black-60 m-0 py-1">
                                    Total
                                  </p>
                                </div>
                              </div>

                            
                                  <div className="row p-0">
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {val?.subactivity}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {val?.provisional_budget === 0
                                          ? "-"
                                          : formatNumber(
                                              val?.provisional_budget /
                                                dividerNumber,
                                              "indian"
                                            )}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {val?.budget === 0
                                          ? "-"
                                          : formatNumber(
                                              val?.budget / dividerNumber,
                                              "indian"
                                            )}
                                      </p>
                                    </div>
                                    <div className="col">
                                      <p className="fs-14px text-black fw-500 m-0 py-1">
                                        {val?.prev_balance === 0
                                          ? "-"
                                          : formatNumber(
                                              val?.prev_balance / dividerNumber,
                                              "indian"
                                            )}
                                      </p>
                                    </div>
                                    <div className="col text-success-100">
                                      <p className="fs-14px fw-500 m-0 py-1"> 
                                      {
                                          (Number(val?.provisional_budget) +
                                          Number(val?.budget) +
                                          Number(val?.prev_balance)) == 0 ? "-" :
                                          formatNumber(
                                            (Number(val?.provisional_budget) +
                                          Number(val?.budget) +
                                          Number(val?.prev_balance)) / dividerNumber, "indian"
                                          )
                                        }
                                      </p>
                                    </div>
                                  </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ))
                     
                     
                  })()}
                    </div>
                  )}
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      {/* =====================bank balance overlay========================== */}

      <BankBalanceOverlay openOverlay={bankBalanceOverlay} bankBalanceOverlays={setBankBalanceOverlay} fetchBankBalance={fetchBankBalance}/>

      {/* ======================approvals overlay=========================== */}

      <div className={`overlay  ${approvalsOverlay ? "d-none" : null}  `}></div>

      <div
        className={`overlayContent overflow-x-hidden overflow-y-auto ${
          approvalsOverlay ? "left-100" : null
        } `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-secondary-100 position-relative text-white d-flex justify-content-between align-items-center p-3 position-sticky top-0 z-3">
          <div className="fw-600 fs-16px d-flex align-items-center gap-1 ">
            <i
              className="icon-close_circle_fill fs-5  cursor-pointer"
              onClick={() => setApprovalsOverlay(true)}
            ></i>
            For Approvals & Payment - FY
            <select
              name="financial_year_id"
              onChange={handleYearDropdown}
              className="bg-transparent border-0 text-white fw-500 fs-16px select"
              disabled
            >
              <option value="" className="text-black">
                select year
              </option>
              {fy_list?.map((fy) => {
                return (
                  <option
                    value={storedGlobalDateId}
                    key={fy.id}
                    className="text-black"
                    selected={
                      String(fy.id) === storedGlobalDateId ||
                      (!storedGlobalDateId &&
                        fy.start_year === moment().format("YYYY"))
                    }
                  >
                    {fy.year}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="d-flex gap-2 align-items-center ">
            <span>
              As on date{" "}
              {
                <select
                  name="financial_year_id"
                  className="bg-transparent border-0 text-white fw-500 fs-16px select"
                  disabled
                >
                  <option value="" className="text-black">
                    select year
                  </option>
                  {fy_list?.map((fy) => {
                    return (
                      <option
                        value={storedGlobalDateId}
                        key={fy.id}
                        className="text-black"
                        selected={
                          String(fy.id) === storedGlobalDateId ||
                          (!storedGlobalDateId &&
                            fy.start_year === moment().format("YYYY"))
                        }
                      >
                        {fy.end_year === moment().format("YYYY")
                          ? moment().format("Do MMM YYYY")
                          : "31 Mar " + fy.end_year}
                      </option>
                    );
                  })}
                </select>
              }
            </span>
            <i
              className="icon-arrow_drop_down_circle fs-18px cursor-pointer"
              onClick={() => setOverlayDropDown((prev) => !prev)}
            ></i>

            <div
              className={`position-absolute bg-prime-100 w-100 p-3 ${
                !overlayDropDown ? "d-none" : "d-inline-block"
              }`}
              style={{ top: "100%", zIndex: "10" }}
            >
              <div className="d-flex flex-column gap-4">
                <span className="d-flex align-items-center gap-2">
                  {" "}
                  <i className="icon-featured_play_list"></i> Export Excel
                </span>
                <span className="d-flex align-items-center gap-2">
                  {" "}
                  <i className="icon-picture_as_pdf"></i> Export PDF
                </span>
                <span
                  className="d-flex align-items-center gap-2 cursor-pointer"
                  onClick={() => setListview(false)}
                >
                  {" "}
                  <i className="icon-view_list"></i> List View
                </span>
                <span
                  className="d-flex align-items-center gap-2 cursor-pointer"
                  onClick={() => setListview(true)}
                >
                  {" "}
                  <i className="icon-grid_on"></i> Grid View
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 d-flex flex-column gap-2">
          {/* ===================================table view ====================================== */}

          {!listview === false ? null : (
            <>
              {/* ======================= vendor table==================== */}
              <div className="w-100 overflow-x-auto">
                <div>
                  {/* =======================heading========================= */}
                  <div className="d-flex justify-content-between text-center border">
                    <div className="fw-bold ps-2">Vendor</div>
                    {/* <div className="fw-bold border px-1" style={{width:"20%"}}>Scheme Name</div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Approval</div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Payment </div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Total</div> */}
                  </div>

                  {/* ===========================datas========================= */}

                  <div
                    className="d-flex border text-center flex-column position-relative overflow-hidden"
                    style={tableAccordian ? null : { height: "34px" }}
                  >
                    <div className="d-flex ">
                      <div className=" p-1">
                        {!tableAccordian ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setTableAccordian(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() => setTableAccordian(false)}
                          ></i>
                        )}
                        Pending for approval
                      </div>
                    </div>

                    {/* ====================child datas================================== */}
                    <div
                      className=""
                      style={{
                        background: "silver",
                        height: paymentAccordian ? null : "84px",
                      }}
                    >
                      <div className="d-flex justify-content-between text-center">
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sr.No.
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          vendor
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Invoice No/Date
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sanction Order/Date
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sanction Amount
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1353
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          radhe radhe
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex border text-center flex-column position-relative overflow-hidden"
                    style={paymentAccordian ? null : { height: "34px" }}
                  >
                    <div className="d-flex ">
                      <div className=" p-1">
                        {!paymentAccordian ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setpaymentAccordion(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() => setpaymentAccordion(false)}
                          ></i>
                        )}
                        Pending for Payment
                      </div>
                      {/* <div className="border p-1" style={{width:"20%"}}>radhe radhe</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                    </div>

                    {/* ====================child datas================================== */}
                    <div
                      className=""
                      style={{
                        background: "silver",
                        height: tableChildAccordian ? null : "84px",
                      }}
                    >
                      <div className="d-flex justify-content-between text-center">
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sr.No.
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          vendor
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Invoice No/Date
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sanction Order/Date
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sanction Amount
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1353
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          radhe radhe
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* =========================DBT/Nach table =============================== */}

              <div className="w-100 overflow-x-auto">
                <div>
                  {/* =======================heading========================= */}
                  <div className="d-flex justify-content-between text-center border">
                    <div className="fw-bold ps-2">DBT/Nach</div>
                    {/* <div className="fw-bold border px-1" style={{width:"20%"}}>Scheme Name</div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Approval</div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Payment </div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Total</div> */}
                  </div>

                  {/* ===========================datas========================= */}

                  <div
                    className="d-flex border text-center flex-column position-relative overflow-hidden"
                    style={dbtNachApproval ? null : { height: "34px" }}
                  >
                    <div className="d-flex ">
                      <div className=" p-1">
                        {!dbtNachApproval ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setDbtNachApproval(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() => setDbtNachApproval(false)}
                          ></i>
                        )}
                        Pending for approval
                      </div>
                      {/* <div className="border p-1" style={{width:"20%"}}>radhe radhe</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                    </div>

                    {/* ====================child datas================================== */}
                    <div
                      className=""
                      style={{
                        background: "silver",
                        height: paymentAccordian ? null : "84px",
                      }}
                    >
                      <div className="d-flex justify-content-between text-center">
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sr.No.
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Department Level/Name
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Scheme/Subhead Code
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Order No./Date
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sanction Amount
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1353
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          radhe radhe
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex border text-center flex-column position-relative overflow-hidden"
                    style={dbtNachPayment ? null : { height: "34px" }}
                  >
                    <div className="d-flex ">
                      <div className=" p-1">
                        {!dbtNachPayment ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setDbtNachPayment(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() => setDbtNachPayment(false)}
                          ></i>
                        )}
                        Pending for Payment
                      </div>
                      {/* <div className="border p-1" style={{width:"20%"}}>radhe radhe</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                    </div>

                    {/* ====================child datas================================== */}
                    <div
                      className=""
                      style={{
                        background: "silver",
                        height: tableChildAccordian ? null : "84px",
                      }}
                    >
                      <div className="d-flex justify-content-between text-center">
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sr.No.
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Department Level/Name
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Scheme/Subhead Code
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Order No./Date
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sanction Amount
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1353
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          radhe radhe
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* =========================salary table ================================= */}

              <div className="w-100 overflow-x-auto">
                <div>
                  {/* =======================heading========================= */}
                  <div className="d-flex justify-content-between text-center border">
                    <div className="fw-bold ps-2">Salary</div>
                    {/* <div className="fw-bold border px-1" style={{width:"20%"}}>Scheme Name</div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Approval</div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Payment </div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Total</div> */}
                  </div>

                  {/* ===========================datas========================= */}

                  <div
                    className="d-flex border text-center flex-column position-relative overflow-hidden"
                    style={tableAccordian ? null : { height: "34px" }}
                  >
                    <div className="d-flex ">
                      <div className=" p-1">
                        {!tableAccordian ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setTableAccordian(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() => setTableAccordian(false)}
                          ></i>
                        )}
                        Pending for approval
                      </div>
                      {/* <div className="border p-1" style={{width:"20%"}}>radhe radhe</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                    </div>

                    {/* ====================child datas================================== */}
                    <div
                      className=""
                      style={{
                        background: "silver",
                        height: paymentAccordian ? null : "84px",
                      }}
                    >
                      <div className="d-flex justify-content-between text-center">
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sr.No.
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          vendor
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Invoice No/Date
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sanction Order/Date
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sanction Amount
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1353
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          radhe radhe
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex border text-center flex-column position-relative overflow-hidden"
                    style={paymentAccordian ? null : { height: "34px" }}
                  >
                    <div className="d-flex ">
                      <div className=" p-1">
                        {!paymentAccordian ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setpaymentAccordion(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() => setpaymentAccordion(false)}
                          ></i>
                        )}
                        Pending for Payment
                      </div>
                      {/* <div className="border p-1" style={{width:"20%"}}>radhe radhe</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                      {/* <div className="border p-1" style={{width:"20%"}}>20000000000</div> */}
                    </div>

                    {/* ====================child datas================================== */}
                    <div
                      className=""
                      style={{
                        background: "silver",
                        height: tableChildAccordian ? null : "84px",
                      }}
                    >
                      <div className="d-flex justify-content-between text-center">
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sr.No.
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Department Level/Name
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Scheme/Subhead Code
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Order No./Date
                        </div>
                        <div
                          className="fw-bold border px-1"
                          style={{ width: "25%" }}
                        >
                          Sanction Amount
                        </div>
                      </div>

                      <div className="d-flex">
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          {" "}
                          1353
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          radhe radhe
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                        <div className="border p-1" style={{ width: "25%" }}>
                          20000000000
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* =========================Budget Allocation table ======================= */}

              <div className="w-100 overflow-x-auto">
                <div>
                  {/* =======================heading========================= */}
                  <div className="d-flex justify-content-between text-center border">
                    <div className="fw-bold ps-2">Budget Allocation</div>
                    {/* <div className="fw-bold border px-1" style={{width:"20%"}}>Scheme Name</div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Approval</div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Payment </div>
                    <div className="fw-bold border px-1" style={{width:"20%"}}>Total</div> */}
                  </div>

                  {/* ===========================datas========================= */}

                  <div
                    className="d-flex border text-center flex-column position-relative overflow-hidden"
                    style={childAllo ? null : { height: "34px" }}
                  >
                    <div className="d-flex ">
                      <div className=" p-1">
                        {!childAllo ? (
                          <i
                            className="icon-arrow_right cursor-pointer"
                            onClick={() => setChildAllo(true)}
                          ></i>
                        ) : (
                          <i
                            className="icon-arrow_drop_down cursor-pointer"
                            onClick={() => setChildAllo(false)}
                          ></i>
                        )}
                        Pending for approval
                      </div>
                    </div>

                    {/* ====================child datas================================== */}
                    <div className={`${childAllo ? "overflow-x-auto" : null}`}>
                      <div
                        className=""
                        style={{
                          background: "silver",
                          width: "1400px",
                          height: paymentAccordian ? null : "84px",
                        }}
                      >
                        <div className="d-flex justify-content-between text-center">
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "25%" }}
                          >
                            Sr.No.
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "25%" }}
                          >
                            Scheme/Subhead Code
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "25%" }}
                          >
                            Order No./Date
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "25%" }}
                          >
                            Child Count
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "25%" }}
                          >
                            Provision Allocation
                          </div>
                          <div
                            className="fw-bold border px-1"
                            style={{ width: "25%" }}
                          >
                            Released Allocation
                          </div>
                        </div>

                        <div className="d-flex">
                          <div className="border p-1" style={{ width: "25%" }}>
                            {" "}
                            1
                          </div>
                          <div className="border p-1" style={{ width: "25%" }}>
                            {" "}
                            1353
                          </div>
                          <div className="border p-1" style={{ width: "25%" }}>
                            radhe radhe
                          </div>
                          <div className="border p-1" style={{ width: "25%" }}>
                            200000000
                          </div>
                          <div className="border p-1" style={{ width: "25%" }}>
                            200000000
                          </div>
                          <div className="border p-1" style={{ width: "25%" }}>
                            200000000
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* =================================== card view=========================== */}
          {listview && (
            <div className="d-flex flex-column gap-2">
              {/* =============================vendor card==================== */}

              <div
                className="bg-white border-Prime-100"
                style={{ borderRadius: "8px" }}
              >
                <div
                  className="bg-prime-20 d-flex justify-content-between align-items-center p-2 transition overflow-hidden "
                  style={{ borderRadius: "8px 8px 0 0" }}
                >
                  {/* ======================card header================== */}
                  <span
                    className="fs-14px white-space-nowrap overflow-x-hidden"
                    style={{ width: "95%", fontStyle: "italic" }}
                  >
                    {/* =======================card header====================== */}
                    <span
                      className="fs-14px white-space-nowrap overflow-x-hidden "
                      style={{ width: "95%", fontStyle: "italic" }}
                    >
                      <span className="fw-500 text-secondary-100">Vendor</span>
                    </span>
                  </span>

                  <span>
                    <i className="icon-remove_red_eye cursor-pointer"></i>
                  </span>
                </div>

                {/* ================== card body ========================= */}

                <div className="row py-2 px-3">
                  <div className="row">
                    <div className="col-4 d-flex flex-column">
                      <span className="text-black-60 fs-12px">Approval</span>
                      <span className="fw-500 fs-14px">
                        {totalVendorApprovalReduce == 0
                          ? "-"
                          : formatNumber(
                              totalVendorApprovalReduce / dividerNumber,
                              "indian"
                            )}
                      </span>
                    </div>
                    <div className="col-4 d-flex flex-column">
                      <span className="text-black-60 fs-12px d-flex justify-content-between align-items-center">
                        Payment
                        <span>
                          <i className="icon-info cursor-pointer"></i>
                        </span>
                      </span>
                      <span className="fw-500 fs-14px">
                        {totalVendorPaymentReduce == 0
                          ? "-"
                          : formatNumber(
                              totalVendorPaymentReduce / dividerNumber,
                              "indian"
                            )}
                      </span>
                    </div>
                    <div className="col-4 d-flex flex-column bg-success-20 py-2">
                      <span className="text-black-60 fs-12px d-flex justify-content-between align-items-center">
                        Total
                        <span>
                          <i className="icon-info cursor-pointer"></i>
                        </span>
                      </span>
                      <span className="fw-500 fs-14px">
                        {totalVendorApprovalReduce + totalVendorPaymentReduce ==
                        0
                          ? "-"
                          : formatNumber(
                              (totalVendorApprovalReduce +
                                totalVendorPaymentReduce) /
                                dividerNumber,
                              "indian"
                            )}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className=" ps-3 py-2 d-flex gap-8px invoice-hover">
                    <span
                      className="d-flex align-items-center border rounded-2 gap-8px"
                      style={{ padding: "2px 8px" }}
                    >
                      Invoice for Approval - {vendorApprovalTotal}
                      <i
                        className="icon-open_in_new cursor-pointer"
                        onClick={() =>
                          navigate("/fund-invoice-list?pending=false")
                        }
                      ></i>
                    </span>
                  </div>
                  <div className="px-3 py-2 d-flex gap-8px invoice-hover">
                    <span
                      className="d-flex align-items-center border rounded-2 gap-8px"
                      style={{ padding: "2px 8px" }}
                    >
                      Invoice for payment - {vendorPaymentTotal}
                      <i
                        className="icon-open_in_new cursor-pointer"
                        onClick={() =>
                          navigate("/fund-invoice-list?pending=true")
                        }
                      ></i>
                    </span>
                  </div>
                </div>

                <div className="px-3 pb-2 d-flex flex-column gap-1">
                  <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                    <Accordion.Item
                      eventKey="0"
                      style={{ borderRadius: "0", border: "0" }}
                    >
                      <Accordion.Header
                        onClick={() => toggleAccordion("0")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                          borderRadius: "0",
                        }}
                      >
                        <div className="w-100 d-flex justify-content-between">
                          <span>Pending for Approval</span>
                          <span
                            className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                            style={{ height: "13.33px", width: "13.33px" }}
                          >
                            {activeKeys.includes("0") ? "-" : "+"}
                          </span>
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>
                        <div
                          className="ps-3 overflow-y-auto overflow-x-hidden"
                          style={{ height: "auto", maxHeight: "200px" }}
                        >
                          {/* ==============================subhead data================================ */}

                          <div className="row fs-12px text-black-60 p-1 mt-1">
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              vendor
                            </div>
                            <div
                              className="col text-start px-0 d-flex justify-content-between align-items-center"
                              style={{ width: "20%" }}
                            >
                              Invoice No/Date
                              <span>
                                <i className="icon-info cursor-pointer pe-2"></i>
                              </span>
                            </div>
                            <div
                              className="col text-start px-0 d-flex justify-content-between align-items-center"
                              style={{ width: "20%" }}
                            >
                              Sanction Order/Date
                              <span>
                                <i className="icon-info cursor-pointer pe-2"></i>
                              </span>
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Sanction Amount
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Action
                            </div>
                          </div>
                          {invoices.length == 0 ? (
                            <div>No Records fount</div>
                          ) : (
                            invoices?.map((val, index) => (
                              <div
                                className="row fs-14px p-1 fw-500 overflow-hidden"
                                style={{
                                  height: "35px",
                                }}
                              >
                                <div
                                  className="row fs-14px p-1 fw-500"
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#f8f8f8" : "white",
                                  }}
                                >
                                  <div
                                    className="col d-flex align-items-center"
                                    style={{ widows: "20%" }}
                                  >
                                    <span>
                                      {addElipsis(val?.company_name, 12)}
                                    </span>
                                  </div>

                                  <div className="col" style={{ width: "20%" }}>
                                    {addElipsis(
                                      val?.invoice_no +
                                        " | " +
                                        val?.invoice_date,
                                      18
                                    )}
                                  </div>
                                  <div className="col" style={{ width: "20%" }}>
                                    {val?.sanction_order_value} |{" "}
                                    {val?.sanction_order_date}
                                  </div>
                                  <div
                                    className="col text-success-100"
                                    style={{ width: "20%" }}
                                  >
                                    {formatNumber(
                                      val?.sanction_amount / dividerNumber,
                                      "indian"
                                    )}
                                  </div>
                                  <div
                                    className="col d-flex gap-2"
                                    style={{ width: "20%" }}
                                  >
                                    <button
                                      className={`${
                                        val?.approval_status == 0
                                          ? val?.l3_approval_status == 2
                                            ? "-"
                                            : "bg-warning-20"
                                          : val?.approval_status == 1
                                          ? "bg-success-20"
                                          : "bg-danger-20 "
                                      }   d-flex align-items-center gap-2 border-0`}
                                      style={{
                                        padding: "2px 8px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      L2
                                      {val?.approval_status == 0 ? (
                                        val?.l3_approval_status == 2 ? (
                                          "-"
                                        ) : (
                                          <i className="icon-warning_amber text-warning-100"></i>
                                        )
                                      ) : val?.approval_status == 1 ? (
                                        <i className="icon-verified text-success-100"></i>
                                      ) : (
                                        <i className="icon-close_circle_stroke text-danger-100"></i>
                                      )}
                                    </button>
                                    <button
                                      className={`${
                                        val?.l3_approval_status == 0
                                          ? val?.approval_status == 2
                                            ? "-"
                                            : "bg-warning-20"
                                          : val?.l3_approval_status == 1
                                          ? "bg-success-20"
                                          : "bg-danger-20 "
                                      }   d-flex align-items-center gap-2 border-0`}
                                      style={{
                                        padding: "2px 8px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      L3
                                      {val?.l3_approval_status == 0 ? (
                                        val?.approval_status == 2 ? (
                                          "-"
                                        ) : (
                                          <i className="icon-warning_amber text-warning-100"></i>
                                        )
                                      ) : val?.l3_approval_status == 1 ? (
                                        <i className="icon-verified text-success-100"></i>
                                      ) : (
                                        <i className="icon-close_circle_stroke text-danger-100"></i>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {/* ==============================pending for payment============== */}
                  <Accordion style={{ border: "80" }}>
                    <Accordion.Item
                      eventKey="80"
                      style={{ borderRadius: "0", border: "0" }}
                    >
                      <Accordion.Header
                        onClick={() => toggleAccordion("80")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                          borderRadius: "0",
                        }}
                      >
                        <div className="w-100 d-flex justify-content-between">
                          <span>Pending for Payment</span>
                          <span
                            className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                            style={{ height: "13.33px", width: "13.33px" }}
                          >
                            {activeKeys.includes("0") ? "-" : "+"}
                          </span>
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>
                        <div
                          className="ps-3 overflow-y-auto overflow-x-hidden"
                          style={{ height: "auto", maxHeight: "200px" }}
                        >
                          {/* ==============================subhead data================================ */}
                          {vendorPaymentData.length != 0 && (
                            <div className="row fs-12px text-black-60 p-1 mt-1">
                              <div
                                className="col text-start px-0"
                                style={{ width: "20%" }}
                              >
                                vendor
                              </div>
                              <div
                                className="col text-start px-0 d-flex justify-content-between align-items-center"
                                style={{ width: "20%" }}
                              >
                                Invoice No/Date
                                <span>
                                  <i className="icon-info cursor-pointer pe-2"></i>
                                </span>
                              </div>
                              <div
                                className="col text-start px-0 d-flex justify-content-between align-items-center"
                                style={{ width: "20%" }}
                              >
                                Sanction Order/Date
                                <span>
                                  <i className="icon-info cursor-pointer pe-2"></i>
                                </span>
                              </div>
                              <div
                                className="col text-start px-0"
                                style={{ width: "20%" }}
                              >
                                Payment Amount
                              </div>
                              <div
                                className="col text-start px-0"
                                style={{ width: "20%" }}
                              >
                                Action
                              </div>
                            </div>
                          )}

                          {vendorPaymentData.length == 0 ? (
                            <div>No Records Found</div>
                          ) : (
                            vendorPaymentData?.map((val, index) => (
                              <div
                                className="row fs-14px p-1 fw-500 overflow-hidden"
                                style={{
                                  height: subheadAccording
                                    ? "35px"
                                    : "fit-content",
                                }}
                              >
                                <div
                                  className="row fs-14px p-1 fw-500"
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "#f8f8f8" : "white",
                                  }}
                                >
                                  <div
                                    className="col d-flex align-items-center"
                                    style={{ widows: "20%" }}
                                  >
                                    <span>
                                      {addElipsis(val?.company_name, 12)}
                                    </span>
                                  </div>

                                  <div className="col" style={{ width: "20%" }}>
                                    {addElipsis(
                                      val?.invoice_no +
                                        " | " +
                                        val?.invoice_date,
                                      18
                                    )}
                                  </div>
                                  <div className="col" style={{ width: "20%" }}>
                                    {val?.sanction_order_value} |{" "}
                                    {val?.sanction_order_date}
                                  </div>
                                  <div
                                    className="col text-success-100"
                                    style={{ width: "20%" }}
                                  >
                                    {formatNumber(
                                      val?.payable_amount / dividerNumber,
                                      "indian"
                                    )}
                                  </div>
                                  <div
                                    className="col d-flex gap-2"
                                    style={{ width: "20%" }}
                                  >
                                    <button
                                      className={`${
                                        val?.payment_status == 0
                                          ? val?.l3_payment_status == 2
                                            ? "-"
                                            : "bg-warning-20"
                                          : val?.payment_status == 1
                                          ? "bg-success-20"
                                          : "bg-danger-20 "
                                      }   d-flex align-items-center gap-2 border-0`}
                                      style={{
                                        padding: "2px 8px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      L2
                                      {val?.payment_status == 0 ? (
                                        val?.l3_payment_status == 2 ? (
                                          "-"
                                        ) : (
                                          <i className="icon-warning_amber text-warning-100"></i>
                                        )
                                      ) : val?.payment_status == 1 ? (
                                        <i className="icon-verified text-success-100"></i>
                                      ) : (
                                        <i className="icon-close_circle_stroke text-danger-100"></i>
                                      )}
                                    </button>
                                    <button
                                      className={`${
                                        val?.l3_payment_status == 0
                                          ? val?.payment_status == 2
                                            ? "-"
                                            : "bg-warning-20"
                                          : val?.l3_payment_status == 1
                                          ? "bg-success-20"
                                          : "bg-danger-20 "
                                      }   d-flex align-items-center gap-2 border-0`}
                                      style={{
                                        padding: "2px 8px",
                                        borderRadius: "4px",
                                      }}
                                    >
                                      L3
                                      {val?.l3_payment_status == 0 ? (
                                        val?.payment_status == 2 ? (
                                          "-"
                                        ) : (
                                          <i className="icon-warning_amber text-warning-100"></i>
                                        )
                                      ) : val?.payment_status == 1 ? (
                                        <i className="icon-verified text-success-100"></i>
                                      ) : (
                                        <i className="icon-close_circle_stroke text-danger-100"></i>
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>

              {/* ===========================DBT/nach card=========================== */}

              <div
                className="bg-white border-Prime-100"
                style={{ borderRadius: "8px" }}
                // key={val?.id}
              >
                <div
                  className="bg-prime-20 d-flex justify-content-between align-items-center p-2 transition overflow-hidden"
                  style={{ borderRadius: "8px 8px 0 0" }}
                >
                  {/* ======================card header================== */}
                  <span
                    className="fs-14px white-space-nowrap overflow-x-hidden"
                    style={{ width: "95%", fontStyle: "italic" }}
                  >
                    {/* =======================card header====================== */}
                    <span
                      className="fs-14px white-space-nowrap overflow-x-hidden "
                      style={{ width: "95%", fontStyle: "italic" }}
                    >
                      <span className="fw-500 text-secondary-100">
                        DBT/NACH
                      </span>
                    </span>
                  </span>

                  <span>
                    <i className="icon-remove_red_eye cursor-pointer"></i>
                  </span>
                </div>

                {/* ================== card body ========================= */}

                <div className="row py-2 px-3">
                  <div className="row">
                    <div className="col-4 d-flex flex-column">
                      <span className="text-black-60 fs-12px">Approval</span>
                      <span className="fw-500 fs-14px">
                        {totalSanctionAmountDbt == 0
                          ? "-"
                          : formatNumber(
                              totalSanctionAmountDbt / dividerNumber,
                              "indian"
                            )}
                      </span>
                    </div>
                    <div className="col-4 d-flex flex-column">
                      <span className="text-black-60 fs-12px d-flex justify-content-between align-items-center">
                        Payment
                        <span>
                          <i className="icon-info cursor-pointer"></i>
                        </span>
                      </span>
                      <span className="fw-500 fs-14px">2,000,000,000.00</span>
                    </div>
                    <div className="col-4 d-flex flex-column bg-success-20 py-2">
                      <span className="text-black-60 fs-12px d-flex justify-content-between align-items-center">
                        Total
                        <span>
                          <i className="icon-info cursor-pointer"></i>
                        </span>
                      </span>
                      <span className="fw-500 fs-14px">2,000,000,000.00</span>
                    </div>
                  </div>
                </div>

                <div className="d-flex">
                  <div className="ps-3 py-2 d-flex gap-8px invoice-hover">
                    <span
                      className="d-flex align-items-center border rounded-2 gap-8px"
                      style={{ padding: "2px 8px" }}
                    >
                      DBT/Nach for Approval - {dbtLength}
                      <i
                        className="icon-open_in_new cursor-pointer"
                        onClick={() =>
                          navigate("beneficiary-reimbursement/approval")
                        }
                      ></i>
                    </span>
                  </div>
                  <div className="px-3 py-2 d-flex gap-8px invoice-hover">
                    <span
                      className="d-flex align-items-center border rounded-2 gap-8px"
                      style={{ padding: "2px 8px" }}
                    >
                      DBT/Nach for Payment - 22
                      <i
                        className="icon-open_in_new cursor-pointer"
                        onClick={() =>
                          navigate("beneficiary-reimbursement/payment")
                        }
                      ></i>
                    </span>
                  </div>
                </div>

                <div className="px-3 pb-2 d-flex flex-column gap-1">
                  <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                    <Accordion.Item
                      eventKey="9"
                      style={{ borderRadius: "0", border: "0" }}
                    >
                      <Accordion.Header
                        onClick={() => toggleAccordion("9")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                          borderRadius: "0",
                        }}
                      >
                        <div className="w-100 d-flex justify-content-between">
                          <span>Pending for Approval</span>
                          <span
                            className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                            style={{ height: "13.33px", width: "13.33px" }}
                          >
                            {activeKeys.includes("9") ? "-" : "+"}
                          </span>
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>
                        <div>
                          {/* ==============================subhead data================================ */}
                          {dbtData.length == 0 ? null : (
                            <div className="row fs-12px text-black-60 p-1 mt-1">
                              <div
                                className="col text-start px-0"
                                style={{ width: "20%" }}
                              >
                                Department Level/Name
                              </div>
                              <div
                                className="col text-start px-0 d-flex justify-content-between align-items-center"
                                style={{ width: "20%" }}
                              >
                                Scheme/Subhead Code
                                <span>
                                  <i className="icon-info cursor-pointer pe-2"></i>
                                </span>
                              </div>
                              <div
                                className="col text-start px-0 d-flex justify-content-between align-items-center"
                                style={{ width: "20%" }}
                              >
                                Order No./Date
                                <span>
                                  <i className="icon-info cursor-pointer pe-2"></i>
                                </span>
                              </div>
                              <div
                                className="col text-start px-0"
                                style={{ width: "20%" }}
                              >
                                Sanction Amount
                              </div>
                              <div
                                className="col text-start px-0"
                                style={{ width: "20%" }}
                              >
                                Action
                              </div>
                            </div>
                          )}

                          {dbtData.length == 0 ? (
                            <div>No Record's Found</div>
                          ) : (
                            dbtData?.map((val, ind) => (
                              <div
                                className="row fs-14px p-1 fw-500 overflow-hidden"
                                style={{
                                  height: subheadAccordingdbt[ind]
                                    ? "35px"
                                    : "fit-content",
                                }}
                              >
                                <div
                                  className="row fs-14px p-1 fw-500"
                                  style={{ background: "#F8F8F8" }}
                                  onClick={() =>
                                    getSingleBeneficiaryReimbursements(val?.id)
                                  }
                                >
                                  <div
                                    className="col d-flex align-items-center"
                                    style={{ width: "20%" }}
                                  >
                                    {subheadAccordingdbt[ind] ? (
                                      <i
                                        className="icon-keyboard_arrow_right cursor-pointer"
                                        onClick={() =>
                                          toggleSubheadAccording(ind)
                                        }
                                      ></i>
                                    ) : (
                                      <i
                                        className="icon-keyboard_arrow_down cursor-pointer"
                                        onClick={() =>
                                          toggleSubheadAccording(ind)
                                        }
                                      ></i>
                                    )}
                                    <span>
                                      {" "}
                                      {addElipsis(
                                        val?.department_level +
                                          " | " +
                                          val?.department_name,
                                        13
                                      )}
                                    </span>
                                  </div>

                                  <div className="col" style={{ width: "20%" }}>
                                    {val?.code} | {val?.subhead_code}
                                  </div>
                                  <div className="col" style={{ width: "20%" }}>
                                    {addElipsis(
                                      val?.reimbursement_order_no +
                                        " | " +
                                        val?.reimbursement_order_date,
                                      13
                                    )}
                                  </div>
                                  <div className="col" style={{ width: "20%" }}>
                                    {val?.reimbursement_order_value == 0
                                      ? "-"
                                      : formatNumber(
                                          val?.reimbursement_order_value /
                                            dividerNumber,
                                          "indian"
                                        )}
                                  </div>
                                  <div
                                    className="col d-flex gap-2"
                                    style={{ width: "20%" }}
                                  >
                                    {val?.l2_payment_approval == null ? (
                                      <button
                                        className="border-0 bg-warning-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L2
                                        <i className="icon-warning_amber text-warning-100"></i>
                                      </button>
                                    ) : val?.l2_payment_approval == 1 ? (
                                      <button
                                        className="border-0 bg-success-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L2
                                        <i className="icon-verified text-success-100"></i>
                                      </button>
                                    ) : (
                                      <button
                                        className="border-0 bg-danger-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L2
                                        <i className="icon-close_circle_stroke  text-danger-100"></i>
                                      </button>
                                    )}
                                    {val?.l3_payment_approval == null ? (
                                      <button
                                        className="border-0 bg-warning-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L3
                                        <i className="icon-warning_amber text-warning-100"></i>
                                      </button>
                                    ) : val?.l3_payment_approval == 1 ? (
                                      <button
                                        className="border-0 bg-success-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L3
                                        <i className="icon-verified text-success-100"></i>
                                      </button>
                                    ) : (
                                      <button
                                        className="border-0 bg-danger-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L3
                                        <i className="icon-close_circle_stroke  text-danger-100"></i>
                                      </button>
                                    )}
                                  </div>
                                </div>

                                <div className="mt-1 p-0 pe-3">
                                  <table className="border-0">
                                    <tr className="bg-prime-20 border-0">
                                      <th className="p-1 border-0 text-start fs-12px text-black-60">
                                        Name
                                      </th>
                                      <th className="p-1 border-0 text-start fs-12px text-black-60">
                                        Mobile/Email
                                      </th>
                                      <th className="p-1 border-0 text-start fs-12px text-black-60">
                                        IFSC
                                      </th>
                                      <th className="p-1 border-0 text-start fs-12px text-black-60">
                                        Gross Amount
                                      </th>
                                      <th className="p-1 border-0 text-start fs-12px text-black-60">
                                        Deduction
                                      </th>
                                      <th className="p-1 border-0 text-start fs-12px text-black-60">
                                        Net Amount Amount Amount
                                      </th>
                                    </tr>
                                    {singleBeneficiarys?.map((val, ind) => (
                                      <tr>
                                        <td className="p-1 border-0 text-start fs-12px text-black-100">
                                          {val?.name}
                                        </td>
                                        <td className="p-1 border-0 text-start fs-12px text-black-100">
                                          {val?.mobile} | {val?.email}
                                        </td>
                                        <td className="p-1 border-0 text-start fs-12px text-black-100">
                                          {val?.ifsc}
                                        </td>
                                        <td className="p-1 border-0 text-start fs-12px text-black-100">
                                          {val?.gross_amount == 0
                                            ? "-"
                                            : formatNumber(
                                                val?.gross_amount /
                                                  dividerNumber,
                                                "indian"
                                              )}
                                        </td>
                                        <td className="p-1 border-0 text-start fs-12px text-black-100">
                                          {val?.deduction_amount == 0
                                            ? "-"
                                            : formatNumber(
                                                val?.deduction_amount /
                                                  dividerNumber,
                                                "indian"
                                              )}
                                        </td>
                                        <td className="p-1 border-0 text-start fs-12px text-black-100">
                                          {Number(val?.gross_amount) -
                                            Number(val?.deduction_amount) ==
                                          0
                                            ? "-"
                                            : formatNumber(
                                                (Number(val?.gross_amount) -
                                                  Number(
                                                    val?.deduction_amount
                                                  )) /
                                                  dividerNumber,
                                                "indian"
                                              )}
                                        </td>
                                      </tr>
                                    ))}
                                  </table>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>

                  <Accordion style={{ border: "0" }}>
                    <Accordion.Item
                      eventKey="9"
                      style={{ borderRadius: "0", border: "0" }}
                    >
                      <Accordion.Header
                        onClick={() => toggleAccordion("82")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                          borderRadius: "0",
                        }}
                      >
                        <div className="w-100 d-flex justify-content-between">
                          <span>Pending for payment</span>
                          <span
                            className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                            style={{ height: "13.33px", width: "13.33px" }}
                          >
                            {activeKeys.includes("9") ? "-" : "+"}
                          </span>
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>
                        <div>
                          {/* ==============================subhead data================================ */}

                          <div className="row fs-12px text-black-60 p-1 mt-1">
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Department Level/Name
                            </div>
                            <div
                              className="col text-start px-0 d-flex justify-content-between align-items-center "
                              style={{ width: "20%" }}
                            >
                              Scheme/Subhead Code
                              <span>
                                <i className="icon-info cursor-pointer pe-2"></i>
                              </span>
                            </div>
                            <div
                              className="col text-start px-0 d-flex justify-content-between align-items-center "
                              style={{ width: "20%" }}
                            >
                              Order No./Date
                              <span>
                                <i className="icon-info cursor-pointer pe-2"></i>
                              </span>
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Sanction Amount
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Action
                            </div>
                          </div>

                          <div
                            className="row fs-14px p-1 fw-500 overflow-hidden"
                            style={{
                              height: subheadAccording ? "35px" : "fit-content",
                            }}
                          >
                            <div
                              className="row fs-14px p-1 fw-500"
                              style={{ background: "#F8F8F8" }}
                            >
                              <div
                                className="col d-flex align-items-center"
                                style={{ widows: "20%" }}
                              >
                                {subheadAccording ? (
                                  <i
                                    className="icon-keyboard_arrow_right cursor-pointer"
                                    onClick={() =>
                                      setSubheadAccording((prev) => !prev)
                                    }
                                  ></i>
                                ) : (
                                  <i
                                    className="icon-keyboard_arrow_down cursor-pointer"
                                    onClick={() =>
                                      setSubheadAccording((prev) => !prev)
                                    }
                                  ></i>
                                )}
                                <span>radhe krishna</span>
                              </div>

                              <div className="col" style={{ width: "20%" }}>
                                25412 | 10/08/2025
                              </div>
                              <div className="col" style={{ width: "20%" }}>
                                25412 | 10/08/2025
                              </div>
                              <div className="col" style={{ width: "20%" }}>
                                2,000,000,000.00
                              </div>
                              <div
                                className="col d-flex gap-2"
                                style={{ width: "20%" }}
                              >
                                <button
                                  className="border-0 bg-success-20 d-flex align-items-center gap-2"
                                  style={{
                                    padding: "2px 8px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  L2
                                  <i className="icon-verified text-success-100"></i>
                                </button>
                                <button
                                  className="border-0 bg-warning-20 d-flex align-items-center gap-2"
                                  style={{
                                    padding: "2px 8px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  L3
                                  <i className="icon-warning_amber text-warning-100"></i>
                                </button>
                              </div>
                            </div>

                            <div className="mt-1 p-0 pe-3">
                              <table className="border-0">
                                <tr className="bg-prime-20 border-0">
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Name
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Mobile/Email
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Action/IFSC
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Gross Amount
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Deduction
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Net Amount Amount Amount
                                  </th>
                                </tr>
                                <tr>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    radha krishna
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>

              {/* =================salary card========================= */}

              <div
                className="bg-white border-Prime-100"
                style={{ borderRadius: "8px" }}
                // key={val?.id}
              >
                <div
                  className="bg-prime-20 d-flex justify-content-between align-items-center p-2 transition overflow-hidden "
                  style={{ borderRadius: "8px 8px 0 0" }}
                >
                  {/* ======================card header================== */}
                  <span
                    className="fs-14px white-space-nowrap overflow-x-hidden"
                    style={{ width: "95%", fontStyle: "italic" }}
                  >
                    {/* =======================card header====================== */}
                    <span
                      className="fs-14px white-space-nowrap overflow-x-hidden "
                      style={{ width: "95%", fontStyle: "italic" }}
                    >
                      <span className="fw-500 text-secondary-100">Salary</span>
                    </span>
                  </span>

                  <span>
                    <i className="icon-remove_red_eye cursor-pointer"></i>
                  </span>
                </div>

                {/* ================== card body ========================= */}

                <div className="row py-2 px-3">
                  <div className="row">
                    <div className="col-4 d-flex flex-column">
                      <span className="text-black-60 fs-12px">Approval</span>
                      <span className="fw-500 fs-14px">2,000,000,000.00</span>
                    </div>
                    <div className="col-4 d-flex flex-column">
                      <span className="text-black-60 fs-12px">Payment</span>
                      <span className="fw-500 fs-14px">2,000,000,000.00</span>
                    </div>
                    <div className="col-4 d-flex flex-column bg-success-20 py-2">
                      <span className="text-black-60 fs-12px">Total</span>
                      <span className="fw-500 fs-14px">2,000,000,000.00</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div className="ps-3 py-2 d-flex gap-8px invoice-hover">
                    <span
                      className="d-flex align-items-center border rounded-2 gap-8px"
                      style={{ padding: "2px 8px" }}
                    >
                      Invoice for Approval - 22{" "}
                      <i
                        className="icon-open_in_new cursor-pointer"
                        onClick={() => navigate("/payroll-salary")}
                      ></i>
                    </span>
                  </div>
                  <div className="ps-3 py-2 d-flex gap-8px invoice-hover">
                    <span
                      className="d-flex align-items-center border rounded-2 gap-8px"
                      style={{ padding: "2px 8px" }}
                    >
                      Invoice for payment - 22{" "}
                      <i
                        className="icon-open_in_new cursor-pointer"
                        onClick={() => navigate("/payroll-salary")}
                      ></i>
                    </span>
                  </div>
                </div>
                <div className="px-3 pb-2 d-flex flex-column gap-1">
                  <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                    <Accordion.Item
                      eventKey="10"
                      style={{ borderRadius: "0", border: "0" }}
                    >
                      <Accordion.Header
                        onClick={() => toggleAccordion("10")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                          borderRadius: "0",
                        }}
                      >
                        <div className="w-100 d-flex justify-content-between">
                          <span>Pending for Approval</span>
                          <span
                            className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                            style={{ height: "13.33px", width: "13.33px" }}
                          >
                            {activeKeys.includes("10") ? "-" : "+"}
                          </span>
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>
                        <div>
                          {/* ==============================subhead data================================ */}

                          <div className="row fs-12px text-black-60 p-1 mt-1">
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              vendor
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Invoice No/Date
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Sanction Order/Date
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Sanction Amount
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Action
                            </div>
                          </div>

                          <div
                            className="row fs-14px p-1 fw-500 overflow-hidden"
                            style={{
                              height: subheadAccording ? "35px" : "fit-content",
                            }}
                          >
                            <div
                              className="row fs-14px p-1 fw-500"
                              style={{ background: "#F8F8F8" }}
                            >
                              <div
                                className="col d-flex align-items-center"
                                style={{ widows: "20%" }}
                              >
                                {subheadAccording ? (
                                  <i
                                    className="icon-keyboard_arrow_right cursor-pointer"
                                    onClick={() =>
                                      setSubheadAccording((prev) => !prev)
                                    }
                                  ></i>
                                ) : (
                                  <i
                                    className="icon-keyboard_arrow_down cursor-pointer"
                                    onClick={() =>
                                      setSubheadAccording((prev) => !prev)
                                    }
                                  ></i>
                                )}
                                <span>radhe krishna</span>
                              </div>

                              <div className="col" style={{ width: "20%" }}>
                                25412 | 10/08/2025
                              </div>
                              <div className="col" style={{ width: "20%" }}>
                                25412 | 10/08/2025
                              </div>
                              <div
                                className="col text-success-100"
                                style={{ width: "20%" }}
                              >
                                2,000,000,000.00
                              </div>
                              <div
                                className="col d-flex gap-2"
                                style={{ width: "20%" }}
                              >
                                <button
                                  className="border-0 bg-success-20 d-flex align-items-center gap-2"
                                  style={{
                                    padding: "2px 8px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  L2
                                  <i className="icon-verified text-success-100"></i>
                                </button>
                                <button
                                  className="border-0 bg-warning-20 d-flex align-items-center gap-2"
                                  style={{
                                    padding: "2px 8px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  L3
                                  <i className="icon-warning_amber text-warning-100"></i>
                                </button>
                              </div>
                            </div>

                            <div className="mt-1 p-0 pe-3">
                              <table className="border-0">
                                <tr className="bg-prime-20 border-0">
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Name
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Mobile/Email
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Action/IFSC
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Gross Amount
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Deduction
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Net Amount Amount Amount
                                  </th>
                                </tr>
                                <tr>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    radha krishna
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  <Accordion style={{ border: "0" }}>
                    <Accordion.Item
                      eventKey="10"
                      style={{ borderRadius: "0", border: "0" }}
                    >
                      <Accordion.Header
                        onClick={() => toggleAccordion("83")}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          position: "relative",
                          borderRadius: "0",
                        }}
                      >
                        <div className="w-100 d-flex justify-content-between">
                          <span>Pending for payment</span>
                          <span
                            className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                            style={{ height: "13.33px", width: "13.33px" }}
                          >
                            {activeKeys.includes("10") ? "-" : "+"}
                          </span>
                        </div>
                      </Accordion.Header>

                      <Accordion.Body>
                        <div>
                          {/* ==============================subhead data================================ */}

                          <div className="row fs-12px text-black-60 p-1 mt-1">
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              vendor
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Invoice No/Date
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Sanction Order/Date
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Amount
                            </div>
                            <div
                              className="col text-start px-0"
                              style={{ width: "20%" }}
                            >
                              Action
                            </div>
                          </div>

                          <div
                            className="row fs-14px p-1 fw-500 overflow-hidden"
                            style={{
                              height: subheadAccording ? "35px" : "fit-content",
                            }}
                          >
                            <div
                              className="row fs-14px p-1 fw-500"
                              style={{ background: "#F8F8F8" }}
                            >
                              <div
                                className="col d-flex align-items-center"
                                style={{ widows: "20%" }}
                              >
                                {subheadAccording ? (
                                  <i
                                    className="icon-keyboard_arrow_right cursor-pointer"
                                    onClick={() =>
                                      setSubheadAccording((prev) => !prev)
                                    }
                                  ></i>
                                ) : (
                                  <i
                                    className="icon-keyboard_arrow_down cursor-pointer"
                                    onClick={() =>
                                      setSubheadAccording((prev) => !prev)
                                    }
                                  ></i>
                                )}
                                <span>radhe krishna</span>
                              </div>

                              <div className="col" style={{ width: "20%" }}>
                                25412 | 10/08/2025
                              </div>
                              <div className="col" style={{ width: "20%" }}>
                                25412 | 10/08/2025
                              </div>
                              <div
                                className="col text-success-100"
                                style={{ width: "20%" }}
                              >
                                2,000,000,000.00
                              </div>
                              <div
                                className="col d-flex gap-2"
                                style={{ width: "20%" }}
                              >
                                <button
                                  className="border-0 bg-success-20 d-flex align-items-center gap-2"
                                  style={{
                                    padding: "2px 8px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  L2
                                  <i className="icon-verified text-success-100"></i>
                                </button>
                                <button
                                  className="border-0 bg-warning-20 d-flex align-items-center gap-2"
                                  style={{
                                    padding: "2px 8px",
                                    borderRadius: "4px",
                                  }}
                                >
                                  L3
                                  <i className="icon-warning_amber text-warning-100"></i>
                                </button>
                              </div>
                            </div>

                            <div className="mt-1 p-0 pe-3">
                              <table className="border-0">
                                <tr className="bg-prime-20 border-0">
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Name
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Mobile/Email
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Action/IFSC
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Gross Amount
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Deduction
                                  </th>
                                  <th className="p-1 border-0 text-start fs-12px text-black-60">
                                    Net Amount Amount Amount
                                  </th>
                                </tr>
                                <tr>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    radha krishna
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                  <td className="p-1 border-0 text-start fs-12px text-black-100">
                                    123456780
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>

              {/* ========================child allocatoin card======================================= */}

              {date?.map((val, ind) => (
                <div
                  className="bg-white border-Prime-100"
                  style={{ borderRadius: "8px" }}
                  key={val?.id}
                >
                  <div
                    className="bg-prime-20 d-flex justify-content-between align-items-center p-2 transition overflow-hidden "
                    style={{ borderRadius: "8px 8px 0 0" }}
                  >
                    {/* ======================card header================== */}
                    <span
                      className="fs-14px white-space-nowrap overflow-x-hidden"
                      style={{ width: "95%", fontStyle: "italic" }}
                    >
                      {/* =======================card header====================== */}
                      <span
                        className="fs-14px white-space-nowrap overflow-x-hidden "
                        style={{ width: "95%", fontStyle: "italic" }}
                      >
                        <span className="fw-500 text-secondary-100">
                          Budget Allocation
                        </span>
                      </span>
                    </span>

                    <span>
                      <i className="icon-remove_red_eye cursor-pointer"></i>
                    </span>
                  </div>

                  {/* ================== Budget Allocation card body ========================= */}

                  <div className="row py-2 px-3">
                    <div className="row">
                      <div className="col-6 d-flex flex-column">
                        <span className="text-black-60 fs-12px">
                          Total Provision{" "}
                        </span>
                        <span className="fw-500 fs-14px">
                          {totalprovisonReduct == 0
                            ? "-"
                            : formatNumber(
                                totalprovisonReduct / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                      <div className="col-6 d-flex flex-column">
                        <span className="text-black-60 fs-12px">
                          {" "}
                          Total Released{" "}
                        </span>
                        <span className="fw-500 fs-14px">
                          {totalprovisonReleased == 0
                            ? "-"
                            : formatNumber(
                                totalprovisonReleased / dividerNumber,
                                "indian"
                              )}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="ps-3 py-2 d-flex gap-8px invoice-hover">
                      <span
                        className="d-flex align-items-center border rounded-2 gap-8px"
                        style={{ padding: "2px 8px" }}
                      >
                        Budget Allocatoin for Approval -{" "}
                        {lengthchildAllocationData}
                        <i
                          className="icon-open_in_new cursor-pointer"
                          onClick={() => navigate("/allocate-budget-approval")}
                        ></i>
                      </span>
                    </div>
                  </div>
                  <div className="px-3 pb-2">
                    <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                      <Accordion.Item
                        eventKey="11"
                        style={{ borderRadius: "0", border: "0" }}
                      >
                        <Accordion.Header
                          onClick={() => toggleAccordion("11")}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            position: "relative",
                            borderRadius: "0",
                          }}
                        >
                          <div className="w-100 d-flex justify-content-between">
                            <span>Pending for Approval</span>
                            <span
                              className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                              style={{ height: "13.33px", width: "13.33px" }}
                            >
                              {activeKeys.includes("11") ? "-" : "+"}
                            </span>
                          </div>
                        </Accordion.Header>

                        <Accordion.Body>
                          <div>
                            {/* ==============================subhead data================================ */}

                            <div className="row fs-12px text-black-60 p-1 mt-1">
                              <div
                                className="col text-start px-0"
                                style={{ width: "20%" }}
                              >
                                Scheme Name
                              </div>
                              <div
                                className="col text-start px-0 d-flex justify-content-between align-items-center"
                                style={{ width: "30%" }}
                              >
                                Order No. | Date
                                <span>
                                  <i className="icon-info cursor-pointer pe-2"></i>
                                </span>
                              </div>
                              <div
                                className="col text-start px-0 d-flex justify-content-between align-items-center"
                                style={{ width: "10%" }}
                              >
                                Child Count
                                <span>
                                  <i className="icon-info cursor-pointer pe-2"></i>
                                </span>
                              </div>
                              <div
                                className="col text-start px-0"
                                style={{ width: "20%" }}
                              >
                                Provision Allocation
                              </div>
                              <div
                                className="col text-start px-0"
                                style={{ width: "20%" }}
                              >
                                Released Allocation
                              </div>
                              <div
                                className="col text-start px-0"
                                style={{ width: "20%" }}
                              >
                                Action
                              </div>
                            </div>

                            {childAllocationData?.map((val, ind) => (
                              <div
                                className="row fs-14px p-1 fw-500 overflow-hidden"
                                style={{
                                  height:
                                    expandedIndex === ind
                                      ? "fit-content"
                                      : "35px",
                                }}
                              >
                                <div
                                  className="row fs-14px p-1 fw-500"
                                  style={{ background: "#F8F8F8" }}
                                  onClick={() => fetchChildEyeData(val?.id)}
                                >
                                  <div
                                    className="col d-flex align-items-center"
                                    style={{ widows: "20%" }}
                                  >
                                    {subheadAccording ? (
                                      <i
                                        className="icon-keyboard_arrow_right cursor-pointer"
                                        onClick={() =>
                                          setSubheadAccording((prev) => !prev)
                                        }
                                      ></i>
                                    ) : (
                                      <i
                                        className={`icon-${
                                          expandedIndex === ind
                                            ? "keyboard_arrow_down"
                                            : "keyboard_arrow_right"
                                        } cursor-pointer`}
                                        onClick={() =>
                                          setExpandedIndex(
                                            expandedIndex === ind ? null : ind
                                          )
                                        }
                                      ></i>
                                    )}
                                    <span>{addElipsis(val?.name, 13)}</span>
                                  </div>

                                  <div className="col" style={{ width: "30%" }}>
                                    {addElipsis(
                                      val?.budget_order_no +
                                        " " +
                                        val?.budget_order_date,
                                      16
                                    )}
                                  </div>
                                  <div className="col" style={{ width: "10%" }}>
                                    {val?.total_departments}
                                  </div>
                                  <div
                                    className="col text-success-100"
                                    style={{ width: "20%" }}
                                  >
                                    {val?.total_scheme_provisional_allocation ==
                                    0
                                      ? "-"
                                      : formatNumber(
                                          val?.total_scheme_provisional_allocation /
                                            dividerNumber,
                                          "indian"
                                        )}
                                  </div>
                                  <div
                                    className="col text-success-100"
                                    style={{ width: "20%" }}
                                  >
                                    {val?.total_scheme_released_allocation == 0
                                      ? "-"
                                      : formatNumber(
                                          val?.total_scheme_released_allocation /
                                            dividerNumber,
                                          "indian"
                                        )}
                                  </div>
                                  <div
                                    className="col d-flex gap-2"
                                    style={{ width: "20%" }}
                                  >
                                    {val?.l2_status == null ? (
                                      <button
                                        className="border-0 bg-warning-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L2
                                        <i className="icon-warning_amber text-warning-100"></i>
                                      </button>
                                    ) : val?.l2_status == 1 ? (
                                      <button
                                        className="border-0 bg-success-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L2
                                        <i className="icon-verified text-success-100"></i>
                                      </button>
                                    ) : (
                                      <button
                                        className="border-0 bg-danger-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L2
                                        <i className="icon-close_circle_stroke text-danger-100"></i>
                                      </button>
                                    )}
                                    {val?.l3_status == null ? (
                                      <button
                                        className="border-0 bg-warning-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L3
                                        <i className="icon-warning_amber text-warning-100"></i>
                                      </button>
                                    ) : val?.l3_status == 1 ? (
                                      <button
                                        className="border-0 bg-success-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L2
                                        <i className="icon-verified text-success-100"></i>
                                      </button>
                                    ) : (
                                      <button
                                        className="border-0 bg-danger-20 d-flex align-items-center gap-2"
                                        style={{
                                          padding: "2px 8px",
                                          borderRadius: "4px",
                                        }}
                                      >
                                        L3
                                        <i className="icon-close_circle_stroke text-danger-100"></i>
                                      </button>
                                    )}
                                  </div>
                                </div>

                                <div className="mt-1 p-0 pe-3">
                                  <table className="border-0">
                                    <tr className="bg-prime-20 border-0">
                                      <th className="p-1 border-0 text-start fs-12px text-black-60">
                                        Department Level/Name
                                      </th>
                                      <th className="p-1 border-0 text-start fs-12px text-black-60">
                                        Department Code
                                      </th>
                                      <th className="p-1 border-0 text-start fs-12px text-black-60">
                                        Subhead Code
                                      </th>
                                      <th className="p-1 border-0 text-start fs-12px text-black-60">
                                        Provisional Allocation
                                      </th>
                                      <th className="p-1 border-0 text-start fs-12px text-black-60">
                                        Released Allocation
                                      </th>
                                    </tr>

                                    {childAllocationEyeData?.map((val, ind) => (
                                      <tr>
                                        <td className="p-1 border-0 text-start fs-12px text-black-100">
                                          { addElipsis(val?.department_level + " | " + val?.name , 20) }  
                                        </td>
                                        <td className="p-1 border-0 text-start fs-12px text-black-100">
                                          {val?.department_code}
                                        </td>
                                        <td className="p-1 border-0 text-start fs-12px text-black-100">
                                          {val?.code}
                                        </td>
                                        <td className="p-1 border-0 text-start fs-12px text-black-100">
                                          {val?.provisional_budget == 0
                                            ? "-"
                                            : formatNumber(
                                                val?.provisional_budget /
                                                  dividerNumber,
                                                "indian"
                                              )}
                                        </td>
                                        <td className="p-1 border-0 text-start fs-12px text-black-100">
                                          {val?.budget == 0
                                            ? "-"
                                            : formatNumber(
                                                val?.budget / dividerNumber,
                                                "indian"
                                              )}
                                        </td>
                                      </tr>
                                    ))}
                                  </table>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* ======================Accumulation overlay =========================== */}

      <div
        className={`overlay  ${
          accumulatedDeductionOverlay ? "d-none" : null
        }  `}
      ></div>

      <div
        className={`overlayContent overflow-x-hidden overflow-y-auto ${
          accumulatedDeductionOverlay ? "left-100" : null
        } `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-secondary-100 position-relative text-white d-flex justify-content-between align-items-center p-3 position-sticky top-0 z-3">
          <div className="fw-600 fs-16px d-flex align-items-center gap-1 ">
            <i
              className="icon-close_circle_fill fs-5  cursor-pointer"
              onClick={() => setAccumulatedDeductionOverlay(true)}
            ></i>
            Accumulated Deduction - FY
            <select
              name="financial_year_id"
              onChange={handleYearDropdown}
              className="bg-transparent border-0 text-white fw-500 fs-16px select"
              disabled
            >
              <option value="" className="text-black">
                select year
              </option>
              {fy_list?.map((fy) => {
                return (
                  <option
                    value={storedGlobalDateId}
                    key={fy.id}
                    className="text-black"
                    selected={
                      String(fy.id) === storedGlobalDateId ||
                      (!storedGlobalDateId &&
                        fy.start_year === moment().format("YYYY"))
                    }
                  >
                    {fy.year}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="d-flex gap-2 align-items-center ">
            <span>
              As on date{" "}
              {
                <select
                  name="financial_year_id"
                  className="bg-transparent border-0 text-white fw-500 fs-16px select"
                  disabled
                >
                  <option value="" className="text-black">
                    select year
                  </option>
                  {fy_list?.map((fy) => {
                    return (
                      <option
                        value={storedGlobalDateId}
                        key={fy.id}
                        className="text-black"
                        selected={
                          String(fy.id) === storedGlobalDateId ||
                          (!storedGlobalDateId &&
                            fy.start_year === moment().format("YYYY"))
                        }
                      >
                        {fy.end_year === moment().format("YYYY")
                          ? moment().format("Do MMM YYYY")
                          : "31 Mar " + fy.end_year}
                      </option>
                    );
                  })}
                </select>
              }
            </span>
            <i
              className="icon-arrow_drop_down_circle fs-18px cursor-pointer"
              onClick={() => setOverlayDropDown((prev) => !prev)}
            ></i>

            <div
              className={`position-absolute bg-prime-100 w-100 p-3 ${
                !overlayDropDown ? "d-none" : "d-inline-block"
              }`}
              style={{ top: "100%", zIndex: "10" }}
            >
              <div className="d-flex flex-column gap-4">
                <span className="d-flex align-items-center gap-2">
                  {" "}
                  <i className="icon-featured_play_list"></i> Export Excel
                </span>
                <span className="d-flex align-items-center gap-2">
                  {" "}
                  <i className="icon-picture_as_pdf"></i> Export PDF
                </span>
                <span className="d-flex align-items-center gap-2">
                  {" "}
                  <i className="icon-view_list"></i> List View
                </span>
                <span className="d-flex align-items-center gap-2">
                  {" "}
                  <i className="icon-grid_on"></i> Grid View
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 d-flex flex-column gap-2">
          {/* =============================vendor card==================== */}

          <div
            className="bg-white border-Prime-100"
            style={{ borderRadius: "8px" }}
            // key={val?.id}
          >
            <div
              className="bg-prime-20 d-flex justify-content-between align-items-center py-2 transition overflow-hidden px-3"
              style={{ borderRadius: "8px 8px 0 0" }}
            >
              {/* ======================card header================== */}
              <span
                className="fs-14px white-space-nowrap overflow-x-hidden"
                style={{ width: "95%", fontStyle: "italic" }}
              >
                {/* =======================card header====================== */}
                <span
                  className="fs-14px white-space-nowrap overflow-x-hidden "
                  style={{ width: "95%", fontStyle: "italic" }}
                >
                  <span className="fw-500 text-secondary-100">Vendor</span>
                </span>
              </span>

              <span>
                <i className="icon-filter_alt cursor-pointer p-2 transition bg-active-prime-100 rounded-2"></i>
              </span>
            </div>

            {/* ================== card body ========================= */}

            <div className="row py-2 px-3">
              <div className="row">
                <div className="col-2 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Total TDS IT</span>
                  <span className="fw-500 fs-14px">
                    {totalTdsIt == 0
                      ? "-"
                      : formatNumber(totalTdsIt / dividerNumber, "indian")}
                  </span>
                </div>
                <div className="col-2 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Total TDS GST</span>
                  <span className="fw-500 fs-14px">
                    {totalaccTotalTdsGst == 0
                      ? "-"
                      : formatNumber(
                          totalaccTotalTdsGst / dividerNumber,
                          "indian"
                        )}
                  </span>
                </div>
                <div className="col-2 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Total Royalty</span>
                  <span className="fw-500 fs-14px">
                    {totalRoyalty == 0
                      ? "-"
                      : formatNumber(totalRoyalty / dividerNumber, "indian")}
                  </span>
                </div>
                <div className="col-2 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">
                    Total Labour Cess
                  </span>
                  <span className="fw-500 fs-14px">
                    {totalacclaboutCess == 0
                      ? "-"
                      : formatNumber(
                          totalacclaboutCess / dividerNumber,
                          "indian"
                        )}
                  </span>
                </div>
                <div className="col-2 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Total Other</span>
                  <span className="fw-500 fs-14px">
                    {totalaccOther == 0
                      ? "-"
                      : formatNumber(totalaccOther / dividerNumber, "indian")}
                  </span>
                </div>
                <div className="col-2 d-flex flex-column bg-success-20 py-2">
                  <span className="text-black-60 fs-12px d-flex justify-content-between align-items-center">
                    Total Deducation
                    <span>
                      <i className="icon-info cursor-pointer"></i>
                    </span>
                  </span>
                  <span className="fw-500 fs-14px">
                    {totalTdsIt +
                      totalaccTotalTdsGst +
                      totalRoyalty +
                      totalacclaboutCess +
                      totalaccOther ==
                    0
                      ? "-"
                      : formatNumber(
                          (totalTdsIt +
                            totalaccTotalTdsGst +
                            totalRoyalty +
                            totalacclaboutCess +
                            totalaccOther) /
                            dividerNumber,
                          "indian"
                        )}
                  </span>
                </div>
              </div>
            </div>
            <div className="px-3 pb-2">
              <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                <Accordion.Item
                  eventKey="0"
                  style={{ borderRadius: "0", border: "0" }}
                >
                  <Accordion.Header
                    onClick={() => toggleAccordion("0")}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "0",
                    }}
                  >
                    <div className="w-100 d-flex justify-content-between">
                      <span>Deduction Details</span>
                      <span
                        className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                        style={{ height: "13.33px", width: "13.33px" }}
                      >
                        {activeKeys.includes("0") ? "-" : "+"}
                      </span>
                    </div>
                  </Accordion.Header>

                  <Accordion.Body>
                    <div
                      className="row fs-12px text-black-60 p-1 mt-1 m-auto"
                      style={{ width: "99%" }}
                    >
                      <div
                        className="col text-start p-0"
                        style={{ width: "20%" }}
                      >
                        vendor
                      </div>
                      <div
                        className="col text-start p-0"
                        style={{ width: "20%" }}
                      >
                        Invoice No/Date
                      </div>
                      <div
                        className="col text-start p-0"
                        style={{ width: "20%" }}
                      >
                        TDS IT
                      </div>
                      <div
                        className="col text-start p-0"
                        style={{ width: "20%" }}
                      >
                        Total TDS GST
                      </div>
                      <div
                        className="col text-start p-0"
                        style={{ width: "20%" }}
                      >
                        Royalty
                      </div>
                      <div
                        className="col text-start p-0"
                        style={{ width: "20%" }}
                      >
                        Labour Cess
                      </div>
                      <div
                        className="col text-start p-0"
                        style={{ width: "20%" }}
                      >
                        Other
                      </div>
                      <div
                        className="col text-start p-0"
                        style={{ width: "20%" }}
                      >
                        Total
                      </div>
                    </div>
                    <div
                      className="overflow-x-hidden overflow-y-auto ps-3"
                      style={{ maxHeight: "200px", height: "auto" }}
                    >
                      {/* ==============================subhead data================================ */}

                      {accumulationVendorData?.map((val, ind) => (
                        <div className="row fs-14px p-1 fw-500 overflow-hidden">
                          <div className="col p-0" style={{ width: "20%" }}>
                            {val?.company_name}
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            {addElipsis(
                              val?.invoice_no + " | " + val?.invoice_date,
                              13
                            )}
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            {val?.tds_it_amount == 0
                              ? "-"
                              : formatNumber(
                                  val?.tds_it_amount / dividerNumber,
                                  "indian"
                                )}
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            {Number(val?.c_gst_amount) +
                              Number(val?.i_gst_amount) +
                              Number(val?.s_gst_amount) ==
                            0
                              ? "-"
                              : formatNumber(
                                  (Number(val?.c_gst_amount) +
                                    Number(val?.i_gst_amount) +
                                    Number(val?.s_gst_amount)) /
                                    dividerNumber,
                                  "indian"
                                )}
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            {val?.royalty_amount == 0
                              ? "-"
                              : formatNumber(
                                  val?.royalty_amount / dividerNumber,
                                  "indian"
                                )}
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            {val?.labour_amount == 0
                              ? "-"
                              : formatNumber(
                                  val?.labour_amount / dividerNumber,
                                  "indian"
                                )}
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            {val?.other_deduction == null
                              ? "-"
                              : val?.other_deduction == 0
                              ? "-"
                              : formatNumber(
                                  val?.other_deduction / dividerNumber,
                                  "indian"
                                )}
                          </div>
                          <div
                            className="col p-0 text-success-100"
                            style={{ width: "20%" }}
                          >
                            {Number(val?.tds_it_amount) +
                              Number(val?.c_gst_amount) +
                              Number(val?.i_gst_amount) +
                              Number(val?.s_gst_amount) +
                              Number(val?.royalty_amount) +
                              Number(val?.labour_amount) +
                              Number(
                                val?.other_deduction == null
                                  ? 0
                                  : val?.other_deduction
                              ) ==
                            0
                              ? "-"
                              : formatNumber(
                                  (Number(val?.tds_it_amount) +
                                    Number(val?.c_gst_amount) +
                                    Number(val?.i_gst_amount) +
                                    Number(val?.s_gst_amount) +
                                    Number(val?.royalty_amount) +
                                    Number(val?.labour_amount) +
                                    Number(
                                      val?.other_deduction == null
                                        ? 0
                                        : val?.other_deduction
                                    )) /
                                    dividerNumber,
                                  "indian"
                                )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

          {/* ===========================DBT card=========================== */}

          <div
            className="bg-white border-Prime-100"
            style={{ borderRadius: "8px" }}
            // key={val?.id}
          >
            <div
              className="bg-prime-20 d-flex justify-content-between align-items-center py-2 transition overflow-hidden px-3"
              style={{ borderRadius: "8px 8px 0 0" }}
            >
              {/* ======================card header================== */}
              <span
                className="fs-14px white-space-nowrap overflow-x-hidden"
                style={{ width: "95%", fontStyle: "italic" }}
              >
                {/* =======================card header====================== */}
                <span
                  className="fs-14px white-space-nowrap overflow-x-hidden "
                  style={{ width: "95%", fontStyle: "italic" }}
                >
                  <span className="fw-500 text-secondary-100">DBT/NACH</span>
                </span>
              </span>

              <span>
                <i className="icon-filter_alt cursor-pointer p-2 transition bg-active-prime-100 rounded-2"></i>
              </span>
            </div>

            {/* ================== card body ========================= */}

            <div className="row py-2 px-3">
              <div className="row">
                <div className="col-4 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Gross Amount</span>
                  <span className="fw-500 fs-14px">
                    {totalGrossAmount == 0
                      ? "-"
                      : formatNumber(
                          totalGrossAmount / dividerNumber,
                          "indian"
                        )}
                  </span>
                </div>
                <div className="col-4 d-flex flex-column bg-success-20 py-2">
                  <span className="text-black-60 fs-12px">
                    Deduction Amount
                  </span>
                  <span className="fw-500 fs-14px text-success-100">
                    {totalDeductions == 0
                      ? "-"
                      : formatNumber(totalDeductions / dividerNumber, "indian")}
                  </span>
                </div>
                <div className="col-4 d-flex flex-column  py-2">
                  <span className="text-black-60 fs-12px">Net Amount Amount Amount</span>
                  <span className="fw-500 fs-14px">
                    {totalGrossAmount - totalDeductions == 0
                      ? "-"
                      : formatNumber(
                          (totalGrossAmount - totalDeductions) / dividerNumber,
                          "indian"
                        )}
                  </span>
                </div>
              </div>
            </div>

            <div className="px-3 pb-2">
              <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                <Accordion.Item
                  eventKey="9"
                  style={{ borderRadius: "0", border: "0" }}
                >
                  <Accordion.Header
                    onClick={() => toggleAccordion("9")}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "0",
                    }}
                  >
                    <div className="w-100 d-flex justify-content-between">
                      <span>Order Details</span>
                      <span
                        className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                        style={{ height: "13.33px", width: "13.33px" }}
                      >
                        {activeKeys.includes("9") ? "-" : "+"}
                      </span>
                    </div>
                  </Accordion.Header>

                  <Accordion.Body>
                    <div>
                      {/* ==============================subhead data================================ */}

                      <div className="row fs-12px text-black-60 p-1 mt-1">
                        <div
                          className="col text-start px-0"
                          style={{ width: "20%" }}
                        >
                          Department Level/Name
                        </div>
                        <div
                          className="col text-start px-0"
                          style={{ width: "20%" }}
                        >
                          Order No./Date
                        </div>
                        <div
                          className="col text-start px-0"
                          style={{ width: "20%" }}
                        >
                          Scheme/Subhead Code
                        </div>
                        <div
                          className="col text-start px-0"
                          style={{ width: "20%" }}
                        >
                          Deduction
                        </div>
                        <div
                          className="col text-start px-0"
                          style={{ width: "20%" }}
                        >
                          Gross Amount
                        </div>
                        <div
                          className="col text-start px-0"
                          style={{ width: "20%" }}
                        >
                          Net Amount
                        </div>
                      </div>

                      {accumulationDBTData?.map((val, ind) => (
                        <div
                          className="row fs-14px p-1 fw-500 overflow-hidden"
                          style={{
                            height: !subheadAccordingdbtAcd[ind]
                              ? "35px"
                              : "fit-content",
                          }}
                          onClick={() =>
                                    getSingleBeneficiaryReimbursements(val?.id)
                                  }
                        >
                          <div
                            className="row fs-14px p-1 fw-500"
                            style={{ background: "#F8F8F8" }}
                          >
                            <div
                              className="col d-flex align-items-center"
                              style={{ widows: "20%" }}
                            >
                              {subheadAccordingdbtAcd ? (
                                <i
                                  className="icon-keyboard_arrow_right cursor-pointer"
                                  onClick={() => toggleAccordionacd(ind)}
                                ></i>
                              ) : (
                                <i
                                  className="icon-keyboard_arrow_down cursor-pointer"
                                  onClick={() => toggleAccordionacd(ind)}
                                ></i>
                              )}
                              <span>
                                {" "}
                                {addElipsis(
                                  val?.department_level +
                                    " | " +
                                    val?.department,
                                  13
                                )}
                              </span>
                            </div>

                            <div className="col" style={{ width: "20%" }}>
                              {addElipsis(
                                val?.reimbursement_order_no +
                                  " | " +
                                  val?.reimbursement_order_date,
                                13
                              )}
                            </div>
                            <div className="col" style={{ width: "20%" }}>
                              {addElipsis(
                                val?.code + " | " + val?.subhead_code,
                                13
                              )}
                            </div>
                            <div className="col" style={{ width: "20%" }}>
                              {val?.reimbursement_order_value == 0
                                ? "-"
                                : formatNumber(
                                    val?.reimbursement_order_value /
                                      dividerNumber,
                                    "indian"
                                  )}
                            </div>
                            <div className="col" style={{ width: "20%" }}>
                              {val?.deduction_amount == 0
                                ? "-"
                                : formatNumber(
                                    val?.deduction_amount / dividerNumber,
                                    "indian"
                                  )}
                            </div>
                            <div className="col" style={{ width: "20%" }}>
                              {Number(val?.reimbursement_order_value) -
                                Number(val?.deduction_amount) ==
                              0
                                ? "-"
                                : formatNumber(
                                    (Number(val?.reimbursement_order_value) -
                                      Number(val?.deduction_amount)) /
                                      dividerNumber,
                                    "indian"
                                  )}
                            </div>
                          </div>

                          <div className="mt-1 p-0 pe-3">
                            <table className="border-0">
                              <tr className="bg-prime-20 border-0">
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  Name
                                </th>
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  Mobile/Email
                                </th>
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  IFSC
                                </th>
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  Gross Amount
                                </th>
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  Deduction
                                </th>
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  Net Amount 
                                </th>
                              </tr>
                              {
                                singleBeneficiarys?.map((val, ind) => (
                                  <tr>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  {val?.name}
                                </td>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  {val?.mobile} | {val?.email}
                                </td>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  {val?.ifsc}
                                </td>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  {val?.gross_amount}
                                </td>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  {val?.deduction_amount}
                                </td>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  {Number(val?.gross_amount) - Number(val?.deduction_amount)}
                                </td>
                              </tr>
                                ))
                              }
                          
                            </table>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>

          {/* =================salery card========================= */}

          <div
            className="bg-white border-Prime-100"
            style={{ borderRadius: "8px" }}
            // key={val?.id}
          >
            <div
              className="bg-prime-20 d-flex justify-content-between align-items-center p-2 transition overflow-hidden px-3"
              style={{ borderRadius: "8px 8px 0 0" }}
            >
              {/* ======================card header================== */}
              <span
                className="fs-14px white-space-nowrap overflow-x-hidden"
                style={{ width: "95%", fontStyle: "italic" }}
              >
                {/* =======================card header====================== */}
                <span
                  className="fs-14px white-space-nowrap overflow-x-hidden "
                  style={{ width: "95%", fontStyle: "italic" }}
                >
                  <span className="fw-500 text-secondary-100">Salary</span>
                </span>
              </span>

              <span>
                <i className="icon-filter_alt cursor-pointer p-2 transition bg-active-prime-100 rounded-2"></i>
              </span>
            </div>

            {/* ================== card body ========================= */}

            <div className="row py-2 px-3">
              <div className="row">
                <div className="col-2 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Total TDS IT</span>
                  <span className="fw-500 fs-14px">2,000,000,000.00</span>
                </div>
                <div className="col-2 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Total TDS GST</span>
                  <span className="fw-500 fs-14px">2,000,000,000.00</span>
                </div>
                <div className="col-2 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Total Royalty</span>
                  <span className="fw-500 fs-14px">2,000,000,000.00</span>
                </div>
                <div className="col-2 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">
                    Total Labour Cess
                  </span>
                  <span className="fw-500 fs-14px">2,000,000,000.00</span>
                </div>
                <div className="col-2 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Total Other</span>
                  <span className="fw-500 fs-14px">2,000,000,000.00</span>
                </div>
                <div className="col-2 d-flex flex-column bg-success-20 py-2">
                  <span className="text-black-60 fs-12px d-flex justify-content-between align-items-center">
                    Total Deducation
                    <span>
                      <i className="icon-info cursor-pointer"></i>
                    </span>
                  </span>
                  <span className="fw-500 fs-14px">2,000,000,000.00</span>
                </div>
              </div>
            </div>
            <div className="px-3 pb-2">
              <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                <Accordion.Item
                  eventKey="10"
                  style={{ borderRadius: "0", border: "0" }}
                >
                  <Accordion.Header
                    onClick={() => toggleAccordion("10")}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "0",
                    }}
                  >
                    <div className="w-100 d-flex justify-content-between">
                      <span>Deduction Details</span>
                      <span
                        className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                        style={{ height: "13.33px", width: "13.33px" }}
                      >
                        {activeKeys.includes("10") ? "-" : "+"}
                      </span>
                    </div>
                  </Accordion.Header>

                  <Accordion.Body>
                    <div>
                      {/* ==============================subhead data================================ */}
                      <p>this is pending design</p>

                      <div className="row fs-12px text-black-60 p-1 mt-1">
                        <div
                          className="col text-start px-0"
                          style={{ width: "20%" }}
                        >
                          vendor
                        </div>
                        <div
                          className="col text-start px-0"
                          style={{ width: "20%" }}
                        >
                          Invoice No/Date
                        </div>
                        <div
                          className="col text-start px-0"
                          style={{ width: "20%" }}
                        >
                          sanction Order/Date
                        </div>
                        <div
                          className="col text-start px-0"
                          style={{ width: "20%" }}
                        >
                          Amount
                        </div>
                        <div
                          className="col text-start px-0"
                          style={{ width: "20%" }}
                        >
                          Action
                        </div>
                      </div>

                      <div
                        className="row fs-14px p-1 fw-500 overflow-hidden"
                        style={{
                          height: subheadAccording ? "35px" : "fit-content",
                        }}
                      >
                        <div
                          className="row fs-14px p-1 fw-500"
                          style={{ background: "#F8F8F8" }}
                        >
                          <div
                            className="col d-flex align-items-center"
                            style={{ widows: "20%" }}
                          >
                            {subheadAccording ? (
                              <i
                                className="icon-keyboard_arrow_right cursor-pointer"
                                onClick={() =>
                                  setSubheadAccording((prev) => !prev)
                                }
                              ></i>
                            ) : (
                              <i
                                className="icon-keyboard_arrow_down cursor-pointer"
                                onClick={() =>
                                  setSubheadAccording((prev) => !prev)
                                }
                              ></i>
                            )}
                            <span>radhe krishna</span>
                          </div>

                          <div className="col" style={{ width: "20%" }}>
                            25412 | 10/08/2025
                          </div>
                          <div className="col" style={{ width: "20%" }}>
                            25412 | 10/08/2025
                          </div>
                          <div
                            className="col text-success-100"
                            style={{ width: "20%" }}
                          >
                            2,000,000,000.00
                          </div>
                        </div>

                        <div className="mt-1 p-0 pe-3">
                          <table className="border-0">
                            <tr className="bg-prime-20 border-0">
                              <th className="p-1 border-0 text-start fs-12px text-black-60">
                                Name
                              </th>
                              <th className="p-1 border-0 text-start fs-12px text-black-60">
                                Mobile/Email
                              </th>
                              <th className="p-1 border-0 text-start fs-12px text-black-60">
                                Action/IFSC
                              </th>
                              <th className="p-1 border-0 text-start fs-12px text-black-60">
                                Gross Amount
                              </th>
                              <th className="p-1 border-0 text-start fs-12px text-black-60">
                                Deduction
                              </th>
                              <th className="p-1 border-0 text-start fs-12px text-black-60">
                                Net Amount 
                              </th>
                            </tr>
                            <tr>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                radha krishna
                              </td>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                123456780
                              </td>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                123456780
                              </td>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                123456780
                              </td>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                123456780
                              </td>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                123456780
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
        </div>
      </div>

      {/* ======================Reconciliation overlay=========================== */}

      <div
        className={`overlay  ${reconciliationOverlay ? "d-none" : null}  `}
      ></div>

      <div
        className={`overlayContent overflow-x-hidden overflow-y-auto ${
          reconciliationOverlay ? "left-100" : null
        } `}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="bg-secondary-100 position-relative text-white d-flex justify-content-between align-items-center p-3 position-sticky top-0 z-3">
          <div className="fw-600 fs-16px d-flex align-items-center gap-1 ">
            <i
              className="icon-close_circle_fill fs-5  cursor-pointer"
              onClick={() => setReconciliationOverlay(true)}
            ></i>
            Reconciliation - FY
            <select
              name="financial_year_id"
              onChange={handleYearDropdown}
              className="bg-transparent border-0 text-white fw-500 fs-16px select"
              disabled
            >
              <option value="" className="text-black">
                select year
              </option>
              {fy_list?.map((fy) => {
                return (
                  <option
                    value={storedGlobalDateId}
                    key={fy.id}
                    className="text-black"
                    selected={
                      String(fy.id) === storedGlobalDateId ||
                      (!storedGlobalDateId &&
                        fy.start_year === moment().format("YYYY"))
                    }
                  >
                    {fy.year}
                  </option>
                );
              })}
            </select>
          </div>

          <div className="d-flex gap-2 align-items-center ">
            <span>
              As on date{" "}
              {
                <select
                  name="financial_year_id"
                  className="bg-transparent border-0 text-white fw-500 fs-16px select"
                  disabled
                >
                  <option value="" className="text-black">
                    select year
                  </option>
                  {fy_list?.map((fy) => {
                    return (
                      <option
                        value={storedGlobalDateId}
                        key={fy.id}
                        className="text-black"
                        selected={
                          String(fy.id) === storedGlobalDateId ||
                          (!storedGlobalDateId &&
                            fy.start_year === moment().format("YYYY"))
                        }
                      >
                        {fy.end_year === moment().format("YYYY")
                          ? moment().format("Do MMM YYYY")
                          : "31 Mar " + fy.end_year}
                      </option>
                    );
                  })}
                </select>
              }
            </span>
            <i
              className="icon-arrow_drop_down_circle fs-18px cursor-pointer"
              onClick={() => setOverlayDropDown((prev) => !prev)}
            ></i>

            <div
              className={`position-absolute bg-prime-100 w-100 p-3 ${
                !overlayDropDown ? "d-none" : "d-inline-block"
              }`}
              style={{ top: "100%", zIndex: "10" }}
            >
              <div className="d-flex flex-column gap-4">
                <span className="d-flex align-items-center gap-2">
                  <i className="icon-featured_play_list"></i> Export Excel
                </span>
                <span className="d-flex align-items-center gap-2">
                  <i className="icon-picture_as_pdf"></i> Export PDF
                </span>
                <span className="d-flex align-items-center gap-2">
                  <i className="icon-view_list"></i> List View
                </span>
                <span className="d-flex align-items-center gap-2">
                  <i className="icon-grid_on"></i> Grid View
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="px-4 py-3 d-flex flex-column gap-2">
          <div
            className="bg-white border-Prime-100"
            style={{ borderRadius: "8px" }}
          >
            <div
              className="bg-prime-20 d-flex justify-content-between align-items-center py-2 transition overflow-hidden px-3"
              style={{ borderRadius: "8px 8px 0 0" }}
            >
              {/* ======================card header================== */}
              <span
                className="fs-14px white-space-nowrap overflow-x-hidden"
                style={{ width: "95%" }}
              >
                {/* =======================card header====================== */}
                <span
                  className="fs-14px white-space-nowrap overflow-x-hidden "
                  style={{ width: "95%" }}
                >
                  <span className="fw-500 text-secondary-100">
                    Accumulated deduction
                  </span>
                </span>
              </span>

              <span>
                <i className="icon-filter_alt cursor-pointer p-2 transition bg-active-prime-100 rounded-2"></i>
              </span>
            </div>

            {/* ================== card body ========================= */}

            <div className="row py-2 px-3">
              <div className="row">
                <div className="col-3 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Total Vendor</span>
                  <span className="fw-500 fs-14px">
                    {totalOverallAccDeduction == 0
                      ? "-"
                      : formatNumber(
                          totalOverallAccDeduction / dividerNumber,
                          "indian"
                        )}
                  </span>
                </div>
                <div className="col-3 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Total DBT/Nach</span>
                  <span className="fw-500 fs-14px">
                    {formatNumber(totalReimbursementOrderValue / dividerNumber, "indian")}
                  </span>
                </div>
                <div className="col-3 d-flex flex-column py-2">
                  <span className="text-black-60 fs-12px">Total Salary</span>
                  <span className="fw-500 fs-14px">
                        -
                  </span>
                </div>
                <div className="col-3 d-flex flex-column bg-success-20 py-2">
                  <span className="text-black-60 fs-12px d-flex justify-content-between align-items-center">
                    Total
                    <span>
                      <i className="icon-info cursor-pointer"></i>
                    </span>
                  </span>
                  <span className="fw-500 fs-14px">
                    {formatNumber((totalReimbursementOrderValue + totalOverallAccDeduction) / dividerNumber, "indian")}
                  </span>
                </div>
              </div>
            </div>

            <div className="px-3 pb-2 d-flex flex-column gap-1">
              <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                <Accordion.Item
                  eventKey="0"
                  style={{ borderRadius: "0", border: "0" }}
                >
                  <Accordion.Header
                    onClick={() => toggleAccordion("0")}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "0",
                      zIndex: "1",
                    }}
                  >
                    <div className="w-100 d-flex justify-content-between">
                      <span>Vendor</span>
                      <span
                        className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                        style={{ height: "13.33px", width: "13.33px" }}
                      >
                        {activeKeys.includes("0") ? "-" : "+"}
                      </span>
                    </div>
                  </Accordion.Header>

                  <Accordion.Body>
                    <div>
                      {/* ==============================subhead data================================ */}

                      <div
                        className="row fs-12px text-black-60 p-1 mt-1 m-auto"
                        style={{ width: "99%" }}
                      >
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          vendor
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Invoice No/Date
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          TDS IT
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Total TDS GST
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Royalty
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Labour Cess
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Other
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Total
                        </div>
                      </div>
                      <div
                        className="overflow-x-hidden overflow-y-auto"
                        style={{ maxHeight: "200px", height: "auto" }}
                      >
                        {accumulatedDeduction?.map((val, ind) => (
                          <div
                            className="row fs-14px p-1 fw-500 overflow-hidden ps-3"
                            style={{
                              height: "35px",
                            }}
                          >
                            {/* <div className="row fs-14px p-1 fw-500" style={{background: "#F8F8F8"}}> */}
                            <div className="col p-0" style={{ width: "20%" }}>
                              {val?.company_name}
                            </div>
                            <div className="col p-0" style={{ width: "20%" }}>
                              {addElipsis(
                                val?.invoice_no + " | " + val?.invoice_date,
                                13
                              )}
                            </div>
                            <div className="col p-0" style={{ width: "20%" }}>
                              {val?.tds_it_amount == 0
                                ? "-"
                                : formatNumber(
                                    val?.tds_it_amount / dividerNumber,
                                    "indian"
                                  )}
                            </div>
                            <div className="col p-0" style={{ width: "20%" }}>
                              {Number(val?.c_gst_amount) +
                                Number(val?.i_gst_amount) +
                                Number(val?.s_gst_amount) ==
                              0
                                ? "-"
                                : formatNumber(
                                    (Number(val?.c_gst_amount) +
                                      Number(val?.i_gst_amount) +
                                      Number(val?.s_gst_amount)) /
                                      dividerNumber,
                                    "indian"
                                  )}
                            </div>
                            <div className="col p-0" style={{ width: "20%" }}>
                              {val?.royalty_amount == 0
                                ? "-"
                                : formatNumber(
                                    val?.royalty_amount / dividerNumber,
                                    "indian"
                                  )}
                            </div>
                            <div className="col p-0" style={{ width: "20%" }}>
                              {val?.labour_amount == 0
                                ? "-"
                                : formatNumber(
                                    val?.labour_amount / dividerNumber,
                                    "indian"
                                  )}
                            </div>
                            <div className="col p-0" style={{ width: "20%" }}>
                              {val?.other_deduction == null
                                ? "-"
                                : val?.other_deduction == 0
                                ? "-"
                                : formatNumber(
                                    val?.other_deduction / dividerNumber,
                                    "indian"
                                  )}
                            </div>
                            <div
                              className="col p-0 text-success-100"
                              style={{ width: "20%" }}
                            >
                              {Number(val?.tds_it_amount) +
                                Number(val?.c_gst_amount) +
                                Number(val?.i_gst_amount) +
                                Number(val?.s_gst_amount) +
                                Number(val?.royalty_amount) +
                                Number(val?.labour_amount) +
                                Number(
                                  val?.other_deduction == null
                                    ? "0"
                                    : val?.other_deduction
                                ) ==
                              0
                                ? "-"
                                : formatNumber(
                                    (Number(val?.tds_it_amount) +
                                      Number(val?.c_gst_amount) +
                                      Number(val?.i_gst_amount) +
                                      Number(val?.s_gst_amount) +
                                      Number(val?.royalty_amount) +
                                      Number(val?.labour_amount) +
                                      Number(
                                        val?.other_deduction == null
                                          ? "0"
                                          : val?.other_deduction
                                      )) /
                                      dividerNumber,
                                    "indian"
                                  )}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div
                        className="row fs-14px p-1 fw-500 overflow-hidden bg-info-20 m-auto"
                        style={{
                          height: "30px",
                          width: "99.9%",
                        }}
                      >
                        {/* <div className="row fs-14px p-1 fw-500" style={{background: "#F8F8F8"}}> */}
                        <div className="col p-0" style={{ width: "20%" }}>
                          Total
                        </div>
                        <div className="col p-0" style={{ width: "20%" }}></div>
                        <div className="col p-0" style={{ width: "20%" }}>
                          {totalTdsIts == 0
                            ? "-"
                            : formatNumber(
                                totalTdsIts / dividerNumber,
                                "indian"
                              )}
                        </div>
                        <div className="col p-0" style={{ width: "20%" }}>
                          {totalGst == 0
                            ? "-"
                            : formatNumber(totalGst / dividerNumber, "indian")}
                        </div>
                        <div className="col p-0" style={{ width: "20%" }}>
                          {totalRoyaltys == 0
                            ? "-"
                            : formatNumber(
                                totalRoyaltys / dividerNumber,
                                "indian"
                              )}
                        </div>
                        <div className="col p-0" style={{ width: "20%" }}>
                          {totalLabour == 0
                            ? "-"
                            : formatNumber(
                                totalLabour / dividerNumber,
                                "indian"
                              )}
                        </div>
                        <div className="col p-0" style={{ width: "20%" }}>
                          {totalOtherDeduction == 0
                            ? "-"
                            : formatNumber(
                                totalOtherDeduction / dividerNumber,
                                "indian"
                              )}
                        </div>
                        <div
                          className="col p-0 text-success-100"
                          style={{ width: "20%" }}
                        >
                          {totalOverallAccDeduction == 0
                            ? "-"
                            : formatNumber(
                                totalOverallAccDeduction / dividerNumber,
                                "indian"
                              )}
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>

              {/* ===================DBT accordion=================== */}

              <Accordion style={{ border: "0" }}>
                <Accordion.Item
                  eventKey="15"
                  style={{ borderRadius: "0", border: "0" }}
                >
                  <Accordion.Header
                    onClick={() => toggleAccordion("15")}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "relative",
                      borderRadius: "0",
                    }}
                  >
                    <div className="w-100 d-flex justify-content-between">
                      <span>DBT/NACH</span>
                      <span
                        className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                        style={{ height: "13.33px", width: "13.33px" }}
                      >
                        {activeKeys.includes("15") ? "-" : "+"}
                      </span>
                    </div>
                  </Accordion.Header>

                  <Accordion.Body>
                    <div>
                      {/* ==============================subhead data================================ */}

                      <div className="row fs-12px text-black-60 p-1 mt-1">
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Department Level/Name
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Order No./Date
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Scheme/Subhead Code
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Gross Amount
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Deduction
                        </div>
                        <div
                          className="col text-start p-0"
                          style={{ width: "20%" }}
                        >
                          Net Amount 
                        </div>
                      </div>
                    {
                    
                        accumulatedDeductionDbt?.map((val, ind)=>(
                          <div
                        className="row fs-14px p-1 fw-500 overflow-hidden"
                        style={{
                          height: openIndex !== ind ? "35px" : "fit-content",
                        }}
                        onClick={() =>
                                    getSingleBeneficiaryReimbursements(val?.id)
                                  }
                      >
                        <div
                          className="row fs-14px p-1 fw-500"
                          style={{ background: "#F8F8F8" }}
                        >
                          <div
                            className="col d-flex align-items-center"
                            style={{ widows: "20%" }}
                          >
     {openIndex === ind ? (
                <i
                  className="icon-keyboard_arrow_down cursor-pointer"
                  onClick={() => toggleAccordions(ind)}
                ></i>
              ) : (
                <i
                  className="icon-keyboard_arrow_right cursor-pointer"
                  onClick={() => toggleAccordions(ind)}
                ></i>
              )}
                            <span>{ addElipsis(val?.department, 13)}</span>
                          </div>

                          <div className="col" style={{ width: "20%" }}>
                            { addElipsis(val?.reimbursement_order_no + " | " + val?.reimbursement_order_date, 13)}
                          </div>
                          <div className="col" style={{ width: "20%" }}>
                            { addElipsis(val?.code + " | " +  val?.subhead_code , 13) }
                          </div>
                          <div className="col" style={{ width: "20%" }}>
                            {val?.reimbursement_order_value}
                          </div>
                          <div className="col" style={{ width: "20%" }}>
                            {val?.deduction_amount}
                          </div>
                          <div className="col" style={{ width: "20%" }}>
                            {Number(val?.reimbursement_order_value) - Number(val?.deduction_amount)}
                          </div>
                        </div>

                          <div className="mt-1 p-0 pe-3">
                          <table className="border-0">
                            <tr className="bg-prime-20 border-0">
                              <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                Name
                              </th>
                              <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                Mobile/Email
                              </th>
                              <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                IFSC
                              </th>
                              <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                Gross Amount
                              </th>
                              <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                Deduction
                              </th>
                              <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                Net Amount 
                              </th>
                            </tr>
                            {
                              singleBeneficiarys.map((val, ind)=>(
                                <tr>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                {val?.name}
                              </td>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                {val?.mobile} | {val?.email}
                              </td>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                {val?.ifsc}
                              </td>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                {val?.gross_amount}
                              </td>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                {val?.deduction_amount}
                              </td>
                              <td className="p-1 border-0 text-start fs-12px text-black-100">
                                {Number(val?.gross_amount) - Number(val?.deduction_amount)}
                              </td>
                            </tr>
                              ))
                            }
                          </table>
                        </div>
                      </div>
                        ))
                    }
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
          </div>
          {/* =============================settlement in progress card==================== */}
            <div
              className="bg-white border-Prime-100"
              style={{ borderRadius: "8px" }}
            >
              <div
                className="bg-prime-20 d-flex justify-content-between align-items-center py-2 transition overflow-hidden px-3"
                style={{ borderRadius: "8px 8px 0 0" }}
              >
                {/* ======================card header================== */}
                <span
                  className="fs-14px white-space-nowrap overflow-x-hidden"
                  style={{ width: "95%" }}
                >
                  {/* =======================card header====================== */}
                  <span
                    className="fs-14px white-space-nowrap overflow-x-hidden "
                    style={{ width: "95%" }}
                  >
                    <span className="fw-500 text-secondary-100">
                      Settlements In Progress
                    </span>
                  </span>
                </span>

                <span>
                  <i className="icon-filter_alt cursor-pointer p-2 transition bg-active-prime-100 rounded-2"></i>
                </span>
              </div>

              {/* ================== card body ========================= */}

              <div className="row py-2 px-3">
                <div className="row">
                  <div className="col-4 d-flex flex-column py-2">
                    <span className="text-black-60 fs-12px">With Held</span>
                    <span className="fw-500 fs-14px">2,000,000,000.00</span>
                  </div>
                  <div className="col-4 d-flex flex-column py-2">
                    <span className="text-black-60 fs-12px">Penalty</span>
                    <span className="fw-500 fs-14px">2,000,000,000.00</span>
                  </div>
                  <div className="col-4 d-flex flex-column bg-success-20 py-2">
                    <span className="text-black-60 fs-12px d-flex justify-content-between align-items-center">
                      Total
                      <span>
                        <i className="icon-info cursor-pointer"></i>
                      </span>
                    </span>
                    <span className="fw-500 fs-14px">2,000,000,000.00</span>
                  </div>
                </div>
              </div>
              <div className="px-3 pb-2 d-flex flex-column gap-1">
                <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                  <Accordion.Item
                    eventKey="17"
                    style={{ borderRadius: "0", border: "0" }}
                  >
                    <Accordion.Header
                      onClick={() => toggleAccordion("17")}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "relative",
                        borderRadius: "0",
                      }}
                    >
                      <div className="w-100 d-flex justify-content-between">
                        <span>Vendor</span>
                        <span
                          className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                          style={{ height: "13.33px", width: "13.33px" }}
                        >
                          {activeKeys.includes("17") ? "-" : "+"}
                        </span>
                      </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      <div>
                        {/* ==============================subhead data================================ */}

                        <div
                          className="row fs-12px text-black-60 p-1 mt-1 m-auto"
                          style={{ width: "99%" }}
                        >
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            vendor
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Invoice No/Date
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Sanction Order/Date
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Amount
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Transaction Date
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            UTR No./Transaction ID
                          </div>
                        </div>
                        <div
                          className="overflow-x-hidden overflow-y-auto"
                          style={{ maxHeight: "200px", height: "auto" }}
                        >
                          {settlementsInProgress?.map((val, ind) => (
                            <div
                              className="row fs-14px p-1 fw-500 overflow-hidden ps-3"
                              style={{
                                height: subheadAccording
                                  ? "35px"
                                  : "fit-content",
                              }}
                            >
                              {/* <div className="row fs-14px p-1 fw-500" style={{background: "#F8F8F8"}}> */}
                              <div className="col p-0" style={{ width: "20%" }}>
                                {val?.company_name}
                              </div>
                              <div className="col p-0" style={{ width: "20%" }}>
                                {addElipsis(
                                  val?.invoice_no + " | " + val?.invoice_date,
                                  15
                                )}
                              </div>
                              <div className="col p-0" style={{ width: "20%" }}>
                                {addElipsis(
                                  val?.sanction_order_no + " | " + val?.sanction_order_date,
                                  15
                                )}
                              </div>
                              <div className="col p-0" style={{ width: "20%" }}>
                                -
                              </div>
                              <div className="col p-0" style={{ width: "20%" }}>
                                -
                              </div>
                              <div className="col p-0" style={{ width: "20%" }}>
                                -
                              </div>
                            </div>
                          ))}
                        </div>

                        <div
                          className="row fs-14px p-1 fw-500 overflow-hidden bg-info-20 m-auto"
                          style={{
                            height: "30px",
                            width: "99.99%",
                          }}
                        >
                          {/* <div className="row fs-14px p-1 fw-500" style={{background: "#F8F8F8"}}> */}
                          <div className="col p-0" style={{ width: "20%" }}>
                            Total
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div
                            className="col p-0 text-success-100"
                            style={{ width: "20%" }}
                          >
                            10000
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                {/* ===================DBT accordion=================== */}

                {/* <Accordion style={{ border: "0" }}>
                  <Accordion.Item
                    eventKey="16"
                    style={{ borderRadius: "0", border: "0" }}
                  >
                    <Accordion.Header
                      onClick={() => toggleAccordion("16")}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "relative",
                        borderRadius: "0",
                      }}
                    >
                      <div className="w-100 d-flex justify-content-between">
                        <span>DBT/NACH</span>
                        <span
                          className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                          style={{ height: "13.33px", width: "13.33px" }}
                        >
                          {activeKeys.includes("16") ? "-" : "+"}
                        </span>
                      </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      <div>

                        <div className="row fs-12px text-black-60 p-1 mt-1">
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Department Level/Name
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Scheme/Subhead Code
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Order No./Date
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Amount
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Transaction Date
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            UTL No./Transaction ID
                          </div>
                        </div>

                        <div
                          className="row fs-14px p-1 fw-500 overflow-hidden"
                          style={{
                            height: subheadAccording ? "35px" : "fit-content",
                          }}
                        >
                          <div
                            className="row fs-14px p-1 fw-500"
                            style={{ background: "#F8F8F8" }}
                          >
                            <div
                              className="col d-flex align-items-center"
                              style={{ widows: "20%" }}
                            >
                              {subheadAccording ? (
                                <i
                                  className="icon-keyboard_arrow_right cursor-pointer"
                                  onClick={() =>
                                    setSubheadAccording((prev) => !prev)
                                  }
                                ></i>
                              ) : (
                                <i
                                  className="icon-keyboard_arrow_down cursor-pointer"
                                  onClick={() =>
                                    setSubheadAccording((prev) => !prev)
                                  }
                                ></i>
                              )}
                              <span>radhe krishna</span>
                            </div>

                            <div className="col" style={{ width: "20%" }}>
                              25412
                            </div>
                            <div className="col" style={{ width: "20%" }}>
                              25412 | 10/08/2025
                            </div>
                            <div className="col" style={{ width: "20%" }}>
                              2,000,000,000.00
                            </div>
                            <div className="col" style={{ width: "20%" }}>
                              2,000,000,000.00
                            </div>
                            <div className="col" style={{ width: "20%" }}>
                              2,000,000,000.00
                            </div>
                          </div>

                          <div className="mt-1 p-0 pe-3">
                            <table className="border-0">
                              <tr className="bg-prime-20 border-0">
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  Name
                                </th>
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  Mobile/Email
                                </th>
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  Action/IFSC
                                </th>
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  Gross Amount
                                </th>
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  Deduction
                                </th>
                                <th className="p-1 border-0 text-start fs-12px text-black-60 fw-500">
                                  Net Amount 
                                </th>
                              </tr>
                              <tr>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  radha krishna
                                </td>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  123456780
                                </td>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  123456780
                                </td>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  123456780
                                </td>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  123456780
                                </td>
                                <td className="p-1 border-0 text-start fs-12px text-black-100">
                                  123456780
                                </td>
                              </tr>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion> */}
              </div>
            </div>


          {/* =============================with held and penalty card==================== */}
            <div
              className="bg-white border-Prime-100"
              style={{ borderRadius: "8px" }}
            >
              <div
                className="bg-prime-20 d-flex justify-content-between align-items-center py-2 transition overflow-hidden px-3"
                style={{ borderRadius: "8px 8px 0 0" }}
              >
                {/* ======================card header================== */}
                <span
                  className="fs-14px white-space-nowrap overflow-x-hidden"
                  style={{ width: "95%" }}
                >
                  {/* =======================card header====================== */}
                  <span
                    className="fs-14px white-space-nowrap overflow-x-hidden "
                    style={{ width: "95%" }}
                  >
                    <span className="fw-500 text-secondary-100">
                      With held and penalty
                    </span>
                  </span>
                </span>

                <span>
                  <i className="icon-filter_alt cursor-pointer p-2 transition bg-active-prime-100 rounded-2"></i>
                </span>
              </div>

              {/* ================== card body ========================= */}

              <div className="row py-2 px-3">
                <div className="row">
                  <div className="col-4 d-flex flex-column py-2">
                    <span className="text-black-60 fs-12px">With Held</span>
                    <span className="fw-500 fs-14px">
                      {totalWithheld == 0
                        ? "-"
                        : formatNumber(totalWithheld / dividerNumber, "indian")}
                    </span>
                  </div>
                  <div className="col-4 d-flex flex-column py-2">
                    <span className="text-black-60 fs-12px">Penalty</span>
                    <span className="fw-500 fs-14px">
                      {totalPenalty == 0
                        ? "-"
                        : formatNumber(totalPenalty / dividerNumber, "indian")}
                    </span>
                  </div>
                  <div className="col-4 d-flex flex-column bg-success-20 py-2">
                    <span className="text-black-60 fs-12px d-flex justify-content-between align-items-center">
                      Total
                      <span>
                        <i className="icon-info cursor-pointer"></i>
                      </span>
                    </span>
                    <span className="fw-500 fs-14px">
                      {totalOverall == 0
                        ? "-"
                        : formatNumber(totalOverall / dividerNumber, "india")}
                    </span>
                  </div>
                </div>
              </div>

              <div className="px-3 pb-2 d-flex flex-column gap-1">
                <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                  <Accordion.Item
                    eventKey="17"
                    style={{ borderRadius: "0", border: "0" }}
                  >
                    <Accordion.Header
                      onClick={() => toggleAccordion("17")}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "relative",
                        borderRadius: "0",
                      }}
                    >
                      <div className="w-100 d-flex justify-content-between">
                        <span>Vendor </span>
                        <span
                          className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                          style={{ height: "13.33px", width: "13.33px" }}
                        >
                          {activeKeys.includes("17") ? "-" : "+"}
                        </span>
                      </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      <div>
                        {/* ==============================subhead data================================ */}

                        <div
                          className="row fs-12px text-black-60 p-1 mt-1 m-auto"
                          style={{ maxHeight: "200px", height: "auto" }}
                        >
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            vendor
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Invoice No/Date
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            With held
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Penalty
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Total
                          </div>
                        </div>
                        <div
                          className="overflow-x-hidden overflow-y-auto"
                          style={{ maxHeight: "200px", height: "auto" }}
                        >
                          {withHeldAndPenalty?.map((val, ind) => (
                            <div
                              className="row ps-3 fs-14px p-1 fw-500 overflow-hidden"
                              style={{
                                height: "30px",
                              }}
                            >
                              {/* <div className="row fs-14px p-1 fw-500" style={{background: "#F8F8F8"}}> */}
                              <div className="col p-0" style={{ width: "20%" }}>
                                {val?.company_name}
                              </div>
                              <div className="col p-0" style={{ width: "20%" }}>
                                {addElipsis(
                                  val?.invoice_no + " | " + val?.invoice_date,
                                  15
                                )}
                              </div>
                              <div className="col p-0" style={{ width: "20%" }}>
                                {val?.withheld_amount == 0
                                  ? "-"
                                  : formatNumber(
                                      val?.withheld_amount / dividerNumber,
                                      "indian"
                                    )}
                              </div>
                              <div className="col p-0" style={{ width: "20%" }}>
                                {val?.penalty_amount == 0
                                  ? "-"
                                  : formatNumber(
                                      val?.penalty_amount / dividerNumber,
                                      "indian"
                                    )}
                              </div>
                              <div
                                className="col p-0 text-success-100"
                                style={{ width: "20%" }}
                              >
                                {Number(val?.withheld_amount) +
                                  Number(val?.penalty_amount) ==
                                0
                                  ? "-"
                                  : formatNumber(
                                      (Number(val?.withheld_amount) +
                                        Number(val?.penalty_amount)) /
                                        dividerNumber,
                                      "indian"
                                    )}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div
                          className="row fs-14px p-1 fw-500 overflow-hidden bg-info-20 m-auto"
                          style={{
                            height: "30px",
                            width: "99.99%",
                          }}
                        >
                          {/* <div className="row fs-14px p-1 fw-500" style={{background: "#F8F8F8"}}> */}
                          <div className="col p-0" style={{ width: "20%" }}>
                            Total
                          </div>
                          <div
                            className="col p-0"
                            style={{ width: "20%" }}
                          ></div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            {totalWithheld == 0
                              ? "-"
                              : formatNumber(
                                  totalWithheld / dividerNumber,
                                  "indian"
                                )}
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            {totalPenalty == 0
                              ? "-"
                              : formatNumber(
                                  totalPenalty / dividerNumber,
                                  "indian"
                                )}
                          </div>
                          <div
                            className="col p-0 text-success-100"
                            style={{ width: "20%" }}
                          >
                            {totalOverall == 0
                              ? "-"
                              : formatNumber(
                                  totalOverall / dividerNumber,
                                  "indian"
                                )}
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
       

          {/* =============================with held interest card==================== */}

          {/* <div
              className="bg-white border-Prime-100"
              style={{ borderRadius: "8px" }}
            >
              <div
                className="bg-prime-20 d-flex justify-content-between align-items-center py-2 transition overflow-hidden px-3"
                style={{ borderRadius: "8px 8px 0 0" }}
              >
                <span
                  className="fs-14px white-space-nowrap overflow-x-hidden"
                  style={{ width: "95%" }}
                >

                  <span
                    className="fs-14px white-space-nowrap overflow-x-hidden "
                    style={{ width: "95%" }}
                  >
                    <span className="fw-500 text-secondary-100">
                      With held interest
                    </span>
                  </span>
                </span>

                <span>
                  <i className="icon-filter_alt cursor-pointer p-2 transition bg-active-prime-100 rounded-2"></i>
                </span>
              </div>


              <div className="row py-2 px-3">
                <div className="row">
                  <div className="col-4 d-flex flex-column py-2">
                    <span className="text-black-60 fs-12px">Interest Earn</span>
                    <span className="fw-500 fs-14px">2,000,000,000.00</span>
                  </div>
                  <div className="col-4 d-flex flex-column py-2">
                    <span className="text-black-60 fs-12px">
                      Interest Deposit
                    </span>
                    <span className="fw-500 fs-14px">2,000,000,000.00</span>
                  </div>
                  <div className="col-4 d-flex flex-column bg-success-20 py-2">
                    <span className="text-black-60 fs-12px d-flex justify-content-between align-items-center">Total 
                    <span>
                         <i className="icon-info cursor-pointer"></i>
                        </span>
                    </span>
                    <span className="fw-500 fs-14px">2,000,000,000.00</span>
                  </div>
                </div>
              </div>
              <div className="px-3 pb-2 d-flex flex-column gap-1">
                <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                  <Accordion.Item
                    eventKey="20"
                    style={{ borderRadius: "0", border: "0" }}
                  >
                    <Accordion.Header
                      onClick={() => toggleAccordion("20")}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "relative",
                        borderRadius: "0",
                      }}
                    >
                      <div className="w-100 d-flex justify-content-between">
                        <span>Interest earn </span>
                        <span
                          className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                          style={{ height: "13.33px", width: "13.33px" }}
                        >
                          {activeKeys.includes("20") ? "-" : "+"}
                        </span>
                      </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      <div>

                        <div className="row fs-12px text-black-60 p-1 mt-1">
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Account
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Account Number
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            IFSC Code
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Date
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Quarter
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Amount
                          </div>
                        </div>

                        <div
                          className="row fs-14px p-1 fw-500 overflow-hidden"
                          style={{
                            height: subheadAccording ? "35px" : "fit-content",
                          }}
                        >
                          <div className="col p-0" style={{ width: "20%" }}>
                            radhe krishna
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div
                            className="col p-0 text-success-100"
                            style={{ width: "20%" }}
                          >
                            10000
                          </div>
                        </div>

                        <div
                          className="row fs-14px p-1 fw-500 overflow-hidden bg-info-20"
                          style={{
                            height: subheadAccording ? "35px" : "fit-content",
                          }}
                        >
                          <div className="col p-0" style={{ width: "20%" }}>
                            Total
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div
                            className="col p-0 text-success-100"
                            style={{ width: "20%" }}
                          >
                            10000
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion activeKey={activeKeys} style={{ border: "0" }}>
                  <Accordion.Item
                    eventKey="21"
                    style={{ borderRadius: "0", border: "0" }}
                  >
                    <Accordion.Header
                      onClick={() => toggleAccordion("21")}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        position: "relative",
                        borderRadius: "0",
                      }}
                    >
                      <div className="w-100 d-flex justify-content-between">
                        <span>Interest deposit </span>
                        <span
                          className="d-flex justify-content-center align-items-center bg-success-100 text-white rounded-circle"
                          style={{ height: "13.33px", width: "13.33px" }}
                        >
                          {activeKeys.includes("21") ? "-" : "+"}
                        </span>
                      </div>
                    </Accordion.Header>

                    <Accordion.Body>
                      <div>

                        <div className="row fs-12px text-black-60 p-1 mt-1">
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Challan No.
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Receipt head
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Date
                          </div>
                          <div
                            className="col text-start p-0"
                            style={{ width: "20%" }}
                          >
                            Amount
                          </div>
                        </div>

                        <div
                          className="row fs-14px p-1 fw-500 overflow-hidden"
                          style={{
                            height: subheadAccording ? "35px" : "fit-content",
                          }}
                        >
                 
                          <div className="col p-0" style={{ width: "20%" }}>
                            radhe krishna
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div
                            className="col p-0 text-success-100"
                            style={{ width: "20%" }}
                          >
                            10000
                          </div>
                        </div>

                        <div
                          className="row fs-14px p-1 fw-500 overflow-hidden bg-info-20"
                          style={{
                            height: subheadAccording ? "35px" : "fit-content",
                          }}
                        >
                          <div className="col p-0" style={{ width: "20%" }}>
                            Total
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div className="col p-0" style={{ width: "20%" }}>
                            10000
                          </div>
                          <div
                            className="col p-0 text-success-100"
                            style={{ width: "20%" }}
                          >
                            10000
                          </div>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
          </div>
        */}
        </div>
      </div>
    </>
  );
};

export default DashboardBox;
