import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';

import { Chart } from 'react-chartjs-2';
import { get_schemes } from '../../helper/Api';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Scheme Bar and Line Graph',
    },
    legend: {
      display: true,
      position: 'top',
    },
  },
  responsive: true,
  maintainAspectRatio: false, // Ensures the graph height adjusts with the container
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: false,
      title: {
        display: true,
        text: 'Schemes',
      },
    },
    y: {
      stacked: false,
      title: {
        display: true,
        text: 'Budget (in units)',
      },
    },
  },
};

export default function BarLineGraph() {
  const [schemes, setSchemes] = useState([]);

  useEffect(() => {
    fetch_schemedata();
  }, []);

  const fetch_schemedata = async () => {
    try {
      const { data } = await get_schemes();
      if (data.status) {
        const updated_schemes = data.schemes.filter((scheme) => {
          return scheme.l2_status === '1' && scheme.l3_status === '1';
        });
        setSchemes(updated_schemes);
      } else {
        setSchemes([]);
      }
    } catch (error) {
      console.error(error);
      setSchemes([]);
    }
  };

  let labels = [];
  let budget = [];
  let utilised_budget = [];
  schemes.forEach((scheme) => {
    if (scheme.name.length > 20) {
      labels.push(scheme.name.substr(0, 20) + '...');
    } else {
      labels.push(scheme.name);
    }
    budget.push(parseInt(scheme.total_budget, 10));
    utilised_budget.push(parseInt(scheme.utilised_budget, 10));
  });

  const data = {
    labels,
    datasets: [
      {
        type: 'line',
        label: 'Scheme Budget',
        data: budget,
        borderColor: '#58CEA7',
        backgroundColor: 'transparent',
        borderWidth: 2,
        tension: 0.4,
        fill: false, // Ensures no fill under the line
      },
      {
        type: 'bar',
        label: 'Utilized Budget',
        data: utilised_budget,
        backgroundColor: '#EC8D87',
        barThickness: 20,
      },
    ],
  };

  return (
    <div style={{ height: '350px', width: '100%' }}>
      <Chart type="bar" options={options} data={data} />
    </div>
  );
}
