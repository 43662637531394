import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ManageDepartment from '../../../components/Multi/Multi-Department/ManageDepartment'
import Tools from "../../../components/Tools/Tools"
import ManageDisbursmentHeader from '../../../components/Disbursment/ManageDisbursmentHeader'
const Departments = () => {
  const {authData}=useSelector((state)=>state.authData);
  const [searchStr, setToSearchStr] = useState('');
  const [ti, setToTier] = useState('');


  const handleDateChange = (start, end, dateColumn, searchStr, fy_id, tier) => {
    setToSearchStr(searchStr);
    setToTier(tier)
};

  return (
    <div>
      <ManageDisbursmentHeader type="Department" onDateChange={handleDateChange} />
      <div className='scheme p-2'>
        <ManageDepartment searchStr={searchStr} tier={ti}></ManageDepartment>
      </div>
    </div>
  )
}

export default Departments