import React, { useState } from 'react'
import './AddUser.css';
import finger_print_img from '../../assets/images/finger_print.png';
import { Alert, Form, Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_USER_START, UPDATE_USER_SUCCESS, UPDATE_USER_FAIL } from '../../RTK/Slices/UserSlice';
import { update_user_by_id, get_user_by_id, get_my_departments, delete_user_role, getUserFullDetailsById } from '../../helper/Api';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { DeleteForever } from '@mui/icons-material';
import { Link } from 'react-router-dom';
const ViewUser = () => {
    const [showModal, setShowModal] = useState(false);
    const { id } = useParams();
    const { authData } = useSelector((state) => state.authData);
    const { loading } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [roleToDelete, setRoleToDelete] = useState(null);
    const [formData, setFormData] = useState({
        code: null,
        user_name: null,
        designation: null,
        user_type: null,
        mobile: null,
        email: null,
        password: null,
        added_by: authData.user.id,
        departments: [],
    });
    const [departments, setDepartments] = useState([]);

    const [error, setError] = useState({
        status: false,
        msg: ''
    });

    useEffect(() => {
        get_user_details();
        // fetchDepartments();
    }, [id])

    const get_user_details = async () => {
        try {
            const { data } = await getUserFullDetailsById(id);
            const user = data.data;
            setFormData({
                code: user.code,
                user_name: user.user_name,
                designation: user.designation,
                user_type: user.user_type,
                mobile: user.mobile,
                email: user.email,
                password: '',
                added_by: user.added_by,
                departments: user.roles
            })
        } catch (error) {
            // console.log(error);
        }
    }
    // const fetchDepartments = async () => {
    //     try {
    //         const departments = await get_my_departments();
    //         if (departments.data.status) {
    //             setDepartments(departments.data.departments);
    //         }
    //     } catch (error) {
    //         console.log("departments", error);
    //     }
    // }
    const handleFormDataChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setFormData({ ...formData, [name]: value })
    }

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     if (!validateForm()) {
    //         alert('Please fill in all fields correctly.');
    //         return;
    //     }
    //     try {
    //         dispatch(UPDATE_USER_START());
    //         const { data } = await update_user_by_id(formData, id);

    //         if (data.status) {
    //             dispatch(UPDATE_USER_SUCCESS(data.users[0]));
    //             toast.success(data.message, {
    //                 position: toast.POSITION.TOP_CENTER
    //             });
    //             navigate('/users');
    //         } else {
    //             toast.error(data.message, {
    //                 position: toast.POSITION.TOP_CENTER
    //             });
    //             dispatch(UPDATE_USER_FAIL(data.message));
    //         }
    //     } catch (error) {
    //         // console.log(error);
    //         toast.error("error getting while update user", {
    //             position: toast.POSITION.TOP_CENTER
    //         });
    //         dispatch(UPDATE_USER_FAIL('error getting while update user'));
    //     }
    // }

    // const validateForm = () => {
    //     // Validate each field individually
    //     if (!formData.code.trim() ||
    //         !formData.user_name.trim() ||
    //         !formData.designation.trim() ||
    //         !formData.user_type.trim() ||
    //         !formData.mobile.trim() ||
    //         !formData.email.trim() ||
    //         !formData.password.trim()) {
    //         return false; // If any field is empty, return false
    //     }
    //     // Validate email format
    //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     if (!emailRegex.test(formData.email)) {
    //         return false; // If email format is invalid, return false
    //     }
    //     // Validate password format
    //     const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    //     if (!passwordRegex.test(formData.password)) {
    //         // setPasswordError('Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character.');
    //         return false;
    //     }
    //     // Add more validation logic as needed
    //     return true; // All fields are valid
    // }
    const deleteDepartment = async () => {
        try {
            const data=await delete_user_role({ role_id: roleToDelete });
            toast.success(data.message, {
                position: toast.POSITION.TOP_CENTER
            });
            setFormData((prevData) => {
                const updatedDepartments = prevData.departments.filter(department => department.id !== roleToDelete);
                return {
                    ...prevData,
                    departments: updatedDepartments,
                };
            });
            setShowModal(false);
            setRoleToDelete(null);
        }
        catch (error) {
            toast.error("error getting while delete role", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    };
    const handleDeleteClick = (roleId) => {
        setShowModal(true);
        setRoleToDelete(roleId);
    };
    const handleCancelDelete = () => {
        setShowModal(false);
        setRoleToDelete(null);
    };
    // console.log(formData);
    return (
        <div>
            <div className="p-3">
                <div className="row">
                    {error.status && (
                        <Alert variant="danger" onClose={() => setError({ status: false, msg: '' })} dismissible>
                            {error.msg}
                        </Alert>
                    )}

                    <Form>
                        <div className="col-md-12">
                            <div className="add_new_user">
                                <p>View User</p>
                                <div>
                                    <button type="button" className="btn btn-primary">
                                        <Link to="/add-user" style={{ textDecoration: 'none', color: 'inherit' }}>Add User Role</Link>
                                    </button>
                                </div>
                            </div>
                            <div className="card p-3 mt-3">
                                <div className="row">
                                    <div className="col-md-12 ">
                                        <div className="form-group row p-2">
                                            <label for="inputEmployee" className="col-sm-4 col-form-label">Employee
                                                Code<span className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control" id="inputEmployee"
                                                    name="code"
                                                    maxLength={10}
                                                    value={formData.code}
                                                    onChange={handleFormDataChange}
                                                    required
                                                    disabled={true}
                                                    placeholder="Enter Employee Code"
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group row p-2">
                                            <label for="inputUserid" className="col-sm-4 col-form-label">User Name<span
                                                className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputUserid"
                                                    name="user_name"
                                                    maxLength={30}
                                                    required
                                                    disabled={true}
                                                    value={formData.user_name}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter User Name" />
                                            </div>
                                        </div>

                                        <div className="form-group row p-2">
                                            <label for="inputDesignation"
                                                className="col-sm-4 col-form-label">Designation<span
                                                    className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputDesignation"
                                                    maxLength={30}
                                                    name="designation"
                                                    value={formData.designation}
                                                    disabled={true}
                                                    onChange={handleFormDataChange}
                                                    required
                                                    placeholder="Enter Designation" />
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputMobile" className="col-sm-4 col-form-label">Mobile
                                                Number<span
                                                    className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="text" className="form-control"
                                                    id="inputMobile"
                                                    inputMode="numeric"
                                                    pattern="[6789][0-9]{9}"
                                                    name="mobile"
                                                    required
                                                    value={formData.mobile}
                                                    disabled={true}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter Mobile Number" />
                                            </div>
                                        </div>
                                        <div className="form-group row p-2">
                                            <label for="inputEmail" className="col-sm-4 col-form-label">Email ID<span
                                                className="text-danger">*</span> :</label>
                                            <div className="col-sm-8">
                                                <input type="email" className="form-control"
                                                    id="inputEmail"
                                                    name="email"
                                                    required
                                                    value={formData.email}
                                                    disabled={true}
                                                    onChange={handleFormDataChange}
                                                    placeholder="Enter Email ID" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Department Table */}
                        <div className="card p-3">
                            <p style={{ textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>Department User Roles</p>
                            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                                <thead style={{ backgroundColor: '#1976d2' }}>
                                    <tr>
                                        <th style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Code</th>
                                        <th style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Name</th>
                                        <th style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Department Level</th>
                                        <th style={{ textAlign: 'center', color: 'white', padding: '8px' }}>User Role</th>
                                        <th style={{ textAlign: 'center', color: 'white', padding: '8px' }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {formData.departments.map((dept) => (
                                        <tr key={dept.id}>
                                            <td style={{ textAlign: 'center', padding: '8px' }}>{dept.code}</td>
                                            <td style={{ textAlign: 'center', padding: '8px' }}>{dept.name}</td>
                                            <td style={{ textAlign: 'center', padding: '8px' }}>{dept.department_level}</td>
                                            <td style={{ textAlign: 'center', padding: '8px' }}>{dept.user_type}</td>
                                            <td style={{ textAlign: 'center', padding: '8px' }}>
                                                {/* {
                                                    authData.user.user_type === 'l3' ?
                                                    authData.user.department_id !== '1' ?
                                                    <></>
                                                    : */}
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger mx-2"
                                                        onClick={(e) => handleDeleteClick(dept.id)}
                                                    ><DeleteForever /></button>
                                                {/* //     : ''
                                                // } */}
                                                
                                                    
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Form>
                </div>
                <Modal
                    show={showModal}
                    onHide={handleCancelDelete}
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Are you sure you want to delete this role?</p>
                        <p><strong>Role ID:</strong> {roleToDelete}</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={(e) => handleCancelDelete()}>
                            Cancel
                        </Button>
                        <Button variant="danger" onClick={(e) => deleteDepartment()}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </div>
    )
}
export default ViewUser
