import Accordion from "react-bootstrap/Accordion";

export function UtilizedBudgetOverlayActivity({
    fy_list,
    setSubheadAccording,
    subheadAccording,
    storedGlobalDateId,
    activities
}) {

    // let modulesArr = ['child', 'dbt', 'disbursement'];
    // let activityInterface = []
    // const mapData = (obj, key, prev = false) => {
    //     obj.map(ele => {
    //             let index = activityInterface.findIndex(e => e.activityId === ele.activity_id);
    //             if(index !== -1) {
    //                 let local = activityInterface[index];
    //                 activityInterface[index] = {
    //                     activityId: local.activityId,
    //                     name: local.name,
    //                     vendor: key === 'disbursement' ? Number(ele.amount) + local.vendor : local.vendor,
    //                     dbt: key === 'dbt' ? Number(ele.amount) + local.dbt : local.dbt,
    //                     // salary: ele.salary,
    //                     budget_allocation: key === 'child' ? Number(ele.amount) + local.budget_allocation : local.budget_allocation,
    //                     total: Number(ele.amount) + local.total,
    //                     prev: {
    //                         vendor: key === 'disbursement' && prev ? local.prev.vendor + Number(ele.amount) : local.prev.vendor,
    //                         dbt: key === 'dbt' && prev ? local.prev.dbt + Number(ele.amount) : local.prev.dbt,
    //                         // salary: ele.salary,
    //                         budget_allocation: key === 'child' && prev ? local.prev.budget_allocation + Number(ele.amount) : local.prev.budget_allocation,
    //                         total: prev === true ? Number(ele.amount) + local.prev.total : local.prev.total,
    //                     },
    //                     current: {
    //                         vendor: key === 'disbursement' && !prev ? Number(ele.amount) + local.current.vendor : local.current.vendor,
    //                         dbt: key === 'dbt' && !prev ? Number(ele.amount) + local.current.dbt : local.current.dbt,
    //                         // salary: ele.salary,
    //                         budget_allocation: key === 'child' && !prev ? Number(ele.amount) + local.current.budget_allocation : local.current.budget_allocation,
    //                         total: !prev ? Number(ele.amount) + local.current.total : local.current.total,
    //                     },
    //                     finalTotal: {
    //                         vendor: key === 'disbursement' ? Number(ele.amount) + local.finalTotal.vendor : local.finalTotal.vendor,
    //                         dbt: key === 'dbt' ? Number(ele.amount) + local.finalTotal.dbt : local.finalTotal.dbt,
    //                         // salary: ele.salary,
    //                         budget_allocation: key === 'child' ? Number(ele.amount) + local.finalTotal.budget_allocation : local.finalTotal.budget_allocation,
    //                         total: Number(ele.amount) + local.finalTotal.total,
    //                     }
    //                 };
    //             } else {
    //                 activityInterface.push({
    //                     activityId: ele.activity_id,
    //                     name: ele.activity,
    //                     vendor: key === 'disbursement' ? Number(ele.amount) : 0,
    //                     dbt: key === 'dbt' ? Number(ele.amount) : 0,
    //                     // salary: ele.salary,
    //                     budget_allocation: key === 'child' ? Number(ele.amount) : 0,
    //                     total: Number(ele.amount),
    //                     prev: {
    //                         vendor: key === 'disbursement' && prev ? Number(ele.amount) : 0,
    //                         dbt: key === 'dbt' && prev ? Number(ele.amount) : 0,
    //                         // salary: ele.salary,
    //                         budget_allocation: key === 'child' && prev ? Number(ele.amount) : 0,
    //                         total: Number(ele.amount),
    //                     },
    //                     current: {
    //                         vendor: key === 'disbursement' && !prev ? Number(ele.amount) : 0,
    //                         dbt: key === 'dbt' && !prev ? Number(ele.amount) : 0,
    //                         // salary: ele.salary,
    //                         budget_allocation: key === 'child' && !prev ? Number(ele.amount) : 0,
    //                         total: Number(ele.amount),
    //                     },
    //                     finalTotal: {
    //                         vendor: key === 'disbursement' ? Number(ele.amount) : 0,
    //                         dbt: key === 'dbt' ? Number(ele.amount) : 0,
    //                         // salary: ele.salary,
    //                         budget_allocation: key === 'child' ? Number(ele.amount) : 0,
    //                         total: Number(ele.amount),
    //                     }
    //                 })
    //             }
    //     })
    // }
    // const mapModules = (current_fy, prev) => {
    //     modulesArr.forEach(e => {
    //         if(current_fy[e].length) {
    //             mapData(current_fy[e], e, prev);
    //         }
    //     })
    // };
    
    // mapModules(activities?.current_fy, false);
    // mapModules(activities?.from_prev_fy, true);

    let activityInterface = [
        {
            activityId:1,
            activityName: "Activity 1",
            Fy: {
                vendor: 43545,
                dbt: 452354,
                budget_allocation: 1354
            },
            previousYearBalance: {
                vendor: 34,
                dbt: 45343,
                budget_allocation: 3534,
            },
        },
        {
            activityId:2,
            activityName: "Activity 1",
            Fy: {
                vendor: 43545,
                dbt: 452354,
                budget_allocation: 1354
            },
            previousYearBalance: {
                vendor: 34,
                dbt: 45343,
                budget_allocation: 3534,
            },
        }
    ]

    return (
        <Accordion.Body className="p-2">
            <div className="row fs-12px text-black-60 p-1">
                <div className="col-2">Activity</div>
                <div className="col-2">Vendor</div>
                <div className="col-2">DBT</div>
                {/* <div className="col-2">Salary</div> */}
                <div className="col-2">Budget Allocation</div>
                <div className="col-2">Total</div>
            </div>
            {
                activityInterface?.map((ele, i) => {
                    return (
                        <>
                            <div
                                className="row fs-14px p-1 fw-500 overflow-hidden"
                                style={{
                                background: "#F8F8F8",
                                height: subheadAccording ? "35px" : "fit-content",
                                }}
                            >
                                <div className="col-2 d-flex align-items-center">
                                {subheadAccording ? (
                                    <i
                                    className="icon-keyboard_arrow_right cursor-pointer"
                                    onClick={() =>
                                        setSubheadAccording(subheadAccording || false)
                                    }
                                    ></i>
                                ) : (
                                    <i
                                    className="icon-keyboard_arrow_down cursor-pointer"
                                    onClick={() =>
                                        setSubheadAccording(subheadAccording || false)
                                    }
                                    ></i>
                                )}
                                <span>{ele?.activityName}</span>
                                </div>
                                <div className="col-2">{ele?.vendor}</div>
                                <div className="col-2">{ele?.dbt}</div>
                                {/* <div className="col-2">2,000,000,000.00</div> */}
                                <div className="col-2">{ele?.budget_allocation}</div>
                                <div className="col-2 text-success-100">{ele.total}</div>
                                <div className="mt-1">
                                    <div className="row fs-14px p-1 fw-500">
                                    <div className="col-2 text-prime-100 fs-12px">FY {fy_list[storedGlobalDateId]?.year}
                                    </div>
                                    <div className="col-2">{ele?.Fy?.vendor}</div>
                                    <div className="col-2">{ele?.Fy?.dbt}</div>
                                    {/* <div className="col-2">2,000,000,000.00</div> */}
                                    <div className="col-2">{ele?.Fy?.budget_allocation}</div>
                                    <div className="col-2 text-success-100">{ele?.current?.total}</div>
                                </div>
                                <div className="row fs-14px p-1 fw-500">
                                    <div className="col-2 text-prime-100 fs-12px">
                                    Previous Year Balance
                                    </div>
                                    <div className="col-2">{ele?.previousYearBalance?.vendor}</div>
                                    <div className="col-2">{ele?.previousYearBalance?.dbt}</div>
                                    {/* <div className="col-2">2,000,000,000.00</div> */}
                                    <div className="col-2">{ele.previousYearBalance.budget_allocation}</div>
                                    <div className="col-2 text-success-100">{ele?.previousYearBalance?.total}</div>
                                </div>
                                </div>
                            </div>
                            {
                                activityInterface.length - 1 === i && <div className="row fs-14px p-1 fw-500 bg-info-20">
                                <div className="col-2 text-prime-100 fs-12px">
                                Total
                                </div>
                                <div className="col-2">{ele?.finalTotal?.vendor}</div>
                                    <div className="col-2">{ele?.finalTotal?.dbt}</div>
                                    {/* <div className="col-2">2,000,000,000.00</div> */}
                                    <div className="col-2">{ele?.finalTotal?.budget_allocation}</div>
                                    <div className="col-2 text-success-100">{ele?.finalTotal?.total}</div>
                            </div>
                            }
                            
                        </>
                    )
                })
            }
            
        </Accordion.Body>
    )

}